import React from 'react';
import {
    IModelServiceApplicationElement,
    IModelServiceApplicationPackage,
    IModelServiceApplicationPackageElement,
    Translation
} from 'educat-common-web';
import ApplicationsTableRow from '../ApplicationsTableRow';
import {withTranslation, WithTranslation} from 'react-i18next';

interface IApplicationsTableConnectedProps {
}

interface IApplicationsTableExternalProps extends WithTranslation {
    readonly applicationItems: typeof IModelServiceApplicationElement[] | [];
    readonly applications: typeof IModelServiceApplicationPackage[] | [];
    readonly toggleModal: () => void;
}

interface IApplicationsTableProps extends IApplicationsTableConnectedProps,
    IApplicationsTableExternalProps {
}

interface IApplicationsTableState {
}

class ApplicationsTable extends React.Component<IApplicationsTableProps, IApplicationsTableState> {

    componentDidMount(): void {
    }

    componentDidUpdate(
        prevProps: Readonly<IApplicationsTableProps>,
        prevState: Readonly<IApplicationsTableState>,
        snapshot?: any
    ): void {
    }


    render() {
        if (!this.props.applications.length) {
            return <p>
                <Translation text={'marketplace.applications.noApplications'}/>
            </p>;
        }

        return (
            <table className="marketplace-table">
                <tbody>
                {this.renderTableRows()}
                </tbody>
            </table>
        )
    }

    private get applications(): typeof IModelServiceApplicationPackage[] {
        return this.props.applications
            .filter((application: typeof IModelServiceApplicationPackage) => true === application.paidRange)
            .sort((a: typeof IModelServiceApplicationPackage, b: typeof IModelServiceApplicationPackage) => {
                if (a.items > b.items) {
                    return 1;
                }
                if (a.items < b.items) {
                    return -1;
                }

                return 0;
            });
    }

    private renderTableRows = () => {
        const applicationItems = this.props.applicationItems.sort((a: typeof IModelServiceApplicationElement, b: typeof IModelServiceApplicationElement) => {
                if (a.order > b.order) {
                    return 1;
                }
                if (a.order < b.order) {
                    return -1;
                }

                return 0;

            }),
            applications = this.applications,
            rows: any[] = [],
            {t} = this.props;

        rows.push(
            <tr key={'applications_header'}>
                <th/>
                {applications.map((application: typeof IModelServiceApplicationPackage, index: number) => {
                    return (
                        <td key={`application_name_${index}`} className="marketplace-table header-td">{application.name}</td>
                    )
                })}
            </tr>
        );

        rows.push(
            <ApplicationsTableRow key={'platform_access'}
                                  item={{id: 'platform_access', icon: 'monitor', name: t('marketplace.applications.platformAccess')}}
                                  presentInApplications={[true, true, true]}/>
        );

        // Commented out on client's request
        // rows.push(
        //     <ApplicationsTableRow key={'test_package'}
        //                           item={{id: 'test_package', icon: 'diagnostics', name: t('marketplace.applications.testPackage')}}
        //                           presentInApplications={applications.map((application: typeof IModelServiceApplicationPackage) => (application.elements
        //                               .filter((applicationElement: typeof IModelServiceApplicationPackageElement) => applicationElement.elementDefinitionType === 'competence_test')
        //                               .length > 0)
        //                           )}/>
        // );

        applicationItems.forEach((item: typeof IModelServiceApplicationElement) => {
            const presentInApplications = applications.map((application: typeof IModelServiceApplicationPackage) => {
                return application.elements
                    .filter((applicationElement: typeof IModelServiceApplicationPackageElement) => applicationElement.elementDefinitionType === 'application_element')
                    .find((element: typeof IModelServiceApplicationPackageElement) => element.elementDefinitionId === item.id);
            });

            rows.push(
                <ApplicationsTableRow key={item.id} item={item} presentInApplications={presentInApplications}/>
            )
        });

        rows.push(
            <tr key={`applications_select`}>
                <th/>
                {applications.map((application: typeof IModelServiceApplicationPackage) => {
                    return (
                        <td key={application.id} className="application-select">
                            <div>
                                <button className="btn btn-theme" type="button" onClick={() => this.props.toggleModal()}>
                                    <Translation text={'marketplace.countrySelect.title'}/>
                                </button>
                            </div>
                        </td>
                    )
                })}
            </tr>
        );

        return rows;
    };

}

export default (withTranslation()(ApplicationsTable));
