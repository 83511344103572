import React from "react";
import {of, Subscription} from "rxjs";
import styles from "./styles.module.scss";
import {WithTranslation, withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
    authTokenSelector,
    BasicModal,
    CustomCard,
    CustomCardType,
    IModelServiceDefinitionApiResponse,
    isNotNullOrUndefined,
    Translation,
} from "educat-common-web";
import {catchError, tap} from "rxjs/operators";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {getTestPackagesAPI} from "../../../api/getTestPackages";
import CompetenceTestCard from "../CompetenceTestList/CompetenceTestCard";


interface IConnectedBuyTestModalProps {
    readonly authToken: string | null;
}

interface IExternalBuyTestModalProps {
    readonly isModalVisible: boolean;
    readonly toggleModal: (item: any) => void;
}

interface IBuyTestModalProps extends IConnectedBuyTestModalProps,
    IExternalBuyTestModalProps,
    WithTranslation {
}

interface IBuyTestModalState {
    readonly isLoading: boolean;
    readonly testList: any[];
}

class BuyTestModal extends React.Component<IBuyTestModalProps, IBuyTestModalState> {
    readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IBuyTestModalProps) {
        super(props);

        this.state = {
            isLoading: false,
            testList: []
        };
        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.getTestPackages();
    }

    componentDidUpdate(prevProps: Readonly<IBuyTestModalProps>, prevState: Readonly<IBuyTestModalState>): void {
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <BasicModal isModalShown={this.props.isModalVisible} closeModal={this.props.toggleModal}>
                <div className={styles.modalWrapper}>
                    <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Header>
                            <div className={styles.closeModalContainer}>
                                <button className={styles.btnClose} onClick={() => this.props.toggleModal(null)}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>

                            <h2 className={styles.modalHeader}>
                                <Translation text={'competence.buyTest.buyCompetenceTest'}/>
                            </h2>
                            <p className={styles.description}><Translation
                                text={'competence.buyTest.buyTestModal.details'}/></p>
                        </CustomCard.Header>
                        <CustomCard.Body>
                            <div className={styles.modalContent}>
                                {
                                    this.state.testList
                                        .filter((competenceTest => isNotNullOrUndefined(competenceTest)))
                                        .map((competenceTest: { [key: string]: any }) => <CompetenceTestCard
                                            competenceTest={competenceTest}
                                            isBuyTestModal={true}
                                            key={competenceTest.id}/>)
                                }
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </div>
            </BasicModal>
        )
    }

    private getTestPackages = () => {
        this.setState({isLoading: true});
        return this.subscriptions.push(
            getTestPackagesAPI(this.props.authToken).pipe(
                tap((resp: typeof IModelServiceDefinitionApiResponse) => {
                    this.setState({testList: resp['hydra:member'], isLoading: false});
                }),
                catchError((error: any) => {
                    this.alertManager.handleApiError(error);
                    this.setState({isLoading: false});
                    return of();
                })
            ).subscribe()
        )
    }
}

export default connect(
    (state: any) => ({
        authToken: authTokenSelector(state),
    }),
    {}
)(withTranslation()(BuyTestModal));
