import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export function removeCartItemsAPI(
    authToken: string,
    cartId: string,
    serviceInstanceIds: string[],
): Observable<any> {
    return educatAPI.put(
        `carts/${cartId}/remove_items`,
        {serviceInstanceIds},
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
