import {CustomCard, isNullOrUndefined, Price, Translation} from 'educat-common-web';
import React from 'react';
import styles from '../styles.module.scss';
import {getTuitionFee, getTuitionFeePrice, ITuitionFee} from '../types';
import {Link} from "react-router-dom";

interface ISideContentProps {
    profile: any;
}

class SideContent extends React.Component<ISideContentProps> {
    render() {
        const tuitionFee = getTuitionFee(this.props.profile);

        return (
            <div className={styles.schoolProfileSideBar}>
                <CustomCard showLocalLoader={false}>
                    <CustomCard.Body>
                        <div className={styles.mentorCardHeader}>
                            <h2 className={`secondary-title ${styles.mentorTitle}`}>
                                <Translation text="fieldOfStudy.fieldOfStudyProfile.sidebar.application"/>
                            </h2>
                            <p className={styles.subtitle}>
                                <Translation text="fieldOfStudy.fieldOfStudyProfile.sidebar.makeAppointment"/>
                            </p>
                        </div>
                        {/* <div className={styles.mentorDetails}>
							<MentorCard />
						</div> */}
                        <div className="button-wrapper centered">
                            <Link to="/panel/mentors" className="btn btn-theme">
                                <Translation text="fieldOfStudy.fieldOfStudyProfile.sidebar.findMentor"/>
                            </Link>
                        </div>
                    </CustomCard.Body>
                </CustomCard>

                <CustomCard showLocalLoader={false}>
                    <CustomCard.Body>
                        <div className={styles.sidebarCard}>
                            <h2 className={`secondary-title ${styles.sidebarCardTitle}`}>
                                <Translation text="fieldOfStudy.fieldOfStudyProfile.sidebar.price"/>
                                {this.renderTuitionFeesLabel(tuitionFee?.period)}
                            </h2>
                            {this.renderTuitionFees(tuitionFee)}
                        </div>
                    </CustomCard.Body>
                </CustomCard>

                <CustomCard showLocalLoader={false}>
                    <CustomCard.Body>
                        <div className={styles.sidebarCard}>
                            <h2 className={`secondary-title ${styles.sidebarCardTitle}`}>
                                <Translation text="fieldOfStudy.fieldOfStudyProfile.sidebar.applicationDeadline"/>
                            </h2>
                            {this.renderDeadlines(this.props.profile.deadlines)}
                        </div>
                    </CustomCard.Body>
                </CustomCard>

                <CustomCard showLocalLoader={false}>
                    <CustomCard.Body>
                        <div className={styles.sidebarCard}>
                            <h2 className={`secondary-title ${styles.sidebarCardTitle}`}>
                                <Translation text="fieldOfStudy.fieldOfStudyProfile.sidebar.semesterBeginning"/>
                            </h2>
                            {this.renderStartDate(this.props.profile.studyStartDate)}
                        </div>
                    </CustomCard.Body>
                </CustomCard>
            </div>
        );
    }

    private renderDeadlines(deadlines: any) {
        if (!deadlines || deadlines.length === 0) {
            return (
                <span>
					<Translation text="fieldOfStudy.fieldOfStudyProfile.sidebar.noDeadlines"/>
				</span>
            );
        }
        return deadlines.map((deadlineItem: any, index: number) => (
            <p key={`${deadlineItem.date}_${index}`} className={[styles.sidebarCardInfo, styles.sidebarCardInfoDeadline].join(' ')}>
                {Intl.DateTimeFormat('pl', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                }).format(new Date(deadlineItem.date))}
            </p>
        ));
    }

    private renderStartDate(startDate: any) {
        if (!startDate) {
            return (
                <span>
					<Translation text="fieldOfStudy.fieldOfStudyProfile.sidebar.noStartDate"/>
				</span>
            );
        }
        return (
            <p className={styles.sidebarCardInfo}>
                {Intl.DateTimeFormat('pl', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                }).format(new Date(startDate))}
            </p>
        );
    }

    private renderTuitionFees(tuitionFee?: ITuitionFee|null) {
        const price = getTuitionFeePrice(tuitionFee);
        if (isNullOrUndefined(price)) {
            return (
                <span>
					<Translation text="fieldOfStudy.fieldOfStudyProfile.sidebar.noTuitionFees"/>
				</span>
            );
        }

        return (
            <p className={styles.sidebarCardInfo}>
                <Price price={price} separateWithNbsp={true}/>
            </p>
        );
    }

    private renderTuitionFeesLabel(period: any) {
        if (isNullOrUndefined(period) || period !== 'month' || period !== 'year' || period !== 'semester' || period !== 'full') {
            return null;
        }

        return <Translation text={`fieldOfStudy.fieldOfStudyProfile.sidebar.priceFor.${period}`}/>;
    }
}

export default SideContent;
