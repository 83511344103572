import {Observable} from 'rxjs';
import {educatAPI, IModelMentorServiceDefinitionApiResponse, RestQueryParams} from 'educat-common-web';


export function getMentorServiceDefinitionsAPI(
    authToken: string | null,
    params?: typeof RestQueryParams
): Observable<typeof IModelMentorServiceDefinitionApiResponse> {
    let headers = undefined,
        queryParams = params ? params : new RestQueryParams();

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.get(
        `mentor_service_definitions`,
        queryParams,
        headers,
    );
}
