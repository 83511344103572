import {RestQueryParams} from 'educat-common-web';
import {Observable} from 'rxjs';
import {testRealisationAPI} from './provider/testRealisationProvider';


export function getTestRealisationAPI(authToken: string, testId: string): Observable<any> {
    let headers = {
            Authorization: `Bearer ${authToken}`,
    };

    return testRealisationAPI.post(
        `test_realisations`,
        {
            serviceInstanceId: testId
        },
        new RestQueryParams(),
        headers
    );
}
