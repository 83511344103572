import {Observable} from "rxjs";
import {educatAPI, RestQueryParams} from "educat-common-web";

export function getLastPurchasedCartAPI(authToken: string | null): Observable<any> {
    let headers: any = {
        accept: 'application/ld+json',
        'content-type': 'application/json',
    };

    if (authToken) {
        headers['Authorization'] = `Bearer ${authToken}`;
    }

    return educatAPI.get(
        `last_purchased_cart?XDEBUG_SESSION_START=PHPSTORM`,
        new RestQueryParams(),
        headers
    );
}
