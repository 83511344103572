import React from 'react';
import {DateComponent, isNullOrUndefined} from 'educat-common-web';

interface IExternalOperationTableItemDateProps {
    readonly date?: string|null;
}

interface IOperationTableItemDateProps extends IExternalOperationTableItemDateProps {
}

interface IOperationTableItemDateState {
}

class OperationTableItemDate extends React.Component<IOperationTableItemDateProps, IOperationTableItemDateState> {
    render() {
        if (null === this.props.date || isNullOrUndefined(this.props.date)) {
            return <>--</>;
        }

        return (
            <DateComponent date={this.props.date} />
        );
    }
}

export default OperationTableItemDate;
