import {
    accountSelector,
    authTokenSelector,
    changeBreadcrumbs,
    isNullOrUndefined,
    Loader,
    RestQueryParams,
    Translation
} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {forkJoin, Observable, of, Subscription} from 'rxjs';
import {catchError, filter, map, tap} from 'rxjs/operators';
import {getPaymentsAPI} from '../../api/getPayments';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {IAlertManagerService} from '../../service/alertManagerService';
import {RootState} from '../../store/reducers';
import {IFiltersListMetadata} from '../../store/reducers/studyFieldsSearchSlice';
import OperationHistoryCard from './OperationHistoryCard';
// import PaymentCardsCard from './PaymentCardsCard';
// import PaymentSummaryCard from './PaymentSummaryCard';

interface IConnectedPaymentsProps {
    readonly account: { [key: string]: any };
    readonly authToken: string;
    changeBreadcrumbs: typeof changeBreadcrumbs;
}

interface IPaymentsProps extends IConnectedPaymentsProps, RouteComponentProps {
}

interface IPaymentsState {
    isProcessing: boolean;
    taskList: any;
    onlineConsultationList: any;
    scheduleList: any;
    paymentsList: any;
    listMetadata:  IFiltersListMetadata | null;
}

class Payments extends React.Component<IPaymentsProps, IPaymentsState> {
    private readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IPaymentsProps) {
        super(props);

        this.state = {
            isProcessing: false,
            taskList: null,
            onlineConsultationList: null,
            scheduleList: null,
            paymentsList: null,
            listMetadata: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
            {labelKey: 'breadcrumbs.payments', path: '/panel/payments'},
        ]);
        this.fetchPaymentsData();
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        // const wallet = {
        //     amount: this.props.account?.wallet?.amount,
        //     currency: {code: this.props.account?.wallet?.currency},
        // };
        return (
            <section className="row payments-section">
                <Loader showLoader={this.state.isProcessing}/>
                <h1 className="sr-only">
                    <Translation text="payments.srTitle"/>
                </h1>
                <div className="col-md-12 col-xl-8">
                    {/*Hidden in current version*/}
                    {/*<PaymentCardsCard cardList={[]}/>*/}
                    <OperationHistoryCard filtersChange={this.fetchPaymentListAfterChange}
                    operationHistoryList={this.state.paymentsList}
                    listMetadata={this.state.listMetadata}
                    changeStudyFieldsPagination={this.fetchPaymentListAfterChange}/>
                </div>
                {/*Hidden in current version*/}
                {/*<div className="col-md-12 col-xl-4">*/}
                {/*    <PaymentSummaryCard paymentAmount={wallet}/>*/}
                {/*</div>*/}
            </section>
        );
    }

    private fetchPaymentsData() {
        if (!this.props.authToken) {
            return null;
        }
        const itemsPerPage = new RestQueryParams()
            .add('itemsPerPage', 10)
            .add('order[createdAt]', 'DESC');

        const paymentsDataApiArray = [
            this.retrieveListData(getPaymentsAPI(this.props.authToken, itemsPerPage), 'paymentsList')
        ];
        this.setState({isProcessing: true});
        this.subscriptions.push(
            forkJoin(paymentsDataApiArray)
                .pipe(
                    tap((response: any) => {
                        let updatedState = {isProcessing: false};
                        response.forEach((updatedStateItem: any) => updatedState = Object.assign(updatedState, {...updatedStateItem}));
                        this.setState(updatedState);
                    })
                )
                .subscribe()
        );
    }

    private retrieveListData(api: Observable<any>, listName: string) {
        return api.pipe(
            filter((response) => !!response || !!response['hydra:member']),
            map((resp: any) => {
                    return {[listName]: resp['hydra:member'], listMetadata: resp['hydra:view']['hydra:first'] ? resp['hydra:view'] : null};
            }),
            catchError((err: any) => {
                this.alertManager?.handleApiError(err);
                this.setState({isProcessing: false});
                return of();
            })
        );
    }

    private fetchPaymentListAfterChange = (params?: typeof RestQueryParams) => {
        if (!this.props.authToken) {
            return null;
        }
        if (isNullOrUndefined(params)) {
            params = new RestQueryParams();
        }

        if (!params.hasKey('itemsPerPage')) {
            params = params.add('itemsPerPage', 10);
        }
        params = params.add('order[createdAt]', 'DESC');

        this.setState({isProcessing: true});
        this.subscriptions.push(
            this.retrieveListData(getPaymentsAPI(this.props.authToken, params), 'paymentsList')
                .pipe(
                    tap((updatedState: any) => {
                        updatedState.isProcessing = false;
                        this.setState(updatedState);
                    })
                )
                .subscribe()
        );
    }
}

export default connect(
    (state: RootState) => ({
        account: accountSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        changeBreadcrumbs,
    }
)(withRouter(Payments));

