import {Details, Translation} from 'educat-common-web';
import React from 'react';
import parse from 'html-react-parser';

interface ICompetenceTestQuestionProps {
    realisationQuestion: any;
}

class CompetenceTestQuestion extends React.Component<ICompetenceTestQuestionProps> {
    render() {
        const question = this.props.realisationQuestion.question,
            selectedAnswer = this.props.realisationQuestion?.testRealisationChosenAnswers?.[0]?.answer,
            questionCorrect = selectedAnswer && selectedAnswer.correct;

        return (
            <Details key={question.id}>
                <Details.Title>
                    <h3 className="question-title">
                        <span className="question-name">
                            {question.name}
                        </span>
                        <span className={`question-icon ${questionCorrect ? 'correct' : 'incorrect'}`}>
                            <i className={`feather ${questionCorrect ? 'icon-check' : 'icon-x'}`}/>
                        </span>
                    </h3>
                </Details.Title>
                <Details.Body>
                    <p>{parse(question.content)}</p>
                    <ul className="answer-list">{this.renderAnswers(question.answers, selectedAnswer?.id)}</ul>
                    <div className="question-solution">
                        <Translation text="competence.competenceTestResult.solution"/>
                        <div>
                            {parse(question.solution)}
                        </div>
                    </div>
                </Details.Body>
            </Details>
        );
    }

    private renderAnswers(answers: any[], selectedAnswerId: string) {
        if (!answers || answers.length === 0) {
            return null;
        }

        return answers
            .filter((answer) => !!answer)
            .map((answer: any) => (
                <li
                    key={answer.id}
                    className={`answer-item ${answer.correct ? 'correct' : 'incorrect'} ${answer.id === selectedAnswerId ? 'selected' : ''}`}
                >
                    <h4 className="answer-title">{parse(answer.content)}</h4>
                    {answer.description ? <p>{parse(answer.description)}</p> : ''}
                </li>
            ));
    }
}

export default CompetenceTestQuestion;
