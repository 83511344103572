import React from "react";
import {Translation} from "educat-common-web";


interface IPurchasedItemsTableProps {
    serviceInstances: any[];
    className?: any;
}

class PurchasedItemsTable extends React.Component<IPurchasedItemsTableProps, any> {

    render() {
        return (
            <div className={this.props.className}>
                <table className="data-table default-table mb-5 text-center">
                    <thead>
                    <tr>
                        <th className="highlight-cell blued p-0 w-50">
                            <Translation text={'cart.paymentSummary.table.serviceName'}/>
                        </th>
                        <th className="highlight-cell blued p-0 w-25">
                            <Translation text={'cart.paymentSummary.table.quantity'}/>
                        </th>
                        <th className="highlight-cell blued p-0 w-25">
                            <Translation text={'cart.paymentSummary.table.netPrice'}/>
                        </th>
                    </tr>
                    </thead>
                    <tbody>{this.renderTableRows()}</tbody>
                </table>
                <table className="data-table default-table mb-5 w-50 ms-auto text-center">
                    <tbody>
                    <tr key={'vatRate'}>
                        <td className="highlight-cell blued w-50">
                            <Translation text={'cart.paymentSummary.table.vatRate'}/>
                        </td>
                        <td className="w-50">{Math.round(this.getVatSum() / 100)}</td>
                    </tr>
                    <tr key={'grossSum'} style={{borderBottom: '0.1rem solid white'}}>
                        <td className="highlight-cell blued">
                            <Translation text={'cart.paymentSummary.table.grossSum'}/>
                        </td>
                        <td>{Math.round(this.getGrossSum() / 100)}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    private renderTableRows() {
        const serviceInstances = this.props.serviceInstances;
        if (!serviceInstances) {
            return null;
        }

        return serviceInstances.map((service: any) => (
            <tr key={service.id}>
                <td className="highlight-cell" style={{background: 'white'}}>{this.getServiceName(service)}</td>
                <td style={{background: 'white'}}>{service.quantity}</td>
                <td style={{background: 'white'}}>{Math.round(service.finalPrice.amount / 100)}</td>
            </tr>
        ));
    }

    private getServiceName(item: any) {
        return this.getCartItemService(item)?.name;
    }

    getVatSum() {
        const cartServiceInstances = this.props.serviceInstances;
        if (!cartServiceInstances) {
            return 0;
        }
        return cartServiceInstances.reduce((accumulator: number, currentValue: any) => {
            return accumulator + (currentValue.finalGrossPrice.amount - currentValue.finalPrice.amount);
        }, 0)
    }

    getGrossSum() {
        const cartServiceInstances = this.props.serviceInstances;
        if (!cartServiceInstances) {
            return 0;
        }
        return cartServiceInstances.reduce((accumulator: number, currentValue: any) => {
            return accumulator + Number(currentValue.finalGrossPrice.amount);
        }, 0)
    }

    getCartItemService(item: any) {
        if (item.mentorServiceDefinitions) {
            const msd = item.mentorServiceDefinitions;
            switch (true) {
                case !!msd.serviceApplicationElement:
                    return msd.serviceApplicationElement;
                case !!msd.serviceApplicationPackage:
                    return msd.serviceApplicationPackage;
                case !!msd.serviceConsultation:
                    return msd.serviceConsultation;
                case !!msd.serviceConsultationPackage:
                    return msd.serviceConsultationPackage;
                default:
                    return null;
            }
        } else if (item.serviceDefinition){
            return item.serviceDefinition;
        }
        return null;
    }

}

export default PurchasedItemsTable;