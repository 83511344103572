import React from "react";
import App from "../App";
import {Route} from "react-router";
import PanelHost from "../components/PanelHost";
import {NotFound, PrivateRoute, AuthPanel, UserRole, AuthType} from "educat-common-web";
import {newPasswordFormConfig} from "./authFormConfig/newPasswordFormConfig";
import {resetPasswordFormConfig} from "./authFormConfig/resetPasswordFormConfig";
import {loginFormConfig} from "./authFormConfig/loginFormConfig";
import {registrationFormConfig} from "./authFormConfig/registrationFormConfig";

 const routes = [
    <Route path="/" component={App} exact key="home" />,
    // <Route path="/panel/" component={PanelHost} key="panel" />,
    <PrivateRoute path="/panel/" component={() => <PanelHost />} key="panel" />,

    // Auth routes
    <Route path="/auth/login/:token/:impersonator" component={() => <AuthPanel userRole={UserRole.APPLICANT} authType={AuthType.LOGIN} formConfig={{login: loginFormConfig}} envData={process.env}/>} exact key="loginWithParams"/>,
    <Route path="/auth/login" component={() => <AuthPanel userRole={UserRole.APPLICANT} authType={AuthType.LOGIN} formConfig={{login: loginFormConfig}} envData={process.env}/>} exact key="login"/>,
    <Route path="/auth/register"
           component={() =>  <AuthPanel userRole={UserRole.APPLICANT} authType={AuthType.REGISTER} formConfig={{registration: registrationFormConfig}} envData={process.env} />}
           exact key="register"/>,
    <Route path="/auth/reset-password"
           component={() =>  <AuthPanel userRole={UserRole.APPLICANT} authType={AuthType.RESET_PASSWORD} formConfig={{resetPassword: resetPasswordFormConfig}} envData={process.env}/>} exact key="reset-password"/>,
    <Route path="/auth/new-password/:id" component={() =>  <AuthPanel userRole={UserRole.APPLICANT} authType={AuthType.NEW_PASSWORD} formConfig={{newPassword: newPasswordFormConfig}} envData={process.env}/>} exact key="new-password"/>,
    <Route path="/auth/confirm-registration/:id" component={() =>  <AuthPanel userRole={UserRole.APPLICANT} authType={AuthType.CONFIRM_REGISTRATION} formConfig={null} envData={process.env}/>} exact key="confirm-registration"/>,

    <Route key="not-found" component={NotFound} />
];
export default routes;
