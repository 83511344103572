import {authTokenSelector, IMultiselectOption, Translation} from 'educat-common-web';
import React from 'react';
import {of, Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {IAlertManagerService} from "../../service/alertManagerService";
import ChangePasswordCard from './ChangePasswordCard';
import ProfileForm from './ProfileForm';
import ProfileInfoCard from './ProfileInfoCard';
import {catchError, map} from "rxjs/operators";
import {connect} from "react-redux";
import {RootState} from "../../store/reducers";
import {getSubjectsAPI} from "../../api/getSubjects";


interface IConnectedProfileProps {
    authToken: string;
}

interface IExternalProfileProps { }

interface IProfileProps extends IConnectedProfileProps,
    IExternalProfileProps {
}

interface IProfileState {
    subjects: typeof IMultiselectOption[] | [];
}


class Profile extends React.Component<IProfileProps, IProfileState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IProfileProps) {
        super(props);

        this.state = {
            subjects: []
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.subscriptions.push(this.getSubjectsList().subscribe());
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <section className="applicant-profile-section row">
                <h1 className="sr-only">
                    <Translation text={'profile.srTitle'} />
                </h1>
                <div className="col-xl-8">
                    <ProfileForm subjects={this.state.subjects} />
                </div>
                <div className="col-xl-4">
                    <ProfileInfoCard />

                    <ChangePasswordCard />
                </div>
            </section>
        );
    }

    private getSubjectsList = () => {
        return getSubjectsAPI(this.props.authToken).pipe(
            map((resp: any) => {
                let list: typeof IMultiselectOption[] = [];
                resp['hydra:member'].map((subject: {[key: string]: any}) => {
                    return list.push({
                        value: subject.id,
                        label: subject.name
                    })
                });
                return this.setState({subjects: list});
            }),
            catchError((error: any) => {
                this.alertManager?.handleApiError(error);
                return of();
            })
        );
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {}
)(Profile);
