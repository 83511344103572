import {educatAPI, RestQueryParams, StudyFieldsListType} from 'educat-common-web';
import {Observable} from 'rxjs';


export interface IChangeSchoolStudyFieldListType {
    schoolStudyFieldsId: string;
    listType: typeof StudyFieldsListType | null;
}

export function changeSchoolStudyFieldListAPI(authToken: string | null, applicantId: string, payload: IChangeSchoolStudyFieldListType): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return educatAPI.put(
        `applicants/${applicantId}/change_school_study_field_list_type`,
        payload,
        new RestQueryParams(),
        headers
    );
}
