import {FormControlType, IFormConfig, InputType, ValidationRules} from 'educat-common-web';


export const changePasswordFormConfig: typeof IFormConfig = {
    controlType: "form",
    class: "auth-form profile",
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: "change_password_form",
            controlType: "group",
            class: "row",
            validationRules: [
                {
                    name: ValidationRules.ARE_FIELDS_SAME,
                    params: {accessors: [(value: any) => value.password, (value: any) => value.newPassword]}
                },
            ],
            controls: {
                currentPassword: {
                    controlType: "control",
                    defaultValue: "",
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 6}},
                    ],
                    placeholder: 'profile.changePasswordForm.controls.placeholder',
                    isLabelHidden: false,
                    label: 'profile.changePasswordForm.controls.currentPassword',
                    type: InputType.PASSWORD,
                    hostClass: 'col-12',
                    autocomplete: 'off'
                },
                password: {
                    controlType: "control",
                    defaultValue: "",
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 6}},
                    ],
                    placeholder: 'profile.changePasswordForm.controls.placeholder',
                    isLabelHidden: false,
                    label: 'profile.changePasswordForm.controls.newPassword',
                    type: InputType.PASSWORD,
                    hostClass: 'col-12',
                    autocomplete: 'off'
                },
                newPassword: {
                    controlType: "control",
                    defaultValue: "",
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 6}},
                    ],
                    placeholder: 'profile.changePasswordForm.controls.placeholder',
                    isLabelHidden: false,
                    label: 'profile.changePasswordForm.controls.repeatNewPassword',
                    type: InputType.PASSWORD,
                    hostClass: 'col-12',
                    autocomplete: 'off'
                }
            }
        },
        {
            controlType: 'group',
            key: 'new_password_submit',
            class: 'row new-password-form',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: 'submit',
                    inputStyles: 'btn-theme',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: 'buttons.changePassword',
                },
            }
        }
    ]
};
