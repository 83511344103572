import React from "react";
import {IModelServiceConsultationPackage, Translation} from "educat-common-web";


interface IConsultationPackagesTableConnectedProps {}

interface IConsultationPackagesTableExternalProps {
    readonly consultations: typeof IModelServiceConsultationPackage[] | [];
    readonly toggleModal: () => void;
    readonly setConsultationAmount: (amount: number) => void;
}

interface IConsultationPackagesTableProps extends IConsultationPackagesTableConnectedProps,
    IConsultationPackagesTableExternalProps {
}

interface IConsultationPackagesTableState {}

class ConsultationPackagesTable extends React.Component<IConsultationPackagesTableProps, IConsultationPackagesTableState> {

    componentDidMount(): void {}

    componentDidUpdate(
        prevProps: Readonly<IConsultationPackagesTableProps>,
        prevState: Readonly<IConsultationPackagesTableState>,
        snapshot?: any
    ): void {}


    render() {
        if (!this.props.consultations.length) {
            return (
                <p>
                    <Translation text={'marketplace.tutors.noConsultationPackages'}/>
                </p>
            );
        }

        return (
            <table className="data-table content-files-table">
                <thead>
                    <tr>
                        <th className="highlight-cell">
                            <Translation text={'marketplace.tutors.table.title'}/>
                        </th>
                        <th className="highlight-cell">
                            <Translation text={'marketplace.tutors.table.hoursAmount'}/>
                        </th>
                        <th className="highlight-cell" />
                    </tr>
                </thead>
                <tbody>
                    {this.renderTableRows()}
                </tbody>
            </table>
        )
    }

    private renderTableRows = () => {
        const consultations = this.props.consultations,
            rows: any[] = [];

        consultations.map((item: typeof IModelServiceConsultationPackage) => {
            return rows.push(
                <tr key={item.id}>
                    <td>
                        {item.name}
                    </td>
                    <td>{item.items}</td>
                    <td className="borderless">
                        <button type="button" className="btn btn-theme" onClick={() => {
                            this.props.setConsultationAmount(item.items);
                            this.props.toggleModal();
                        }}>
                            <Translation text={"marketplace.selectMentor"}/>
                        </button>
                    </td>
                </tr>
            )
        });

        return rows;
    };

}

export default ConsultationPackagesTable;
