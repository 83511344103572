import {authTokenSelector, IModelCartServiceInstance, isNotNullOrUndefined, Translation} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {addItemToCart} from '../../../../store/reducers/cartSlice';
import {isCartLoadingSelector} from '../../../../store/selectors/cartSelectors';
import styles from './styles.module.scss';
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import { createTestFromPackageAPI } from '../../../../api/createTestFromPackage';
import {catchError, tap} from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";


interface IConnectedTestCardProps {
    readonly authToken: string | null;
    readonly addItemToCart: typeof addItemToCart;
    readonly isCartProcessing: boolean;
    readonly packageServiceInstances: typeof IModelCartServiceInstance[] | [];
}

interface IExternalTestCardProps {
    test: any;
}

interface ITestCardProps extends IConnectedTestCardProps, IExternalTestCardProps, RouteComponentProps {
}

class TestCard extends React.Component<ITestCardProps> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: ITestCardProps) {
        super(props);

        this.state = {};

        fixInjectedProperties(this);
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        const test = this.props.test;
        const isTestBought = isNotNullOrUndefined(test.serviceInstance),
            isTestComplete = isNotNullOrUndefined(test.mostRecentTestRealisationDetails),
            isTestPassed = isTestComplete && test.mostRecentTestRealisationDetails.isPass;
        const testName = isTestBought ? test.serviceInstance.serviceDefinition.name : test.suggestedTest.name,
            logo = this.logo;

        return (
            <div className="col-xxl-3 col-xl-6 col-lg-12 mb-1">
                <div className={`${styles.testCard} ${isTestComplete || !isTestBought ? styles.complete : ''}`}
                     style={logo ? {backgroundImage: `url(${logo})`} : undefined}>
                    <div className={styles.testDetails}>
                        <h3 className={styles.testTitle}>
                            {isTestComplete ? (
                                <a className={styles.titleLink}
                                   href={`/panel/diagnostics/result/${test.mostRecentTestRealisationDetails.testRealisationId}`}>
                                    {testName}
                                </a>
                            ) : (
                                <span>{testName}</span>
                            )}
                        </h3>
                        <div className={styles.details}>
                            {this.renderTestDetails(isTestComplete, isTestPassed, isTestBought, test)}
                            {this.renderTestButton(isTestComplete, isTestPassed, isTestBought, test)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private get logo(): string | null | undefined {
        const test = this.props.test;

        if (isNotNullOrUndefined(test.serviceInstance)) {
            return isNotNullOrUndefined(test.serviceInstance.serviceDefinition.serviceCompetenceTest) ?
                test.serviceInstance.serviceDefinition.serviceCompetenceTest?.mediaObject?.original :
                test.serviceInstance.serviceDefinition.serviceSubjectTest?.mediaObject?.original;
        }

        return test.suggestedTest.serviceSubjectTest ?
            test.suggestedTest.serviceSubjectTest.mediaObject?.original :
            test.suggestedTest.serviceCompetenceTest.mediaObject?.original;
    }

    private renderTestButton = (isTestComplete: boolean, isTestPassed: boolean, isTestBought: boolean, test: any) => {
        const isCompetenceTest = isNotNullOrUndefined(test?.serviceInstance?.serviceDefinition?.serviceCompetenceTest) ||
                isNotNullOrUndefined(test?.suggestedTest?.serviceCompetenceTest),
            isPackageTestAvailable = undefined !== this.props.packageServiceInstances && this.props.packageServiceInstances.length;

        if (!isCompetenceTest && isPackageTestAvailable && !isTestBought) {
            return (
                <button className={styles.testBtn} onClick={() => this.redirectToTest(test)}>
                    <span>
                        <Translation text={'buttons.takeTest'}/>
                    </span>
                </button>
            )
        }

        if (!isTestBought) {
            return (
                <button className={styles.testBtn} onClick={() => this.addToCart(test)}>
                    <span>
                        <Translation text={'buttons.buyTest'}/>
                    </span>
                </button>
            );
        }

        if (isCompetenceTest) {
            return null;
        }

        return (
            <Link to={`/panel/diagnostics/test/${test.serviceInstance.id}`} className={`${styles.testBtn} ${styles.testBtnLink}`}>
                <span>
                    <Translation text={'buttons.takeTest'}/>
                </span>
            </Link>
        );
    };

    private renderTestDetails = (isTestComplete: boolean, isTestPassed: boolean, isTestBought: boolean, test: any) => {
        if (isTestBought && isTestComplete) {
            return (
                <div className={styles.testCompletion}>
                    <span className={`${styles.testStatus} ${isTestPassed ? styles.passed : ''}`}>
                        {isTestPassed ? <i className="feather icon-check"/> : <i className="feather icon-x"/>}
                    </span>
                    <div className={styles.testPoints}>
                        <p className={styles.points}>{test.mostRecentTestRealisationDetails.resultPercentage}</p>
                        <p className={styles.maxPoints}>
                            <span>
                                <Translation text="dashboard.tests.testDetails.points"/>
                            </span>
                        </p>
                    </div>
                </div>
            );
        }

        if (isTestBought && !isTestComplete) {
            if (isNotNullOrUndefined(test?.serviceInstance?.serviceDefinition?.serviceCompetenceTest)) {
                return null;
            }

            return (
                <Link to={`/panel/diagnostics/test/${test.serviceInstance.id}`}  className={`${styles.testBtn} ${styles.visible}`}>
                    <span>
                        <Translation text="buttons.takeTest"/>
                    </span>
                </Link>
            );
        }

        return (
            <div className={styles.testDescription}>
                <div className={styles.price}>
                    <p className={styles.priceValue}>{test.suggestedTest.finalGrossPrice.amount / 100}</p>
                    <p className={styles.priceLabel}>
                        <Translation text="dashboard.tests.testDetails.price"/>
                    </p>
                </div>
                <div className={styles.reviews}>
                    <span>
                        <i className="feather icon-star"/>
                    </span>
                    <p className={styles.reviewCount}>{test.suggestedTest.reviewCount ? test.suggestedTest.reviewCount : '--'}</p>
                </div>
            </div>
        );
    };

    private addToCart = (item: any): void => {
        if (this.props.isCartProcessing) {
            return;
        }

        this.props.addItemToCart(item.suggestedTest);
    };

    private redirectToTest = (item: any): void => {
        if (undefined === this.props.packageServiceInstances || !this.props.packageServiceInstances.length) {
            return;
        }
        const serviceInstanceId = this.props.packageServiceInstances[0].id;
        this.subscriptions.push(
            createTestFromPackageAPI(this.props.authToken, serviceInstanceId, item.suggestedTest.id)
                .pipe(
                    tap((resp: typeof IModelCartServiceInstance) => {
                        this.props.history.push(`/panel/diagnostics/test/${resp.id}`);
                    }),
                    catchError((error: any) => {
                        this.alertManager?.handleApiError(error.response);
                        return of();
                    })
                )
                .subscribe()
        );
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        isCartProcessing: isCartLoadingSelector(state),
    }),
    {
        addItemToCart,
    }
)(withRouter(TestCard));
