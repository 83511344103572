import { Container } from 'inversify';
import getDecorators, { fixInjectedProperties } from './decorators';
import {AlertManagerService} from '../service';

const container = new Container();

container.bind<AlertManagerService>('AlertManagerService').to(AlertManagerService).inSingletonScope();
const { lazyInject } = getDecorators(container);

export { container, lazyInject, fixInjectedProperties };
