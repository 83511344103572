import React from "react";
import {AddTaskModal, CustomCard, Translation} from "educat-common-web";
import TasksTable from "./TasksTable";
import {Task} from "../types";


interface IExternalTasksCardProps {
	readonly applicantId: string;
	readonly taskList: Task[] | null;
	readonly taskStatusChanged: () => void;
}

interface ITasksCardProps extends IExternalTasksCardProps {}

interface ITasksCardState {
	isPrivateTasksShown: boolean;
	isAddTaskModalVisible: boolean;
}

class TasksCard extends React.Component<ITasksCardProps, ITasksCardState> {
	constructor(props: ITasksCardProps) {
		super(props);
		this.state = {
			isPrivateTasksShown: false,
			isAddTaskModalVisible: false
		};
	}

	render() {
		return (
			<>
				<CustomCard showLocalLoader={false}>
					<CustomCard.Body>
						<div className="tasks-header">
							<h2 className="secondary-title">
								<Translation text={"application.yourTasks.title"} />
							</h2>
							<div className="application-button-wrapper">
								<button
									className={`private-button ${this.state.isPrivateTasksShown ? "" : 'active'}`}
									type="button"
									onClick={() => this.togglePrivateTaskFilter()}
								>
									<Translation text={"application.yourTasks.allTasks"} />
								</button>
								<button
									className={`private-button ${this.state.isPrivateTasksShown ? 'active' : ""}`}
									type="button"
									onClick={() => this.togglePrivateTaskFilter()}
								>
									<Translation text={"application.yourTasks.privateTasks"} />
								</button>
							</div>
						</div>
						<div className="table-wrapper">
							<TasksTable
								taskList={this.props.taskList}
								taskStatusChanged={this.props.taskStatusChanged}
								isPrivateList={this.state.isPrivateTasksShown}
							/>
						</div>
						<div className="application-button-wrapper">
							<button className="add-new-button btn btn-theme" onClick={() => this.toggleAddTaskModal()}>
								<Translation text={"application.yourTasks.addNewTask"} />
							</button>
						</div>
					</CustomCard.Body>
				</CustomCard>
				{this.state.isAddTaskModalVisible ?
					(<AddTaskModal isModalVisible={this.state.isAddTaskModalVisible}
								   applicantId={this.props.applicantId}
								   refreshList={this.props.taskStatusChanged}
								   toggleModal={() => this.toggleAddTaskModal()}/>) : null
				}
			</>
		);
	}
	private togglePrivateTaskFilter() {
		this.setState({isPrivateTasksShown: !this.state.isPrivateTasksShown});
	}

	private toggleAddTaskModal = () => {
		this.setState({isAddTaskModalVisible: !this.state.isAddTaskModalVisible});
	}
}

export default TasksCard;
