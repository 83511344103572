import React from 'react';
import {
    convertScheduleInstanceToISchedules,
    CustomCard,
    IModelApplication,
    IModelScheduleInstance,
    isNotNullOrUndefined, TimeLine,
    TimelineDate,
    Translation
} from 'educat-common-web';
import {Link, NavLink} from 'react-router-dom';
import styles from './styles.module.scss';
import {ScheduleMode} from '../../../models/schedule';

interface IExternalTimetableDetailsProps {
    readonly applications: typeof IModelApplication[] | null;
    readonly scheduleInstances: typeof IModelScheduleInstance[] | null;
}

interface ITimetableDetailsProps extends IExternalTimetableDetailsProps {
}

interface ITimetableDetailsState {
}

class TimetableDetails extends React.Component<ITimetableDetailsProps, ITimetableDetailsState> {
    render() {
        const schedules = (this.props.scheduleInstances || []).map(convertScheduleInstanceToISchedules),
            hasSchedules = schedules.length > 0,
            hasApplication = this.hasApplication;
        let placeholderText = null;
        if (!hasApplication && !hasSchedules) {
            placeholderText = 'dashboard.schedule.noApplication';
        } else if (!hasSchedules) {
            placeholderText = 'dashboard.schedule.noSchedule';
        }

        return (
            <CustomCard showLocalLoader={false}>
                <CustomCard.Header>
                    <div className={styles.header}>
                        <h2 className="custom-card-title">
                            <NavLink to={hasApplication ? 'application' : 'marketplace/applications'} className="card-title-link"
                                     exact={true}>
                                <Translation text="dashboard.schedule.title"/>
                            </NavLink>
                        </h2>

                        <TimelineDate/>
                    </div>
                </CustomCard.Header>
                <CustomCard.Body>
                    <div className="pb-5">
                        <Translation text="dashboard.schedule.description"/>
                    </div>

                    <div className={styles.scheduleWrapper}>
                        {isNotNullOrUndefined(placeholderText) ?
                            (<div className={styles.schedulePlaceholder}>
                                <p><Translation text={placeholderText}/></p>
                                {!hasApplication && <div className={`button-wrapper mt-5`}>
                                    <Link className="btn btn-theme" to={'/panel/mentors'}>
                                        <Translation text="buttons.buyApplication"/>
                                    </Link>
                                </div>}
                            </div>) :
                            <>
                                {hasSchedules && <TimeLine data={schedules}
                                                                             mode={ScheduleMode.MONTH}
                                                                             itemHeight={40}
                                                                             dayWidth={24}
                                                                             links={[]}
                                                                             interactiveMode={false}/>}
                                <div className={`button-wrapper mt-5`}>
                                    <Link className="btn btn-theme" to={'/panel/mentors'}>
                                        <Translation text="buttons.buyApplication"/>
                                    </Link>
                                </div>
                            </>
                        }
                    </div>
                </CustomCard.Body>
            </CustomCard>
        );
    }

    private get hasApplication(): boolean {
        return this.props.applications !== null && this.props.applications.length > 0;
    }
}

export default TimetableDetails;
