import { combineReducers } from "redux";
import {connectRouter} from "connected-react-router";
import {createBrowserHistory} from "history";
import {
    accountSlice,
    alertSlice,
    authSlice,
    changePasswordSlice,
    consultationDataSlice,
    initLogout,
    loginSlice,
    onlineConsultationSlice,
    sagaSlice,
    videoCallDetailsSlice,
    insurancesSlice
} from "educat-common-web";
import studyFieldsSearchSlice from "./studyFieldsSearchSlice";
import mentorsSearchSlice from "./mentorsSearchSlice";
import cartSlice from "./cartSlice";

const history = createBrowserHistory();

const appReducer = combineReducers({
    auth: authSlice,
    login: loginSlice,
    account: accountSlice,
    changePassword: changePasswordSlice,
    alert: alertSlice,
    saga: sagaSlice,
    studyFieldsSearch: studyFieldsSearchSlice,
    mentorsSearch: mentorsSearchSlice,
    consultationData: consultationDataSlice,
    cart: cartSlice,
    videoCallDetails: videoCallDetailsSlice,
    onlineConsultation: onlineConsultationSlice,
    insurances: insurancesSlice,
    router: connectRouter(history)
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any) => {
    if (action.type === initLogout.type) {
        return appReducer(undefined, { type: undefined });
    }

    return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
