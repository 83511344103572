import React, {Component} from 'react';
import {authTokenSelector, BasicModal, CustomCard, CustomCardType, Translation} from "educat-common-web";
import styles from "./styles.module.scss";
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import CountrySelect from "./CountrySelect";
import RealmSelect from "./RealmSelect";
import MentorSelect, {MarketplaceServiceType} from "./MentorSelect";


interface IConnectedSelectApplicationProps {
    authToken: string | null;
}

interface IExternalSelectApplicationProps {
    isModalShown: boolean;
    toggleModal: () => void;
    type: MarketplaceServiceType;
    consultationsAmount?: number;
}

interface ISelectApplicationProps extends IConnectedSelectApplicationProps, IExternalSelectApplicationProps {
}

interface ISelectApplicationState {
    isLoading: boolean;
    selectedCountry: string | null;
    selectedRealm: string | null;
}

class SelectApplicationModal extends Component <ISelectApplicationProps, ISelectApplicationState> {
    constructor(props: ISelectApplicationProps) {
        super(props);
        this.state = {
            isLoading: false,
            selectedCountry: null,
            selectedRealm: null,
        };
    }

    componentDidMount(): void {
    }

    render() {
        return (
            <BasicModal isModalShown={this.props.isModalShown} closeModal={this.props.toggleModal}>
                <div className={styles.modalWrapper}>
                    <CustomCard type={CustomCardType.MODAL_CARD} containerStyle={styles.cardWrapper}
                                showLocalLoader={this.state.isLoading}>
                        <CustomCard.Header>
                            <div className={styles.modalHeader}>
                                <button onClick={() => this.props.toggleModal()}>
                                    <i className="feather icon-x" />
                                </button>
                            </div>
                        </CustomCard.Header>

                        <CustomCard.Body>
                            {this.renderTitle()}
                            <div className={styles.selectContainer}>
                                {this.renderContent()}
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </div>
            </BasicModal>
        );
    }

    private renderTitle = () => {
        let title = 'marketplace.countrySelect.title',
            description = 'marketplace.countrySelect.description';

        if (this.state.selectedCountry && !this.state.selectedRealm) {
            title = 'marketplace.realmSelect.title';
            description = 'marketplace.realmSelect.description';
        }

        if (this.state.selectedCountry && this.state.selectedRealm) {
            title = 'marketplace.mentorSelect.title';
            description = 'marketplace.mentorSelect.description';
        }

        return (
            <div className={styles.selectTitle}>
                <h2>
                    <Translation text={title}/>
                </h2>
                <p>
                    <Translation text={description}/>
                </p>
            </div>
        )
    };

    private renderContent = () => {
        if (this.state.selectedCountry && !this.state.selectedRealm) {
            return <RealmSelect selectRealm={(realmId => this.setState({selectedRealm: realmId}))}/>
        }

        if ((this.state.selectedCountry && this.state.selectedRealm) || this.props.type === MarketplaceServiceType.SERVICE_CONSULTATION_PACKAGE) {
            return (
                <MentorSelect country={this.state.selectedCountry}
                              realm={this.state.selectedRealm}
                              consultationsAmount={this.props.consultationsAmount}
                              type={this.props.type}/>
            )
        }

        return (
            <CountrySelect selectCountry={(country => this.setState({selectedCountry: country}))}/>
        )
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {}
)(SelectApplicationModal);
