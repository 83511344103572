import {
    CustomCard,
    FieldOfStudyCardList,
    IModelApplicantSchoolStudyFields,
    isNotNullOrUndefined,
    StudyFieldsListType,
    Translation,
    UserRole
} from 'educat-common-web';
import React from 'react';
import {Link} from "react-router-dom";

interface IExternalChosenApplicationCardProps {
    applications: { [key: string]: any }[] | null;
    toggleReviewState: (currentState: typeof StudyFieldsListType | null, schoolStudyFieldId: string) => void;
}

interface IChosenApplicationCardProps extends IExternalChosenApplicationCardProps {
}

interface IChosenApplicationCardState {
}

class ChosenApplicationCard extends React.Component<IChosenApplicationCardProps, IChosenApplicationCardState> {

    constructor(props: IChosenApplicationCardProps) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <CustomCard showLocalLoader={false}>
                <CustomCard.Body>
                    <h2 className="secondary-title">
                        <Translation text={'application.chosenApplications.title'}/>
                    </h2>
                    <div className={`mb-5 row`}>
                        <FieldOfStudyCardList fieldOfStudyArray={this.applicantSchoolStudyFields}
                                              toggleReviewState={this.props.toggleReviewState}
                                              userRole={UserRole.APPLICANT}
                                              noTableItemsTranslationKey={'application.chosenApplications.noData'}
                        />
                    </div>
                    <div className="button-wrapper">
                        <Link to="/panel/field-of-study-search" className="btn btn-theme btn-rounded">
                            <Translation text={'application.chosenApplications.goToSearch'}/>
                        </Link>
                    </div>
                </CustomCard.Body>
            </CustomCard>
        );
    }

    private get applicantSchoolStudyFields(): typeof IModelApplicantSchoolStudyFields[] | null {
        if (!Array.isArray(this.props.applications) || 0 === this.props.applications.length) {
            return null;
        }

        return this.props.applications
            .filter(item => isNotNullOrUndefined(item.applicantSchoolStudyFields))
            .map(item => item.applicantSchoolStudyFields);
    }
}

export default ChosenApplicationCard;
