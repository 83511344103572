import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export function rescheduleOnlineConsultationAPI(
    authToken: string | null,
    consultationId: string,
    newDate: string,
): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.put(
        `online_consultation/${consultationId}/reschedule`,
        {newDate: newDate},
        new RestQueryParams(),
        headers
    );
}
