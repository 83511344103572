import React, {Component} from 'react';
import styles from './styles.module.scss';
import {
    CustomCard,
    ICurrency,
    IModelLanguage,
    IModelMentorServiceDefinition,
    ItemDescription,
    Price,
    Translation,
    UserDisplayName,
    getMentorSchool,
    getMentorStudyField
} from 'educat-common-web';
import {connect} from 'react-redux';
import {RootState} from '../../../../../../store/reducers';
import {isCartLoadingSelector} from '../../../../../../store/selectors/cartSelectors';
import {addItemToCart} from '../../../../../../store/reducers/cartSlice';
import ReactStars from 'react-rating-stars-component';
import AccountImage from '../../../../../Common/AccountImage';


interface IMentorServiceItemProps {
    mentorServiceItem: typeof IModelMentorServiceDefinition;
    isCartProcessing: boolean;
    readonly addItemToCart: typeof addItemToCart;
}

interface IMentorServiceItemState {
}

class MentorServiceItem extends Component<IMentorServiceItemProps, IMentorServiceItemState> {
    render() {
        const mentor = this.props.mentorServiceItem.mentor,
            addToCartDisabled = this.props.isCartProcessing || !this.props.mentorServiceItem.active;

        return (
            <CustomCard showLocalLoader={this.props.isCartProcessing}>
                <div className={styles.mentorItem}>
                    <div className={styles.itemDetails}>
                        <div className={styles.itemImgContainer}>
                            <AccountImage account={mentor.account} type={'mediumThumb'} adaptive={true}/>
                        </div>

                        <div className={styles.mentorDetails}>
                            <ReactStars
                                count={5}
                                value={mentor.rating}
                                edit={false}
                                emptyIcon={<i className="star-icon empty"> </i>}
                                filledIcon={<i className="star-icon full"> </i>}
                                size={24}
                                activeColor="#fa9504"
                            />
                            <h3>
                                <UserDisplayName account={mentor.account}/>
                            </h3>
                            <p className={styles.schoolDetails}>
                                <span className={styles.realm}>{getMentorSchool(mentor)}</span>
                                <span>{getMentorStudyField(mentor)}</span>
                            </p>
                            <p className={styles.languages}>
                                <Translation text={'marketplace.mentorSelect.languages'}/>
                                {mentor.languages && mentor.languages.length ?
                                    mentor.languages.map((language: typeof IModelLanguage, index: number) => (
                                        <span key={language.id}>
                                                <Translation text={`language.${language.id}`}/>
                                            {mentor.languages.length === (index + 1) ? '' : ', '}
                                            </span>

                                    ))
                                    : null
                                }
                            </p>
                            <div className={styles.description}>
                                <ItemDescription description={mentor.longDescription}/>
                            </div>
                        </div>
                    </div>
                    <div className={styles.itemPrice}>
                        <h5 className={styles.name}>{this.props.mentorServiceItem.name}</h5>

                        <div>
                            <Price price={this.props.mentorServiceItem.totalGrossPrice}
                                   amountComponent={(amount: number) => <span className={styles.amount}>{amount}</span>}
                                   currencyComponent={(currency: typeof ICurrency) => <span className={styles.currency}>{currency}</span>}/>
                        </div>

                        <button className="btn btn-theme mt-4"
                                disabled={addToCartDisabled}
                                onClick={() => this.addToCart(this.props.mentorServiceItem)}>
                            <Translation text={'marketplace.addToCart'}/>
                        </button>
                    </div>
                </div>
            </CustomCard>
        );
    }

    private addToCart = (item: typeof IModelMentorServiceDefinition): void => {
        if (this.props.isCartProcessing) {
            return;
        }

        this.props.addItemToCart(item);
    };
}

export default connect(
    (state: RootState) => ({
        isCartProcessing: isCartLoadingSelector(state),
    }),
    {
        addItemToCart
    }
)(MentorServiceItem);
