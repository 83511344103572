import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export function checkAvailableFreeConsultationAPI(authToken: string | null, mentorId: string): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return educatAPI.get(
        `mentors/${mentorId}/is_trial_accessible`,
        new RestQueryParams(),
        headers
    );
}
