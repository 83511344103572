import {educatAPI, IModelServiceConsultationPackageApiResponse, RestQueryParams} from 'educat-common-web';
import {Observable} from 'rxjs';


export function getServiceConsultationPackagesAPI(
    authToken: string | null
): Observable<typeof IModelServiceConsultationPackageApiResponse> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.get(
        `service_consultation_packages`,
        new RestQueryParams(),
        headers
    );
}
