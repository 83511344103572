import {Loader, LoaderType, Translation} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {isCartLoadingSelector} from '../../../../store/selectors/cartSelectors';
import SubjectTestCard from './SubjectTestCard';
import {IModelCartServiceInstance} from "educat-common-web";

interface ISubjectTestListProps {
    readonly subjectTestArray: any[];
    readonly isCartProcessing: boolean;
    readonly packageServiceInstances?: typeof IModelCartServiceInstance[] | [];
}

interface ISubjectTestListState {
    isLoading: boolean;
}

class SubjectTestList extends React.Component<ISubjectTestListProps, ISubjectTestListState> {
    constructor(props: ISubjectTestListProps) {
        super(props);

        this.state = {
            isLoading: false
        }
    }

    render() {
        if (!this.props.subjectTestArray ||
            !Array.isArray(this.props.subjectTestArray) ||
            (this.props.subjectTestArray.length === 0)
        ) {
            return (
                <p>
                    <Translation text={'defaultList.noItems'}/>
                </p>
            );
        }

        return (
            <div className="d-flex row">
                <Loader type={LoaderType.Local} showLoader={this.props.isCartProcessing || this.state.isLoading}/>
                {this.renderListItems(this.props.subjectTestArray)}
            </div>
        );
    }

    private renderListItems(subjectTestArray: any[]) {
        return subjectTestArray
            .map((subjectTest: { [key: string]: any }) => <SubjectTestCard subjectTest={subjectTest}
                                                                           key={subjectTest.id}
                                                                           packageServiceInstances={this.props.packageServiceInstances}
                                                                           onSubmit={(isLoading: boolean) => this.setState({isLoading: isLoading})}/>);
    }
}

export default connect(
    (state: RootState) => ({
        isCartProcessing: isCartLoadingSelector(state),
    }),
    {}
)(SubjectTestList);
