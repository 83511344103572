import { createSelector } from "@reduxjs/toolkit";
import {RootState} from "../reducers";
import {IMentorsSearchState} from "../reducers/mentorsSearchSlice";


export const selectMentorsSearch = (state: RootState): IMentorsSearchState => {
    return state.mentorsSearch;
};

export const showMentorsSearchSelector = createSelector(
    [selectMentorsSearch],
    (state: IMentorsSearchState) => state.showMentorsSearch
);

export const mentorFiltersSelector = createSelector(
    [selectMentorsSearch],
    (state: IMentorsSearchState) => state.filters
);

export const mentorsSortingSelector = createSelector(
    [selectMentorsSearch],
    (state: IMentorsSearchState) => state.sorting
);

export const mentorsPaginationSelector = createSelector(
    [selectMentorsSearch],
    (state: IMentorsSearchState) => state.pagination
);

export const mentorsListSelector = createSelector(
    [selectMentorsSearch],
    (state: IMentorsSearchState) => state.mentorsList.list
);

export const mentorsListMetadataSelector = createSelector(
    [selectMentorsSearch],
    (state: IMentorsSearchState) => state.mentorsList.metadata
);

export const isMentorSearchLoadingSelector = createSelector(
    [selectMentorsSearch],
    (state: IMentorsSearchState) => state.isLoading
);

export const mentorHostTypeSelector = createSelector(
    [selectMentorsSearch],
    (state: IMentorsSearchState) => state.mentorHostType
);
