import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export function getStudyFieldsAPI(authToken: string, params?: typeof RestQueryParams): Observable<any> {
    const queryParams = params ? params : new RestQueryParams();
    return educatAPI.get(
        `study_fields`,
        queryParams,
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
