import React from 'react';
import styles from '../ConsultationPackageItem/styles.module.scss';
import {isNotNullOrUndefined, Price} from 'educat-common-web';

interface IConnectedConsultationPackageItemPriceProps {
}

interface IExternalConsultationPackageItemPriceProps {
    consultation: any;
}

interface IConsultationPackageItemPriceProps extends IExternalConsultationPackageItemPriceProps,
    IConnectedConsultationPackageItemPriceProps {
}

interface IConsultationPackageItemPriceState {
}


class ConsultationPackageItemPrice extends React.Component<IConsultationPackageItemPriceProps, IConsultationPackageItemPriceState> {

    render() {
        const consultation = this.props.consultation,
            priceTags = [];

        if (consultation?.finalGrossPrice?.amount !== consultation?.totalGrossPrice?.amount) {
            priceTags.push(<span className={`${styles.priceBadge} ${styles.discounted}`} key="normal_price">
                <Price price={consultation.totalGrossPrice}
                       amountComponent={(amount: number) => <span className={styles.amount}>{amount}</span>}/>
            </span>);
        } else if (isNotNullOrUndefined(consultation?.serviceApplicationPackageNotDiscountedFinalGrossPrice?.amount)) {
            priceTags.push(<span className={`${styles.priceBadge} ${styles.discounted}`} key="normal_price">
                <Price price={consultation?.serviceApplicationPackageNotDiscountedFinalGrossPrice}
                       amountComponent={(amount: number) => <span className={styles.amount}>{amount}</span>}/>
            </span>);
        }
        priceTags.push(
            <span className={styles.priceBadge} key="discounted_price">
            <Price price={consultation?.finalGrossPrice}
                   amountComponent={(amount: number) => <span className={styles.amount}>{amount}</span>}/>
            </span>
        );

        return priceTags;
    }
}

export default ConsultationPackageItemPrice;
