import {IModelMentor, IModelMentorSchoolStudyField, isNotNullOrUndefined,} from 'educat-common-web';
import React from 'react';

interface IConnectedMentorItemSchoolDetailsProps {
}

interface IExternalMentorItemSchoolDetailsProps {
    mentor: typeof IModelMentor;
}

interface IMentorItemSchoolDetailsProps extends IConnectedMentorItemSchoolDetailsProps,
    IExternalMentorItemSchoolDetailsProps {
}

interface IMentorItemSchoolDetailsState {
}


class MentorItemSchoolDetails extends React.Component<IMentorItemSchoolDetailsProps, IMentorItemSchoolDetailsState> {

    render() {
        const mentor = this.props.mentor,
            mentorSchoolStudyFields = mentor?.mentorSchoolStudyFields
                ?.filter((mentorSchoolStudyField: typeof IModelMentorSchoolStudyField) => isNotNullOrUndefined(mentorSchoolStudyField));

        if (!Array.isArray(mentorSchoolStudyFields) || 0 === mentorSchoolStudyFields.length) {
            return null;
        }

        return (
            <div className="school-details-container">
                {mentorSchoolStudyFields?.map((mentorSchoolStudyField: typeof IModelMentorSchoolStudyField) => {
                    const schoolName = mentorSchoolStudyField.schoolStudyFields.school?.name,
                        studyFieldName = mentorSchoolStudyField.schoolStudyFields.studyField?.name;

                    if (!schoolName && !studyFieldName) {
                        return null;
                    }

                    return (
                        <p className="school-details mentors-list" key={mentorSchoolStudyField.id}>
                            {studyFieldName ? <span className="tutor-realms">{studyFieldName}</span> : null}
                            {schoolName ? <span>{schoolName}</span> : null}
                        </p>
                    );
                })}
            </div>
        );
    }
}

export default MentorItemSchoolDetails;
