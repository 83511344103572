import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export function getUserTestsAPI(authToken: string): Observable<any> {
    return educatAPI.get(
        `test_realisations/my_tests`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
