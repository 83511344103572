import {IModelMentor} from 'educat-common-web';
import React from 'react';
import AccountImage from '../../../../Common/AccountImage';


interface IConnectedMentorItemImageProps {
}

interface IExternalMentorItemImageProps {
    readonly mentor: typeof IModelMentor;
}

interface IMentorItemImageProps
    extends IConnectedMentorItemImageProps,
        IExternalMentorItemImageProps {
}

interface IMentorItemImageState {
}

class MentorItemImage extends React.Component<IMentorItemImageProps, IMentorItemImageState> {

    render() {
        return (
            <div className="col-md-2 col-12 mentor-img-wrapper">
                <AccountImage account={this.props.mentor?.account} type={'mediumThumb'}/>
            </div>
        );
    }
}

export default MentorItemImage;
