import React from 'react';
import {applicantAppliedListCountSelector, applicantFavouriteListCountSelector, Translation} from 'educat-common-web';
import styles from "./styles.module.scss";
import {FieldOfStudySearchRoute} from '../../MainSearch';
import {connect} from "react-redux";
import {RootState} from '../../../../store/reducers';


interface IConnectedFieldOfStudyHeaderProps {
    readonly favouriteListCount: number | null;
    readonly appliedListCount: number | null;
}

interface IExternalFieldOfStudyHeaderProps {
    readonly schoolSearchType: FieldOfStudySearchRoute;
}

interface IFieldOfStudyHeaderProps extends IConnectedFieldOfStudyHeaderProps,
    IExternalFieldOfStudyHeaderProps {
}

interface IFieldOfStudyHeaderState {
}


class FieldOfStudyHeader extends React.Component<IFieldOfStudyHeaderProps, IFieldOfStudyHeaderState> {
    // TODO PPP ten komponent do rozbiórki, on jest bez sensu. Jak ktoś tutaj trafi niech ze mną pogada o tym komponencie
    render() {
        let title = 'fieldOfStudy.title';
        if (this.props.schoolSearchType === FieldOfStudySearchRoute.FAVOURITE) {
            title = 'fieldOfStudy.favouriteList.title';
        }

        if (this.props.schoolSearchType === FieldOfStudySearchRoute.APPLIED) {
            title = 'fieldOfStudy.appliedList.title'
        }

        return (
            <header className="header-section">
                <h2 className="header-title">
                    <Translation text={title}/>

                    <div className={styles.iconWrapper}>
                        {this.renderTitleIcons()}
                    </div>
                </h2>
                <div className="header-description">
                    {this.renderSubtitle()}
                </div>
            </header>
        );
    }

    private renderTitleIcons() {
        const favouriteListCount = this.props.favouriteListCount ? this.props.favouriteListCount : 0,
            appliedListCount = this.props.appliedListCount ? this.props.appliedListCount : 0;

        switch (this.props.schoolSearchType) {
            case FieldOfStudySearchRoute.FAVOURITE:
                return (
                    <>
                        <i className={`${styles.buttonIcon} ${favouriteListCount === 0 ? 'feather icon-heart' : styles.activeHeartIcon}`}/>
                        <span className={styles.counter}>({favouriteListCount})</span>
                    </>
                );
            case FieldOfStudySearchRoute.APPLIED:
                return (
                    <>
                        <i className={`${styles.buttonIcon} ${appliedListCount === 0 ? 'feather icon-star' : styles.activeStarIcon}`}/>
                        <span className={styles.counter}>({appliedListCount})</span>
                    </>
                );
            default:
                return;
        }
    }

    private renderSubtitle() {
        switch (this.props.schoolSearchType) {
            case FieldOfStudySearchRoute.FAVOURITE:
                return (
                    <>
                        <p className="mb-0">
                            <Translation text="fieldOfStudy.favouriteList.subtitle.l1"/>
                        </p>
                        <ul className="px-4">
                            <li><Translation text="fieldOfStudy.favouriteList.subtitle.p1"/></li>
                            <li><Translation text="fieldOfStudy.favouriteList.subtitle.p2"/></li>
                            <li><Translation text="fieldOfStudy.favouriteList.subtitle.p3"/></li>
                            <li><Translation text="fieldOfStudy.favouriteList.subtitle.p4"/></li>
                        </ul>
                        <p className="mb-0">
                            <Translation text="fieldOfStudy.favouriteList.subtitle.l2"/>
                        </p>
                    </>
                );
            case FieldOfStudySearchRoute.APPLIED:
                return (
                    <>
                        <Translation text={'fieldOfStudy.appliedList.subtitle'}/>
                    </>
                );
            default:
                return (
                    <Translation text={'fieldOfStudy.subtitle'}/>
                );
        }
    }
}


export default connect(
    (state: RootState) => ({
        favouriteListCount: applicantFavouriteListCountSelector(state),
        appliedListCount: applicantAppliedListCountSelector(state),
    }),
    {}
)(FieldOfStudyHeader);
