import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export function getStudyFieldAPI(authToken: string | null, studyFieldId: string): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.get(
        `study_fields/${studyFieldId}`,
        new RestQueryParams(),
        headers
    );
}
