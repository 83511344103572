import {educatAPI, IModelServiceApplicationPackageApiResponse, RestQueryParams} from 'educat-common-web';
import {Observable} from 'rxjs';


export function getServiceApplicationPackagesAPI(
    authToken: string | null
): Observable<typeof IModelServiceApplicationPackageApiResponse> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.get(
        `service_application_packages`,
        new RestQueryParams(),
        headers
    );
}
