import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import MentorItem from './MentorItem';
import {isSameValue, Loader, LoaderType, Translation, IModelMentor} from 'educat-common-web';
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {isMentorSearchLoadingSelector, mentorsListSelector} from "../../../store/selectors/mentorsSearchSelectors";


export enum MentorType {
    MENTOR = 'mentor',
    MENTOR_SCHOLAR = 'mentor_scholar',
    SCHOLAR = 'scholar'
}

interface IConnectedMentorsListProps {
    readonly mentorsList: any[] | null;
    readonly isMentorsListLoading: boolean;
}

interface IExternalMentorsListProps {
    isTutor?: boolean;
}

interface IMentorsListProps extends IConnectedMentorsListProps,
    IExternalMentorsListProps,
    RouteComponentProps {
}

interface IMentorsListState {
    mentorList: any[] | null;
}


class MentorsList extends React.Component<IMentorsListProps, IMentorsListState> {
    constructor(props: IMentorsListProps) {
        super(props);

        this.state = {
            mentorList: null
        };
    }

    componentDidMount(): void {
        this.setState({mentorList: this.props.mentorsList});
    }

    componentDidUpdate(prevProps: Readonly<IMentorsListProps>, prevState: Readonly<IMentorsListState>, snapshot?: any): void {
        if (!isSameValue(this.props.mentorsList, prevProps.mentorsList)) {
            this.setState({mentorList: this.props.mentorsList});
        }
    }

    render() {
        return (
            <>
                <h2 className="sr-only">
                    <Translation text={this.props.isTutor ? `tutors.tutorList.srTitle` : `mentors.mentorList.srTitle`} />
                </h2>
                <ul className="mentor-list-container">
                    {this.state.mentorList && this.state.mentorList.length > 0 ?
                        this.state.mentorList.map((mentor: typeof IModelMentor, index: number) => {
                            return <MentorItem key={index} mentor={mentor} isTutor={this.props.isTutor} />
                        }) :
                        <p>
                            <Translation text={this.props.isTutor ? `tutors.tutorList.noTutors` : `mentors.mentorList.noMentors`} />
                        </p>
                    }
                </ul>

                <Loader type={LoaderType.Local} showLoader={this.props.isMentorsListLoading} />
            </>
        );
    }
}


export default connect(
    (state: RootState) => ({
        mentorsList: mentorsListSelector(state),
        isMentorsListLoading: isMentorSearchLoadingSelector(state)
    }),
    {}
)(withRouter(MentorsList));
