import React, {Component} from 'react';
import {authTokenSelector, getRealmsAPI, IModelRealm, isNotNullOrUndefined} from "educat-common-web";
import styles from "./styles.module.scss";
import {connect} from "react-redux";
import {RootState} from "../../../../../store/reducers";
import {catchError, map} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {fixInjectedProperties, lazyInject} from "../../../../../ioc";
import {IAlertManagerService} from "../../../../../service/alertManagerService";


const realmIconsWhiteList = ["discipline-agriculture", "discipline-applied-sciences", "discipline-arts", "discipline-business",
    "discipline-education", "discipline-engineering", "discipline-environmental", "discipline-hospitality",
    "discipline-humanities", "discipline-it", "discipline-journalism", "discipline-law", "discipline-medicine",
    "discipline-natural-sciences", "discipline-social-sciences"];

interface IConnectedRealmSelectProps {
    authToken: string | null;
}

interface IExternalRealmSelectProps {
    selectRealm: (realmId: string) => void;
}

interface IRealmSelectProps extends IConnectedRealmSelectProps, IExternalRealmSelectProps {
}

interface IRealmSelectState {
    isLoading: boolean;
    realmList: typeof IModelRealm[] | [];
}
class RealmSelect extends Component <IRealmSelectProps, IRealmSelectState> {
    private subscriptions: Subscription[] = [];
    @lazyInject("AlertManagerService") private alertManager: IAlertManagerService | undefined;

    constructor(props: IRealmSelectProps) {
        super(props);
        this.state = {
            isLoading: true,
            realmList: []
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.subscriptions.push(this.getRealmList());
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <div className={styles.realmList}>
                {this.state.realmList.length ?
                    this.state.realmList.map((realm: typeof IModelRealm) => {
                        const hasIcon = realmIconsWhiteList.includes(realm.parentRealmIcon);
                        return (
                            <div className={styles.realm} key={realm.id} onClick={() => this.props.selectRealm(realm.id)}>
                                {isNotNullOrUndefined(realm.parentRealmIcon) && hasIcon ?
                                    <img src={require(`../../../../../assets/images/${realm.parentRealmIcon}.svg`).default} alt=""/> :
                                    <div className={styles.realmIcon} />
                                }
                                <p className={styles.realmName}>{realm.name}</p>
                            </div>
                        )
                    }) : null
                }
            </div>
        );
    }

    private getRealmList = () => {
        return getRealmsAPI(this.props.authToken).pipe(
            map((resp: any) => {
                const realms = (resp['hydra:member'] || []);
                this.setState({
                    isLoading: false,
                    realmList: realms
                });
            }),
            catchError((error: any) => {
                this.setState({isLoading: false});
                this.alertManager?.handleApiError(error);
                return of();
            })
        ).subscribe();
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {}
)(RealmSelect);
