import {Translation} from "educat-common-web";
import React from 'react';
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {RootState} from "../../../../store/reducers";
import {studyFieldsListSelector} from "../../../../store/selectors/studyFieldsSearchSelectors";
import FieldOfStudyList from "../../Common/FieldOfStudyList";
import {FieldOfStudySearchRoute} from "../index";


interface IConnectedMainSearchListProps {
    readonly fieldOfStudyList: any[];
}

interface IExternalMainSearchListProps {
}

interface IMainSearchListProps extends IConnectedMainSearchListProps,
    IExternalMainSearchListProps,
    RouteComponentProps {
}

interface IMainSearchListState {
}


class MainSearchList extends React.Component<IMainSearchListProps, IMainSearchListState> {

    render() {
        return (
            <>
                {!this.props.fieldOfStudyList.length ?
                    <p><Translation text={'fieldOfStudy.noData'}/></p> :
                    <FieldOfStudyList fieldOfStudyList={this.props.fieldOfStudyList}
                                      itemType={FieldOfStudySearchRoute.SEARCH}/>
                }
            </>
        );
    }
}

export default connect(
    (state: RootState) => ({
        fieldOfStudyList: studyFieldsListSelector(state),
    }),
    {}
)(withRouter(MainSearchList));
