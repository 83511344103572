import {IModelMentor} from 'educat-common-web';
import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import MentorItemImage from './MentorItemImage';
import MentorItemGeneralInfo from './MentorItemGeneralInfo';
import MentorItemSpecificInfo from './MentorItemSpecificInfo';


interface IConnectedMentorItemProps {
}

interface IExternalMentorItemProps {
    readonly mentor: typeof IModelMentor;
    readonly isTutor?: boolean;
}

interface IMentorItemProps
    extends IConnectedMentorItemProps,
        IExternalMentorItemProps,
        RouteComponentProps {
}

interface IMentorItemState {
}

class MentorItem extends React.Component<IMentorItemProps, IMentorItemState> {

    render() {
        return (
            <li key={this.props.mentor.id} className="mentor-details-section-wrapper mentor-list-item row">
                <MentorItemImage mentor={this.props.mentor}/>

                <MentorItemGeneralInfo mentor={this.props.mentor} isTutor={this.props.isTutor}/>

                <MentorItemSpecificInfo mentor={this.props.mentor} isTutor={this.props.isTutor}/>
            </li>
        );
    }
}

export default withRouter(MentorItem);
