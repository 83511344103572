import React from 'react';
import {
    Form,
    FormControlChangeType,
    getRealmsAPI,
    IFilterValues,
    IFormConfig,
    IGroupedMultiselectOption,
    IMultiselectOption,
    isNotEmpty,
    isNotNullOrUndefined,
    isNullOrUndefined,
    isSameValue,
    Loader,
    LoaderType,
    RestQueryParams,
    SchoolType
} from 'educat-common-web';
import {studySelectFormConfig} from './studySelectFormConfig';
import {filter, map, tap} from 'rxjs/operators';
import {BehaviorSubject, forkJoin, Subscription} from 'rxjs';
import {getSchoolsAPI} from '../../../../api/getSchools';
import {getStudyFieldsAPI} from '../../../../api/getStudyFields';
import {StudySelectData} from '../../../../models/selectedFilters';
import {WithTranslation, withTranslation} from 'react-i18next';


export enum MainSearchGroupType {
    REALMS = 'realms',
    SUB_REALMS = 'subRealms',
    STUDY_FIELDS = 'studyFields',
    COUNTRIES = 'countries',
    SCHOOLS = 'schools'
}

interface IExternalStudySelectFormProps {
    data: StudySelectData;
    authToken: string;
    filterValues: typeof IFilterValues;
    setFilters: (value: string, type: string) => void;
}

interface IStudySelectFormProps extends IExternalStudySelectFormProps,
    WithTranslation {
}

interface IStudySelectFormState {
    formConfig: typeof IFormConfig;
    collegeValues: typeof IMultiselectOption[] | null;
    studyFieldsValues: typeof IGroupedMultiselectOption[] | null;
    isDataLoading: boolean | null;
    isConfigLoading: boolean;
}


class StudySelectForm extends React.Component<IStudySelectFormProps, IStudySelectFormState> {
    private subscriptions: Subscription[] = [];
    private readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(props: IStudySelectFormProps) {
        super(props);

        this.state = {
            formConfig: null,
            collegeValues: null,
            studyFieldsValues: null,
            isDataLoading: null,
            isConfigLoading: true
        };
    }

    componentDidMount(): void {
        this.setFormConfig();

        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => {
                    this.onValueUpdated(data.value);
                })
            ).subscribe()
        );
    }

    componentDidUpdate(
        prevProps: Readonly<IStudySelectFormProps>,
        prevState: Readonly<IStudySelectFormState>,
        snapshot?: any
    ): void {
        this.subscriptions = this.subscriptions.filter(subscription => !subscription.closed);

        if (!isSameValue(this.state.collegeValues, prevState.collegeValues) ||
            !isSameValue(this.state.studyFieldsValues, prevState.studyFieldsValues) ||
            !isSameValue(this.props.data.whereToStudy?.value, prevProps.data.whereToStudy?.value) ||
            !isSameValue(this.props.data.studySubject?.value, prevProps.data.studySubject?.value) ||
            !isSameValue(this.props.filterValues, prevProps.filterValues)) {
            this.setFormConfig();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <div>
                {this.state.formConfig && <Form config={this.state.formConfig}
                                                onValueStateChange={this.onValueStateChange}
                                                value={this.mapData(this.props.data)}
                                                controlName={'searchSchoolFormConfig'}/>
                }

                <Loader type={LoaderType.Local} showLoader={this.state.isDataLoading || this.state.isConfigLoading}/>
            </div>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private setFormConfig = (): void => {
        if (!this.props.filterValues || !this.props.filterValues?.country) {
            return;
        }

        let schools: typeof IMultiselectOption[] = [],
            studyFields: typeof IGroupedMultiselectOption[] = [];

        if (isNotNullOrUndefined(this.state.collegeValues)) {
            schools = this.state.collegeValues as [];
        }
        if (isNotNullOrUndefined(this.props.data.whereToStudy?.value)) {
            schools.push(this.props.data.whereToStudy);
        }

        if (isNotNullOrUndefined(this.state.studyFieldsValues)) {
            studyFields = this.state.studyFieldsValues as [];
        }
        if (isNotNullOrUndefined(this.props.data.studySubject?.value)) {
            studyFields.push(this.props.data.studySubject);
        }

        const {t} = this.props,
            whereToStudyMultiselectOptions = [
            {
                label: MainSearchGroupType.COUNTRIES,
                options: this.props.filterValues.country.map((country: typeof IMultiselectOption): typeof IMultiselectOption => ({
                    value: country.value,
                    label: t(`country.${country.label}`),
                })),
            },
            {
                label: MainSearchGroupType.SCHOOLS,
                options: schools
            }
        ];


        const formConfig = studySelectFormConfig(
            whereToStudyMultiselectOptions,
            studyFields,
            (value: any, controlName: string) => this.handleMultiselectInputChange(value, controlName)
        );

        this.setState({
            isConfigLoading: false,
            formConfig
        });
    };

    private handleMultiselectInputChange = (value: string, controlName: string) => {
        if (value.length >= 3) {
            let queryParams = new RestQueryParams();

            if (controlName === 'whereToStudy') {
                queryParams = queryParams.add('name', value);
                queryParams = queryParams.add('type', SchoolType.College);
                this.loadSchools(queryParams, schools => this.setState({collegeValues: schools}));
            }

            if (controlName === 'studySubject') {
                queryParams = queryParams.add('name', value);
                this.loadStudyFields(queryParams);
            }
        }
    };

    private loadSchools = (queryParams: typeof RestQueryParams, onSuccess: (schools: typeof IMultiselectOption[]) => void) => {
        return this.subscriptions.push(
            getSchoolsAPI(this.props.authToken, queryParams).pipe(
                map((resp: any) => resp['hydra:member']
                    .map((school: {[key: string]: any}) => {
                        const {t} = this.props;
                        const schoolNameWithCountry = `${school.name} | ${t(`country.${school.countries[0].id}`)}`;
                        return {
                            value: school.id,
                            label: schoolNameWithCountry
                        }
                    })),
                tap(onSuccess)
            ).subscribe()
        );
    };

    private loadStudyFields = (queryParams: typeof RestQueryParams) => {
        const requests = {
            realmsValue: getRealmsAPI(this.props.authToken, queryParams).pipe(
                map((resp: any) => (resp['hydra:member'] || [])
                    .map((school: { [key: string]: any }) => ({
                        value: school.id,
                        label: school.name,
                        parentRealmId: school?.parentRealmId
                    }))),
            ),
            studyFieldValues: getStudyFieldsAPI(this.props.authToken, queryParams).pipe(
                map((resp: any) => (resp['hydra:member'] || [])
                    .map((school: { [key: string]: any }) => ({
                        value: school.id,
                        label: school.name
                    }))),
            )
        };

        this.setState({isDataLoading: true});
        this.subscriptions.push(
            forkJoin(requests).pipe(
                tap((result: any) => {
                    const studySubjectsMultiselectOptions = [
                        {
                            label: MainSearchGroupType.REALMS,
                            options: result.realmsValue.filter((item: { [key: string]: any }) => !item.parentRealmId)
                        },
                        {
                            label: MainSearchGroupType.SUB_REALMS,
                            options: result.realmsValue.filter((item: { [key: string]: any }) => item.parentRealmId)
                        },
                        {
                            label: MainSearchGroupType.STUDY_FIELDS,
                            options: result.studyFieldValues
                        },
                    ];
                    this.setState({isDataLoading: false, studyFieldsValues: studySubjectsMultiselectOptions});
                })
            ).subscribe()
        );
    };

    private onValueUpdated = (value: any) => {
        const hasWhereToStudy = isNotEmpty(value?.whereToStudy);
        const hasFormConfig = isNotEmpty(this.state.formConfig);

        if (hasWhereToStudy && hasFormConfig) {
            this.setFieldValue(value, 'whereToStudy', MainSearchGroupType.SCHOOLS);
        }

        const hasStudySubject = isNotEmpty(value?.studySubject);
        if (hasStudySubject && hasFormConfig) {
            this.setFieldValue(value, 'studySubject', MainSearchGroupType.STUDY_FIELDS);
        }
    };

    private setFieldValue = (value: any, fieldName: string, type: MainSearchGroupType) => {
        const formControls = this.state.formConfig.controls[0].controls,
            selectedGroup = formControls[fieldName].multiselectOptions
                .filter((item: { [key: string]: any }) => isNotNullOrUndefined(item))
                .find((group: any) => {
                    return isNotNullOrUndefined(group.options) ? group.options.find((item: any) => item.value === value[fieldName]) : null;
                }),
            selectedType = selectedGroup?.label,
            filterType = isNullOrUndefined(selectedType) ? type : selectedType;

        let fieldValue = value[fieldName];
        if (Array.isArray(fieldValue)) {
            fieldValue = fieldValue[0];
        }

        this.props.setFilters(fieldValue, filterType);
    };

    private mapData = (data: StudySelectData) => {
        return {
            studySubject: data.studySubject?.value,
            whereToStudy: data.whereToStudy?.value,
        };
    };
}

export default (withTranslation()(StudySelectForm));
