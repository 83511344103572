import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams, IModelCart} from 'educat-common-web';


export function getCurrentCartAPI(authToken: string): Observable<typeof IModelCart> {
    return educatAPI.get(
        `current_cart`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
