import React from 'react';
import styles from './styles.module.scss';
import {isNotNullOrUndefined, isNullOrUndefined, Translation} from 'educat-common-web';
import {DisplayedFilters} from '../../../models/selectedFilters';
import SelectedFilter from '../SelectedFilter';


interface IExternalSelectedFiltersProps {
    removeAllFilters: () => void;
    removeFilterValue: (controlKey: string, value: string) => void;
    selectedFilters: DisplayedFilters | null;
}

interface ISelectedFiltersProps extends IExternalSelectedFiltersProps {
}

interface ISelectedFiltersState {
}

class SelectedFilters extends React.Component<ISelectedFiltersProps, ISelectedFiltersState> {

    render() {
        return (
            <div className={styles.selectedFilters}>
                <div>
                    <p className={styles.label}><Translation text={'filters.selectedFilters'}/></p>
                </div>
                <div className={styles.filtersWrapper}>
                    {this.renderSelectedFilters()}
                    {this.hasAnyFilters && <button onClick={() => this.props.removeAllFilters()}>
                        <Translation text={'filters.removeAll'}/>
                    </button>}
                </div>
            </div>
        );
    }

    private get hasAnyFilters(): boolean {
        if (isNullOrUndefined(this.props.selectedFilters)) {
            return false;
        }

        return Object.values(this.props.selectedFilters as DisplayedFilters).some(value => {
            if (isNullOrUndefined(value)) {
                return false;
            }
            if (Array.isArray(value)) {
                return value.length > 0;
            }

            return true;
        });
    }

    private renderSelectedFilters = () => {
        if (isNullOrUndefined(this.props.selectedFilters)) {
            return null;
        }
        const filters = this.props.selectedFilters as DisplayedFilters;

        return Object.keys(filters)
            .filter((key: string) => isNotNullOrUndefined(filters[key]))
            .map((key: any) => filters[key]
                .filter((item: any) => isNotNullOrUndefined(item))
                .map((item: any) => <SelectedFilter key={item.value}
                                                    filter={item}
                                                    filterKey={key}
                                                    removeFilterValue={this.props.removeFilterValue}/>));
    };
}

export default SelectedFilters;
