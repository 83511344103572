import {educatAPI, RestQueryParams} from 'educat-common-web';
import {Observable} from 'rxjs';


export function getOnlineConsultationsAPI(authToken: string | null, params?: typeof RestQueryParams): Observable<any> {
    let headers = undefined,
        queryParams = params ? params : new RestQueryParams();

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return educatAPI.get(
        `online_consultations`,
        queryParams,
        headers
    );
}
