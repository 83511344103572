import {BasicModal, CustomCard, CustomCardType, Translation} from 'educat-common-web';
import React, {Component} from 'react';
import styles from './styles.module.scss';


interface IExternalPaymentModalProps {
    isModalVisible: boolean;
    closeModal: () => void;
    confirmSubmit: () => void;
}

interface IPaymentModalProps extends IExternalPaymentModalProps {
}


class PaymentModal extends Component<IPaymentModalProps> {
    render() {
        return (
            <BasicModal isModalShown={this.props.isModalVisible} closeModal={this.props.closeModal}>
                <CustomCard showLocalLoader={false} type={CustomCardType.MODAL_CARD}>
                    <CustomCard.Header>
                        <div className={styles.closeModal}>
                            <button onClick={() => this.props.closeModal()}>
                                <span className="feather icon-x"/>
                            </button>
                        </div>
                        <div>
                            <h2 className={styles.notificationModalTitle}>
                                <Translation text={'competence.competenceTest.notificationModal.title'}/>
                            </h2>
                        </div>
                    </CustomCard.Header>
                    <CustomCard.Body>
                        <p className={styles.description}>
                            <Translation text={'competence.competenceTest.notificationModal.description'}/>
                        </p>
                    </CustomCard.Body>
                    <CustomCard.Footer>
                        <div className="d-flex justify-content-between">
                            <button type="button" onClick={() => this.props.closeModal()} className="btn btn-danger">
                                <Translation text="buttons.cancel"/>
                            </button>
                            <button type="button" onClick={() => this.props.confirmSubmit()} className="btn btn-success">
                                <Translation text="buttons.send"/>
                            </button>
                        </div>
                    </CustomCard.Footer>
                </CustomCard>
            </BasicModal>
        );
    }
}

export default PaymentModal;
