import {deepCloneObject} from "educat-common-web";
import createAPIHandler from "../base/apiFactory";

export type ServiceSubjectTypes = {
    pl: { [key: string]: any[] },
    international: { [key: string]: any[] },
}
export enum PolishMaturityClass {
	CLASS_PL_1 = "class_pl_1",
	CLASS_PL_2 = "class_pl_2",
	CLASS_PL_3 = "class_pl_3",
	CLASS_PL_4 = "class_pl_4",
}

export enum InternationalMaturityClass {
	CLASS_IB_1 = "class_ib_1",
	CLASS_IB_2 = "class_ib_2"
}

export const serviceSubjectTestAPI = createAPIHandler({
	url: process.env.REACT_APP_API_URL,
	defaultHeaders: {
		Accept: "application/ld+json",
		"Content-type": "application/ld+json",
		"Accept-Language": "pl-PL, pl;q=0.9, en;q=0.5, *;q=0.2",
	},
	mapper: (data: any): any => {
        const subjectTests: ServiceSubjectTypes = {
            pl: {
                [`${PolishMaturityClass.CLASS_PL_1}`]: [],
                [`${PolishMaturityClass.CLASS_PL_2}`]: [],
                [`${PolishMaturityClass.CLASS_PL_3}`]: [],
                [`${PolishMaturityClass.CLASS_PL_4}`]: [],
            },
            international: {
                [`${InternationalMaturityClass.CLASS_IB_1}`]: [],
                [`${InternationalMaturityClass.CLASS_IB_2}`]: [],
            }
        };

        if (data && data['hydra:member']) {
            const subjectTestsArray = deepCloneObject(data['hydra:member']);
            subjectTestsArray.forEach((subjectTestItem: any) => {
                const subjectTestClass = subjectTestItem.serviceSubjectTest.class,
                internationalClasses = [
                    InternationalMaturityClass.CLASS_IB_1,
                    InternationalMaturityClass.CLASS_IB_2
                ],
                isInternationalClass =  internationalClasses.includes(subjectTestClass);
                isInternationalClass ? subjectTests.international[subjectTestClass].push(subjectTestItem) : subjectTests.pl[subjectTestClass].push(subjectTestItem);
            })
        }
        return subjectTests;
    },
	isList: false,
});
