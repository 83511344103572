import {
    authTokenSelector,
    filterValuesSelector,
    IFilterValues,
    isNotNullOrUndefined,
    isSameValue,
    IMultiselectOption,
    StudyFieldsListType,
    Loader,
    LoaderType,
    IFormConfig,
    getGroupedStudyFieldFilterValue,
    isNullOrUndefined,
    deepCloneObject
} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {forkJoin, of, Subscription} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {
    applyStudyFieldsFilters,
    changeListType,
    changeStudyFieldsCountryFilter,
    changeStudyFieldsFieldFilter,
    changeStudyFieldsFilters,
    changeStudyFieldsRealmFilter,
    changeStudyFieldsSchoolFilter,
    changeStudyFieldsSorting,
    IStudyFieldFilters,
    resetAllStudyFieldsFilters,
    resetStudyFieldsFiltersToDefaultAccountFilters
} from '../../../store/reducers/studyFieldsSearchSlice';
import {
    createSelectedFiltersFromMentorsFilters,
    createSelectedFiltersFromStudyFieldFilters,
    DisplayedFilters,
    StudySelectData
} from '../../../models/selectedFilters';
import {getSchoolAPI} from '../../../api/getSchool';
import {getStudyFieldAPI} from '../../../api/getStudyField';
import {RootState} from '../../../store/reducers';
import {
    isMainSearchLoadingSelector,
    studyFieldsFiltersSelector
} from '../../../store/selectors/studyFieldsSearchSelectors';
import FieldOfStudyHeader from "../Common/FieldOfStudyHeader";
import StudySelectForm, {MainSearchGroupType} from "../Common/StudySelectForm";
import FieldOfStudyNavigation from "../Common/FieldOfStudyNavigation";
import SelectedFilters from "../../Filter/SelectedFilters";
import SortingForm from "../../MentorHost/SortingFilter";
import FieldOfStudyFooter from "../Common/FieldOfStudyFooter";
import {FieldOfStudySearchRoute} from "../MainSearch";
import FilterModel from '../../../models/filters';
import {WithTranslation, withTranslation} from "react-i18next";
import styles from "./styles.module.scss";
import Filter from "../../Filter";
import {mentorFiltersFormConfig} from "../../Filter/mentorFiltersFormConfig";
import {
    applyMentorsFilters,
    changeMentorsCountryFilter,
    changeMentorsFieldFilter,
    changeMentorsFilters,
    changeMentorsRealmFilter,
    changeMentorsSchoolFilter,
    changeMentorsSorting,
    IMentorFilters,
    resetAllMentorsFilters,
    resetMentorsFiltersToDefaultAccountFilters,
} from "../../../store/reducers/mentorsSearchSlice";
import {mentorFiltersSelector} from "../../../store/selectors/mentorsSearchSelectors";
import moment from "moment";


export enum SearchListType {
    STUDY_FIELD_LIST = 'study-field-list',
    MENTOR_LIST = 'mentor-list'
}

export const sortingValues = (t: any): typeof IMultiselectOption[] => [
    {
        value: 'title_asc',
        label: t('fieldOfStudy.form.labels.titleAsc')
    },
    {
        value: 'title_desc',
        label: t('fieldOfStudy.form.labels.titleDesc')
    },
];

interface IConnectedSearchListWrapperProps {
    readonly studyFieldsFilters: IStudyFieldFilters | null;
    readonly filterValues: typeof IFilterValues;
    readonly authToken: string;
    readonly isListLoading: boolean;
    readonly changeStudyFieldsFilters: typeof changeStudyFieldsFilters;
    readonly applyStudyFieldsFilters: typeof applyStudyFieldsFilters;
    readonly resetStudyFieldsFiltersToDefaultAccountFilters: typeof resetStudyFieldsFiltersToDefaultAccountFilters;
    readonly changeStudyFieldsRealmFilter: typeof changeStudyFieldsRealmFilter;
    readonly changeStudyFieldsFieldFilter: typeof changeStudyFieldsFieldFilter;
    readonly changeStudyFieldsCountryFilter: typeof changeStudyFieldsCountryFilter;
    readonly changeStudyFieldsSchoolFilter: typeof changeStudyFieldsSchoolFilter;
    readonly changeStudyFieldsSorting: typeof changeStudyFieldsSorting;
    readonly resetAllStudyFieldsFilters: typeof resetAllStudyFieldsFilters;
    readonly changeListType: typeof changeListType;
    readonly mentorsFilters: IMentorFilters | null;
    readonly changeMentorsFilters: typeof changeMentorsFilters;
    readonly applyMentorsFilters: typeof applyMentorsFilters;
    readonly changeMentorsSorting: typeof changeMentorsSorting;
    readonly resetAllMentorsFilters: typeof resetAllMentorsFilters;
    readonly resetMentorsFiltersToDefaultAccountFilters: typeof resetMentorsFiltersToDefaultAccountFilters;
    readonly changeMentorsSchoolFilter: typeof changeMentorsSchoolFilter;
    readonly changeMentorsCountryFilter: typeof changeMentorsCountryFilter;
    readonly changeMentorsFieldFilter: typeof changeMentorsFieldFilter;
    readonly changeMentorsRealmFilter: typeof changeMentorsRealmFilter;
}

interface IExternalSearchListWrapperProps {
    readonly schoolSearchType: FieldOfStudySearchRoute;
    readonly componentFactory: () => React.ReactNode;
    readonly searchListType: SearchListType;
}

interface ISearchListWrapperProps extends IConnectedSearchListWrapperProps,
    IExternalSearchListWrapperProps,
    WithTranslation {
}

interface ISearchListWrapperState {
    readonly selectedFilters: DisplayedFilters | null;
}


class SearchListWrapper extends React.Component<ISearchListWrapperProps, ISearchListWrapperState> {
    private readonly subscriptions: Subscription[] = [];
    private readonly filter = new FilterModel();

    constructor(props: ISearchListWrapperProps) {
        super(props);

        this.state = {
            selectedFilters: null,
        };
    }

    componentDidMount(): void {
        this.setupSelectedFilters();

        if (FieldOfStudySearchRoute.SEARCH === this.props.schoolSearchType) {
            this.props.resetStudyFieldsFiltersToDefaultAccountFilters();
            this.props.changeListType(null);
        } else {
            const listType: typeof StudyFieldsListType = this.props.schoolSearchType === FieldOfStudySearchRoute.FAVOURITE ?
                StudyFieldsListType.FAVOURITE : StudyFieldsListType.APPLIED;
            this.props.resetAllStudyFieldsFilters();
            this.props.changeListType(listType);
        }

        if (this.isStudyFieldsList) {
            this.props.applyStudyFieldsFilters();
        } else {
            this.props.resetMentorsFiltersToDefaultAccountFilters();
            this.props.applyMentorsFilters();
        }

    }

    componentDidUpdate(
        prevProps: Readonly<ISearchListWrapperProps>,
        prevState: Readonly<ISearchListWrapperState>,
        snapshot?: any
    ): void {
        if (!isSameValue(this.props.studyFieldsFilters, prevProps?.studyFieldsFilters) ||
            !isSameValue(this.props.mentorsFilters, prevProps?.mentorsFilters) ||
            !isSameValue(this.props.filterValues, prevProps?.filterValues)) {
            this.setupSelectedFilters();
        }
    }

    componentWillUnmount(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        const {t} = this.props;
        return (
            <article className={`${styles.sectionContentWrapper} ${this.isStudyFieldsList ? 'col-xl-10' : 'col-xl-12'}`}>
                {this.isStudyFieldsList &&
                    (<FieldOfStudyHeader schoolSearchType={this.props.schoolSearchType}/>)
                }

                <div className={`${styles.content} row`}>

                    {(this.props.schoolSearchType === FieldOfStudySearchRoute.SEARCH || this.props.searchListType === SearchListType.MENTOR_LIST) &&
                    (<StudySelectForm setFilters={this.setFilters}
                                      data={this.createStudySelectData()}
                                      authToken={this.props.authToken}
                                      filterValues={this.props.filterValues}/>)}

                    {this.isStudyFieldsList &&
                        (<FieldOfStudyNavigation schoolSearchType={this.props.schoolSearchType} />)
                    }


                    <div className={`${styles.filtersContainer} ${!this.isStudyFieldsList ? styles.mentorFiltersContainer : ''}`}>
                        <SelectedFilters removeAllFilters={this.removeAllFilters}
                                         removeFilterValue={this.removeFilterValue}
                                         selectedFilters={this.state.selectedFilters}/>
                        <SortingForm sort={this.isStudyFieldsList ? this.props.changeStudyFieldsSorting : this.props.changeMentorsSorting} sortingValues={sortingValues(t)}/>
                    </div>

                    {this.props.searchListType === SearchListType.MENTOR_LIST &&
                        (<Filter authToken={this.props.authToken}
                                changeFilters={this.changeFilters}
                                formConfigFactory={this.createFormConfigFactory()}
                        />)
                    }
                    <div className={styles.listContainer}>
                        {this.props.componentFactory()}

                        <Loader type={LoaderType.Local} showLoader={this.isStudyFieldsList && this.props.isListLoading}/>
                    </div>
                </div>
                <FieldOfStudyFooter/>
            </article>
        )
    }

    private setFilters = (value: string, type: string) => {
        if (type === MainSearchGroupType.REALMS || type === MainSearchGroupType.SUB_REALMS) {
            this.isStudyFieldsList ? this.props.changeStudyFieldsRealmFilter(value) : this.props.changeMentorsRealmFilter(value);
        }

        if (type === MainSearchGroupType.STUDY_FIELDS) {
            this.isStudyFieldsList ? this.props.changeStudyFieldsFieldFilter(value) : this.props.changeMentorsFieldFilter(value);
        }

        if (type === MainSearchGroupType.COUNTRIES) {
            if (this.isStudyFieldsList) {
                this.props.changeStudyFieldsCountryFilter(value);
                this.props.changeStudyFieldsSchoolFilter(null);
            } else {
                this.props.changeMentorsCountryFilter(value);
                this.props.changeMentorsSchoolFilter(null);
            }
        }

        if (type === MainSearchGroupType.SCHOOLS) {
            this.isStudyFieldsList ? this.props.changeStudyFieldsSchoolFilter(value) : this.props.changeMentorsSchoolFilter(value);
        }

        this.isStudyFieldsList ? this.props.applyStudyFieldsFilters() : this.props.applyMentorsFilters();
    };

    private removeAllFilters = () => {
        if (this.isStudyFieldsList) {
            this.props.resetAllStudyFieldsFilters();
            this.props.applyStudyFieldsFilters();
        } else {
            localStorage.setItem('disabledDefaultFilters', 'school,studyField');
            this.props.resetAllMentorsFilters();
            this.props.applyMentorsFilters();
        }
    };

    private removeFilterValue = (controlKey: string, value: string): void => {
        if (controlKey === 'studyField' || controlKey === 'school'){
            const disabledDefaultFilters = localStorage.getItem('disabledDefaultFilters');
            if (disabledDefaultFilters){
                if (!disabledDefaultFilters.includes(controlKey)){
                    localStorage.setItem('disabledDefaultFilters', disabledDefaultFilters + "," + controlKey);
                }
            } else {
                localStorage.setItem('disabledDefaultFilters', controlKey);
            }
        }
        if ((this.isStudyFieldsList && null === this.props.studyFieldsFilters) ||
            (!this.isStudyFieldsList && null === this.props.mentorsFilters)) {
            return;
        }

        const raw = this.isStudyFieldsList ? this.filter.mapStudyFieldFiltersToForm(this.props.studyFieldsFilters) : this.filter.mapMentorFiltersToForm(this.props.mentorsFilters);
        if (isNullOrUndefined(raw[controlKey])) {
            return;
        }

        if (controlKey === 'availability' || controlKey === 'price') {
            raw[controlKey] = null;
        }

        if (Array.isArray(raw[controlKey])) {
            raw[controlKey] = raw[controlKey].filter((item: any) => {
                return item !== value;
            });


        } else {
            raw[controlKey] = null;
        }

        if (this.isStudyFieldsList) {
            this.props.changeStudyFieldsFilters(this.filter.withStudyFieldFiltersFromForm(raw).filters as IStudyFieldFilters);
            this.props.applyStudyFieldsFilters();
        } else {
            this.props.changeMentorsFilters(this.filter.withMentorsFiltersFromForm(raw).filters as IMentorFilters);
            this.props.applyMentorsFilters();
        }
    };

    private createStudySelectData = (): StudySelectData => {
        const studySubjectId = this.isStudyFieldsList ? this.props.studyFieldsFilters?.studyField?.id : this.props.mentorsFilters?.studyFields?.id,
            studySubjectLabel = this.isStudyFieldsList ? this.state.selectedFilters?.studyField?.[0]?.label : this.state.selectedFilters?.studyField?.[0]?.label,
            whereToStudyId = this.isStudyFieldsList ? this.props.studyFieldsFilters?.school?.id : this.props.mentorsFilters?.mentorSchoolStudyFields?.school?.id,
            whereToStudyLabel = this.isStudyFieldsList ? this.state.selectedFilters?.school?.[0]?.label : this.state.selectedFilters?.school?.[0]?.label;

        let whereToStudy = (isNotNullOrUndefined(whereToStudyId) && isNotNullOrUndefined(whereToStudyLabel)) ? {
            value: whereToStudyId as string,
            label: whereToStudyLabel as string,
        } : undefined;
        if (undefined === whereToStudy && this.state.selectedFilters?.country?.length === 1) {
            whereToStudy = this.state.selectedFilters?.country[0];
        }

        return {
            studySubject: (isNotNullOrUndefined(studySubjectId) && isNotNullOrUndefined(studySubjectLabel)) ? {
                value: studySubjectId as string,
                label: studySubjectLabel as string,
            } : undefined,
            whereToStudy,
        };
    };

    private setupSelectedFilters = () => {
        if (!this.props.studyFieldsFilters || !this.props.mentorsFilters || !this.props.filterValues) {
            this.setState({selectedFilters: null});

            return;
        }

        const selectedFilters = this.isStudyFieldsList ? createSelectedFiltersFromStudyFieldFilters(this.props.studyFieldsFilters) as any :
            createSelectedFiltersFromMentorsFilters(this.props.mentorsFilters) as any;
        const filterValues = this.props.filterValues;
        const merged: DisplayedFilters = {};

        for (const key of Object.keys(filterValues)) {
            if (key in selectedFilters) {
                let value: any;
                const selectedValue = (selectedFilters as any)[key];

                if (isNullOrUndefined(selectedValue)) {
                    continue;
                }

                if (Array.isArray(selectedValue)) {
                    value = filterValues[key].filter((item: any) => {
                        return selectedValue.map((i: any) => {
                            return i && typeof i === 'object' ? item.value === i.value : item.value === i;
                        });
                    });

                    value = selectedValue.map((j: any) => {
                        return value.filter((el: any) => {
                            return j && typeof j === 'object' ? el.value === j.value : el.value === j;
                        })[0];
                    });

                    merged[key] = value;
                } else {
                    value = filterValues[key].filter((item: any) => {
                        return selectedValue && typeof selectedValue === 'object' ?
                            item.value === selectedValue?.value : item.value === selectedValue;
                    });
                    merged[key] = value;
                }
            }
        }

        if (isNotNullOrUndefined(selectedFilters['price'])) {
            merged.price = [{value: selectedFilters['price'], label: `${selectedFilters['price']} zł`}];
        }

        if (isNotNullOrUndefined(selectedFilters['availability'])) {
            const startDate = moment(selectedFilters['availability'].split('to')[0]).format('DD/MM/YYYY'),
                endDate = moment(selectedFilters['availability'].split('to')[1]).format('DDD/MM/YYYY');
            merged.availability = [{value: selectedFilters['availability'], label: `${startDate} - ${endDate}`}];
        }

        this.preloadSelectedValues(merged, {
            schoolId: selectedFilters?.school,
            studyFieldId: selectedFilters?.studyField,
        });
    };

    private preloadSelectedValues = (merged: DisplayedFilters, extra: { schoolId?: string, studyFieldId?: string }): void => {
        const schoolId = extra.schoolId,
            studyFieldId = extra.studyFieldId,
            requests = {
                preloadedSchoolValue: schoolId ?
                    getSchoolAPI(schoolId, this.props.authToken).pipe(
                        map((data: any) => ([{value: data.id, label: data.name}])),
                    ) :
                    of([]),
                preloadedStudyFieldValue: studyFieldId ?
                    getStudyFieldAPI(this.props.authToken, studyFieldId).pipe(
                        map((data: any) => ([{value: data.id, label: data.name}]))
                    ) : of([])
            };

        this.subscriptions.push(
            forkJoin(requests).pipe(
                tap((result: any) => {
                    merged.school = result.preloadedSchoolValue;
                    merged.studyField = result.preloadedStudyFieldValue;

                    this.setState({selectedFilters: merged});
                })
            ).subscribe()
        );
    };

    private changeFilters = (value: { [key: string]: any }) => {
        if (this.filter && isNotNullOrUndefined(value)) {
            const obj = this.filter.withMentorsFiltersFromForm(value);
            this.props.changeMentorsFilters(obj.filters as any);
            this.props.applyMentorsFilters();
        }
    };

    private createFormConfigFactory = (): (() => typeof IFormConfig) => {
        return () => {
            const groupedStudyFieldOptions = getGroupedStudyFieldFilterValue(this.props.filterValues?.realm || [], this.props.filterValues?.subRealm || []);
            return mentorFiltersFormConfig(
                this.translateOptionLabel(this.props.filterValues?.country, 'country'),
                groupedStudyFieldOptions,
                this.translateOptionLabel(this.props.filterValues?.languages, 'language'),
                this.props.filterValues?.assistance
            );
        };
    };

    private translateOptionLabel(options: typeof IMultiselectOption[], translationKey: string) {
        if (!options || options.length === 0) {
            return [];
        }
        const {t} = this.props;
        return deepCloneObject(options).map((option: typeof IMultiselectOption) => {
            option.label = t(`${translationKey}.${option.label}`);
            return option;
        });
    };

    private get isStudyFieldsList(): boolean {
        return this.props.searchListType === SearchListType.STUDY_FIELD_LIST;
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        filterValues: filterValuesSelector(state),
        studyFieldsFilters: studyFieldsFiltersSelector(state),
        isListLoading: isMainSearchLoadingSelector(state),
        mentorsFilters: mentorFiltersSelector(state),
    }),
    {
        changeStudyFieldsFilters,
        applyStudyFieldsFilters,
        resetStudyFieldsFiltersToDefaultAccountFilters,
        changeStudyFieldsRealmFilter,
        changeStudyFieldsFieldFilter,
        changeStudyFieldsCountryFilter,
        changeStudyFieldsSchoolFilter,
        changeStudyFieldsSorting,
        resetAllStudyFieldsFilters,
        changeListType,
        changeMentorsFilters,
        changeMentorsSchoolFilter,
        changeMentorsCountryFilter,
        changeMentorsFieldFilter,
        changeMentorsRealmFilter,
        applyMentorsFilters,
        changeMentorsSorting,
        resetAllMentorsFilters,
        resetMentorsFiltersToDefaultAccountFilters
}
)(withTranslation()(SearchListWrapper));
