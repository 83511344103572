import {Observable} from "rxjs";
import {educatAPI, RestQueryParams, IModelCart} from 'educat-common-web';


export function changeCartItemQuantityAPI(
    authToken: string,
    itemId: string,
    quantity: number
): Observable<typeof IModelCart> {
    return educatAPI.put(
        `service_instances/${itemId}`,
        {
            quantity,
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
