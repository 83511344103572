import {authTokenSelector, changeTaskStatusAPI, Loader, LoaderType, TaskStatusCell, Translation, ViewTaskModal} from "educat-common-web";
import React from "react";
import {connect} from "react-redux";
import {of, Subscription} from "rxjs";
import {catchError, delay, tap} from "rxjs/operators";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {RootState} from "../../../../store/reducers";
import {Task, TaskStatus} from "../../types";

interface IConnectedTasksTableProps {
    readonly authToken: string;
}

interface ITasksTableExternalProps {
    isPrivateList: boolean;
    taskList: Task[] | null;
    taskStatusChanged: () => void;
}

interface ITasksTableProps
    extends ITasksTableExternalProps,
    IConnectedTasksTableProps {
}

interface ITasksTableState {
    isLoading: boolean;
    isLongListShown: boolean;
    taskSelected: Task | null;
}

class TasksTable extends React.Component<ITasksTableProps, ITasksTableState> {
    readonly subscriptions: Subscription[] = [];
    @lazyInject("AlertManagerService") private alertManager: IAlertManagerService;

    constructor(props: ITasksTableProps) {
        super(props);

        this.state = {
            isLoading: false,
            isLongListShown: false,
            taskSelected: null,
        };
        fixInjectedProperties(this);
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        if (!this.props.taskList || !Array.isArray(this.props.taskList) || (this.props.taskList.length === 0 && !this.state.isLoading)) {
            return (<p>
                <Translation text={"application.yourTasks.noTasks"} />
            </p>);
        }

        return (<React.Fragment>
            <Loader type={LoaderType.Local} showLoader={this.state.isLoading} />
            <table className="data-table default-table mb-5">
                <thead>
                    <tr>
                        <th className="highlight-cell">
                            <Translation text={"application.yourTasks.table.taskName"} />
                        </th>
                        <th className="highlight-cell">
                            <Translation text={"application.yourTasks.table.deadline"} />
                        </th>
                        <th className="highlight-cell">
                            <Translation text={"application.yourTasks.table.status"} />
                        </th>
                        <th className="highlight-cell"><Translation
                            text={'application.yourTasks.table.mark'} />
                        </th>
                        <th className="highlight-cell">
                            <Translation text={"application.yourTasks.table.private"} />
                        </th>
                    </tr>
                </thead>
                <tbody>{this.renderTableRows(this.props.taskList)}</tbody>
            </table>
            {this.renderShowMoreButton()}
            {this.state.taskSelected ?
                (<ViewTaskModal isModalVisible={this.state.taskSelected}
                    task={this.state.taskSelected}
                    toggleModal={() => this.closeViewTaskModal()} />) : null
            }
        </React.Fragment>);
    }

    private renderTableRows(taskList: any) {
        if (!taskList) {
            return null;
        }
        let currentList: Task[] = taskList;
        if (this.props.isPrivateList) {
            currentList = taskList.filter((task: Task) => task.private);
        }

        if (!this.state.isLongListShown && currentList.length > 5) {
            currentList = currentList.slice(0, 5);
        }
        return currentList.map((item: Task) =>
            <tr key={item.id}>
                <td className="highlight-cell">
                    <button type="button"  id={item.id}  className="item-link" onClick={() => this.selectTask(item)}>
                        {item.subject}
                    </button>
                </td>
                <td>{new Date(item.endsAt).toLocaleDateString("en-GB")}</td>
                <td className="status-type-cell">
                    <TaskStatusCell status={item.status} id={item.id} taskStatusChange={this.changeTaskStatus}
                        isApplicantView={true} />
                </td>
                <td>
                    {item.review ? item.review : '-'}
                </td>
                <td>
                    <div className="checkbox-container">
                        <div className="checkbox-wrapper">
                            <span className={`checkbox-checkmark ${item.private
                                    ? "checked"
                                    : ""}`}>
                                <span className="sr-only">
                                    <Translation text={`${item.private
                                            ? "application.yourTasks.table.srLabelCheckboxChecked"
                                            : "application.yourTasks.table.srLabelCheckbox"}`} />
                                </span>
                            </span>
                        </div>
                    </div>
                </td>
            </tr>);
    }

    private closeViewTaskModal() {
        if (this.state.taskSelected) this.setState({taskSelected: null})
    }

    private selectTask(task: Task) {
        this.setState({taskSelected: task});
    }

    private renderShowMoreButton() {
        if (!this.props.taskList) {
            return null;
        }
        let currentList: Task[] = this.props.taskList;

        if (this.props.isPrivateList) {
            currentList = this.props.taskList.filter((task: Task) => task.private);
        }
        if (this.state.isLongListShown && currentList && currentList.length > 5) {
            return (<button className={`default-expand-list-button ${this.state.isLongListShown
                    ? "long-list"
                    : ""}`} type="button" onClick={() => this.toggleLongList()}>
                <Translation text={"application.yourTasks.table.seeLessTasks"} />
            </button>);
        }
        if (!this.state.isLongListShown && currentList && currentList.length > 5) {
            return (<button className="default-expand-list-button" type="button" onClick={() => this.toggleLongList()}>
                <Translation text={"application.yourTasks.table.seeMoreTasks"} />
            </button>);
        }
        return null;
    }

    private toggleLongList() {
        this.setState({
            isLongListShown: !this.state.isLongListShown
        });
    }

    private changeTaskStatus = (taskId: string, newStatus: TaskStatus) => {
        if (!this.props.authToken) {
            return null;
        }

        this.setState({isLoading: true});
        this.subscriptions.push(changeTaskStatusAPI(this.props.authToken, taskId, newStatus).pipe(delay(1000), tap(() => {
            this.alertManager.addAlert("application.yourTasks.table.changeTaskStatusSuccess");
            this.setState({isLoading: false});
            this.props.taskStatusChanged();
        }), catchError((err: any) => {
            this.alertManager.handleApiError(err);
            this.setState({isLoading: false});
            return of();
        })).subscribe());
    };
}

export default connect((state: RootState) => ({authToken: authTokenSelector(state)}), {})(TasksTable);
