import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    IChangeFilterOptionId,
    IChangeFilterOptionIdOptional,
    IFiltersFieldsList,
    IFiltersListMetadata,
    IPaginationParams,
    SortingParams
} from "./studyFieldsSearchSlice";
import {deepCloneObject} from "educat-common-web";


export enum MentorHostType {
    MENTOR = 'mentor',
    TUTOR = 'tutor'
}

export interface IMentorFilters {
    countries: {
        id: string[] | null
    } | null;
    mentorSchoolStudyFields: {
        school: {
            id: string | null
        } | null
    } | null;
    realms: {
        id: string | null;
    } | null;
    studyFields: {
      id: string | null;
    } | null;
    basePrice: string | null;
    free_terms: string | null;
    languages: {
        id: string[] | null
    } | null;
    mentorTaskTypes: {
        id: string[] | null
    } | null;
}

export interface IMentorsSearchState {
    showMentorsSearch: boolean;
    mentorHostType: MentorHostType;
    filters: IMentorFilters | null;
    sorting: SortingParams | null;
    pagination: IPaginationParams | null;
    mentorsList: IFiltersFieldsList;
    isLoading: boolean;
}

export interface IChangeMentorsList {
    list: {[key: string]: any}[];
    metadata: IFiltersListMetadata | null;
}

export interface IChangeShowMentorSearch {
    showMentorSearch: boolean;
}

export interface IChangeMentorFilters {
    filters: IMentorFilters;
}

export interface IChangeMentorSorting {
    sorting: SortingParams;
}

export interface IChangeMentorPagination {
    pagination: IPaginationParams;
}

export interface IChangeMentorSearchLoading {
    isLoading: boolean;
}

export interface IChangeMentorHostType {
    hostType: MentorHostType;
}

const initialState: IMentorsSearchState = {
    showMentorsSearch: false,
    mentorHostType: MentorHostType.MENTOR,
    filters: {
        countries: null,
        mentorSchoolStudyFields: null,
        realms: null,
        studyFields: null,
        basePrice: null,
        free_terms: null,
        languages: null,
        mentorTaskTypes: null
    },
    sorting: null,
    pagination: {
        page: 1,
        itemsPerPage: 10
    },
    mentorsList: {
        list: [],
        metadata: null
    },
    isLoading: true
};

const studyFiledsSearchSlice = createSlice({
    name: "search",
    initialState: initialState,
    reducers: {
        changeShowMentorsSearch: {
            reducer: (state: IMentorsSearchState, action: PayloadAction<IChangeShowMentorSearch>) => {
                return {
                    showMentorsSearch: action.payload.showMentorSearch,
                    mentorHostType: state.mentorHostType,
                    filters: state.filters,
                    sorting: state.sorting,
                    pagination: state.pagination,
                    mentorsList: state.mentorsList,
                    isLoading: state.isLoading
                }
            },
            prepare(showMentorSearch: boolean) {
                return {
                    payload: { showMentorSearch: showMentorSearch }
                };
            }
        },
        applyMentorsFilters: (state: IMentorsSearchState) => {
            return {
                ...state,
                isLoading: true
            }
        },
        resetMentorsFiltersToDefaultAccountFilters: (state: IMentorsSearchState) => state,
        changeMentorsFilters: {
            reducer: (state: IMentorsSearchState, action: PayloadAction<IChangeMentorFilters>) => {
                return {
                    showMentorsSearch: state.showMentorsSearch,
                    mentorHostType: state.mentorHostType,
                    filters: action.payload.filters,
                    sorting: state.sorting,
                    pagination: state.pagination,
                    mentorsList: state.mentorsList,
                    isLoading: true
                }
            },
            prepare(filters: IMentorFilters) {
                return {
                    payload: {
                        filters: {
                            countries: filters.countries,
                            mentorSchoolStudyFields: filters.mentorSchoolStudyFields,
                            realms: filters.realms,
                            studyFields: filters.studyFields,
                            basePrice:filters.basePrice,
                            free_terms: filters.free_terms,
                            languages: filters.languages,
                            mentorTaskTypes: filters.mentorTaskTypes
                        }
                    }
                };
            }
        },
        changeMentorsRealmFilter: {
            reducer: (state: IMentorsSearchState, action: PayloadAction<IChangeFilterOptionId>) => {
                return {
                    showMentorsSearch: state.showMentorsSearch,
                    mentorHostType: state.mentorHostType,
                    filters: {
                        countries: state.filters ? state.filters.countries : null,
                        mentorSchoolStudyFields: state.filters ? state.filters.mentorSchoolStudyFields : null,
                        realms: {
                            id: action.payload.id
                        },
                        studyFields: state.filters ? state.filters.studyFields : null,
                        basePrice: state.filters ? state.filters.basePrice : null,
                        free_terms: state.filters ? state.filters.free_terms : null,
                        languages: state.filters ? state.filters.languages : null,
                        mentorTaskTypes: state.filters ? state.filters.mentorTaskTypes : null,
                    },
                    sorting: state.sorting,
                    pagination: state.pagination,
                    mentorsList: state.mentorsList,
                    isLoading: true
                };
            },
            prepare(id: string) {
                return {
                    payload: {
                        id: id,
                    }
                };
            }
        },
        changeMentorsFieldFilter: {
            reducer: (state: IMentorsSearchState, action: PayloadAction<IChangeFilterOptionId>) => {
                return {
                    showMentorsSearch: state.showMentorsSearch,
                    mentorHostType: state.mentorHostType,
                    filters: {
                        countries: state.filters ? state.filters.countries : null,
                        mentorSchoolStudyFields: state.filters ? state.filters.mentorSchoolStudyFields : null,
                        realms: state.filters ? state.filters.realms : null,
                        studyFields: {
                            id: action.payload.id
                        },
                        basePrice: state.filters ? state.filters.basePrice : null,
                        free_terms: state.filters ? state.filters.free_terms : null,
                        languages: state.filters ? state.filters.languages : null,
                        mentorTaskTypes: state.filters ? state.filters.mentorTaskTypes : null,
                    },
                    sorting: state.sorting,
                    pagination: state.pagination,
                    mentorsList: state.mentorsList,
                    isLoading: true
                };
            },
            prepare(id: string) {
                return {
                    payload: {
                        id: id
                    }
                };
            }
        },
        changeMentorsCountryFilter: {
            reducer: (state: IMentorsSearchState, action: PayloadAction<IChangeFilterOptionId>) => {
                let countries = [];
                countries.push(action.payload.id);
                return {
                    showMentorsSearch: state.showMentorsSearch,
                    mentorHostType: state.mentorHostType,
                    filters: {
                        countries: {
                            id: countries
                        },
                        mentorSchoolStudyFields: state.filters ? state.filters.mentorSchoolStudyFields : null,
                        realms: state.filters ? state.filters.realms : null,
                        studyFields: state.filters ? state.filters.studyFields : null,
                        basePrice: state.filters ? state.filters.basePrice : null,
                        free_terms: state.filters ? state.filters.free_terms : null,
                        languages: state.filters ? state.filters.languages : null,
                        mentorTaskTypes: state.filters ? state.filters.mentorTaskTypes : null,
                    },
                    sorting: state.sorting,
                    pagination: state.pagination,
                    mentorsList: state.mentorsList,
                    isLoading: true
                };
            },
            prepare(id: string) {
                return {
                    payload: { id: id }
                };
            }
        },
        changeMentorsSchoolFilter: {
            reducer: (state: IMentorsSearchState, action: PayloadAction<IChangeFilterOptionIdOptional>) => {
                return {
                    showMentorsSearch: state.showMentorsSearch,
                    mentorHostType: state.mentorHostType,
                    filters: {
                        countries: state.filters ? state.filters.countries : null,
                        mentorSchoolStudyFields: {
                            school: {
                                id: action.payload.id
                            }
                        },
                        realms: state.filters ? state.filters.realms : null,
                        studyFields: state.filters ? state.filters.studyFields : null,
                        basePrice: state.filters ? state.filters.basePrice : null,
                        free_terms: state.filters ? state.filters.free_terms : null,
                        languages: state.filters ? state.filters.languages : null,
                        mentorTaskTypes: state.filters ? state.filters.mentorTaskTypes : null,
                    },
                    sorting: state.sorting,
                    pagination: state.pagination,
                    mentorsList: state.mentorsList,
                    isLoading: true
                };
            },
            prepare(id: string | null) {
                return {
                    payload: { id: id }
                };
            }
        },
        changeMentorsSorting: {
            reducer: (state: IMentorsSearchState, action: PayloadAction<IChangeMentorSorting>) => {
                return {
                    showMentorsSearch: state.showMentorsSearch,
                    mentorHostType: state.mentorHostType,
                    filters: state.filters,
                    sorting: action.payload.sorting,
                    pagination: state.pagination,
                    mentorsList: state.mentorsList,
                    isLoading: true
                }
            },
            prepare(sortingParams: SortingParams) {
                return {
                    payload: {
                        sorting: sortingParams,
                    }
                };
            }
        },
        changeMentorsPagination: {
            reducer: (state: IMentorsSearchState, action: PayloadAction<IChangeMentorPagination>) => {
                return {
                    showMentorsSearch: state.showMentorsSearch,
                    mentorHostType: state.mentorHostType,
                    filters: state.filters,
                    sorting: state.sorting,
                    pagination: action.payload.pagination,
                    mentorsList: state.mentorsList,
                    isLoading: true
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: { pagination: pagination }
                };
            }
        },
        changeMentorsList: {
            reducer: (state: IMentorsSearchState, action: PayloadAction<IChangeMentorsList>) => {
                return {
                    showMentorsSearch: state.showMentorsSearch,
                    mentorHostType: state.mentorHostType,
                    filters: state.filters,
                    sorting: state.sorting,
                    pagination: state.pagination,
                    mentorsList: {
                        list: action.payload.list,
                        metadata: action.payload.metadata
                    },
                    isLoading: state.isLoading
                };
            },
            prepare(mentorsList: IFiltersFieldsList) {
                return {
                    payload: {
                        list: mentorsList.list,
                        metadata: mentorsList.metadata
                    }
                };
            }
        },
        changeMentorSearchLoading: {
            reducer: (state: IMentorsSearchState, action: PayloadAction<IChangeMentorSearchLoading>) => {
                return {
                    showMentorsSearch: state.showMentorsSearch,
                    mentorHostType: state.mentorHostType,
                    filters: state.filters,
                    sorting: state.sorting,
                    pagination: state.pagination,
                    mentorsList: state.mentorsList,
                    isLoading: action.payload.isLoading
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: { isLoading: isLoading }
                };
            }
        },
        changeMentorHostType: {
            reducer: (state: IMentorsSearchState, action: PayloadAction<IChangeMentorHostType>) => {
                return {
                    showMentorsSearch: state.showMentorsSearch,
                    mentorHostType: action.payload.hostType,
                    filters: state.filters,
                    sorting: state.sorting,
                    pagination: state.pagination,
                    mentorsList: state.mentorsList,
                    isLoading: state.isLoading
                };
            },
            prepare(hostType: MentorHostType) {
                return {
                    payload: { hostType: hostType }
                };
            }
        },
        resetAllMentorsFilters: (state: IMentorsSearchState) => {
            return {
                ...state,
                filters: deepCloneObject(initialState.filters),
            }
        },
    }
});

export const {
    changeShowMentorsSearch,
    changeMentorsFilters,
    changeMentorsSorting,
    changeMentorsPagination,
    changeMentorsList,
    changeMentorSearchLoading,
    changeMentorsSchoolFilter,
    changeMentorsCountryFilter,
    changeMentorsFieldFilter,
    changeMentorsRealmFilter,
    changeMentorHostType,
    applyMentorsFilters,
    resetMentorsFiltersToDefaultAccountFilters,
    resetAllMentorsFilters
} = studyFiledsSearchSlice.actions;

export default studyFiledsSearchSlice.reducer;
