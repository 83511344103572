import React from 'react';
import {IFieldOfStudy} from '../../../FieldOfStudy/FieldOfStudyProfile/types';
import {FieldOfStudySearchRoute} from '../../MainSearch';
import FieldOfStudyListItem from './FieldOfStudyListItem';
import styles from "./styles.module.scss";


interface IConnectedFieldOfStudyListProps {
}

interface IExternalFieldOfStudyListProps {
    fieldOfStudyList: IFieldOfStudy[];
    itemType: FieldOfStudySearchRoute;
}

interface IFieldOfStudyListProps extends IConnectedFieldOfStudyListProps,
    IExternalFieldOfStudyListProps {
}

interface IFieldOfStudyListState {
}


class FieldOfStudyList extends React.Component<IFieldOfStudyListProps, IFieldOfStudyListState> {

    render() {
        return (
            <div className={styles.fieldOfStudyListWrapper}>
                <ul className={styles.fieldOfStudyList}>
                    {this.renderListItems(this.props.fieldOfStudyList)}
                </ul>
            </div>
        );
    }

    private renderListItems = (fieldOfStudyList: IFieldOfStudy[]) => {
        return fieldOfStudyList.map((school: IFieldOfStudy) => <FieldOfStudyListItem fieldOfStudyItem={school}
                                                                                     key={school.id}
                                                                                     itemType={this.props.itemType}/>);
    };
}

export default FieldOfStudyList;
