import {
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    collectionInputDataMapper,
    collectionOutputDataMapper,
    multiCollectionInputDataMapper,
    multiCollectionOutputDataMapper,
    MultiselectMenuPlacement,
    MultiSelectType,
    studyDurationList,
    studyLevelsList,
    studyModesList
} from 'educat-common-web';


export const filterFormConfig = (
    countries: typeof IMultiselectOption[] = [],
    studyFields: typeof IMultiselectOption[] = [],
    levels: typeof IMultiselectOption[] = [],
    schools: typeof IMultiselectOption[] = [],
    handleMultiselectInputChange?: any
): typeof IFormConfig => {
    return {
        controlType: 'group',
        class: 'fields-filters',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);

            return previousStateSnapshot;
        },
        controls: [
            {
                controlType: 'group',
                key: 'country',
                class: 'row',
                controls: {
                    country: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.DEFAULT,
                        validationRules: [],
                        placeholder: 'filters.form.placeholders.allOptions',
                        label: 'filters.form.labels.country',
                        multiselectOptions: countries,
                        inputDataMapper: multiCollectionInputDataMapper,
                        outputDataMapper: multiCollectionOutputDataMapper,
                        hostClass: 'col-xl-12',
                        menuPlacement: MultiselectMenuPlacement.TOP,
                        isCustomMultiValueContainer: true
                    }
                }
            },
            {
                controlType: 'group',
                key: 'realm',
                class: 'row',
                controls: {
                    subRealm: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.GROUPED,
                        validationRules: [],
                        placeholder: 'filters.form.placeholders.allOptions',
                        label: 'filters.form.labels.realm',
                        multiselectOptions: studyFields,
                        inputDataMapper: multiCollectionInputDataMapper,
                        outputDataMapper: multiCollectionOutputDataMapper,
                        hostClass: 'col-xl-12',
                        menuPlacement: MultiselectMenuPlacement.BOTTOM,
                        isGroupedComponentCustom: true
                    }
                }
            },
            {
                controlType: 'group',
                key: 'mode',
                class: 'row',
                controls: {
                    mode: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.DEFAULT,
                        validationRules: [],
                        placeholder: 'filters.form.placeholders.allOptions',
                        label: 'filters.form.labels.mode',
                        multiselectOptions: studyModesList,
                        inputDataMapper: multiCollectionInputDataMapper,
                        outputDataMapper: multiCollectionOutputDataMapper,
                        hostClass: 'col-xl-12',
                        menuPlacement: MultiselectMenuPlacement.TOP,
                        isCustomMultiValueContainer: true
                    }
                }
            },
            // commented out until there is a option to sort by price
            // {
            //     controlType: 'group',
            //     key: 'price',
            //     class: 'row',
            //     controls: {
            //         price: {
            //             controlType: 'control',
            //             defaultValue: [0, 25],
            //             formControlType: FormControlType.RANGE,
            //             label: 'filters.form.labels.price',
            //             hostClass: 'col-xl-12',
            //             rangeStep: 1,
            //             rangeMinValue: 0,
            //             rangeMaxValue: 100,
            //             rangeValueUnit: 'fieldOfStudy.form.rangeUnit.currency',
            //             isLabelHidden: true
            //         }
            //     }
            // },
            {
                controlType: 'group',
                key: 'level',
                class: 'row',
                controls: {
                    level: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.DEFAULT,
                        validationRules: [],
                        placeholder: 'filters.form.placeholders.allOptions',
                        label: 'filters.form.labels.level',
                        multiselectOptions: studyLevelsList,
                        inputDataMapper: multiCollectionInputDataMapper,
                        outputDataMapper: multiCollectionOutputDataMapper,
                        hostClass: 'col-xl-12',
                        menuPlacement: MultiselectMenuPlacement.TOP,
                        isCustomMultiValueContainer: true,
                    }
                }
            },
            {
                controlType: 'group',
                key: 'duration',
                class: 'row',
                controls: {
                    duration: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.DEFAULT,
                        validationRules: [],
                        placeholder: 'filters.form.placeholders.duration',
                        label: 'filters.form.labels.duration',
                        multiselectOptions: studyDurationList,
                        inputDataMapper: multiCollectionInputDataMapper,
                        outputDataMapper: multiCollectionOutputDataMapper,
                        hostClass: 'col-xl-12',
                        menuPlacement: MultiselectMenuPlacement.TOP,
                        isCustomMultiValueContainer: true,
                    }
                }
            },
            {
                controlType: 'group',
                key: 'school',
                class: 'row',
                controls: {
                    school: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SINGLE,
                        validationRules: [],
                        placeholder: 'profile.profileForm.autocompletePlaceholder',
                        label: 'filters.form.labels.school',
                        multiselectOptions: schools,
                        inputDataMapper: collectionInputDataMapper,
                        outputDataMapper: collectionOutputDataMapper,
                        hostClass: 'col-xl-12',
                        menuPlacement: MultiselectMenuPlacement.TOP,
                        handleMultiselectInputChange: (value: any) => {
                            if (handleMultiselectInputChange) {
                                return handleMultiselectInputChange(value, 'school');
                            }
                        },
                    }
                }
            },
        ]
    };
};
