import React, {Component} from 'react';
import styles from "./styles.module.scss";
import {
    authTokenSelector,
    Form,
    FormControlChangeType,
    getMentorAPI,
    IFormConfig,
    IModelCartServiceInstance,
    IMultiselectOption,
    isNotNullOrUndefined,
    isNullOrUndefined,
    isSameValue,
    Translation
} from "educat-common-web";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {BehaviorSubject, of, Subscription} from "rxjs";
import {catchError, filter, map, tap} from "rxjs/operators";
import {studyFieldFormConfig} from "./studyFieldFormConfig";
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {isCartLoadingSelector} from "../../../../store/selectors/cartSelectors";
import {updateCartApplicationPackageSchoolStudyFields} from '../../../../store/reducers/cartSlice';


interface IConnectedApplicationPackageStudyFieldProps {
    readonly authToken: string | null;
    readonly isCartLoading: boolean;
    readonly updateCartApplicationPackageSchoolStudyFields: typeof updateCartApplicationPackageSchoolStudyFields;
}

interface IExternalApplicationPackageStudyFieldProps {
    readonly mentorId: string | undefined;
    readonly serviceInstance: typeof IModelCartServiceInstance;
}

interface IApplicationPackageStudyFieldProps extends IExternalApplicationPackageStudyFieldProps, IConnectedApplicationPackageStudyFieldProps {
}

interface IApplicationPackageStudyFieldState {
    value: any;
    formConfig: typeof IFormConfig | null;
    isConfigLoading: boolean;
    schoolStudyFieldsValues: typeof IMultiselectOption[] | [];
}

class ApplicationPackageStudyField extends Component<IApplicationPackageStudyFieldProps, IApplicationPackageStudyFieldState> {
    private subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IApplicationPackageStudyFieldProps) {
        super(props);
        this.state = {
            value: null,
            formConfig: null,
            isConfigLoading: true,
            schoolStudyFieldsValues: []
        };
        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.getMentorSchoolStudyFields();
        this.setFormConfig();

        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onFormValueChange(data.value)),
            ).subscribe()
        );

        if (this.props.serviceInstance) {
            this.setState({value: {schoolStudyField: this.props.serviceInstance?.extraData?.schoolStudyFieldId}});
        }
    }

    componentDidUpdate(prevProps: Readonly<IApplicationPackageStudyFieldProps>, prevState: Readonly<IApplicationPackageStudyFieldState>, snapshot?: any): void {
        if (!isSameValue(this.state.schoolStudyFieldsValues, prevState.schoolStudyFieldsValues)) {
            this.setFormConfig(this.state.schoolStudyFieldsValues);
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <div className={`${styles.packageStudyFields}`}>
                <p className={styles.title}>
                    <Translation text="cart.cartDetails.selectStudyField"/>
                </p>
                <div>
                    {this.state.formConfig && <Form config={this.state.formConfig}
                                                    onValueStateChange={this.onValueStateChange}
                                                    value={this.state.value}
                                                    controlName={'studyFieldForm'}/>}
                </div>
            </div>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onFormValueChange = (value: any) => {
        this.onStudyFieldSelected(value.schoolStudyField);
        this.setState({value: value});
    };

    private setFormConfig = (schoolStudyFields: typeof IMultiselectOption[] = []): void => {
        const formConfig = studyFieldFormConfig(schoolStudyFields);

        this.setState({isConfigLoading: false, formConfig});
    };

    private getMentorSchoolStudyFields = () => {
        if (isNullOrUndefined(this.props.mentorId) || isNullOrUndefined(this.props.authToken)) {
            return;
        }

        this.subscriptions.push(
            getMentorAPI(this.props.mentorId, this.props.authToken).pipe(
                map((resp: { [key: string]: any }) => {
                    const multiselectOptions: typeof IMultiselectOption[] = (resp?.mentorSchoolStudyFields || [])
                        .filter((option: { [key: string]: any }) => isNotNullOrUndefined(option))
                        .map((option: { [key: string]: any }) => {
                            return ({
                                value: option.schoolStudyFields.id,
                                label: option.schoolStudyFields.studyField.name
                            })
                        });


                    this.setState({schoolStudyFieldsValues: multiselectOptions});
                }),
                catchError((error: any) => {
                    this.alertManager?.handleApiError(error);
                    return of();
                })
            ).subscribe()
        )
    };

    private onStudyFieldSelected = (schoolStudyField: string) => {
        if (this.props.isCartLoading) {
            return;
        }

        this.props.updateCartApplicationPackageSchoolStudyFields(this.props.serviceInstance.id, schoolStudyField)
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        isCartLoading: isCartLoadingSelector(state),
    }),
    {
        updateCartApplicationPackageSchoolStudyFields
    }
)(ApplicationPackageStudyField);
