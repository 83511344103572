import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {changeBreadcrumbs, Pagination, Translation} from 'educat-common-web';
import {connect} from 'react-redux';
import {changeMentorHostType, changeMentorsPagination, MentorHostType} from '../../store/reducers/mentorsSearchSlice';
import MentorsList from './MentorsList';
import {withTranslation, WithTranslation} from "react-i18next";
import SearchListWrapper, {SearchListType} from "../FieldOfStudySearch/SearchListWrapper";
import {DisplayedFilters} from "../../models/selectedFilters";
import {FieldOfStudySearchRoute} from "../FieldOfStudySearch/MainSearch";
import {mentorsListMetadataSelector} from "../../store/selectors/mentorsSearchSelectors";
import {RootState} from "../../store/reducers";
import {IFiltersListMetadata} from "../../store/reducers/studyFieldsSearchSlice";


interface IConnectedMentorHostProps {
    readonly listMetadata: IFiltersListMetadata | null;
    readonly changeBreadcrumbs: typeof changeBreadcrumbs;
    readonly changeMentorHostType: typeof changeMentorHostType;
    readonly changeMentorsPagination: typeof changeMentorsPagination;
}

interface IExternalMentorHostProps {
    isTutor: boolean;
}

interface IMentorHostProps extends IConnectedMentorHostProps,
    IExternalMentorHostProps,
    RouteComponentProps,
    WithTranslation {
}

interface IMentorHostState {
    readonly selectedFilters: DisplayedFilters | null;
}


class MentorHost extends React.Component<IMentorHostProps, IMentorHostState> {
    constructor(props: IMentorHostProps) {
        super(props);

        this.state = {
            selectedFilters: null
        };

        if (this.props.isTutor) {
            this.props.changeMentorHostType(MentorHostType.TUTOR);
        } else {
            this.props.changeMentorHostType(MentorHostType.MENTOR);
        }
    }

    componentDidMount(): void {
        const teacherTypeBreadcrumb = this.props.isTutor ? {
            labelKey: 'breadcrumbs.tutors',
            path: '/panel/tutors'
        } : {labelKey: 'breadcrumbs.mentors', path: '/panel/mentors'};
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
            teacherTypeBreadcrumb
        ]);
    }

    render() {
        return (
            <article className="mentor-host">
                <header className="mentor-header col-xl-8">
                    <h1 className="mentor-title">
                        <Translation text={this.props.isTutor ? 'tutors.title' : 'mentors.title'}/>
                    </h1>
                    <p className="mentor-subtitle">
                        <Translation text={this.props.isTutor ? 'tutors.subtitle' : 'mentors.subtitle'}/>
                    </p>
                </header>
                <SearchListWrapper
                    schoolSearchType={FieldOfStudySearchRoute.MENTOR}
                    searchListType={SearchListType.MENTOR_LIST}
                    componentFactory={
                        () =>
                            <MentorsList isTutor={this.props.isTutor}/>}
                />

                <footer>
                    <Pagination listMetadata={this.props.listMetadata} changePage={this.props.changeMentorsPagination}/>
                </footer>
            </article>
        );
    }
}

export default connect(
    (state: RootState) => ({
        listMetadata: mentorsListMetadataSelector(state)
    }),
    {
        changeBreadcrumbs,
        changeMentorHostType,
        changeMentorsPagination
    }
)(withTranslation()(withRouter(MentorHost)));
