import {IModelServiceDefinitionApiResponse, RestQueryParams} from 'educat-common-web';
import {Observable} from 'rxjs';
import {serviceSubjectTestAPI} from './provider/serviceSubjectTestProvider';


export function getServiceDefinitionsAPI(
    authToken: string | null
): Observable<typeof IModelServiceDefinitionApiResponse> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return serviceSubjectTestAPI.get(
        `service_definitions?serviceSubjectTest[null]=false`,
        new RestQueryParams(),
        headers
    );
}
