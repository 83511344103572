import React from "react";
import {Observable, of, Subscription} from "rxjs";
import styles from "./styles.module.scss";
import {WithTranslation, withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {catchError, tap} from "rxjs/operators";
import {
    authTokenSelector,
    BasicModal, cancelConsultationAPI,
    ConsultationStatus,
    CustomCard,
    CustomCardType,
    IModelCartOnlineConsultation,
    IModelCartServiceInstance,
    isNotNullOrUndefined,
    isNullOrUndefined,
    Translation,
} from "educat-common-web";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import MentorItemCalendar, {ModalCoordinates} from "../../Cart/CartCard/MentorItemCalendar";
import MentorCalendarModal from "../../Cart/CartCard/MentorCalendarModal";
import {getBookedServiceInstancesAPI} from "../../../api/getBookedServiceIntances";
import {rescheduleOnlineConsultationAPI} from "../../../api/rescheduleOnlineConsultation";
import {createOnlineConsultationAPI, ICreateOnlineConsultation} from "../../../api/createOnlineConsultation";
const uuid = require("uuid/v4");

interface IConnectedServiceHourSelectionModalProps {
    readonly authToken: string | null;
}

interface IExternalServiceHourSelectionModalProps {
    readonly isModalVisible: boolean;
    readonly toggleModal: () => void;
    readonly serviceInstance: typeof IModelCartServiceInstance;
}

interface IServiceHourSelectionModalProps extends
    IConnectedServiceHourSelectionModalProps,
    IExternalServiceHourSelectionModalProps,
    WithTranslation {}

interface IServiceHourSelectionModalState {
    readonly isLoading: boolean;
    readonly allServiceInstances: any[];
    readonly bookedConsultationSlots: typeof IModelCartOnlineConsultation[] | null;
    readonly modalCalendarId: string | null;
    readonly modalCalendar: {[key: string]: any} | null;
    readonly modalStartDate: Date | null;
    readonly modalSelectedDate: Date | null;
    readonly modalAdditionalUnavailableDates: Date[];
    readonly modalCoordinates: ModalCoordinates | null;
    readonly onModalDateSelected?: (date: Date) => void;
}

class ServiceHourSelectionModal extends React.Component<IServiceHourSelectionModalProps, IServiceHourSelectionModalState> {
    readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IServiceHourSelectionModalProps) {
        super(props);

        this.state = {
            isLoading: true,
            allServiceInstances: [],
            bookedConsultationSlots: [],
            modalCalendarId: null,
            modalCalendar: null,
            modalStartDate: null,
            modalSelectedDate: null,
            modalAdditionalUnavailableDates: [],
            modalCoordinates: null,
        };
        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.getBookedServiceInstances();
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <BasicModal isModalShown={this.props.isModalVisible} closeModal={this.props.toggleModal}>
                <div className={styles.modalWrapper}>
                    <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Header>
                            <div className={styles.closeModalContainer}>
                                <button className={styles.btnClose} onClick={() => this.props.toggleModal()}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>

                            <h2 className={styles.modalHeader}>
                                <Translation text="payments.selectServiceHour.title"/>
                            </h2>
                        </CustomCard.Header>
                        <CustomCard.Body>
                            <div className={styles.modalContent}>
                                <MentorItemCalendar serviceInstance={this.props.serviceInstance}
                                                    mentorServiceDefinitions={this.props.serviceInstance.mentorServiceDefinitions}
                                                    allServiceInstances={this.state.allServiceInstances}
                                                    bookedConsultationSlots={this.state.bookedConsultationSlots}
                                                    openCalendarModal={this.openCalendarModal}
                                                    isServiceHourSelection={true}
                                                    removeSlot={this.removeSlot}
                                                    onDateSelected={this.onDateSelected}/>
                            </div>


                            <MentorCalendarModal isModalVisible={this.isModalShown}
                                                 calendarId={this.state.modalCalendarId as string}
                                                 closeModal={this.closeCalendarModal}
                                                 startDate={this.state.modalStartDate as Date}
                                                 selectedDate={this.state.modalSelectedDate as Date}
                                                 additionalUnavailableDates={this.state.modalAdditionalUnavailableDates}
                                                 modalCoordinates={this.state.modalCoordinates}
                                                 calendarDetails={this.state.modalCalendar}
                                                 onDateSelected={(date: Date) => {
                                                     if (isNotNullOrUndefined(this.state.onModalDateSelected)) {
                                                         (this.state.onModalDateSelected as any)(date);
                                                     }
                                                     this.closeCalendarModal();
                                                 }}/>
                        </CustomCard.Body>
                    </CustomCard>
                </div>
            </BasicModal>
        )
    }

    private openCalendarModal = (
        onModalDateSelected: (date: Date) => void,
        modalCalendarId: string,
        modalCalendar: {[key: string]: any} | null,
        modalAdditionalUnavailableDates: Date[],
        modalStartDate: Date,
        modalCoordinates: ModalCoordinates | null,
        modalSelectedDate: Date|null = null
    ) => {
        this.setState({
            onModalDateSelected,
            modalCalendarId: modalCalendarId,
            modalCalendar: modalCalendar,
            modalStartDate: modalStartDate,
            modalSelectedDate: modalSelectedDate,
            modalAdditionalUnavailableDates: modalAdditionalUnavailableDates
        });
    };

    private get isModalShown(): boolean {
        return isNotNullOrUndefined(this.state.modalCalendarId);
    };

    private closeCalendarModal = () => {
        this.setState({onModalDateSelected: undefined, modalCalendarId: null, modalStartDate: null, modalAdditionalUnavailableDates: []});
        this.getBookedServiceInstances();
    };

    private getBookedServiceInstances = () => {
        this.subscriptions.push(
            getBookedServiceInstancesAPI(this.props.authToken, this.props.serviceInstance.id).pipe(
                tap((resp: {[key: string]: any}) => {
                    const bookedConsultationSlots: typeof IModelCartOnlineConsultation[] = (resp['hydra:member'] || [])
                        .filter((slot: {[key: string]: any}) => isNotNullOrUndefined(slot) && slot.status !== ConsultationStatus.CANCELLED)
                        .map((slot: { [key: string]: any }) => ({
                            from: slot.startsAt,
                            to: slot.endsAt,
                            '@type': slot['@type'],
                            '@id': slot.id
                        }));
                    this.setState({bookedConsultationSlots: bookedConsultationSlots, isLoading: false});
                }),
                catchError((error: any) => {
                    this.setState({isLoading: false});
                    this.alertManager.handleApiError(error);
                    return of();
                })
            ).subscribe()
        )
    };

    private removeSlot = (consultationDate: typeof IModelCartOnlineConsultation) => {
        const consultationId = consultationDate?.['@id'];
        if (isNullOrUndefined(consultationId)) {
            return;
        }

        return this.subscriptions.push(
            this.handleApiRequest(cancelConsultationAPI(this.props.authToken, consultationId),
            'payments.selectServiceHour.consultationCancelled')
            .subscribe()
        )
    };

    private onDateSelected = (date: Date | null | undefined, consultationDate?: typeof IModelCartOnlineConsultation) => {
        if (isNotNullOrUndefined(consultationDate)) {
            return this.rescheduleConsultation(consultationDate, date);
        }

        return this.createConsultation(date);
    };

    private createConsultation = (date: Date | null | undefined) => {
        if (null === date || undefined === date) {
            return;
        }

        const payload: ICreateOnlineConsultation = {
            serviceInstanceId: this.props.serviceInstance.id,
            onlineConsultations: [
                {
                    onlineConsultationId: uuid(),
                    startsAt: date.toISOString(),
                    secret: uuid(),
                    notes: null
                }
            ]
        };

        this.subscriptions.push(
            this.handleApiRequest(createOnlineConsultationAPI(this.props.authToken, payload),
            'payments.selectServiceHour.consultationScheduled')
            .subscribe()
        )
    };
    private rescheduleConsultation = (consultationDate: typeof IModelCartOnlineConsultation, date: Date | null | undefined) => {
        const consultationId = consultationDate?.['@id'];
        if (isNullOrUndefined(consultationId) || undefined === date || null === date) {
            return;
        }

        return this.subscriptions.push(
            this.handleApiRequest(rescheduleOnlineConsultationAPI(this.props.authToken, consultationId, date.toISOString()),
            'payments.selectServiceHour.consultationRescheduled')
            .subscribe()
        )
    };

    private handleApiRequest = (api: Observable<any>, successMessage: string) => {
        this.setState({isLoading: true});

        return api.pipe(
            tap(() => {
                this.alertManager.addAlert(successMessage, null, null, true);
                this.getBookedServiceInstances();
                this.setState({isLoading: false});
            }),
            catchError((error: any) => {
                this.setState({isLoading: false});
                this.alertManager.handleApiError(error);
                return of(error);
            })
        )
    }
}

export default connect(
    (state: any) => ({
        authToken: authTokenSelector(state),
    }),
    {}
)(withTranslation()(ServiceHourSelectionModal));
