import {Observable} from "rxjs";
import {educatAPI, RestQueryParams} from "educat-common-web";

export function confirmPaymentAPI(paymentId: string, authToken: string | null): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.put(
        `payment/${paymentId}/confirm?XDEBUG_SESSION_START=PHPSTORM`,
        {},
        new RestQueryParams(),
        headers
    );
}
