import {InputType} from 'educat-common-web';
import {ValidationRules} from 'educat-common-web';
import {FormControlType, IFormConfig, MultiSelectType} from 'educat-common-web';

export const operationHistoryFiltersConfig: typeof IFormConfig = {
    class: 'default-form operation-history-filters',
    dataAccessor: (data: any) => data, // data is not grouped
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'sort_by',
            class: 'row',
            controls: {
                [`createdAt[after]`]: {
                    controlType: 'control',
                    additionalStyles: '',
                    formControlType: FormControlType.DATE,
                    validationRules: [
                        {
                            name: ValidationRules.IS_DATE_LTE_THAN,
                            params: {
                                valueAccessor: (data: any) => data,
                                compareAccessor: () => new Date(),
                            }
                        }
                    ],
                    placeholder: 'DD/MM/YYYY',
                    isLabelHidden: false,
                    label: 'payments.operationHistory.filters.operationDateFrom',
                    maxDate: new Date(),
                    type: InputType.DATE,
                    dateFormat: 'dd/MM/yyyy',
                    minDate: new Date(1920,1,1),
                    showYearDropdown: true,
                    hostClass: 'col-xl-4',
                },
                [`createdAt[before]`]: {
                    controlType: 'control',
                    additionalStyles: '',
                    formControlType: FormControlType.DATE,
                    validationRules: [
                        {
                            name: ValidationRules.IS_DATE_LTE_THAN,
                            params: {
                                valueAccessor: (data: any) => data,
                                compareAccessor: () => new Date(),
                            }
                        },
                        {name: ValidationRules.IS_REQUIRED}
                    ],
                    placeholder: 'DD/MM/YYYY',
                    isLabelHidden: false,
                    label: 'payments.operationHistory.filters.operationDateTo',
                    maxDate: new Date(),
                    type: InputType.DATE,
                    dateFormat: 'dd/MM/yyyy',
                    minDate: new Date(1920,1,1),
                    showYearDropdown: true,
                    openToDate: new Date(),
                    hostClass: 'col-xl-4',
                },
                period: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    multiselectOptions: [],
                    validationRules: [],
                    placeholder: 'payments.operationHistory.filters.forPeriod.placeholder',
                    label: 'payments.operationHistory.filters.forPeriod.title',
                    hostClass: 'col-xl-4',
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return [];
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }

                        return data;
                    },

                }
            },

        }
    ]
};
