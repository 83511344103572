import {educatAPI, IModelServiceDefinitionApiResponse, RestQueryParams} from 'educat-common-web';
import {Observable} from 'rxjs';


export function getTestPackagesAPI(
    authToken: string | null
): Observable<typeof IModelServiceDefinitionApiResponse> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return educatAPI.get(
        `service_definitions?serviceTestPackage[null]=false`,
        new RestQueryParams(),
        headers
    );
}
