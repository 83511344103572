import {RestQueryParams} from 'educat-common-web';
import {Observable} from 'rxjs';
import {testRealisationAPI} from './provider/testRealisationProvider';


export function getTestResultAPI(authToken: string, testRealisationId: string): Observable<any> {
    return testRealisationAPI.get(
        `test_realisations/${testRealisationId}`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
