import React from 'react';
import {Translation, IPrice, Price, IFormConfig, Form, FormControlChangeType, isSameValue} from 'educat-common-web';
import {BehaviorSubject, Subscription} from 'rxjs';
import styles from "./styles.module.scss";
import {cartQuantityFormConfig} from './cartQuantityFormConfig';
import {debounceTime, distinctUntilChanged, filter, tap} from 'rxjs/operators';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {isCartLoadingSelector} from '../../../../store/selectors/cartSelectors';
import {updateCartItemQuantity} from '../../../../store/reducers/cartSlice';


interface IConnectedCartItemQuantityProps {
    readonly isCartLoading: boolean;
    readonly updateCartItemQuantity: typeof updateCartItemQuantity;
}

interface IExternalCartItemQuantityProps {
    readonly quantity: number;
    readonly servicePrice: typeof IPrice;
    readonly itemId: string;
    readonly maxValue?: number | null;
    readonly disabled?: boolean | null;
}

interface ICartItemQuantityProps extends IConnectedCartItemQuantityProps,
    IExternalCartItemQuantityProps {}

interface ICartItemQuantityState {
    formConfig: typeof IFormConfig;
}


class CartItemQuantity extends React.Component<ICartItemQuantityProps, ICartItemQuantityState> {
    private subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(props: ICartItemQuantityProps) {
        super(props);

        this.state = {
            formConfig: cartQuantityFormConfig(this.disabled, this.props.maxValue),
        };
    }

    componentDidMount(): void {
        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                debounceTime(500),
                distinctUntilChanged(),
                tap((data: any)  => this.onFormValueChange(data.value)),
            ).subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<ICartItemQuantityProps>) {
        if (!isSameValue(this.props.isCartLoading, prevProps?.isCartLoading) || !isSameValue(this.props.disabled, prevProps?.disabled)) {
            this.setState({
                formConfig: cartQuantityFormConfig(this.props.disabled, this.props.maxValue),
            });
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <div className={`${styles.action} col-xxl-3`}>
                <div className={styles.quantity}>
                    <p>
                        <Translation text="cart.cartDetails.amount"/>
                    </p>

                    <Form config={this.state.formConfig}
                          onValueStateChange={this.onValueStateChange}
                          value={{quantity: this.props.quantity}}
                          controlName={'quantityForm'}/>
                </div>
                <div className={styles.price}>
                    <p className={styles.title}>
                        <Translation text="cart.cartDetails.price"/>
                    </p>
                    <p className={styles.value}>
                        <Price price={this.props.servicePrice} />
                    </p>
                </div>
            </div>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onFormValueChange = (value: any) => {
        if (this.disabled) {
            return;
        }

        return this.props.updateCartItemQuantity(this.props.itemId, value.quantity);
    };

    private get disabled(): boolean {
        return this.props.isCartLoading || true === this.props.disabled;
    }
}

export default connect(
    (state: RootState) => ({
        isCartLoading: isCartLoadingSelector(state),
    }),
    {
        updateCartItemQuantity,
    }
)(CartItemQuantity);
