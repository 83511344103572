import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export function createPaymentAPI(authToken: string | null, cartId: string, paymentMethod: string): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.post(
        `payments`,
        {
            subjectId: cartId,
            paymentMethod: paymentMethod
        },
        new RestQueryParams(),
        headers
    );
}
