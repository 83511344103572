import {educatAPI, IModelServiceApplicationElementApiResponse, RestQueryParams} from 'educat-common-web';
import {Observable} from 'rxjs';


export function getServiceApplicationElementsAPI(
    authToken: string | null,
    params?: typeof RestQueryParams
): Observable<typeof IModelServiceApplicationElementApiResponse> {
    let queryParams = params ? params : new RestQueryParams(),
        headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.get(
        'service_application_elements',
        queryParams,
        headers
    );
}
