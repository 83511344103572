import {
    applicationOriginList,
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    InputType,
    MultiSelectType,
    collectionInputDataMapper,
    collectionOutputDataMapper,
    ValidationRules
} from 'educat-common-web';
import {BaccalaureateSubjectType, BaccalaureateType, StudyLevel} from './index';
import {isNullOrUndefined} from 'educat-common-web';


export type baccalaureateSubjectsConfig = {
    baccalaureateSubjectsNumber: number,
    onAdd?: () => {},
    onRemove?: (index: number) => {},
};

// list of value keys that can possibly influence form config
export const VOLATILE_PROFILE_FORM_KEYS: ReadonlyArray<string> = ['noCountry', 'country', 'noSchoolStudyField', 'school', 'baccalaureateType', 'baccalaureateType', 'studyLevel'];

export const profileFormConfig: typeof IFormConfig = (
    value: any,
    baccalaureateSubjectsControlsFormConfig: baccalaureateSubjectsConfig,
    countriesList?: typeof IMultiselectOption[] | [],
    subjectsList?: typeof IMultiselectOption[] | [],
    schoolList?: typeof IMultiselectOption[] | [],
    collegeList?: typeof IMultiselectOption[] | [],
    schoolStudyFieldList?: typeof IMultiselectOption[] | [],
    handleMultiselectInputChange?: any,
    isProfileFormShortened?: boolean) => {

    const baccalaureateSubjectControls: any[] = [];
    for (let i = 0; i < baccalaureateSubjectsControlsFormConfig.baccalaureateSubjectsNumber; i++) {
        baccalaureateSubjectControls.push(addBaccalaureateSubjectControls(subjectsList || [], i, baccalaureateSubjectsControlsFormConfig.onRemove, value?.baccalaureateType));
    }

    const customBaccalaureateTypeControl = [];
    if (BaccalaureateType.CUSTOM === value?.baccalaureateType) {
        customBaccalaureateTypeControl.push(addBaccalaureateTypeControl());
    }

    const studyLevelControls = [];
    if (StudyLevel.OTHER === value?.studyLevel) {
        studyLevelControls.push(addStudyLevelControl());
    }

    const highSchoolControl = [];
    if (!isProfileFormShortened) {
        highSchoolControl.push(addHighSchoolControl(schoolList || [], handleMultiselectInputChange))
    }

    const validationRules = isProfileFormShortened ? [{name: ValidationRules.IS_REQUIRED, params: {}}] : [];

    return {
        class: 'profile-form',
        dataAccessor: (data: any) => data, // data is not grouped
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);

            return previousStateSnapshot;
        },
        controls: [
            ...highSchoolControl,
            {
                controlType: 'group',
                key: 'country',
                class: 'row',
                controls: {
                    country: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SINGLE,
                        multiselectOptions: countriesList ? countriesList : [],
                        validationRules: validationRules,
                        placeholder: 'profile.profileForm.selectPlaceholder',
                        label: 'profile.profileForm.controls.whereToStudy',
                        hostClass: 'col-xl-12 form-row required',
                        disabled: true === value?.noCountry,
                        inputDataMapper: collectionInputDataMapper,
                        outputDataMapper: collectionOutputDataMapper,
                    },
                    noCountry: {
                        controlType: 'control',
                        defaultValue: false,
                        formControlType: FormControlType.CHECKBOX,
                        validationRules: [],
                        placeholder: '',
                        label: '',
                        isLabelHidden: true,
                        isCheckboxLabelRaw: false,
                        checkboxLabel: 'profile.profileForm.controls.noOptionCheckbox',
                        hostClass: 'offset-xl-6 col-xl-6 form-row no-option-checkbox'
                    }
                }
            },
            {
                controlType: 'group',
                key: 'school',
                class: 'row',
                controls: {
                    school: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SINGLE,
                        multiselectOptions: collegeList ? collegeList : [],
                        validationRules: validationRules,
                        placeholder: 'profile.profileForm.autocompletePlaceholder',
                        label: 'profile.profileForm.controls.whatCollege',
                        hostClass: 'col-xl-12 form-row required',
                        inputDataMapper: collectionInputDataMapper,
                        outputDataMapper: collectionOutputDataMapper,
                        openMenuOnClick: false,
                        disabled:  true === value?.noSchool || isNullOrUndefined(value?.country),
                        handleMultiselectInputChange: (value: any) => {
                            if (handleMultiselectInputChange) {
                                return handleMultiselectInputChange(value, 'college');
                            }
                        }
                    },
                    noSchool: {
                        controlType: 'control',
                        defaultValue: false,
                        formControlType: FormControlType.CHECKBOX,
                        validationRules: [],
                        placeholder: '',
                        label: '',
                        isLabelHidden: true,
                        isCheckboxLabelRaw: false,
                        checkboxLabel: 'profile.profileForm.controls.noOptionCheckbox',
                        hostClass: 'offset-xl-6 col-xl-6 form-row no-option-checkbox'
                    }
                }
            },
            {
                controlType: 'group',
                key: 'school_study_field',
                class: 'row',
                controls: {
                    schoolStudyField: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SINGLE,
                        multiselectOptions: schoolStudyFieldList ? schoolStudyFieldList : [],
                        validationRules: validationRules,
                        placeholder: 'profile.profileForm.selectPlaceholder',
                        label: 'profile.profileForm.controls.studySubject',
                        hostClass: 'col-xl-12 form-row required',
                        inputDataMapper: collectionInputDataMapper,
                        outputDataMapper: collectionOutputDataMapper,
                        disabled: true === value?.noSchoolStudyField || isNullOrUndefined(value?.school),
                    },
                    noSchoolStudyField: {
                        controlType: 'control',
                        defaultValue: false,
                        formControlType: FormControlType.CHECKBOX,
                        validationRules: [],
                        placeholder: '',
                        label: '',
                        isLabelHidden: true,
                        isCheckboxLabelRaw: false,
                        checkboxLabel: 'profile.profileForm.controls.noOptionCheckbox',
                        hostClass: 'offset-xl-6 col-xl-6 form-row no-option-checkbox'
                    }
                }
            },
            {
                controlType: 'group',
                key: 'study_level',
                class: 'row',
                controls: {
                    studyLevel: {
                        hostClass: 'col-xl-12 form-row required',
                        controlType: 'control',
                        // defaultValue: null,
                        formControlType: FormControlType.RADIO,
                        options: [
                            {value: 'bachelor', displayValue: 'Licencjackie'},
                            {value: 'master', displayValue: 'Magisterskie'},
                            {value: 'other', displayValue: 'Inne'}
                        ],
                        validationRules: validationRules,
                        placeholder: 'profile.profileForm.selectPlaceholder',
                        label: 'profile.profileForm.controls.studyLevel',
                        type: InputType.RADIO,
                    }
                }
            },
            ...studyLevelControls,
            {
                controlType: 'group',
                key: 'baccalaureate_type',
                class: 'row',
                controls: {
                    baccalaureateType: {
                        hostClass: 'col-xl-12 form-row required',
                        controlType: 'control',
                        // defaultValue: null,
                        formControlType: FormControlType.RADIO,
                        options: [
                            {value: BaccalaureateType.POLISH_BACCALAUREATE, displayValue: 'Polska matura'},
                            {value: BaccalaureateType.INTERNATIONAL_BACCALAUREATE, displayValue: 'IB (Matura międzynarodowa)'},
                            {value: BaccalaureateType.CUSTOM, displayValue: 'Inne'}
                        ],
                        validationRules: validationRules,
                        placeholder: 'profile.profileForm.selectPlaceholder',
                        label: 'profile.profileForm.controls.maturityExamType',
                        type: InputType.RADIO,
                    }
                }
            },
            ...customBaccalaureateTypeControl,
            ...baccalaureateSubjectControls,
            {
                controlType: 'group',
                key: 'add_subject',
                class: 'row justify-content-center',
                controls: {
                    addSubject: {
                        hostClass: 'col-xl-12 form-row',
                        controlType: 'control',
                        formControlType: FormControlType.BUTTON,
                        buttonType: 'button',
                        inputStyles: 'btn-add-subject',
                        defaultContainerStyles: '',
                        containerStyles: 'd-flex flex-row-reverse',
                        defaultValue: null,
                        buttonDisabled: false,
                        btnText: 'profile.profileForm.controls.addSubject',
                        customClickHandler: () => {
                            if (baccalaureateSubjectsControlsFormConfig.onAdd) {
                                return baccalaureateSubjectsControlsFormConfig.onAdd();
                            }
                        }
                    },
                },
            },
            {
                controlType: 'group',
                key: 'study_start_year',
                class: 'row',
                controls: {
                    studyStartYear: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SINGLE,
                        multiselectOptions: getStudyStartYearOptions(2020),
                        validationRules: validationRules,
                        placeholder: 'profile.profileForm.selectPlaceholder',
                        label: 'profile.profileForm.controls.studyStart',
                        hostClass: 'col-xl-12 form-row required',
                        inputDataMapper: collectionInputDataMapper,
                        outputDataMapper: collectionOutputDataMapper,
                    }
                }
            },
            {
                controlType: 'group',
                key: 'origin',
                class: 'row',
                controls: {
                    origin: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SINGLE,
                        multiselectOptions: applicationOriginList,
                        validationRules: validationRules,
                        placeholder: 'profile.profileForm.selectPlaceholder',
                        label: 'profile.profileForm.controls.whereFrom',
                        hostClass: 'col-xl-12 form-row required',
                        inputDataMapper: collectionInputDataMapper,
                        outputDataMapper: collectionOutputDataMapper
                    }
                }
            },
            {
                controlType: 'group',
                key: 'profile_update_submit',
                class: 'row justify-content-center',
                controls: {
                    submitButton: {
                        controlType: 'control',
                        formControlType: FormControlType.BUTTON,
                        buttonType: 'submit',
                        inputStyles: 'btn btn-theme',
                        defaultContainerStyles: '',
                        containerStyles: 'button-container w-100 d-flex flex-row-reverse',
                        defaultValue: null,
                        btnText: isProfileFormShortened ? 'profile.profileForm.send' : 'profile.profileForm.save',
                        buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                            if ((mappedOutputValue.country ||( !mappedOutputValue.country && mappedOutputValue.noCountry)) &&
                                (mappedOutputValue.schoolStudyField || (!mappedOutputValue.schoolStudyField && mappedOutputValue.noSchoolStudyField)) &&
                                (mappedOutputValue.school ||  (!mappedOutputValue.school && mappedOutputValue.noSchool)) &&
                                mappedOutputValue.baccalaureateType && mappedOutputValue.studyLevel &&
                                mappedOutputValue.baccalaureateSubject0 && mappedOutputValue.subjectType0 &&
                                mappedOutputValue.studyStartYear && mappedOutputValue.origin) {
                                return false;
                            }

                            return !isFormValid;
                        }
                    },
                },
            },
        ]
    };
};

export const addBaccalaureateSubjectControls = (
    subjectsList: typeof IMultiselectOption[] | [],
    index: number,
    onRemove?: (index: number) => {},
    baccalaureateType?: BaccalaureateType
) => {
    const isFirst = (0 === index);

    const controls: any = {
        [`baccalaureateSubject${index}`]: {
            controlType: 'control',
            defaultValue: '',
            formControlType: FormControlType.AUTOCOMPLETE,
            multiselectType: MultiSelectType.SINGLE,
            multiselectOptions: subjectsList,
            validationRules: [],
            placeholder: 'profile.profileForm.selectPlaceholder',
            label: isFirst ? 'profile.profileForm.controls.maturityExamSubjects' : '',
            hostClass: isFirst ? 'col-xl-12 form-row required' : 'col-xl-12 form-row position-relative',
            inputDataMapper: collectionInputDataMapper,
            outputDataMapper: collectionOutputDataMapper,
        },
    };

    if (!isFirst) {
        controls[`removeSubject${index}`] = {
            hostClass: 'remove-subject',
            controlType: 'control',
            formControlType: FormControlType.BUTTON,
            buttonType: 'button',
            inputStyles: 'btn-remove-subject',
            defaultContainerStyles: '',
            containerStyles: '',
            defaultValue: null,
            buttonDisabled: false,
            btnText: 'x',
            customClickHandler: () => {
                if (onRemove) {
                    onRemove(index);
                }
            }
        };
    }
    controls[`subjectType${index}`] = {
        hostClass: 'col-xl-12 form-row',
        controlType: 'control',
        formControlType: FormControlType.RADIO,
        options: baccalaureateType === BaccalaureateType.INTERNATIONAL_BACCALAUREATE ?
            [
                {value: BaccalaureateSubjectType.PRIMARY, displayValue: 'Standard Level'},
                {value: BaccalaureateSubjectType.EXTENDED, displayValue: 'Higher Level'}
            ] :
            [
                {value: BaccalaureateSubjectType.PRIMARY, displayValue: 'Podstawowy'},
                {value: BaccalaureateSubjectType.EXTENDED, displayValue: 'Rozszerzony'},
            ],
        validationRules: [],
        placeholder: '',
        label: '',
        type: InputType.RADIO,
    };

    return {
        controlType: 'group',
        key: `baccalaureate_subjects_${index}`,
        class: 'col-xl-12 form-row position-relative',
        controls: controls,
    };
};

export const addStudyLevelControl = () => (
    {
        controlType: 'group',
        key: 'custom_study_level',
        class: 'row justify-content-end',
        controls: {
            customStudyLevel: {
                controlType: 'control',
                defaultValue: '',
                formControlType: FormControlType.INPUT,
                validationRules: [],
                placeholder: 'profile.profileForm.controls.customStudyLevel',
                label: '',
                type: InputType.TEXT,
                hostClass: 'col-6',
            },
        }
    }
);

export const addHighSchoolControl = (
    schoolList?: typeof IMultiselectOption[] | [],
    handleMultiselectInputChange?: any
) => (
    {
        controlType: 'group',
        key: 'high_school',
        class: 'row',
        controls: {
            highSchool: {
                controlType: 'control',
                defaultValue: '',
                formControlType: FormControlType.AUTOCOMPLETE,
                multiselectType: MultiSelectType.SINGLE,
                multiselectOptions: schoolList ? schoolList : [],
                validationRules: [],
                placeholder: 'profile.profileForm.autocompletePlaceholder',
                label: 'profile.profileForm.controls.highSchool',
                hostClass: 'col-xl-12 form-row required',
                inputDataMapper: collectionInputDataMapper,
                outputDataMapper: collectionOutputDataMapper,
                openMenuOnClick: false,
                handleMultiselectInputChange: (value: any) => {
                    if (handleMultiselectInputChange) {
                        return handleMultiselectInputChange(value, 'highSchool');
                    }
                }
            }
        }
    }
);

export const addBaccalaureateTypeControl = () => (
    {
        controlType: 'group',
        key: 'custom_baccalaureate_type',
        class: 'row justify-content-end',
        controls: {
            customBaccalaureateType: {
                controlType: 'control',
                defaultValue: '',
                formControlType: FormControlType.INPUT,
                validationRules: [],
                placeholder: 'profile.profileForm.controls.customMaturityExamType',
                label: '',
                type: InputType.TEXT,
                hostClass: 'col-6',
            },
        }
    }
);

function getStudyStartYearOptions(startValue?: number) {
    let options: typeof IMultiselectOption[] = [];
    const yearValue = startValue || new Date().getFullYear();

    for (let step = 0; step <= 8; step++) {
        options.push({
            value: yearValue + step,
            label: yearValue + step
        })
    }
    return options;
}
