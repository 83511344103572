import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import styles from "./styles.module.scss";
import {Translation} from "educat-common-web";



export enum ApplicationSteps {
    DIRECTION = 'direction',
    SCHOOL = 'school',
    APPLIED = 'application',
    DOCUMENTS = 'documents',
    STUDY = 'study'
}

export const applicationProcessSteps: ApplicationSteps[] = [
    ApplicationSteps.DIRECTION,
    ApplicationSteps.SCHOOL,
    ApplicationSteps.APPLIED,
    ApplicationSteps.DOCUMENTS,
    ApplicationSteps.STUDY,
];

interface IConnectedApplicationWizardProps {}

interface IExternalApplicationWizardProps {}

interface IApplicationWizardProps extends IConnectedApplicationWizardProps,
    IExternalApplicationWizardProps,
    RouteComponentProps {
}

interface IApplicationWizardState {
    activeApplicationStep: ApplicationSteps;
}


class ApplicationWizard extends React.Component<IApplicationWizardProps, IApplicationWizardState> {
    private subscription: Subscription | null = null;
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;
    steps: any[] = [];

    constructor(props: IApplicationWizardProps) {
        super(props);

        this.state = {
            activeApplicationStep: ApplicationSteps.DOCUMENTS
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {}

    componentWillUnmount() {
        if (null !== this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        return (
            <div>
                {this.renderApplicationSteps()}
            </div>
        );
    }

    private renderApplicationSteps() {
        this.steps = this.getWizardSteps();
        const wizardSteps: any[] = [];
        this.steps.forEach((step: any, i: number) => {
            const active = step.isActive ? styles.active : '';
            const enabled = step.isEnabled ? styles.enabled : '';
            const imgUrl = step.isEnabled ? `icon_${step.name}_active.png` : `icon_${step.name}.png`;

            wizardSteps.push(
                <li className={`${styles.step} ${active} ${enabled}`} key={i}>
                    <div className={styles.stepDetails}>
                        <div className={styles.title}>
                            <h3><Translation text={`dashboard.applicationProgress.steps.${step.name}.title`}/></h3>
                            <span className={styles.stepComplete}>
                                <i className="feather icon-check"/>
                            </span>
                        </div>
                        <div className={styles.detailsContainer}>
                            <img src={require(`../../../assets/images/${imgUrl}`).default} alt={step.name}/>
                            <div className={styles.details}>
                                <Translation text={`dashboard.applicationProgress.steps.${step.name}.description`}/>
                            </div>
                        </div>
                    </div>
                </li>
            )
        });

        return (
            <div className={`${styles.applicationProgress} ${styles[this.state.activeApplicationStep]}`}>
                <div className={styles.bg}/>
                <ul className={styles.steps}>
                    { wizardSteps }
                </ul>
            </div>
        );
    }

    private getWizardSteps(): any[] {
        const stepArray = Array.from(applicationProcessSteps);
        const stepsObj: any[] = [];
        stepArray.forEach((stepName: ApplicationSteps, i: number) => {
            const stepHeadIndex = applicationProcessSteps.indexOf(this.state.activeApplicationStep);
            const enabled = (i <= stepHeadIndex) && (stepsObj.length ? stepsObj[stepsObj.length - 1].isEnabled : true);

            stepsObj.push({
                name: stepName,
                isActive: stepName === this.state.activeApplicationStep,
                isEnabled: enabled,
            });
        });

        return stepsObj;
    }
}

export default withRouter(ApplicationWizard);
