import React from "react";
import {authTokenSelector, Loader, LoaderType, Translation} from "educat-common-web";
import styles from "../styles.module.scss";
import {getLastPurchasedCartAPI} from "../../../api/getLastPurchasedCart";
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {tap} from "rxjs/operators";
import PurchasedItemsTable from "../PurchasedItemsTable";
import {Link} from "react-router-dom";

interface IPaymentCompletedProps {
    readonly authToken: string | null;
    cart: any;
}

interface IPaymentCompletedState {
    purchasedCart: any;
    isLoading: boolean;
}

class PaymentCompleted extends React.Component<IPaymentCompletedProps, IPaymentCompletedState> {

    constructor(props:any) {
        super(props);
        this.state = {
            purchasedCart: null,
            isLoading: true
        }
    }

    componentDidMount() {
        getLastPurchasedCartAPI(this.props.authToken).pipe(
            tap((response:any) => {
                this.setState({purchasedCart: response, isLoading: false})
            })
        ).subscribe();
    }

    render() {
        return (
            <div className={styles.cartWrapper}>
                <Loader showLoader={this.state.isLoading} type={LoaderType.Local}/>
                <p className={styles.title}>
                    <Translation text="cart.paymentCompleted.title" />
                </p>
                <PurchasedItemsTable serviceInstances={this.state.purchasedCart?.serviceInstances}/>
                <div className={'me-auto ms-auto'} style={{maxWidth: '900px'}}>
                    <p className={'fw-bold mb-4'}><Translation text="cart.paymentCompleted.nextStep"/></p>
                    <div className={'d-flex flex-row w-100 justify-content-between align-items-center mb-2'}>
                        <Translation text="cart.paymentCompleted.goToPayments"/>
                        <div className="button-wrapper justify-content-center w-25">
                            <Link to="/panel/payments" className={"btn btn-theme btn-rounded w-100"}>
                                <Translation text={'cart.paymentCompleted.buttons.payments'}/>
                            </Link>
                        </div>
                    </div>
                    <div className={'d-flex flex-row w-100 justify-content-between align-items-center'}>
                        <Translation text="cart.paymentCompleted.goToDashboard"/>
                        <div className="button-wrapper justify-content-center w-25">
                            <Link to="/panel/dashboard" className={"btn btn-theme btn-rounded w-100"}>
                                <Translation text={'cart.paymentCompleted.buttons.dashboard'}/>
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
}))(PaymentCompleted);

