import React from 'react';
import styles from './styles.module.scss';
// import ReactStars from 'react-rating-stars-component';
import {getMentorName, IModelMentor} from 'educat-common-web';
import {RouteComponentProps, withRouter} from 'react-router';
import AccountImage from '../../Common/AccountImage';


interface IExternalMentorCardProps {
    readonly isTutor: boolean;
    readonly mentor: typeof IModelMentor;
}

interface IMentorCardProps extends IExternalMentorCardProps,
    RouteComponentProps {
}

interface IMentorCardState {
}

class MentorCard extends React.Component<IMentorCardProps, IMentorCardState> {

    render() {
        const mentor = this.props.mentor;
            // mentorImage = mentor.account.avatar
            //     ? mentor.account.avatar.mediumThumb
            //     : noUser;

        return (
            <div className={styles.mentorDetailsContainer}
                 onClick={() => this.props.history.push(`/panel/${this.props.isTutor ? 'tutors' : 'mentors'}${mentor.account?.user?.active ? '/'+mentor.id : ''}`)}>
                <div className={styles.mentorDetails}>
                    <div className={styles.itemImgContainer}>
                        <AccountImage account={this.props.mentor?.account} type={'mediumThumb'} adaptive={true}/>
                    </div>
                    <div className={styles.details}>
                        <p className={styles.name}>
                            {getMentorName(mentor)}
                        </p>
                        {/*Hidden in current version*/}
                        {/*<ReactStars*/}
                        {/*    count={5}*/}
                        {/*    value={mentor.rating}*/}
                        {/*    edit={false}*/}
                        {/*    emptyIcon={<i className="star-icon empty"></i>}*/}
                        {/*    filledIcon={<i className="star-icon full"></i>}*/}
                        {/*    size={24}*/}
                        {/*    activeColor="#fa9504"/>*/}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(MentorCard);
