import React from 'react';
import styles from './styles.module.scss';
import {Translation, truncateString} from 'educat-common-web';
import {DisplayedFilter} from '../../../models/selectedFilters';
import {WithTranslation, withTranslation} from 'react-i18next';


interface IExternalSelectedFilterProps {
    filter: DisplayedFilter;
    filterKey: string;
    removeFilterValue: (controlKey: string, value: string) => void;
}

interface ISelectedFilterProps extends IExternalSelectedFilterProps,
    WithTranslation {
}

interface ISelectedFilterState {
    isTooltipShown: boolean;
}

class SelectedFilter extends React.Component<ISelectedFilterProps, ISelectedFilterState> {

    constructor(props: ISelectedFilterProps) {
        super(props);

        this.state = {
            isTooltipShown: false,
        };
    }

    render() {
        const label = this.props.filterKey === 'country' ?
            this.props.t(`country.${this.props.filter.label}`) :
            (this.props.filterKey === 'languages' ? this.props.t(`language.${this.props.filter.label}`) : this.props.filter.label);

        return (
            <div className={`tooltip-wrapper ${styles.filterItem}`}>
                <div className={`tooltip-content top ${this.state.isTooltipShown ? '' : 'd-none'} ${styles.filterItemTooltip}`}>
                    {label}
                </div>
                <p onMouseOverCapture={() => this.toggleTooltipShown()}
                   onMouseOutCapture={() => this.toggleTooltipShown()}>
                    {truncateString(label, 10)}
                </p>
                <button onClick={() => this.props.removeFilterValue(this.props.filterKey, this.props.filter.value)}>
                    <Translation text={'filters.remove'}/>
                </button>
            </div>
        );
    }

    private toggleTooltipShown() {
        this.setState(state => ({isTooltipShown: !state.isTooltipShown}));
    }
}

export default (withTranslation()(SelectedFilter));
