import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams, IModelMentor} from 'educat-common-web';


export function getMyMentorsAPI(authToken: string | null, params?: typeof RestQueryParams): Observable<typeof IModelMentor> {
    let queryParams = params ? params : new RestQueryParams(),
        headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.get(
        `my_mentors`,
        queryParams,
        headers
    );
}
