import {CustomCard, Translation} from "educat-common-web";
import React from "react";
import EventCalendar from "../../Dashboard/EventCalendar";

interface IExternalEventCalendarCardProps {}

interface IEventCalendarCardProps extends IExternalEventCalendarCardProps {}

interface IEventCalendarCardState {}

class EventCalendarCard extends React.Component<IEventCalendarCardProps, IEventCalendarCardState> {
	constructor(props: IEventCalendarCardProps) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<CustomCard showLocalLoader={false}>
				<CustomCard.Header>
					<Translation text="dashboard.eventCalendar.title" />
				</CustomCard.Header>
				<CustomCard.Body>
					<EventCalendar />
				</CustomCard.Body>
			</CustomCard>
		);
	}
}

export default EventCalendarCard;
