import React from 'react';
import {CustomCard, IModelMentorServiceDefinition, isNotNullOrUndefined, Translation} from 'educat-common-web';
import ConsultationPackageItem from './ConsultationPackageItem';


interface IExternalConsultationPackagesCardProps {
    readonly mentorServiceDefinitions: typeof IModelMentorServiceDefinition[];
    readonly mentorId: string;
}

interface IConsultationPackagesCardProps extends IExternalConsultationPackagesCardProps {
}

interface IConsultationPackagesCardState {
}

class ConsultationPackagesCard extends React.Component<IConsultationPackagesCardProps, IConsultationPackagesCardState> {

    componentDidMount() {
    }

    render() {
        const serviceDefinitions = this.props.mentorServiceDefinitions;

        if (!serviceDefinitions.length) {
            return null;
        }

        return (
            <CustomCard showLocalLoader={false}>
                <CustomCard.Body>
                    <div className="mb-5">
                        <h2 className="secondary-title" id="consultationPackages">
                            <Translation text={'mentorProfile.mentorConsultationPackages.consultationTitle'}/>
                        </h2>
                        {serviceDefinitions
                            .filter(item => isNotNullOrUndefined(item.serviceConsultationPackage) && item.active)
                            .map(item => <ConsultationPackageItem consultation={item} key={item.id}/>)}

                        {serviceDefinitions
                            .filter(item => isNotNullOrUndefined(item.serviceApplicationPackage) && item.active)
                            .map(item => <ConsultationPackageItem consultation={item} key={item.id}/>)}

                        {serviceDefinitions
                            .filter(item => isNotNullOrUndefined(item.serviceConsultation) && item.active)
                            .map(item => <ConsultationPackageItem consultation={item}
                                                                  key={item.id}
                                                                  mentorId={this.props.mentorId}/>)}
                    </div>
                    {/*Commented out on client's request*/}
                    {/*<div>*/}
                    {/*    <h2 className="secondary-title">*/}
                    {/*        <Translation text={'mentorProfile.mentorConsultationPackages.applicationElementsTitle'}/>*/}
                    {/*    </h2>*/}
                    {/*    {serviceDefinitions*/}
                    {/*        .filter(item => isNotNullOrUndefined(item.serviceApplicationElement)  && item.active)*/}
                    {/*        .map(item => <ConsultationPackageItem consultation={item} key={item.id}/>)}*/}
                    {/*</div>*/}
                </CustomCard.Body>
            </CustomCard>
        );
    }
}

export default ConsultationPackagesCard;
