import {
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    MultiselectMenuPlacement,
    MultiSelectType,
    collectionInputDataMapper,
    collectionOutputDataMapper
} from 'educat-common-web';


export const mentorSelectFormConfig = (
    studyFields: typeof IMultiselectOption[] = [],
    schools: typeof IMultiselectOption[] = [],
    handleMultiselectInputChange?: any
): typeof IFormConfig => {
    return {
        controlType: 'group',
        class: 'marketplace-mentor-select-form row',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);

            return previousStateSnapshot;
        },
        controls: [
            {
                controlType: 'group',
                key: 'price',
                class: 'col-6',
                controls: {
                    price: {
                        controlType: 'control',
                        defaultValue: null,
                        formControlType: FormControlType.RANGE,
                        label: 'marketplace.mentorSelect.form.labels.price',
                        hostClass: 'col-12',
                        rangeStep: 1,
                        rangeMinValue: 0,
                        rangeMaxValue: 20000,
                        rangeValueUnit: 'fieldOfStudy.form.rangeUnit.currency',
                        isLabelHidden: true
                    },

                }
            },
            // commented until filtering by rating is added
            // {
            //     controlType: 'group',
            //     key: 'realm',
            //     class: 'row',
            //     controls: {
            //         rating: {
            //             controlType: 'control',
            //             defaultValue: '',
            //             formControlType: FormControlType.AUTOCOMPLETE,
            //             multiselectType: MultiSelectType.SINGLE,
            //             validationRules: [],
            //             placeholder: 'Wszystkie',
            //             label: 'marketplace.mentorSelect.form.labels.rating',
            //             multiselectOptions: [],
            //             inputDataMapper: collectionInputDataMapper,
            //             outputDataMapper: collectionOutputDataMapper,
            //             hostClass: 'col-xl-12',
            //             menuPlacement: MultiselectMenuPlacement.TOP
            //         }
            //     }
            // },
            {
                controlType: 'group',
                key: 'availability',
                class: 'col-6',
                controls: {
                    availability: {
                        controlType: 'control',
                        defaultValue: null,
                        formControlType: FormControlType.DATE_RANGE_PICKER,
                        validationRules: [],
                        placeholder: 'marketplace.mentorSelect.form.placeholders.selectDate',
                        label: 'marketplace.mentorSelect.form.labels.availability',
                        hostClass: 'col-12',
                    }
                }
            },
            {
                controlType: 'group',
                key: 'studyField',
                class: 'col-6',
                controls: {
                    studyField: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SINGLE,
                        validationRules: [],
                        placeholder: 'marketplace.mentorSelect.form.placeholders.typeOption',
                        label: 'marketplace.mentorSelect.form.labels.realm',
                        multiselectOptions: studyFields,
                        inputDataMapper: collectionInputDataMapper,
                        outputDataMapper: collectionOutputDataMapper,
                        hostClass: 'col-12 elastic-search-select',
                        menuPlacement: MultiselectMenuPlacement.BOTTOM,
                        handleMultiselectInputChange: (value: any) => {
                            if (handleMultiselectInputChange) {
                                return handleMultiselectInputChange(value, 'studyField');
                            }
                        },
                    }
                }
            },
            {
                controlType: 'group',
                key: 'school',
                class: 'col-6',
                controls: {
                    school: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SINGLE,
                        validationRules: [],
                        placeholder: 'marketplace.mentorSelect.form.placeholders.typeOption',
                        label: 'marketplace.mentorSelect.form.labels.school',
                        multiselectOptions: schools,
                        inputDataMapper: collectionInputDataMapper,
                        outputDataMapper: collectionOutputDataMapper,
                        hostClass: 'col-12 elastic-search-select',
                        menuPlacement: MultiselectMenuPlacement.BOTTOM,
                        handleMultiselectInputChange: (value: any) => {
                            if (handleMultiselectInputChange) {
                                return handleMultiselectInputChange(value, 'school');
                            }
                        },
                    }
                }
            }
        ]
    };
};
