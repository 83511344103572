import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    IModelCartOnlineConsultation,
    IModelCart,
    ModelPurchasableService,
    IModelCartServiceExtraData,
    deepCloneObject,
    IModelCartGenericServiceExtraData
} from 'educat-common-web';


export interface ICartState {
    cart: typeof IModelCart | null;
    error: string | null;
    isCartLoading: boolean;
}

export interface ISetCartState {
    readonly cart: typeof IModelCart | null;
}

export interface ISetCartError {
    readonly error: string;
}

export interface IChangeIsCartLoading {
    readonly isLoading: boolean;
}

export interface IUpdateCartOnlineConsultationDates {
    readonly serviceInstanceId: string;
    readonly onlineConsultationDates: typeof IModelCartOnlineConsultation[] | [];
}

export interface IUpdateCartApplicationPackageSchoolStudyFields {
    readonly serviceInstanceId: string;
    readonly schoolStudyFieldId: string;
}

export interface IRemoveCartItem {
    readonly serviceInstanceIds: string[];
}

export interface IAddItemToCart {
    readonly service: typeof ModelPurchasableService;
    readonly extraData?: typeof IModelCartServiceExtraData;
    readonly genericServiceExtraData?: typeof IModelCartGenericServiceExtraData;
}

export interface IUpdateCartItemQuantity {
    readonly serviceInstanceId: string;
    readonly quantity: number;
}

const initialState: ICartState = {
    cart: null,
    error: null,
    isCartLoading: false
};

const cartSlice = createSlice({
    name: 'cart',
    initialState: deepCloneObject(initialState),
    reducers: {
        setCartState: {
            reducer: (state: ICartState, action: PayloadAction<ISetCartState>) => {
                return {
                    cart: deepCloneObject(action.payload.cart),
                    error: state.error,
                    isCartLoading: state.isCartLoading
                };
            },
            prepare(cart: typeof IModelCart | null) {
                return {
                    payload: {cart: cart}
                };
            }
        },
        setCartStateFailure: {
            reducer: (state: ICartState, action: PayloadAction<ISetCartError>) => {
                return {
                    cart: state.cart,
                    error: action.payload.error,
                    isCartLoading: state.isCartLoading
                }
            },
            prepare(error: string) {
                return {
                    payload: {error: error}
                }
            }
        },
        getCartData: {
            reducer: (state: ICartState) => {
                return {
                    cart: state.cart,
                    error: state.error,
                    isCartLoading: state.isCartLoading
                }
            },
            prepare() {
                return {
                    payload: {}
                };
            }
        },
        changeIsCartLoading: {
            reducer: (state: ICartState, action: PayloadAction<IChangeIsCartLoading>) => {
                return {
                    cart: state.cart,
                    error: state.error,
                    isCartLoading: action.payload.isLoading
                }
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading}
                };
            }
        },
        updateCartOnlineConsultationDates: {
            reducer: (state: ICartState) => {
                return {
                    cart: state.cart,
                    error: state.error,
                    isCartLoading: true
                }
            },
            prepare(serviceInstanceId: string, onlineConsultationDates: typeof IModelCartOnlineConsultation[] | []) {
                return {
                    payload: {serviceInstanceId, onlineConsultationDates}
                };
            }
        },
        updateCartApplicationPackageSchoolStudyFields: {
            reducer: (state: ICartState) => {
                return {
                    cart: state.cart,
                    error: state.error,
                    isCartLoading: true
                }
            },
            prepare(serviceInstanceId: string, schoolStudyFieldId: string) {
                return {
                    payload: {serviceInstanceId, schoolStudyFieldId}
                };
            }
        },
        removeCartItem: {
            reducer: (state: ICartState) => {
                return {
                    cart: state.cart,
                    error: state.error,
                    isCartLoading: true
                }
            },
            prepare(serviceInstanceIds: string[]) {
                return {
                    payload: {serviceInstanceIds: serviceInstanceIds}
                };
            }
        },
        addItemToCart: {
            reducer: (state: ICartState) => {
                return {
                    cart: state.cart,
                    error: state.error,
                    isCartLoading: true
                }
            },
            prepare(service: typeof ModelPurchasableService,
                    extraData?: typeof IModelCartServiceExtraData,
                    genericServiceExtraData?: typeof IModelCartGenericServiceExtraData) {
                return {
                    payload: {
                        service,
                        extraData,
                        genericServiceExtraData
                    }
                };
            }
        },
        updateCartItemQuantity: {
            reducer: (state: ICartState) => {
                return {
                    cart: state.cart,
                    error: state.error,
                    isCartLoading: true
                }
            },
            prepare(serviceInstanceId: string, quantity: number) {
                return {
                    payload: {serviceInstanceId, quantity}
                };
            }
        },
        resetCart: () => {
            return deepCloneObject(initialState);
        }
    }
});

export const {
    setCartState,
    setCartStateFailure,
    getCartData,
    changeIsCartLoading,
    updateCartOnlineConsultationDates,
    removeCartItem,
    addItemToCart,
    updateCartItemQuantity,
    updateCartApplicationPackageSchoolStudyFields,
    resetCart,
} = cartSlice.actions;

export default cartSlice.reducer;
