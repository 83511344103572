import {Observable} from "rxjs";
import {educatAPI, RestQueryParams} from "educat-common-web";
import {ModelServiceDefinitionType, IModelCartOnlineConsultation, IModelCart} from 'educat-common-web';


export interface ICartPayloadItem {
    definitionId: string;
    definitionType: typeof ModelServiceDefinitionType;
    mentorServiceExtraData?: {
        onlineConsultationDates?: typeof IModelCartOnlineConsultation[],
        mentorDefinitionsId?: string;
        schoolStudyFieldsId?: string;
    },
    specialOfferServiceExtraData?: {
        schoolStudyFieldsId: string;
    },
    genericServiceExtraData?: {
        mentorId: string;
    }
}

export interface ICartItemDefinition {
    itemDefinitions: ICartPayloadItem[] | [];
}

export function addCartItemsAPI(
    authToken: string,
    cartId: string,
    cartItemDefinitions: ICartItemDefinition,
): Observable<typeof IModelCart> {
    return educatAPI.put(
        `carts/${cartId}/add_items`,
        cartItemDefinitions,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
