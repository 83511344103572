import {authTokenSelector, changeBreadcrumbs, CustomCard, deepCloneObject, IModelCartServiceInstance, Loader, LoaderType, Translation} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {forkJoin, Observable, of, Subscription} from 'rxjs';
import {catchError, filter, tap} from 'rxjs/operators';
import {getRecentTestResultsAPI} from '../../api/getRecentTestResults';
import {getServiceCompetenceTestsAPI} from '../../api/getServiceCompetenceTests';
import {getServiceDefinitionsAPI} from '../../api/getServiceDefinitions';
import {ServiceSubjectTypes} from '../../api/provider/serviceSubjectTestProvider';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {IAlertManagerService} from '../../service/alertManagerService';
import {RootState} from '../../store/reducers';
import CompetenceTestList from './CompetenceTestList';
import SubjectTestListHost from './SubjectTestListHost';
import TestResultsCard from './TestResultsCard';
import BuyTestModal from "./BuyTestModal";
import {getServiceInstanceTestPackagesAPI} from "../../api/getServiceInstanceTestPackages";

interface IConnectedCompetenceProps {
    changeBreadcrumbs: typeof changeBreadcrumbs;
    authToken: string;
}

interface ICompetenceProps extends IConnectedCompetenceProps {
}

interface ICompetenceState {
    resultsList: any[];
    predispositionTestList: any[];
    serviceInstanceTestPackages: typeof IModelCartServiceInstance[] | [];
    subjectTestList: ServiceSubjectTypes;
    isLoading: boolean;
    isModalVisible: boolean;
}

class Competence extends React.Component<ICompetenceProps, ICompetenceState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: ICompetenceProps) {
        super(props);

        this.state = {
            resultsList: [],
            predispositionTestList: [],
            serviceInstanceTestPackages: [],
            subjectTestList: {pl: {}, international: {}},
            isLoading: false,
            isModalVisible: false
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
            {labelKey: 'breadcrumbs.competence', path: '/panel/diagnostics'},
        ]);
        this.fetchTestData();
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <section className={`section competence row`}>
                <Loader showLoader={this.state.isLoading} loaderType={LoaderType.Local}/>
                <div className="col-xxl-8">
                    <div className="header-section">
                        <h1 className="header-title">
                            <Translation text={'competence.title'}/>
                        </h1>
                        <p className="header-description">
                            <Translation text={'competence.description'}/>
                        </p>
                    </div>

                    <CustomCard showLocalLoader={false}>
                        <CustomCard.Header>
                            <h2 className="subheader competence">
                                <Translation text={'competence.predispositionDiagnostics.title'}/>
                            </h2>
                        </CustomCard.Header>
                        <CustomCard.Body>
                            <CompetenceTestList competenceTestArray={this.state.predispositionTestList}/>

                            <div className='competence-btn-container'>
                                <button className="btn btn-theme" onClick={() => this.toggleModal()}>
                                    <Translation text={'competence.buyTest.buyCompetenceTest'}/>
                                </button>
                            </div>
                        </CustomCard.Body>

                    </CustomCard>

                    <CustomCard showLocalLoader={false}>
                        <CustomCard.Header>
                            <h2 className="subheader competence">
                                <Translation text={'competence.subjectTests.title.polish'}/>
                            </h2>
                        </CustomCard.Header>
                        <CustomCard.Body>
                            <SubjectTestListHost subjectTestList={this.state.subjectTestList.pl}
                                                 maturityExamType="pl"
                                                 toggleModal={this.toggleModal}
                                                 packageServiceInstances={this.state.serviceInstanceTestPackages}/>
                        </CustomCard.Body>
                    </CustomCard>
                    <CustomCard showLocalLoader={false}>
                        <CustomCard.Header>
                            <h2 className="subheader competence">
                                <Translation text={'competence.subjectTests.title.international'}/>
                            </h2>
                        </CustomCard.Header>
                        <CustomCard.Body>
                            <SubjectTestListHost subjectTestList={this.state.subjectTestList.international}
                                                 maturityExamType="international"
                                                 toggleModal={this.toggleModal}
                                                 packageServiceInstances={this.state.serviceInstanceTestPackages}/>
                        </CustomCard.Body>
                    </CustomCard>
                </div>
                <div className="col-xxl-4">
                    <TestResultsCard resultList={this.state.resultsList}/>
                </div>

                {this.state.isModalVisible &&
                    <BuyTestModal isModalVisible={this.state.isModalVisible} toggleModal={this.toggleModal}/>
                }
            </section>
        );
    }


    private retrieveListData(api: Observable<any>, listName: string) {
        return api.pipe(
            filter((response) => !!response),
            tap((resp: any) => {
                if (resp['hydra:member']) {
                    let updatedState = deepCloneObject(this.state);
                    updatedState[listName] = resp['hydra:member'];
                    this.setState(updatedState);
                }
                if (resp.mostRecentResults) {
                    this.setState({resultsList: resp.mostRecentResults})
                }
                if (resp.international || resp.pl) {
                    this.setState({subjectTestList: {pl: resp.pl, international: resp.international}});
                }
            }),
            catchError((err: any) => {
                this.alertManager?.handleApiError(err);
                this.setState({isLoading: false});
                return of();
            })
        );
    }

    private fetchTestData() {
        const testApiArray = [
            this.retrieveListData(getServiceCompetenceTestsAPI(this.props.authToken), 'predispositionTestList'),
            this.retrieveListData(getServiceDefinitionsAPI(this.props.authToken), 'subjectTestList'),
            this.retrieveListData(getRecentTestResultsAPI(this.props.authToken), 'resultsList'),
            this.retrieveListData(getServiceInstanceTestPackagesAPI(this.props.authToken), 'serviceInstanceTestPackages')
        ];
        this.setState({isLoading: true});
        this.subscriptions.push(
            forkJoin(testApiArray)
                .pipe(
                    tap(() => {
                        this.setState({isLoading: false});
                    })
                )
                .subscribe()
        );
    }

    private toggleModal = () => {
        this.setState({isModalVisible: !this.state.isModalVisible});
    }
}

export default connect(() => (state: RootState) => ({
    authToken: authTokenSelector(state),
}), {
    changeBreadcrumbs,
})(Competence);
