import {educatAPI, IModelServiceDefinitionApiResponse, RestQueryParams} from "educat-common-web";
import {Observable} from "rxjs";


export function getServiceInstanceTestPackagesAPI(
    authToken: string | null
): Observable<typeof IModelServiceDefinitionApiResponse> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return educatAPI.get(
        `service_instances/test_packages`,
        new RestQueryParams(),
        headers
    );
}
