import {CustomCard, Translation} from 'educat-common-web';
import React from 'react';

interface ICompetenceTestSideNavProps {
    readonly sections: any[];
    readonly selectedSectionIndex: number;
    readonly selectedSectionIndexChange: (index: number) => void;
}

class CompetenceTestSideNav extends React.Component<ICompetenceTestSideNavProps> {
    render() {
        return (
            <CustomCard showLocalLoader={false}>
                <CustomCard.Header>
                    <h2 className="view-subtitle">
                        <Translation text="competence.competenceTest.sidebarTitle"/>
                    </h2>
                </CustomCard.Header>
                <CustomCard.Body>
                    {!this.props.sections || !this.props.sections.length ?
                        <p><Translation text="competence.competenceTest.noData"/></p> :
                        <ul className="test-section-list">{this.renderNavItems(this.props.sections)}</ul>
                    }
                </CustomCard.Body>
            </CustomCard>
        );
    }

    private renderNavItems(sections: any[]) {
        return sections.map((sectionItem: any, index: number) => (
            <li key={`${sectionItem.sectionId}`}
                className={`test-section-item ${index === this.props.selectedSectionIndex ? 'active' : ''}`}
                onClick={() => this.props.selectedSectionIndexChange(index)}>
                <span className="test-section-link">{sectionItem.sectionName}</span>
            </li>
        ));
    }
}

export default CompetenceTestSideNav;
