import {CustomCard, isNotNullOrUndefined, Translation} from 'educat-common-web';
import React from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import styles from './styles.module.scss';

interface ICompetenceTestTimerProps {
    readonly startedAt: string;
    readonly maximumTime?: number | null;
}

interface ICompetenceTestTimerState {
    now: Date;
}

class CompetenceTestTimer extends React.Component<ICompetenceTestTimerProps, ICompetenceTestTimerState> {
    private interval: ReturnType<typeof setInterval>;

    constructor(props: any) {
        super(props);

        this.state = {
            now: new Date(),
        };
    }

    componentDidMount(): void {
        this.interval = setInterval(() => {
            this.setState({now: new Date()});
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const now = moment(this.state.now);
        const startedAt = moment(this.props.startedAt);
        const hasMaximumTime = isNotNullOrUndefined(this.props.maximumTime);
        const diffInMilliseconds = now.diff(startedAt, 'milliseconds'),
            diffInMinutes = now.diff(startedAt, 'minutes'),
            elapsed = hasMaximumTime ?
                diffInMinutes >= (this.props.maximumTime as number) :
                false;

        return (
            <CustomCard showLocalLoader={false}>
                <CustomCard.Header>
                    <h2 className="view-subtitle">
                        <Translation text="competence.competenceTest.timer"/>
                    </h2>
                </CustomCard.Header>
                <div className="test-timer">
                    <span className={elapsed ? styles.timerElapsed : ''}>
                        <Moment add={{milliseconds: diffInMilliseconds}} format={'mm:ss'} interval={0}>0</Moment>
                    </span>
                    {hasMaximumTime ?
                        <Translation
                            text={'competence.competenceTest.maximumTime'}
                            config={{maximumTime: this.props.maximumTime}}/> :
                        ''}
                </div>
            </CustomCard>
        );
    }
}

export default CompetenceTestTimer;
