import React from 'react';
import {getDisplayName, isNotEmpty, isNullOrUndefined, RestQueryParams, Translation} from 'educat-common-web';
import {RealizationStatus} from '../../types';
import OperationTableItemUsedHoursCell from '../OperationTableItemUsedHoursCell';
import OperationTableItemDate from '../OperationTableItemDate';

interface IExternalOperationTableItemRowProps {
    readonly service: any;
    readonly refreshPaymentList: (params?: typeof RestQueryParams) => void;
    readonly date?: string | null;
    readonly paymentStatus?: string;
}

interface IOperationTableItemRowProps extends IExternalOperationTableItemRowProps {
}

interface IOperationTableItemRowState {
}

class OperationTableItemRow extends React.Component<IOperationTableItemRowProps, IOperationTableItemRowState> {

    render() {
        const service = this.props.service;

        return <tr>
            <td>{this.renderMentorServiceName()}</td>
            <td>{service?.serviceDefinition?.name ? service?.serviceDefinition?.name : '--'}</td>
            <td><OperationTableItemDate date={this.props.date}/></td>
            <td>--</td>
            <td>
                <span className="default-status-badge">
                    <Translation
                        text={`payments.operationHistory.table.realization.${this.realizationStatus}`}/>
                </span>
                {this.isItemPaid() ? <OperationTableItemUsedHoursCell item={service} refreshPaymentList={this.props.refreshPaymentList}/> : null}
            </td>
            <td>--</td>
        </tr>;
    }

    renderMentorServiceName = () => {
        const mentorServiceDefinitions = this.props.service?.mentorServiceDefinitions;
        if (isNullOrUndefined(mentorServiceDefinitions?.name)) {
            return '--';
        }
        let name = mentorServiceDefinitions.name;
        const displayName = getDisplayName(mentorServiceDefinitions.mentor?.account);
        if (isNotEmpty(displayName)) {
            name += ` - ${displayName}`;
        }

        return name;
    };

    isItemPaid(){
        return this.props.paymentStatus === 'status_paid'
    }

    get realizationStatus(){
        const service = this.props.service
        if (service.completedAt){
            return RealizationStatus.COMPLETED;
        }
        if (this.isItemPaid()) {
            return RealizationStatus.OPEN;
        }
        return RealizationStatus.AWAITS_PAYMENT;

    }
}

export default OperationTableItemRow;
