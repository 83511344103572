import {
    applicantIdSelector,
    authTokenSelector,
    changeApplyingSchoolStudyFieldsCount,
    changeFavouriteSchoolStudyFieldsCount,
    changeApplicantSchoolStudyFields,
    StudyFieldsListType,
    Translation,
    isSameValue
} from 'educat-common-web';
import React from 'react';
import {WithTranslation, withTranslation} from "react-i18next";
import {of, Subscription} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {fixInjectedProperties, lazyInject} from '../../../../ioc';
import {IAlertManagerService} from '../../../../service/alertManagerService';
import {FieldOfStudyListType, IFieldOfStudy} from '../../../FieldOfStudy/FieldOfStudyProfile/types';
import {FieldOfStudySearchRoute} from '../../MainSearch';
import styles from "./styles.module.scss";
import {
    changeSchoolStudyFieldListAPI,
    IChangeSchoolStudyFieldListType
} from "../../../../api/changeSchoolStudyFieldListType";
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {applyStudyFieldsFilters} from "../../../../store/reducers/studyFieldsSearchSlice";


interface IConnectedActionButtonsProps {
    readonly authToken: string;
    readonly applicantId: string;
    readonly changeApplyingSchoolStudyFieldsCount: typeof changeApplyingSchoolStudyFieldsCount;
    readonly changeFavouriteSchoolStudyFieldsCount: typeof changeFavouriteSchoolStudyFieldsCount;
    readonly changeApplicantSchoolStudyFields: typeof changeApplicantSchoolStudyFields;
    readonly applyStudyFieldsFilters: typeof applyStudyFieldsFilters;
}

interface IExternalActionButtonsProps {
    fieldOfStudyItem: IFieldOfStudy;
    itemType: FieldOfStudySearchRoute;
}

interface IActionButtonsProps extends IConnectedActionButtonsProps,
    IExternalActionButtonsProps,
    WithTranslation {
}

interface IActionButtonsState {
    fieldOfStudy: IFieldOfStudy;
    isLoading: boolean;
}


class ActionButtons extends React.Component<IActionButtonsProps, IActionButtonsState> {
    readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IActionButtonsProps) {
        super(props);

        this.state = {
            fieldOfStudy: this.props.fieldOfStudyItem,
            isLoading: false,
        };
        fixInjectedProperties(this);
    }

    componentDidUpdate(
        prevProps: Readonly<IActionButtonsProps>,
        prevState: Readonly<IActionButtonsState>,
        snapshot?: any
    ): void {
        if (!isSameValue(this.props.fieldOfStudyItem, prevProps.fieldOfStudyItem)) {
            this.setState({fieldOfStudy: this.props.fieldOfStudyItem});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <div className={styles.actionButtonsWrapper}>
                {this.renderActionButtons(this.props.itemType)}
            </div>

        );
    }

    private renderActionButtons(itemType: FieldOfStudySearchRoute) {
        const {t} = this.props,
            fieldOfStudy = this.state.fieldOfStudy,
            buttonLoaderActive = this.state.isLoading,
            favouriteButton = (
                <button className={styles.actionButton}
                        disabled={buttonLoaderActive}
                        onClick={() => this.changeListType(fieldOfStudy.id, StudyFieldsListType.FAVOURITE)}
                        data-descr={t('fieldOfStudy.fieldOfStudyItem.actionButton.favourite.popup')}>
                    <span className="sr-only">
                        <Translation text="fieldOfStudy.fieldOfStudyItem.actionButton.favourite.stTitle"/>
                    </span>
                    {this.renderButtonIcon('favourite', fieldOfStudy.listType)}
                </button>
            ),
            appliedButton = (
                <button className={styles.actionButton}
                        disabled={buttonLoaderActive}
                        data-descr={t('fieldOfStudy.fieldOfStudyItem.actionButton.application.popup')}
                        onClick={() => this.changeListType(fieldOfStudy.id, StudyFieldsListType.APPLIED)}>
                    <span className="sr-only">
                        <Translation text="fieldOfStudy.fieldOfStudyItem.actionButton.application.stTitle"/>
                    </span>
                    {this.renderButtonIcon('applied', fieldOfStudy.listType)}
                </button>
            ),
            removeButton = (
                <button className={`${styles.closeButton} ${this.state.isLoading ? styles.loaderActive : ''}`}
                        disabled={buttonLoaderActive}
                        data-descr={t('fieldOfStudy.fieldOfStudyItem.actionButton.remove.popup')}
                        onClick={() => this.changeListType(fieldOfStudy.id, null)}>
                    <span className="sr-only">
                        <Translation text="fieldOfStudy.fieldOfStudyItem.actionButton.remove.stTitle"/>
                    </span>
                    {this.renderButtonIcon('remove', null)}
                </button>
            );

        switch (itemType) {
            case FieldOfStudySearchRoute.SEARCH:
                return <React.Fragment>
                    {appliedButton}
                    {favouriteButton}
                </React.Fragment>;
            case FieldOfStudySearchRoute.FAVOURITE:
                return <React.Fragment>
                    {appliedButton}
                    {removeButton}
                </React.Fragment>;
            case FieldOfStudySearchRoute.APPLIED:
                return <React.Fragment>
                    {favouriteButton}
                    {removeButton}
                </React.Fragment>
        }
    }

    private renderButtonIcon = (buttonType: string, belongsToList: FieldOfStudyListType | null) => {
        if (buttonType === 'applied') {
            return this.state.isLoading ?
                <i className={styles.loaderIcon}/> :
                <i className={`${styles.buttonIcon} ${belongsToList === FieldOfStudyListType.APPLYING ? styles.activeStarIcon : styles.inactiveStarIcon}`}/>
        }

        if (buttonType === 'favourite') {
            return this.state.isLoading ?
                <i className={styles.loaderIcon}/> :
                <i className={`${styles.buttonIcon} ${belongsToList === FieldOfStudyListType.FAVOURITE ? styles.activeHeartIcon : styles.inactiveHeartIcon}`}/>
        }

        if (buttonType === 'remove') {
            return this.state.isLoading ? <i className={styles.removeLoaderIcon}/> :
                <i className="feather icon-x"/>;
        }
    };

    private changeListType = (schoolStudyFieldsId: string, listType: typeof StudyFieldsListType | null) => {
        this.setState({isLoading: true});

        const payload: IChangeSchoolStudyFieldListType = {
            schoolStudyFieldsId: schoolStudyFieldsId,
            listType: listType
        };

        this.subscriptions.push(
            changeSchoolStudyFieldListAPI(this.props.authToken, this.props.applicantId, payload).pipe(
                map((resp: { [key: string]: any }) => {
                    const applyingCount = resp.applicantSchoolStudyFields.filter((field: { [key: string]: any }) => field.listType === StudyFieldsListType.APPLIED).length,
                        favouriteCount = resp.applicantSchoolStudyFields.filter((field: { [key: string]: any }) => field.listType === StudyFieldsListType.FAVOURITE).length;

                    this.setState({isLoading: false});
                    this.props.changeApplyingSchoolStudyFieldsCount(applyingCount);
                    this.props.changeFavouriteSchoolStudyFieldsCount(favouriteCount);
                    this.props.changeApplicantSchoolStudyFields(resp.applicantSchoolStudyFields);
                    this.props.applyStudyFieldsFilters();
                }),
                catchError((error: any) => {
                    this.alertManager?.handleApiError(error);
                    return of();
                })
            ).subscribe()
        )
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        applicantId: applicantIdSelector(state)
    }),
    {
        changeApplyingSchoolStudyFieldsCount,
        changeFavouriteSchoolStudyFieldsCount,
        changeApplicantSchoolStudyFields,
        applyStudyFieldsFilters,
    }
)(withTranslation()(ActionButtons));
