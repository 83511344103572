import { createSelector } from "@reduxjs/toolkit";
import {RootState} from "../reducers";
import {ICartState} from "../reducers/cartSlice";
import {isNotNullOrUndefined} from 'educat-common-web';


export const selectCart = (state: RootState): ICartState => {
    return state.cart;
};

export const cartSelector = createSelector(
    [selectCart],
    (state: ICartState) => state.cart
);

export const cartErrorSelector = createSelector(
    [selectCart],
    (state: ICartState) => state.error
);

export const isCartLoadingSelector = createSelector(
    [selectCart],
    (state: ICartState) => state.isCartLoading
);

export const isCartProcessingSelector = createSelector(
    [selectCart],
    (state: ICartState) => isNotNullOrUndefined(state.cart) && state.isCartLoading
);
