import {
    authTokenSelector,
    changeBreadcrumbs,
    deepCloneObject,
    filterValuesSelector,
    getGroupedStudyFieldFilterValue,
    IFilterValues,
    IFormConfig,
    IMultiselectOption,
    isNotNullOrUndefined,
} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {Route, RouteComponentProps, Switch, withRouter} from 'react-router-dom';
import {applyStudyFieldsFilters, changeStudyFieldsFilters} from '../../store/reducers/studyFieldsSearchSlice';
import Filter from '../Filter';
import AppliedList from './AppliedList';
import FavouriteList from './FavouriteList';
import MainSearch, {FieldOfStudySearchRoute} from './MainSearch';
import styles from './styles.module.scss';
import FilterModel from '../../models/filters';
import {filterFormConfig} from '../Filter/filterFormConfig';
import {WithTranslation, withTranslation} from 'react-i18next';
import {RootState} from '../../store/reducers';
import SearchListWrapper, {SearchListType} from './SearchListWrapper';


interface IConnectedFieldOfStudySearchProps {
    readonly authToken: string;
    readonly filterValues: typeof IFilterValues;
    readonly changeBreadcrumbs: typeof changeBreadcrumbs;
    readonly changeStudyFieldsFilters: typeof changeStudyFieldsFilters;
    readonly applyStudyFieldsFilters: typeof applyStudyFieldsFilters;
}

interface IExternalFieldOfStudySearchProps {
    schoolSearchType: FieldOfStudySearchRoute;
}

interface IFieldOfStudySearchProps extends IConnectedFieldOfStudySearchProps,
    IExternalFieldOfStudySearchProps,
    RouteComponentProps,
    WithTranslation {
}

interface IFieldOfStudySearchState {
}


class FieldOfStudySearch extends React.Component<IFieldOfStudySearchProps, IFieldOfStudySearchState> {
    private readonly filter = new FilterModel();

    render() {
        return (
            <section className={`section ${styles.fieldOfStudySection} row`}>
                <div>
                    <Filter authToken={this.props.authToken}
                            changeFilters={this.changeFilters}
                            formConfigFactory={this.createFormConfigFactory()}
                    />
                </div>
                <Switch>
                    <Route path="/panel/field-of-study-search"
                           component={() => <SearchListWrapper
                               schoolSearchType={FieldOfStudySearchRoute.SEARCH}
                               searchListType={SearchListType.STUDY_FIELD_LIST}
                               componentFactory={
                                   () =>
                                       <MainSearch changeBreadcrumbs={this.props.changeBreadcrumbs}/>}/>}
                           exact
                           key="field-of-study-search"/>
                    <Route path="/panel/field-of-study-search/favourite"
                           component={() => <SearchListWrapper
                               schoolSearchType={FieldOfStudySearchRoute.FAVOURITE}
                               searchListType={SearchListType.STUDY_FIELD_LIST}
                               componentFactory={
                                   () =>
                                       <FavouriteList changeBreadcrumbs={this.props.changeBreadcrumbs}/>}/>}
                           exact
                           key="favourite"/>
                    <Route path="/panel/field-of-study-search/applied"
                           component={() => <SearchListWrapper
                               schoolSearchType={FieldOfStudySearchRoute.APPLIED}
                               searchListType={SearchListType.STUDY_FIELD_LIST}
                               componentFactory={
                                   () =>
                                       <AppliedList changeBreadcrumbs={this.props.changeBreadcrumbs}/>}/>}
                           exact
                           key="applied"/>
                </Switch>
            </section>

        );
    }

    private createFormConfigFactory = (): (
        (collegeValues: typeof IMultiselectOption[] | [], collegeChangeHandler: (value: any, controlName: string) => void) => typeof IFormConfig
        ) => {
        return (collegeValues: typeof IMultiselectOption[] | [],
                collegeChangeHandler: (value: any, controlName: string) => void) => {
            const groupedStudyFieldOptions = getGroupedStudyFieldFilterValue(this.props.filterValues?.realm || [], this.props.filterValues?.subRealm || []);

            return filterFormConfig(
                this.translateCountries(this.props.filterValues?.country),
                groupedStudyFieldOptions,
                [],
                collegeValues,
                collegeChangeHandler
            );
        };
    };

    private translateCountries(countries: typeof IMultiselectOption[]) {
        if (!countries || countries.length === 0) {
            return [];
        }
        const {t} = this.props;
        return deepCloneObject(countries).map((country: typeof IMultiselectOption) => {
            country.label = t(`country.${country.label}`);
            return country;
        });
    };

    private changeFilters = (value: { [key: string]: any }) => {
        if (this.filter && isNotNullOrUndefined(value)) {
            const obj = this.filter.withStudyFieldFiltersFromForm(value);
            this.props.changeStudyFieldsFilters(obj.filters as any);
            this.props.applyStudyFieldsFilters();
        }
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        filterValues: filterValuesSelector(state),
    }),
    {
        changeBreadcrumbs,
        changeStudyFieldsFilters,
        applyStudyFieldsFilters,
    }
)(withTranslation()(withRouter(FieldOfStudySearch)));
