import {authTokenSelector, changeBreadcrumbs, getDisplayName, IModelMentor, isNotNullOrUndefined} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {of, Subscription} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {getMentorAPI} from '../../../api/getMentor';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import {IAlertManagerService} from '../../../service/alertManagerService';
import CalendarCard from '../Common/CalendarCard';
import ConsultationPackagesCard from '../Common/ConsultationPackagesCard';
// import StudentOpinionCard from '../Common/StudentOpinionsCard';
import VideoPresentationCard from '../Common/VideoPresentationCard';
import MentorDetailsCard from "../MentorProfile/MentorDetailsCard";
import {RootState} from '../../../store/reducers';

interface IConnectedTutorProfileProps {
    readonly authToken: string;
    changeBreadcrumbs: typeof changeBreadcrumbs;
}

interface IExternalTutorProfileProps {
}

interface ITutorProfileProps extends IConnectedTutorProfileProps,
    IExternalTutorProfileProps,
    RouteComponentProps {
}

interface ITutorProfileState {
    profile: typeof IModelMentor | null;
}


class TutorProfile extends React.Component<ITutorProfileProps, ITutorProfileState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: ITutorProfileProps) {
        super(props);

        this.state = {
            profile: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.getTutors();
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        if (!this.state.profile) {
            return null;
        }
        return (
            <section className={`row`}>
                <div className="col-md-12 col-xl-8">
                    <MentorDetailsCard mentor={this.state.profile}/>
                    <ConsultationPackagesCard mentorId={this.state.profile.id}
                                              mentorServiceDefinitions={this.state.profile.mentorServiceDefinitions}/>

                    {/* Hidden in current version */}
                    {/*<StudentOpinionCard mentorName={getDisplayName(this.state.profile.account)}/>*/}
                </div>
                <div className="col-md-12 col-xl-4">
                    {this.renderSideContent()}
                </div>
            </section>
        );
    }

    private renderSideContent = () => {
        const isScholar = this.state.profile?.type === 'scholar';

        return isNotNullOrUndefined(this.state.profile?.videoLink) ? (
                <div className="school-profile-sidebar">
                    <CalendarCard isScholar={isScholar} mentor={this.state.profile}/>

                    <VideoPresentationCard videoUrl={this.state.profile.videoLink} isScholar={isScholar}/>
                </div>
            ) :
            null;
    };

    private getTutors = () => {
        const routeState: any = this.props.location.state;
        let id: string | null | undefined;
        if (routeState) {
            this.changeBreadcrumbs(routeState.name);
            id = routeState.id;
        } else {
            const matches = /\/panel\/.+\/(.+)$/.exec(this.props.location.pathname);
            id = matches?.[1];
        }

        this.subscriptions.push(
            getMentorAPI(this.props.authToken, id as string).pipe(
                tap((resp: any) => {
                    this.changeBreadcrumbs(getDisplayName(resp.account));
                    this.setState({profile: resp});
                }),
                catchError((error: any) => {
                    this.alertManager?.handleApiError(error.response);
                    return of(error);
                })
            ).subscribe()
        )
    };

    private changeBreadcrumbs = (name: string): void => {
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
            {labelKey: 'breadcrumbs.tutors', path: '/panel/tutors'},
            {labelKey: name, path: ''}
        ]);
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {
        changeBreadcrumbs
    }
)(withRouter(TutorProfile));
