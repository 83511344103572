import {
    accountSelector,
    applicantSchoolStudyFieldsSelector,
    authTokenSelector,
    changeApplicantSchoolStudyFields,
    changeBreadcrumbs,
    deepCloneObject,
    isNotEmpty,
    isNotNullOrUndefined,
    isNullOrUndefined,
    isSameValue,
    Loader,
    LoaderType,
    StudyFieldsListType
} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {of, Subscription} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {changeSchoolStudyFieldListAPI, IChangeSchoolStudyFieldListType} from '../../../api/changeSchoolStudyFieldListType';
import {getFieldOfStudyAPI} from '../../../api/getFieldOfStudy';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import {IAlertManagerService} from '../../../service/alertManagerService';
import {RootState} from '../../../store/reducers';
import FieldDetailsCard from './FieldDetailsCard';
import FieldLogoCard from './FieldLogoCard';
import {Map} from './Map';
import RealmAndRequirementsCard from './RealmAndRequirementsCard';
import SideContent from './SideContent';
import styles from './styles.module.scss';
import {FieldOfStudyListType, IFieldOfStudyProfile} from './types';


interface IConnectedFieldOfStudyProfileProps {
    readonly changeBreadcrumbs: typeof changeBreadcrumbs;
    readonly changeApplicantSchoolStudyFields: typeof changeApplicantSchoolStudyFields;
    readonly authToken: string | null;
    readonly account: any;
    readonly applicantSchoolStudyFields: any;
}

interface IExternalFieldOfStudyProfileProps {
}

interface IFieldOfStudyProfileProps extends IConnectedFieldOfStudyProfileProps, IExternalFieldOfStudyProfileProps, RouteComponentProps {
}

interface IFieldOfStudyProfileState {
    profile: IFieldOfStudyProfile | null;
    profileListType: FieldOfStudyListType,
    isLoading: boolean;
}

class FieldOfStudyProfile extends React.Component<IFieldOfStudyProfileProps, IFieldOfStudyProfileState> {
    readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IFieldOfStudyProfileProps) {
        super(props);

        this.state = {
            profile: null,
            profileListType: FieldOfStudyListType.NULL,
            isLoading: true,
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        const slug = this.props.match.params;
        this.subscriptions.push(
            getFieldOfStudyAPI(this.props.authToken, (slug as any).id)
                .pipe(
                    tap((resp: any) => {
                        let profile = this.getFieldDetailsFromRaw(resp),
                            currentProfileListState: FieldOfStudyListType = this.getCurrentProfileListState(resp);

                        this.setState({
                            profile: profile,
                            isLoading: false,
                            profileListType: currentProfileListState
                        });
                    }),
                    catchError((error: any) => {
                        this.alertManager?.handleApiError(error);
                        this.setState({isLoading: false});
                        return of(error);
                    })
                )
                .subscribe()
        );
    }

    componentDidUpdate(
        prevProps: Readonly<IFieldOfStudyProfileProps>,
        prevState: Readonly<IFieldOfStudyProfileState>,
        snapshot?: any
    ): void {

        if (this.state.profile && this.state.profile.school?.name !== prevState.profile?.school?.name) {
            let schoolUrl = '';
            if (isNotEmpty(this.state.profile.www)) {
                schoolUrl = this.state.profile.www as string;
            }
            if ('' === schoolUrl && isNotEmpty(this.state.profile.school.www)) {
                schoolUrl = this.state.profile.school.www as string;
            }

            this.props.changeBreadcrumbs([
                {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
                {labelKey: 'breadcrumbs.schools', path: '/panel/field-of-study-search'},
                {labelKey: this.state.profile.school.name, path: schoolUrl, isLinkExternal: true, isItemActive: true},
            ]);
        }

        if (this.state.profile && !isSameValue(this.props.applicantSchoolStudyFields, prevProps.applicantSchoolStudyFields)) {
            const updatedState = this.getCurrentProfileListState(this.state.profile);
            this.setState({profileListType: updatedState});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <div className={styles.schoolProfileSection}>
                {this.renderFieldOfStudy()}
                <Loader type={LoaderType.Local} showLoader={this.state.isLoading}/>
            </div>
        );
    }

    private renderFieldOfStudy() {
        if (!this.state.profile) {
            return null;
        }
        return <div className="row">
            <div className="col-md-12 col-xl-8">
                <FieldLogoCard logo={this.state.profile.school.logo}/>

                <FieldDetailsCard
                    school={this.state.profile?.school}
                    studyField={this.state.profile?.studyField}
                    duration={this.state.profile?.duration}
                    levels={this.state.profile?.levels}
                    mode={this.state.profile?.mode}
                    currentProfileListState={this.state.profileListType}
                    addToFavorite={() => this.changeStudyProfileListType(StudyFieldsListType.FAVOURITE)}
                    addToApplicationList={() => this.changeStudyProfileListType(StudyFieldsListType.APPLIED)}
                />

                <RealmAndRequirementsCard
                    realm={this.state.profile.studyField.realm}
                    requirements={this.state.profile.requirements}
                    languageRequirements={this.state.profile.languageRequirements}
                />
                {this.renderMapCard()}
            </div>
            <div className="col-md-12 col-xl-4">
                <SideContent profile={this.state.profile}/>
            </div>
        </div>

    }

    private renderMapCard() {
        if (!this.state.profile?.position) {
            return null;
        }
        return <div className={styles.mapBg}>
            <Map
                isMarkerShown
                position={this.state.profile.position}
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{height: `100%`}}/>}
                containerElement={<div style={{height: `100%`}}/>}
                mapElement={<div style={{height: `100%`, borderRadius: 6}}/>}
            />
        </div>
    }

    private changeStudyProfileListType = (type: typeof StudyFieldsListType) => {
        if (!this.state.profile || !this.props.authToken || !this.props.account.applicantId) {
            return null;
        }
        const payload: IChangeSchoolStudyFieldListType = {
            schoolStudyFieldsId: this.state.profile.id,
            listType: type
        };
        this.setState({isLoading: true});
        this.subscriptions.push(
            changeSchoolStudyFieldListAPI(this.props.authToken, this.props.account.applicantId, payload)
                .pipe(
                    tap((resp: any) => {
                        const message = type === StudyFieldsListType.FAVOURITE ?
                            'fieldOfStudy.fieldOfStudyItem.actionButton.favourite.requestMessages.added' :
                            'fieldOfStudy.fieldOfStudyItem.actionButton.application.requestMessages.added';
                        this.alertManager?.addAlert(message);
                        this.props.changeApplicantSchoolStudyFields(resp.applicantSchoolStudyFields);
                        this.setState({
                            isLoading: false,
                        });
                    }),
                    catchError((error: any) => {
                        this.alertManager?.handleApiError(error);
                        this.setState({isLoading: false});
                        return of(error);
                    })
                )
                .subscribe()
        );
    };

    private getCurrentProfileListState(fieldOfStudy: any) {
        const updatedListType = FieldOfStudyListType.NULL;
        if (isNullOrUndefined(fieldOfStudy)) {
            return updatedListType;
        }
        const currentFieldOfStudyOnUserList = this.props.applicantSchoolStudyFields.find((applicantStudyField: any) => applicantStudyField.schoolStudyFields.id === fieldOfStudy.id);

        return currentFieldOfStudyOnUserList ? currentFieldOfStudyOnUserList.listType : updatedListType;
    }

    private getFieldDetailsFromRaw = (data: { [key: string]: any }): IFieldOfStudyProfile => {
        let fieldOfStudyProfile: IFieldOfStudyProfile;
        let deadlines = data.deadlines.deadlines.map((deadline: { [key: string]: any }) => {
            return {
                date: deadline.date,
                type: deadline.type,
            };
        });
        let schoolCountries = data.school.countries.map((country: { [key: string]: any }) => {
            return {
                id: country.id,
            };
        });
        let localization = null;
        if (isNotNullOrUndefined(data.school?.localization) && isNotNullOrUndefined(data.school?.localization.lat) && isNotNullOrUndefined(data.school?.localization.long)) {
            localization = {lat: data.school?.localization.lat, lng: data.school.localization.long};
        }
        fieldOfStudyProfile = {
            id: data.id,
            studyField: {
                id: data.studyField.id,
                name: data.studyField.name,
                realm: {
                    id: data.studyField.realm.id,
                    name: data.studyField.realm.name,
                },
            },
            deadlines: deadlines,
            languageRequirements: {
                ielts: data.languageRequirements.ielts,
                pte: data.languageRequirements.pte,
                toeflInternet: data.languageRequirements.toeflInternet,
                toeflPaper: data.languageRequirements.toeflPaper,
            },
            levels: data.levels,
            requirements: {
                html: data.requirements.html,
                text: data.requirements.text,
            },
            school: {
                id: data.school.id,
                city: data.school.city,
                countries: schoolCountries,
                logo: data.school.logo,
                name: data.school.name,
                rankingPlace: data.school.rankingPlace,
                type: data.school.type,
                www: data.school.www,
            },
            studyStartDate: data.studyStartDate,
            mode: data.mode,
            listType: data.listType,
            www: data.www,
            duration: {
                length: data.duration.length,
                unit: data.duration.unit,
            },
            tuitionFees: deepCloneObject(data.tuitionFees),
            position: localization
        };

        return fieldOfStudyProfile;
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        account: accountSelector(state),
        applicantSchoolStudyFields: applicantSchoolStudyFieldsSelector(state),
    }),
    {
        changeBreadcrumbs,
        changeApplicantSchoolStudyFields,
    }
)(withRouter(FieldOfStudyProfile));
