import {Details, IModelCartServiceInstance, Translation} from 'educat-common-web';
import React from 'react';
import {Subscription} from 'rxjs';
import SubjectTestList from './SubjectTestList';
import styles from './styles.module.scss';

interface ISubjectTestListHostProps {
    readonly subjectTestList: { [key: string]: any[] };
    readonly maturityExamType: string;
    readonly toggleModal: () => void;
    readonly packageServiceInstances?: typeof IModelCartServiceInstance[] | [];
}

interface ISubjectTestListHostState {
}

class SubjectTestListHost extends React.Component<ISubjectTestListHostProps, ISubjectTestListHostState> {
    readonly subscriptions: Subscription[] = [];

    constructor(props: ISubjectTestListHostProps) {
        super(props);

        this.state = {};
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return <div className={styles.subjectTestListWrapper}>{this.renderResultsList(this.props.subjectTestList)}</div>;
    }

    private renderResultsList(list: { [key: string]: any[] }) {
        return Object.entries(list).map(([key, value]) => (
            <Details key={key}>
                <Details.Title>
                    <Translation text={`competence.subjectTests.classes.${this.props.maturityExamType}.${key}`}/>
                </Details.Title>
                <Details.Body>
                    <SubjectTestList subjectTestArray={value} packageServiceInstances={this.props.packageServiceInstances}/>

                    {value && value.length > 0 ?
                        (<div className='competence-btn-container details-btn-container'>
                        <button className="btn btn-theme" onClick={() => this.props.toggleModal()}>
                            <Translation text={'competence.buyTest.buySubjectTest'}/>
                        </button>
                    </div>) : null}
                </Details.Body>
            </Details>
        ));
    }
}

export default SubjectTestListHost;
