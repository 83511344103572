import {deepCloneObject} from "educat-common-web";
import createAPIHandler from "../base/apiFactory";

export const testRealisationAPI = createAPIHandler({
  url: process.env.REACT_APP_API_URL,
  defaultHeaders: {
    Accept: "application/ld+json",
    "Content-type": "application/ld+json",
    "Accept-Language": "pl-PL, pl;q=0.9, en;q=0.5, *;q=0.2",
  },
  mapper: (data: any): any => {
    if (data) {
      let testRealisationQuestionsGrouped: any[] = [];
      if (data.testRealisationQuestions) {
        data.testRealisationQuestions.forEach((testRealisation: any) => {
          const sectionName = testRealisation.question.section.name,
            sectionId = testRealisation.question.section.id,
            testRealisationQuestionWithSection = {
              sectionName: sectionName,
              sectionId: sectionId,
              testRealisationQuestions: [testRealisation],
            },
            indexOfSection = testRealisationQuestionsGrouped.findIndex(
              (testRealisationQuestion: any) => testRealisationQuestion.sectionId === sectionId
            );

          indexOfSection === -1
            ? testRealisationQuestionsGrouped.push(testRealisationQuestionWithSection)
            : testRealisationQuestionsGrouped[indexOfSection].testRealisationQuestions.push(testRealisation);
        });
      }
      const updatedData = deepCloneObject(data);
      updatedData.testRealisationQuestions = testRealisationQuestionsGrouped;
      return updatedData;
    }
  },
  isList: false,
});
