import React, {Component} from 'react';
import {
    authTokenSelector,
    RestQueryParams,
    IFormConfig,
    IMultiselectOption,
    Form,
    FormControlChangeType,
    SchoolType, isSameValue
} from "educat-common-web";
import styles from "./styles.module.scss";
import {connect} from "react-redux";
import {RootState} from "../../../../../store/reducers";
import {catchError, debounceTime, filter, map, tap} from "rxjs/operators";
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {fixInjectedProperties, lazyInject} from "../../../../../ioc";
import {IAlertManagerService} from "../../../../../service/alertManagerService";
import {getMentorServiceDefinitionsAPI} from "../../../../../api/getMentorServiceDefinitions";
import {mentorSelectFormConfig} from "./mentorSelectFormConfig";
import MentorItem from './MentorServiceItem';
import {getSchoolStudyFieldsAPI} from "../../../../../api/getSchoolStudyFields";
import {getSchoolsAPI} from "../../../../../api/getSchools";
import { IModelMentorServiceDefinition, Translation } from 'educat-common-web';


export enum MarketplaceServiceType {
    SERVICE_CONSULTATION_PACKAGE = 'serviceConsultationPackage',
    SERVICE_APPLICATION_PACKAGE = 'serviceApplicationPackage'
}

interface IConnectedMentorSelectProps {
    authToken: string | null;
}

interface IExternalMentorSelectProps {
    type: MarketplaceServiceType;
    country: string | null;
    realm: string | null;
    consultationsAmount?: number;
}

interface IMentorSelectProps extends IConnectedMentorSelectProps, IExternalMentorSelectProps {
}

interface IMentorSelectState {
    isLoading: boolean;
    mentorList: typeof IModelMentorServiceDefinition[] | [];
    formConfig: typeof IFormConfig | null;
    value: any;
    schoolValues: typeof IMultiselectOption[] | [];
    studyFieldValues: typeof IMultiselectOption[] | [];
}
class MentorSelect extends Component <IMentorSelectProps, IMentorSelectState> {
    private subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject("AlertManagerService") private alertManager: IAlertManagerService | undefined;

    constructor(props: IMentorSelectProps) {
        super(props);
        this.state = {
            isLoading: true,
            mentorList: [],
            formConfig: null,
            value: null,
            schoolValues: [],
            studyFieldValues: []
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.subscriptions.push(this.getMentorServiceDefinitions());
        this.setFormConfig();

        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                debounceTime(500),
                tap((data: any) => this.onFormValueChange(data.value)),
            ).subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<IMentorSelectProps>, prevState: Readonly<IMentorSelectState>, snapshot?: any): void {
        if (!isSameValue(this.state.schoolValues, prevState.schoolValues) ||
            !isSameValue(this.state.studyFieldValues, prevState.studyFieldValues)) {
            this.setFormConfig();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <>
                <div className={styles.selectMentorForm}>
                    {this.state.formConfig && <Form config={this.state.formConfig}
                                                    onValueStateChange={this.onValueStateChange}
                                                    value={this.state.value}
                                                    controlName={'filterForm'}/>}
                </div>
                <div className={styles.mentorList}>
                    {this.state.mentorList.length ?
                        this.state.mentorList.map((mentor: typeof IModelMentorServiceDefinition) => {
                            return (
                                <MentorItem mentorServiceItem={mentor} key={mentor.id}/>
                            )
                        }) : (<p className={styles.noMentors}><Translation text="marketplace.mentorSelect.noMentors" /></p>)
                    }
                </div>
            </>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onFormValueChange = (value: any) => {
        this.setState({value: value});

        let queryParams = new RestQueryParams();
        if (value.price) {
            queryParams = queryParams.add('grossItemPrice.amount[between]', `${value.price[0] * 100}..${value.price[1] * 100}`);
        }

        if (value.availability) {
            queryParams = queryParams.add(
                'mentor.account.calendars.calendarFreeTerms[free_terms]',
                `${new Date(value.availability[0]).toISOString()}to${new Date(value.availability[1]).toISOString()}`
            );
        }

        if (value.studyField) {
            queryParams = queryParams.add('mentor.mentorSchoolStudyFields.schoolStudyFields.studyField.id', value.studyField);
        }

        if (value.school) {
            queryParams = queryParams.add('mentor.mentorSchoolStudyFields.schoolStudyFields.school.id', value.school);
        }

        this.getMentorServiceDefinitions(queryParams);
        this.setState({value: value});
    };

    private getMentorServiceDefinitions = (params?: typeof RestQueryParams) => {
        let queryParams = params ? params : new RestQueryParams();
        queryParams = this.props.type === MarketplaceServiceType.SERVICE_APPLICATION_PACKAGE ?
            queryParams.add('serviceApplicationPackage[null]', 'false') :
            queryParams.add('serviceConsultationPackage[null]', 'false');
        if (this.props.country) {
            queryParams = queryParams.add('mentor.countries.id', this.props.country.toUpperCase());
        }

        if (this.props.realm) {
            queryParams = queryParams.add('mentor.realms.id', this.props.realm);
        }

        if (this.props.consultationsAmount) {
            queryParams = queryParams.add('serviceConsultationPackage.items', this.props.consultationsAmount);
        }

        this.setState({isLoading: true});
        return getMentorServiceDefinitionsAPI(this.props.authToken, queryParams).pipe(
            map((resp: any) => {
                const mentorDefinitions = (resp['hydra:member'] || []);
                this.setState({
                    isLoading: false,
                    mentorList: mentorDefinitions
                });
            }),
            catchError((error: any) => {
                this.setState({isLoading: false});
                this.alertManager?.handleApiError(error);
                return of();
            })
        ).subscribe();
    };


    private setFormConfig = (): void => {
        const formConfig = mentorSelectFormConfig(
                this.state.studyFieldValues,
                this.state.schoolValues,
                this.handleMultiselectInputChange,
            );

        this.setState({formConfig});
    };

    private handleMultiselectInputChange = (value: string, controlName: string) => {
        if (value.length >= 3) {
            let queryParams = new RestQueryParams();

            if (controlName === 'school') {
                queryParams = queryParams.add('name', value);
                queryParams = queryParams.add('countries.id', this.props.country?.toUpperCase());
                queryParams = queryParams.add('type', SchoolType.College);
                this.getMultiselectsData(getSchoolsAPI(this.props.authToken, queryParams), 'schoolValues');
            }

            if (controlName === 'studyField') {
                queryParams = queryParams.add('name', value);
                this.getMultiselectsData(getSchoolStudyFieldsAPI(this.props.authToken, queryParams), 'studyFieldValues');
            }
        }
    };

    private getMultiselectsData = (api: Observable<any>, stateMultiselectOptions: string) => {
        return this.subscriptions.push(
            api.pipe(
                tap((resp: any) => {
                    const multiselectOptions: { [key: string]: any }[] = (resp['hydra:member'] || [])
                        .map((option: { [key: string]: any }) => ({
                            value: option.id,
                            label: option.studyField ? option.studyField.name : option.name,
                        }));
                    let update: any = {};
                    update[stateMultiselectOptions] = multiselectOptions;
                    this.setState(update);
                }),
                catchError((error: any) => {
                    this.alertManager?.handleApiError(error);
                    return of(error);
                })
            ).subscribe()
        );
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {}
)(MentorSelect);
