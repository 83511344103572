import {
    authTokenSelector,
    changeBreadcrumbs,
    CustomCard,
    // getMentorsAPI,
    IModelMentor,
    Loader,
    LoaderType,
    Translation,
} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {of, Subscription} from 'rxjs';
import {catchError, filter, tap} from 'rxjs/operators';
import {getServiceCompetenceTestsAPI} from '../../../api/getServiceCompetenceTests';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import {IAlertManagerService} from '../../../service/alertManagerService';
import {RootState} from '../../../store/reducers';
import CompetenceTestList from './../CompetenceTestList';
// import {Carousel} from "react-responsive-carousel";
// import MentorDetails from "./MentorDetails";


interface IConnectedMorrisbyTestProps {
    changeBreadcrumbs: typeof changeBreadcrumbs;
    authToken: string;
}

interface IMorrisbyTestProps extends IConnectedMorrisbyTestProps {
}

interface IMorrisbyTestState {
    predispositionTestList: any[];
    mentorsList: typeof IModelMentor[];
    isLoading: boolean;
    selectedMentorIndex: number;
}

class MorrisbyTest extends React.Component<IMorrisbyTestProps, IMorrisbyTestState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IMorrisbyTestProps) {
        super(props);

        this.state = {
            predispositionTestList: [],
            mentorsList: [],
            isLoading: false,
            selectedMentorIndex: 0
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
            {labelKey: 'breadcrumbs.competence', path: '/panel/diagnostics'},
            {labelKey: 'breadcrumbs.morrisbyTest', path: ''},
        ]);
        this.getCompetenceTests();
        // this.getMentors();
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        // hidden in current version
        // const genericServiceExtraData = {mentorId: this.state.mentorsList[this.state.selectedMentorIndex]?.id};
        return (
            <section className={`section morrisby-test row`}>
                <Loader showLoader={this.state.isLoading} loaderType={LoaderType.Local}/>
                <div className="col-xxl-8 mt-5">

                    <div className="morrisby-test-wrapper">
                        <CompetenceTestList competenceTestArray={this.state.predispositionTestList}
                                            isMorrisbyDetailsView={true}
                                            // hidden in current version
                                            // genericServiceExtraData={genericServiceExtraData}
                        />
                    </div>


                    <CustomCard showLocalLoader={false}>
                        <CustomCard.Header>
                            <h2 className="subheader competence">
                                <Translation text="competence.morrisbyTest.descriptionTitle"/>
                            </h2>
                        </CustomCard.Header>
                        <CustomCard.Body>
                            <Translation text="competence.morrisbyTest.description"/>
                            <ul className="morrisby-section-list">
                                <li><Translation text="competence.morrisbyTest.report"/></li>
                                <li><Translation text="competence.morrisbyTest.platformAccess"/></li>
                                <li><Translation text="competence.morrisbyTest.selectedField"/></li>
                                <li><Translation text="competence.morrisbyTest.benefits"/></li>
                            </ul>
                        </CustomCard.Body>
                    </CustomCard>

                    {/*Hidden in current version*/}
                    {/*<CustomCard showLocalLoader={false}>*/}
                    {/*    <CustomCard.Header>*/}
                    {/*        <h2 className="subheader competence">*/}
                    {/*            <Translation text="competence.morrisbyTest.testDiscussion"/>*/}
                    {/*        </h2>*/}
                    {/*    </CustomCard.Header>*/}
                    {/*    <CustomCard.Body>*/}
                    {/*        <Carousel showArrows={true}*/}
                    {/*                  showStatus={false}*/}
                    {/*                  showIndicators={false}*/}
                    {/*                  onChange={(e: number) => this.setState({selectedMentorIndex: e})}>*/}
                    {/*            {this.state.mentorsList.map((mentor: any) => <MentorDetails key={mentor.id}*/}
                    {/*                                                                        mentor={mentor}/>)}*/}
                    {/*        </Carousel>*/}
                    {/*    </CustomCard.Body>*/}
                    {/*</CustomCard>*/}
                </div>
            </section>
        );
    }


    private getCompetenceTests() {
        this.setState({isLoading: true});
        return this.subscriptions.push(
            getServiceCompetenceTestsAPI(this.props.authToken).pipe(
                filter((response) => !!response),
                tap((resp: any) => {
                    if (resp['hydra:member']) {
                        this.setState({predispositionTestList: resp['hydra:member'], isLoading: false});
                    }
                }),
                catchError((err: any) => {
                    this.alertManager?.handleApiError(err);
                    return of();
                })
            ).subscribe()
        );
    }

    // Hidden in current version
    // private getMentors() {
    //     this.subscriptions.push(
    //         getMentorsAPI(this.props.authToken).pipe(
    //             tap((resp: any) => {
    //                 this.setState({mentorsList: resp['hydra:member']})
    //             }),
    //             catchError((err: any) => {
    //                 this.alertManager?.handleApiError(err);
    //                 return of();
    //             })
    //         ).subscribe()
    //     )
    // };
}

export default connect(() => (state: RootState) => ({
    authToken: authTokenSelector(state),
}), {
    changeBreadcrumbs,
})(MorrisbyTest);
