import React from 'react';
import styles from "./styles.module.scss";
import {CustomCard, Translation, YouTubeVideo} from "educat-common-web";


interface IExternalVideoPresentationCardProps {
    videoUrl: string;
    isScholar?: boolean;
}

interface IVideoPresentationCardProps extends IExternalVideoPresentationCardProps {
}

interface IVideoPresentationCardState {
}


class VideoPresentationCard extends React.Component<IVideoPresentationCardProps, IVideoPresentationCardState> {

    constructor(props: IVideoPresentationCardProps) {
        super(props);
        this.state = {};
    }

    render() {
        const titleTranslationKey = this.props.isScholar ? 'tutorProfile.tutorVideo.title' : 'mentorProfile.mentorVideo.title';

        return (
            <CustomCard showLocalLoader={false}>
                <CustomCard.Body>
                    <h2 className="secondary-title">
                        <Translation text={titleTranslationKey} />
                    </h2>
                    <div className={styles.videoWrapper}>
                        <YouTubeVideo youtubeLink={this.props.videoUrl} />
                    </div>
                </CustomCard.Body>
            </CustomCard>
        );
    }
}

export default VideoPresentationCard;
