import React from 'react';
import {BasicModal, Loader, LoaderType, Translation} from 'educat-common-web';
import styles from './styles.module.scss';
import MentorWeekCalendar from '../../../MentorHost/MentorsList/MentorItem/MentorWeekCalendar';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {isCartLoadingSelector} from '../../../../store/selectors/cartSelectors';
import {ModalCoordinates} from '../MentorItemCalendar';


interface IConnectedMentorCalendarModalProps {
    readonly isCartLoading: boolean;
}

interface IExternalMentorCalendarModalProps {
    isModalVisible: boolean;
    closeModal: () => void;
    calendarId: string;
    startDate: Date;
    selectedDate: Date;
    additionalUnavailableDates: Date[];
    modalCoordinates: ModalCoordinates | null;
    onDateSelected: (date: Date) => void;
    calendarDetails: {[key: string]: any} | null;
}

interface IMentorCalendarModalProps extends IConnectedMentorCalendarModalProps,
    IExternalMentorCalendarModalProps {
}

interface IMentorCalendarModalState {
}


class MentorCalendarModal extends React.Component<IMentorCalendarModalProps, IMentorCalendarModalState> {

    render() {
        // left and top value calculated from left menu width (33rem) and header (7.5rem) and space between button and modal.
        const inlineStyleCoordinates = {
            top: `${this.props.modalCoordinates ?  (this.props.modalCoordinates.yCoords / 10) - 10 : 0}rem`,
            left: `${this.props.modalCoordinates ?  (this.props.modalCoordinates.xCoords / 10) - 27 : 0}rem`,
        };
        return (
            <BasicModal isModalShown={this.props.isModalVisible}
                        closeModal={this.props.closeModal}
                        isModalCustom={true}
                        modalBgStyles={styles.modalBg}
                        wrapperInlineStyles={inlineStyleCoordinates}>
                <BasicModal.Header>
                    <div className={styles.modalHeader}>
                        <div className={styles.btnContainer}>
                            <button onClick={() => this.props.closeModal()}>
                                <i className="feather icon-x"/>
                            </button>
                        </div>
                        <h2 className={styles.modalTitle}>
                            <Translation text="cart.chooseConsultationDate"/>
                        </h2>
                    </div>
                </BasicModal.Header>
                <BasicModal.Body>
                    <Loader type={LoaderType.Local} showLoader={this.props.isCartLoading}/>

                    <MentorWeekCalendar startDate={this.props.startDate ? this.props.startDate : new Date()}
                                        calendarDetails={this.props.calendarDetails}
                                        calendarId={this.props.calendarId}
                                        daysNumberDisplay={8}
                                        selectedDate={this.props.selectedDate}
                                        additionalUnavailableDates={this.props.additionalUnavailableDates}
                                        onDateSelected={this.props.onDateSelected}/>
                </BasicModal.Body>
            </BasicModal>
        );
    }
}

export default connect(
    (state: RootState) => ({
        isCartLoading: isCartLoadingSelector(state),
    })
)(MentorCalendarModal);
