import {IModelCartGenericServiceExtraData, Loader, LoaderType, Translation} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {isCartLoadingSelector} from '../../../store/selectors/cartSelectors';
import CompetenceTestCard from './CompetenceTestCard';

interface ICompetenceTestListProps {
    readonly competenceTestArray: any[];
    readonly isCartProcessing: boolean;
    readonly isMorrisbyDetailsView?: boolean;
    readonly genericServiceExtraData?: typeof IModelCartGenericServiceExtraData;
}

class CompetenceTestList extends React.Component<ICompetenceTestListProps> {
    render() {
        if (!this.props.competenceTestArray ||
            !Array.isArray(this.props.competenceTestArray) ||
            (this.props.competenceTestArray.length === 0)
        ) {
            return (
                <p>
                    <Translation text={'defaultList.noItems'}/>
                </p>
            );
        }

        return (
            <div className="d-flex row">
                <Loader type={LoaderType.Local} showLoader={this.props.isCartProcessing}/>
                {this.renderListItems(this.props.competenceTestArray)}
            </div>
        );
    }

    private renderListItems(competenceTestArray: any[]) {
        return competenceTestArray
            .map((competenceTest: { [key: string]: any }) => <CompetenceTestCard competenceTest={competenceTest}
                                                                                 key={competenceTest.id}
                                                                                 isMorrisbyDetailsView={this.props.isMorrisbyDetailsView}
                                                                                 genericServiceExtraData={this.props.genericServiceExtraData}/>);
    }
}

export default connect(
    (state: RootState) => ({
        isCartProcessing: isCartLoadingSelector(state),
    }),
    {}
)(CompetenceTestList);
