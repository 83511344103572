import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, concatMap, debounceTime, filter, map, mergeMap, switchMap, tap,} from 'rxjs/operators';
import {BehaviorSubject, Observable, ObservableInput, of} from 'rxjs';
import {
    accountSelector,
    addAlert,
    AlertType,
    authTokenSelector,
    deepCloneObject,
    flattenObj,
    IAccount,
    isNotNullOrUndefined,
    isNullOrUndefined,
    mapStudyDurationValuesToFilters,
    RestQueryParams,
    SchoolType
} from 'educat-common-web';
import {
    applyStudyFieldsFilters,
    changeFieldsList,
    changeMainSearchLoading,
    changeStudyFieldsFilters,
    changeStudyFieldsPagination,
    changeStudyFieldsSorting,
    IFiltersListMetadata,
    IStudyFieldFilters,
    resetStudyFieldsFiltersToDefaultAccountFilters,
    SortingParams
} from '../reducers/studyFieldsSearchSlice';
import {getSchoolStudyFieldsAPI} from '../../api/getSchoolStudyFields';
import {
    studyFieldsFiltersSelector,
    studyFieldsListTypeSelector,
    studyFieldsPaginationSelector,
    studyFieldsSortingSelector
} from '../selectors/studyFieldsSearchSelectors';
import {RootState} from '../reducers';


const resetStudyFieldsFiltersToDefaultAccountFiltersEpic: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(resetStudyFieldsFiltersToDefaultAccountFilters.type),
        map(() => accountSelector(state$.value)),
        mergeMap((account: typeof IAccount): any => {
            const filters: IStudyFieldFilters | null = {
                price: null,
                duration_filter: null,
                mode: null,
                school: {
                    id: account?.profileSchoolStudyFields?.school?.id,
                },
                countries: null,
                studyField: {
                    id: account?.profileSchoolStudyFields?.studyField?.id
                },
                realm: null,
                level_filter: null,
            };

            return of(
                changeStudyFieldsFilters(filters),
            );
        })
    );

const applyStudyFieldsSearchFilters: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(action$, state$, applyStudyFieldsFilters, doFetch);

const changeStudyFieldsSearchSorting: Epic = (action$, state$: StateObservable<any>) =>
    getAction(action$, state$, changeStudyFieldsSorting, doFetch);

const changeStudyFieldsSearchPagination: Epic = (action$, state$: StateObservable<any>) =>
    getAction(action$, state$, changeStudyFieldsPagination, doFetch);

export type FetchAction = { token: string | null, flattenedParams: any };
const fetchSubject = new BehaviorSubject<FetchAction>({token: null, flattenedParams: null});
const resultsSubject = new BehaviorSubject<any>(null);
fetchSubject.asObservable().pipe(
    debounceTime(250),
    switchMap(fetch => {
        if (isNullOrUndefined(fetch.token)) {
            return of(null);
        }

        return getSchoolStudyFieldsList(fetch.token as string, new RestQueryParams(fetch.flattenedParams));
    }),
    tap(action => resultsSubject.next(action))
).subscribe(); // subscription with same lifetime as the application, no need to unsubscribe

const doFetch = (state: RootState) => {
    const authToken = authTokenSelector(state),
        paginationParams = studyFieldsPaginationSelector(state),
        filters = deepCloneObject(studyFieldsFiltersSelector(state)),
        sorting = studyFieldsSortingSelector(state),
        listType = studyFieldsListTypeSelector(state);

    filters.duration_filter = mapStudyDurationValuesToFilters(filters?.duration_filter);

    if (Array.isArray(filters?.countries?.id)) {
        filters.school = filters.school || {};
        filters.school.countries = filters?.countries;
    }
    delete filters.countries;

    if (isNotNullOrUndefined(filters?.realm?.id)) {
        filters['studyField.realm.id'] = filters?.realm?.id;
        delete filters.realm;
    }

    const filterObj = {...filters, ...paginationParams},
        flattened = flattenObj(filterObj);

    if (null !== listType) {
        flattened.push({path: 'list_type_filter', val: listType});
    }

    switch (sorting) {
        case SortingParams.TITLE_ASC:
            flattened.push({path: 'order[studyField.name]', val: 'ASC'});
            break;

        case SortingParams.TITLE_DESC:
            flattened.push({path: 'order[studyField.name]', val: 'DESC'});
            break;
    }

    fetchSubject.next({token: authToken, flattenedParams: flattened});

    return resultsSubject.asObservable().pipe(
        filter((action: any) => null !== action),
        concatMap(action => of(action, changeMainSearchLoading(false))),
    );
};

const getSchoolStudyFieldsList = (authToken: string, params?: typeof RestQueryParams) => {
    params = (params || new RestQueryParams()).add('school.type', SchoolType.College);

    return getList(getSchoolStudyFieldsAPI(authToken, params), updateListSuccessActions, updateListErrorActions);
};

export const getList = (
    api: Observable<any>,
    successActions: (list: any[], metadata: IFiltersListMetadata | null) => any[],
    errorActions: (error: any) => any[]
) => {
    return api.pipe(
        mergeMap((resp: any) => {
            const viewData = resp['hydra:view'];
            let metadata: IFiltersListMetadata | null = null;

            if (viewData.hasOwnProperty('hydra:first') &&
                viewData.hasOwnProperty('hydra:last')) {
                metadata = {
                    'hydra:first': viewData['hydra:first'],
                    'hydra:last': viewData['hydra:last'],
                    'hydra:next': viewData['hydra:next']
                };
            }

            if (viewData.hasOwnProperty('hydra:next') && metadata) {
                metadata['hydra:next'] = viewData['hydra:next'];
            }

            if (viewData.hasOwnProperty('hydra:previous') && metadata) {
                metadata['hydra:previous'] = viewData['hydra:previous'];
            }

            return of(...successActions(resp['hydra:member'], metadata));
        }),
        catchError((error: any) => of(...errorActions(error)))
    );
};

export const getErrorMessage = (error: any) => {
    let errorMessage;
    if (error.response && error.response.message) {
        errorMessage = error.response.message;
    } else if (error.response && error.response['hydra:description']) {
        errorMessage = error.response['hydra:description'];
    } else {
        errorMessage = 'Something went wrong. Please try again later.';
    }

    return errorMessage;
};

export const getAction = (
    action$: Observable<any>,
    state$: StateObservable<any>,
    actionType: any,
    doFetch: (state: RootState) => ObservableInput<any>
) => {
    return action$.pipe(
        ofType(actionType.type),
        map(() => state$.value),
        switchMap(doFetch)
    );
};

const updateListSuccessActions = (list: any[], metadata: IFiltersListMetadata | null): any[] => {
    return [
        changeFieldsList({
            list: list,
            metadata: metadata,
        })
    ]
};

const updateListErrorActions = (error: any): any[] => {
    return [
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
    ]
};

const studyFieldsSearchEpic = combineEpics(
    applyStudyFieldsSearchFilters,
    changeStudyFieldsSearchSorting,
    changeStudyFieldsSearchPagination,
    resetStudyFieldsFiltersToDefaultAccountFiltersEpic
);

export default studyFieldsSearchEpic;
