import {
    FormControlType,
    IFormConfig,
    InputType,
    ValidationRules,
} from 'educat-common-web';

export const loginFormConfig: typeof IFormConfig = {
    controlType: 'form',
    class: 'auth-form',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'login_email',
            controlType: 'group',
            class: 'row',
            controls: {
                email: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.IS_EMAIL, params: {}}
                    ],
                    placeholder: 'auth.formControls.placeholder',
                    isLabelHidden: false,
                    label: 'auth.formControls.email',
                    type: InputType.EMAIL,
                    hostClass: 'col-xl-6 form-control'
                },
                password: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 6}}
                    ],
                    placeholder: 'auth.formControls.placeholder',
                    isLabelHidden: false,
                    label: 'auth.formControls.password',
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-6 form-control'
                },
            }
        },
        {
            controlType: 'group',
            key: 'registration_agreements',
            class: 'form-row agreement-container checkbox-hide',
            controls: {
                registrationTermsAgreement: {
                    controlType: 'control',
                    defaultValue: false,
                    formControlType: FormControlType.CHECKBOX,
                    validationRules: [],
                    placeholder: '',
                    label: '',
                    isLabelHidden: true,
                    isCheckboxLabelRaw: true,
                    checkboxLabel: `Logując się na Platformę EduCat zgadzam się na <a href="https://educat.study/pl/polityka-prywatnosci/" target="_blank">Regulamin i Politykę Prywatności</a>.`,
                    hostClass: 'col-xl-12 checkbox-container'
                }
            }
        },
        {
            controlType: 'group',
            key: 'login_submit',
            class: 'login-submit-button',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: 'submit',
                    inputStyles: 'btn-theme',
                    defaultContainerStyles: '',
                    labelHidden: true,
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: 'buttons.login',
                },
            },
        },
    ]
};
