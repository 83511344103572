import React from "react";
import styles from "../styles.module.scss";
import {
    authTokenSelector,
    IModelCart,
    Loader,
    LoaderType,
    refreshAccountStateData,
    Translation
} from "educat-common-web";
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {cartSelector} from "../../../store/selectors/cartSelectors";
import {getCartData, resetCart} from "../../../store/reducers/cartSlice";
import PaymentModal from "../CartSummaryCard/PaymentModal";
import {createPaymentAPI} from "../../../api/createPayment";
import {catchError, switchMap, tap} from "rxjs/operators";
import {of} from "rxjs";
import {confirmPaymentAPI} from "../../../api/confirmPayment";
import {getPaymentCheckoutAPI} from "../../../api/getPaymentCheckout";
import {createPayuInstallments} from "../../../api/createPayuInstallments";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {WithTranslation, withTranslation} from "react-i18next";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import PurchasedItemsTable from "../PurchasedItemsTable";

interface IConnectedPaymentSummaryProps {
    readonly getCartData: typeof getCartData;
    readonly cart: typeof IModelCart | null;
    readonly authToken: string | null;
    readonly resetCart: typeof resetCart;
    readonly refreshAccountStateData: typeof refreshAccountStateData;
}

interface IExternalPaymentSummaryProps {
}

interface IPaymentSummaryProps extends IConnectedPaymentSummaryProps,
    IExternalPaymentSummaryProps,
    RouteComponentProps,
    WithTranslation {
}

interface IPaymentSummaryState {
    paymentMethod: 'card' | 'p24' | 'payu-installments';
    isPaymentModalVisible: boolean;
    isLoading: boolean;
}


class PaymentSummary extends React.Component<IPaymentSummaryProps, IPaymentSummaryState> {

    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: any) {
        super(props);
        this.state = {
            paymentMethod: 'card',
            isPaymentModalVisible: false,
            isLoading: false
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.props.getCartData();
        if (this.isPaymentAwaitingConfirmation()) {
            this.confirmPayment();
        }
    }


    render() {
        return (
            <div className={styles.cartWrapper}>
                <Loader showLoader={this.state.isLoading} type={LoaderType.Local}/>
                <p className={styles.title}>
                    <Translation text="cart.paymentSummary.title"/>
                </p>
                <div className="row">
                    <PurchasedItemsTable className={'payment-method col-xxl-8'}
                                         serviceInstances={this.props.cart?.serviceInstances}/>
                    <div className={'payment-method col-xxl-4'}>
                        <div className={'title'}>
                            <h3>Wybierz formę płatności</h3>
                        </div>
                        <div className={'content fw-bold'}>
                            <div className={'blue-border d-flex align-items-center p-3 mb-3 bg-white'} onClick={() => {
                                this.onPaymentMethodChanged({target: {value: 'card'}})
                            }}>
                                <input type={'radio'} name={'payment-method'}
                                       checked={this.state.paymentMethod === 'card'} value={'card'}
                                       onChange={this.onPaymentMethodChanged}/>
                                <label>Karta kredytowa/debetowa</label>
                            </div>

                            <div className={'blue-border d-flex align-items-center p-3 mb-3 bg-white'} onClick={() => {
                                this.onPaymentMethodChanged({target: {value: 'p24'}})
                            }}>
                                <input type={'radio'} name={'payment-method'}
                                       checked={this.state.paymentMethod === 'p24'} value={'p24'}
                                       onChange={this.onPaymentMethodChanged}/>
                                <label>Przelew bankowy lub BLIK</label>
                            </div>

                            {
                            <div className={'blue-border d-flex align-items-center p-3 mb-3 bg-white'} onClick={() => {
                                this.onPaymentMethodChanged({target: {value: 'payu-installments'}})
                            }}>
                                <input type={'radio'} name={'payment-method'}
                                       checked={this.state.paymentMethod === 'payu-installments'}
                                       value={'payu-installments'} onChange={this.onPaymentMethodChanged}/>
                                <label>Raty z PayU</label>
                            </div>
                            }

                            <button className="btn btn-theme m-5" onClick={() => {
                                this.proceedToPayment()
                            }}>
                                <Translation text="cart.paymentSummary.paymentMethod.proceed"/>
                            </button>
                        </div>
                        <div className="button-wrapper justify-content-end mt-5">
                            <Link to="/panel/cart" className={"btn btn-theme btn-rounded btn-no-arrow"}>
                                <Translation text={'cart.paymentSummary.backToCart'}/>
                            </Link>
                        </div>
                    </div>
                </div>
                {this.state.isPaymentModalVisible ? (
                    <PaymentModal isModalVisible={this.state.isPaymentModalVisible}
                                  closeModal={this.togglePaymentModal}
                                  cart={this.props.cart}/>
                ) : null
                }
            </div>
        )
    }

    onPaymentMethodChanged = (e: any) => {
        this.setState({paymentMethod: e.target.value});
    }

    private proceedToPayment() {
        if (this.state.paymentMethod === 'card') {
            this.togglePaymentModal()
        } else if (this.state.paymentMethod === 'p24') {
            this.redirectToPrzelewy24Checkout();
        } else if (this.state.paymentMethod === 'payu-installments') {
            this.redirectToPayuInstallments();
        }
    }

    private togglePaymentModal = () => {
        this.setState({isPaymentModalVisible: !this.state.isPaymentModalVisible});
    }

    private renderTableRows() {
        const serviceInstances = this.props.cart?.serviceInstances;
        if (!serviceInstances) {
            return null;
        }

        return serviceInstances.map((service: any) => (
            <tr key={service.id}>
                <td className="highlight-cell">{this.getServiceName(service)}</td>
                <td>{service.quantity}</td>
                <td>{Math.round(service.finalPrice.amount / 100)}</td>
            </tr>
        ));
    }

    getCartItemService(item: any) {
        if (item.mentorServiceDefinitions) {
            const msd = item.mentorServiceDefinitions;
            switch (true) {
                case !!msd.serviceApplicationElement:
                    return msd.serviceApplicationElement;
                case !!msd.serviceApplicationPackage:
                    return msd.serviceApplicationPackage;
                case !!msd.serviceConsultation:
                    return msd.serviceConsultation;
                case !!msd.serviceConsultationPackage:
                    return msd.serviceConsultationPackage;
                default:
                    return null;
            }
        } else if (item.serviceDefinition) {
            return item.serviceDefinition;
        }
        return null;
    }

    private getServiceName(item: any) {
        return this.getCartItemService(item)?.name;
    }

    getVatSum() {
        const cartServiceInstances = this.props.cart?.serviceInstances;
        if (!cartServiceInstances) {
            return null;
        }
        return cartServiceInstances.reduce((accumulator: number, currentValue: any) => {
            return accumulator + (currentValue.finalGrossPrice.amount - currentValue.finalPrice.amount);
        }, 0)
    }

    getGrossSum() {
        const cartServiceInstances = this.props.cart?.serviceInstances;
        if (!cartServiceInstances) {
            return null;
        }
        return cartServiceInstances.reduce((accumulator: number, currentValue: any) => {
            return accumulator + Number(currentValue.finalGrossPrice.amount);
        }, 0)
    }

    private redirectToPrzelewy24Checkout() {
        this.setState({isLoading: true})
        return createPaymentAPI(this.props.authToken, this.props.cart.id, 'p24').pipe(
            switchMap((response: any) => {
                return getPaymentCheckoutAPI(response.id, this.props.authToken).pipe(
                    tap((getPaymentSecretResp: any) => {
                        window.location.href = getPaymentSecretResp.checkoutUrl;
                    })
                )
            })
        ).subscribe();
    }

    private redirectToPayuInstallments() {
        this.setState({isLoading: true});
        return createPaymentAPI(this.props.authToken, this.props.cart.id, 'payu-installments').pipe(
            switchMap((response: any) => {
                return createPayuInstallments(response.id, this.props.authToken).pipe(
                    catchError(() => {
                        this.setState({isLoading: false});
                        this.alertManager?.handleApiError({message: 'formValidation.errors.payuError'});
                        return of();
                    }),
                    tap((result: any) => {
                        window.location.href = result.checkoutUrl;
                    })
                )
            })
        ).subscribe();
    }

    private isPaymentAwaitingConfirmation() {
        return this.props.location.search.includes('?id=');
    }

    private confirmPayment() {
        const {t} = this.props;
        this.setState({isLoading: true});
        const query = new URLSearchParams(this.props.location.search);
        const paymentId = query.get('id') ?? '';

        confirmPaymentAPI(paymentId, this.props.authToken).pipe(
            catchError((error: any) => {
                this.setState({isLoading: false});
                this.alertManager?.handleApiError(error.response);
                this.props.resetCart();
                this.props.refreshAccountStateData();
                this.props.history.push('/panel/cart');
                return of();
            }),
            tap(() => {
                this.setState({isLoading: false});
                this.alertManager.addAlert(t('cart.addPayment.cartPaid'));
                this.props.resetCart();
                this.props.refreshAccountStateData();
                this.props.history.push('/panel/cart/payment/success');
            })
        ).subscribe();
    }
}

export default connect(
    (state: RootState) => ({
        cart: cartSelector(state),
        authToken: authTokenSelector(state)
    }),
    {
        resetCart,
        getCartData,
        refreshAccountStateData
    }
)(withRouter(withTranslation()(PaymentSummary)));
