import React from 'react';
import {changeBreadcrumbs} from 'educat-common-web';
import {connect} from "react-redux";
import {RootState} from "../../store/reducers";
import {getCartData} from '../../store/reducers/cartSlice';
import CartCard from "./CartCard";
import CartSummaryCard from "./CartSummaryCard";
import styles from "./styles.module.scss";
import {Translation} from 'educat-common-web';
import {cartSelector} from "../../store/selectors/cartSelectors";
import {IModelCart} from 'educat-common-web';

interface IConnectedCartProps {
    readonly cart: typeof IModelCart | null;
    readonly changeBreadcrumbs: typeof changeBreadcrumbs;
    readonly getCartData: typeof getCartData;
}

interface IExternalCartProps {}

interface ICartProps extends IConnectedCartProps,
    IExternalCartProps {}

interface ICartState {}


class Cart extends React.Component<ICartProps, ICartState> {

    componentDidMount(): void {
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
            {labelKey: 'breadcrumbs.cart', path: '/panel/cart'}
        ]);

        this.props.getCartData();
    }

    render() {
        const serviceInstances = this.props.cart?.serviceInstances || [];

        return (
            <div className={styles.cartWrapper}>
                <p className={styles.title}>
                    <Translation text="cart.title" />
                </p>
                <div className="row">
                    <div className="col-xxl-8 cart-wrapper">
                        <CartCard serviceInstances={serviceInstances}/>
                    </div>
                    <div className="col-xxl-4">
                        <CartSummaryCard cart={this.props.cart} />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state: RootState) => ({
        cart: cartSelector(state)
    }),
    {
        changeBreadcrumbs,
        getCartData
    }
)(Cart);
