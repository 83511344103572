import {CustomCard, FieldOfStudyStudyLevel, Translation} from 'educat-common-web';
import React from 'react';
import Actions from '../Actions';
import {FieldOfStudyListType} from '../types';
import styles from './styles.module.scss';

interface IExternalFieldDetailsCardProps {
    school: { [key: string]: any } | undefined;
    studyField: { [key: string]: any } | undefined;
    duration: { [key: string]: any } | undefined;
    levels: { [key: string]: any } | undefined;
    mode: string | undefined;
    currentProfileListState: FieldOfStudyListType;
    addToFavorite: () => void;
    addToApplicationList: () => void;
}

interface IFieldDetailsCardProps extends IExternalFieldDetailsCardProps {
}

class FieldDetailsCard extends React.Component<IFieldDetailsCardProps> {
    render() {
        return (
            <CustomCard showLocalLoader={false}>
                <CustomCard.Body>
                    <div className={styles.schoolDetails}>
                        <div className={styles.detailsHeader}>
                            <h1>
                                {this.props.school ? <p>{this.props.school.name}</p> : null}
                                {this.props.studyField ? <p className={styles.subject}>{this.props.studyField.name}</p> : null}
                            </h1>
                            {this.props.school && this.props.school.rankingPlace ? (
                                <div className={styles.schoolRanking}>
                                    <p className={styles.number}>
                                        <Translation text="fieldOfStudy.fieldOfStudyProfile.rankingNo"
                                                     option={{rankNumber: this.props.school.rankingPlace}}/>
                                    </p>
                                    <p className={styles.label}>
                                        <Translation text="fieldOfStudy.fieldOfStudyProfile.ranking"/>
                                    </p>
                                </div>
                            ) : null}
                        </div>

                        <div className={styles.schoolDescriptionWrapper}>
                            <div className={styles.schoolDescription}>
                                {this.renderLocation(this.props.school)}
                                {this.renderMode(this.props.mode)}

                                {this.renderDuration(this.props.duration)}
                                <span className={styles.divider}/>
                                {this.renderSchoolLevels(this.props.levels)}
                            </div>
                            {this.renderActionsButton(this.props.currentProfileListState)}
                        </div>
                    </div>
                </CustomCard.Body>
            </CustomCard>
        );
    }

    private renderDuration(duration: any) {
        if (!duration) {
            return null;
        }

        return (
            <p className={styles.description}>
				<span>
					<Translation
                        text={`fieldOfStudy.fieldOfStudyItem.${duration.unit === 'months' ? 'durationMonth' : 'durationYear'}`}
                        config={{duration: duration.length}}
                    />
				</span>
            </p>
        );
    }

    private renderLocation(school: any) {
        if (!school) {
            return null;
        }
        return (
            <>
                <i className="feather icon-map-pin"/>
                <p className={styles.description}>
                    <span>{school.city}, </span>
                    <span>
						<Translation text={`country.${school.countries[0].id}`}/>
					</span>
                </p>
                <span className={styles.divider}/>
            </>
        );
    }

    private renderSchoolLevels(levels: any) {
        if (!levels || !levels.levels) {
            return null;
        }
        return levels.levels
            .filter((levelItem: any) => levelItem.name === FieldOfStudyStudyLevel.BACHELOR || levelItem.name === FieldOfStudyStudyLevel.MASTER || levelItem.name === FieldOfStudyStudyLevel.PREPARATION)
            .map((levelItem: any, index: number) =>
                <p className={styles.description} key={`${levelItem.name}_${index}`}>
                    <Translation text={`fieldOfStudy.fieldOfStudyItem.studyLevel.${levelItem.name}`}/>
                </p>
            );
    }

    private renderMode(mode: any) {
        if (!mode) {
            return null;
        }
        return (
            <>
                <p className={styles.description}>
                    <Translation text={`fieldOfStudy.fieldOfStudyItem.studyMode.${mode}`}/>
                </p>
                <span className={styles.divider}/>
            </>
        );
    }

    private renderActionsButton(listType: FieldOfStudyListType) {
        let options = {
            isFavoriteAdded: listType === FieldOfStudyListType.FAVOURITE,
            isApplyingAdded: listType === FieldOfStudyListType.APPLYING,
        };

        return (
            <Actions isFavoriteAdded={options.isFavoriteAdded}
                     isApplyingAdded={options.isApplyingAdded}
                     addToFavorite={this.props.addToFavorite}
                     addToApplying={this.props.addToApplicationList}/>
        );
    }
}

export default FieldDetailsCard;
