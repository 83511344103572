import React from 'react';
import {
    authTokenSelector,
    DateComponent,
    deepCloneObject,
    IModelCartOnlineConsultation,
    IModelCartServiceInstance,
    IModelMentorServiceDefinition,
    isNotNullOrUndefined,
    isNullOrUndefined,
    Translation
} from 'educat-common-web';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import styles from './styles.module.scss';
import {isCartLoadingSelector} from '../../../../store/selectors/cartSelectors';
import {updateCartOnlineConsultationDates} from '../../../../store/reducers/cartSlice';
import moment from 'moment';


export type ModalCoordinates = {
    yCoords: number,
    xCoords: number
}

interface IConnectedMentorItemCalendarProps {
    readonly isCartLoading: boolean;
    readonly authToken: string;
    readonly updateCartOnlineConsultationDates: typeof updateCartOnlineConsultationDates;
}

interface IExternalMentorItemCalendarProps {
    readonly serviceInstance: typeof IModelCartServiceInstance;
    readonly mentorServiceDefinitions: typeof IModelMentorServiceDefinition;
    readonly allServiceInstances: typeof IModelCartServiceInstance[];
    readonly openCalendarModal: (onModalDateSelected: (date: Date) => void,
                                 calendarId: string,
                                 calendarModal: {[key: string]: any},
                                 additionalUnavailableDates: Date[],
                                 startDate: Date,
                                 modalCoordinates: ModalCoordinates | null,
                                 selectedDate?: Date | null) => void,
    readonly isServiceHourSelection?: boolean;
    readonly bookedConsultationSlots?: typeof IModelCartOnlineConsultation[] | null;
    readonly removeSlot?: (consultationDate: typeof IModelCartOnlineConsultation) => void;
    readonly onDateSelected?: (date: Date | null | undefined, consultationDate?: typeof IModelCartOnlineConsultation) => void;
}

interface IMentorItemCalendarProps extends IConnectedMentorItemCalendarProps,
    IExternalMentorItemCalendarProps {

}

interface IMentorItemCalendarState {
    isModalShown: boolean;
}


class MentorItemCalendar extends React.Component<IMentorItemCalendarProps, IMentorItemCalendarState> {
    constructor(props: IMentorItemCalendarProps) {
        super(props);

        this.state = {
            isModalShown: false,
        };
    }

    render() {
        const serviceInstance = this.props.serviceInstance;
        const consultationDates = isNotNullOrUndefined(this.props.bookedConsultationSlots) ? this.props.bookedConsultationSlots : serviceInstance.extraData.onlineConsultationDates;

        return (
            <div className={`${styles.serviceTerms} 
                             ${this.props.isServiceHourSelection ? styles.serviceModal : ''} 
                             ${!this.props.isServiceHourSelection && 'col-xxl-4'}`}>
                <p className={styles.title}>
                    <Translation text="cart.cartDetails.meetingDates"/>
                </p>
                <div className={styles.eventsContainer}>
                    {consultationDates.map((consultationDate: typeof IModelCartOnlineConsultation) =>
                        <span className={styles.eventDate}
                                key={consultationDate['@id']}>
                            <DateComponent date={consultationDate.from} format="dddd, D.MM.YYYY [godz.] HH:00"/>
                            <button disabled={this.props.isCartLoading ||
                                    (this.isBtnDisabled(consultationDate.from) && this.props.isServiceHourSelection)}
                                    onClick={(event: any) => this.openModal(consultationDate, event)}>
                                <i className="feather icon-edit-2"/>
                            </button>

                            {isNotNullOrUndefined(this.props.isServiceHourSelection) && this.props.isServiceHourSelection ?
                                (<button disabled={this.props.isCartLoading || this.isBtnDisabled(consultationDate.from)}
                                         onClick={() => {
                                             if (undefined !== this.props.removeSlot) {
                                                this.props.removeSlot(consultationDate)
                                             }
                                         }}>
                                    <i className="feather icon-trash-2"/>
                                </button>) : null
                            }

                        </span>)}

                    {this.openSlots.map(key => <div className={styles.selectEvent} key={key}>
                        <button className={styles.freeEvent}
                                disabled={this.props.isCartLoading}
                                onClick={(event: any) => this.openModal(null, event)}>
                            <Translation text="cart.cartDetails.dateNotSelected"/>
                        </button>
                    </div>)}
                </div>
            </div>
        );
    }

    private get openSlots(): number[] {
        const serviceInstance = this.props.serviceInstance;
        const consultationDates = this.props.isServiceHourSelection && this.props.bookedConsultationSlots ?
            this.props.bookedConsultationSlots : serviceInstance.extraData.onlineConsultationDates;
        const maxSlots = serviceInstance.usedLimit * serviceInstance.quantity;
        const missingSlots = maxSlots - consultationDates.length;
        const slots: number[] = [];
        for (let i = 0; i < missingSlots; i++) {
            slots.push(i);
        }

        return slots;
    }

    private openModal = (consultationDate?: typeof IModelCartOnlineConsultation, event?: any) => {
        if (this.props.isCartLoading) {
            return;
        }

        let calendarId = this.props.mentorServiceDefinitions?.mentor?.account?.calendars?.[0].id;
        const mentorId = isNotNullOrUndefined(this.props.serviceInstance.mentorServiceDefinitions) ? this.props.serviceInstance.mentorServiceDefinitions.mentor.id :
            this.props.serviceInstance?.extraData?.mentorId,
            calendarDetails = this.props.mentorServiceDefinitions?.mentor?.account?.calendars?.[0];

        if (isNotNullOrUndefined(this.props.serviceInstance) &&
            !isNullOrUndefined(this.props.serviceInstance.extraData) &&
            !isNullOrUndefined(this.props.serviceInstance.extraData.calendarId)) {
            calendarId = this.props.serviceInstance.extraData.calendarId;
        }

        if (isNullOrUndefined(calendarId)) {
            return;
        }
        let startDate = new Date();
        let selectedDate: Date | null = null;
        if (isNotNullOrUndefined(consultationDate)) {
            startDate = new Date(consultationDate.from);
            selectedDate = new Date(consultationDate.from);
        }

        const additionalDates: Date[] = [];
        this.props.allServiceInstances
            .filter(service => {
                if ((isNullOrUndefined(service?.mentorServiceDefinitions) && isNullOrUndefined(service?.extraData?.onlineConsultationDates)) || isNullOrUndefined(mentorId)) {
                    return false
                }
                return mentorId === service.mentorServiceDefinitions.mentor.id;
            })
            .forEach(service => {
                service.extraData.onlineConsultationDates
                    .filter((entry: typeof IModelCartOnlineConsultation) => entry !== consultationDate)
                    .forEach((entry: typeof IModelCartOnlineConsultation) => {
                        additionalDates.push(new Date(entry.from));
                    });
            });

        const modalCoordinates = (event?.pageY && event?.pageX) ? {yCoords: event.pageY, xCoords: event.pageX} : null;
        this.props.openCalendarModal(
            date => undefined !== this.props.onDateSelected ? this.props.onDateSelected(date, consultationDate) :
                this.onDateSelected(date, consultationDate),
            calendarId,
            calendarDetails,
            additionalDates,
            (startDate as Date),
            modalCoordinates,
            selectedDate
        );
    };

    private onDateSelected = (date: Date | null | undefined, entry?: typeof IModelCartOnlineConsultation) => {
        if (this.props.isCartLoading) {
            return;
        }

        const clone = this.props.serviceInstance.extraData.onlineConsultationDates.map(deepCloneObject);
        let updated = false;
        if (isNullOrUndefined(entry)) {
            if (isNotNullOrUndefined(date)) {
                clone.push({
                    from: (date as Date).toISOString(),
                    to: moment(date).add(1, 'hour').toISOString(),
                });
                updated = true;
            }
        } else {
            const index = clone.findIndex((candidate: typeof IModelCartOnlineConsultation) => candidate['@id'] === entry['@id']);

            if (-1 !== index) {
                if (isNotNullOrUndefined(date)) {
                    clone[index] = {
                        from: (date as Date).toISOString(),
                        to: moment(date).add(1, 'hour').toISOString(),
                    };
                } else {
                    clone.splice(index);
                }
                updated = true;
            }
        }

        if (updated) {
            this.props.updateCartOnlineConsultationDates(this.props.serviceInstance.id, clone
                .map((entry: typeof IModelCartOnlineConsultation) => {
                    delete entry['@type'];
                    delete entry['@id'];

                    return entry;
                }));
        }
    };

    private isBtnDisabled(startsAt: string): boolean {
        const oneDay = 24 * 60 * 60 * 1000;
        return (new Date(startsAt).getTime() - new Date().getTime()) <= oneDay;
    }
}

export default connect(
    (state: RootState) => ({
        isCartLoading: isCartLoadingSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        updateCartOnlineConsultationDates,
    }
)(MentorItemCalendar);
