import React from 'react';
import {
    authTokenSelector,
    consultationSlotsToBlockSelector,
    Form,
    FormControlChangeType,
    getAvailableConsultationSlotsAPI,
    IBlockerEvent,
    IFormConfig,
    isNotNullOrUndefined,
    isNullOrUndefined,
    isSameValue,
    Loader,
    LoaderType,
} from 'educat-common-web';
import {connect} from 'react-redux';
import {RootState} from '../../../../../store/reducers';
import {fixInjectedProperties, lazyInject} from '../../../../../ioc';
import moment from 'moment';
import {BehaviorSubject, of, Subscription} from 'rxjs';
import {IAlertManagerService} from '../../../../../service/alertManagerService';
import {mentorCalendarFormConfig} from './mentorCalendarFormConfig';
import styles from './styles.module.scss';
import {catchError, filter, map, tap} from 'rxjs/operators';


interface IConnectedMentorWeekCalendarProps {
    readonly authToken: string;
    readonly slotsToBlock: typeof IBlockerEvent[];
}

interface IExternalMentorWeekCalendarProps {
    startDate: Date;
    calendarDetails?: { [key: string]: any } | null;
    calendarId?: string | null;
    daysNumberDisplay?: number;
    selectedDate?: Date;
    additionalUnavailableDates?: Date[] | null;
    onDateSelected?: (date: Date) => void;
}

interface IMentorWeekCalendarProps extends IConnectedMentorWeekCalendarProps,
    IExternalMentorWeekCalendarProps {
}

interface IMentorWeekCalendarState {
    formConfig: typeof IFormConfig | null;
    value: any;
    isLoading: boolean;
    availableConsultationSlots: { [key: string]: any } | null;
}

const DEFAULT_DAYS_NUMBER_DISPLAY = 5;

class MentorWeekCalendar extends React.Component<IMentorWeekCalendarProps, IMentorWeekCalendarState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IMentorWeekCalendarProps) {
        super(props);

        this.state = {
            formConfig: null,
            value: this.newValueFromProps(),
            isLoading: false,
            availableConsultationSlots: null,
        };

        fixInjectedProperties(this);
    }

    private get daysNumberDisplay(): number {
        return this.props.daysNumberDisplay || DEFAULT_DAYS_NUMBER_DISPLAY;
    }

    componentDidMount(): void {
        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onFormValueChange(data.value)),
            ).subscribe()
        );

        if (this.props.calendarId) {
            this.getAvailableConsultationSlots(this.props.calendarId, this.props.startDate);
        }

        this.setFormConfig();
    }

    componentDidUpdate(
        prevProps: Readonly<IMentorWeekCalendarProps>,
        prevState: Readonly<IMentorWeekCalendarState>,
        snapshot?: any
    ): void {
        const hasStartDateChanged = (!isSameValue(this.props.startDate, prevProps.startDate) && this.props.startDate),
            hasCalendarIdChanged = (!isSameValue(this.props.calendarId, prevProps.calendarId) && isNotNullOrUndefined(this.props.calendarId));
        if (!isSameValue(this.state.availableConsultationSlots, prevState.availableConsultationSlots) ||
            !isSameValue(this.props.daysNumberDisplay, prevProps.daysNumberDisplay) ||
            hasStartDateChanged) {
            this.setFormConfig();
        }
        if (hasStartDateChanged || hasCalendarIdChanged) {
            this.getAvailableConsultationSlots(this.props.calendarId, this.props.startDate);
        }
        if (this.props.selectedDate && this.props.selectedDate?.getTime() !== prevProps.selectedDate?.getTime()) {
            this.setState({
                value: this.newValueFromProps(),
            });
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <div className={styles.weekCalendarContainer}>
                <div>
                    {this.state.formConfig && <Form config={this.state.formConfig}
                                                    controlName={'calendarForm'}
                                                    onValueStateChange={this.onValueStateChange}
                                                    value={this.state.value}/>}
                </div>

                <Loader showLocalLoader={this.state.isLoading} type={LoaderType.Local}/>
            </div>
        )
    }

    private setFormConfig = () => {
        this.setState({
            formConfig: mentorCalendarFormConfig(
                this.props.startDate,
                this.state.availableConsultationSlots,
                this.daysNumberDisplay,
                this.props.calendarDetails
            )
        });
    };

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onFormValueChange = (value: any) => {
        if (isNotNullOrUndefined(value?.date?.name)) {
            if ('weekDate' === value.date.name) {
                this.getAvailableConsultationSlots(this.props.calendarId, value.date.value);
            }

            if ('selectedDate' === value.date.name && isNotNullOrUndefined(this.props.onDateSelected)) {
                (this.props.onDateSelected as any)(new Date(value.date.value));
            }
        }
        this.setState({value: value});
    };

    private getAvailableConsultationSlots(calendarId: string | null | undefined, date: Date) {
        if (isNullOrUndefined(calendarId)) {
            this.setState({availableConsultationSlots: {}});

            return;
        }

        this.setState({isLoading: true});

        const firstDay = moment(new Date(date)).format('YYYY-MM-DD'),
            lastDay = moment(date)
                .add(this.daysNumberDisplay - 1, 'd')
                .format('YYYY-MM-DD');

        this.subscriptions.push(
            getAvailableConsultationSlotsAPI(
                this.props.authToken,
                firstDay,
                lastDay,
                calendarId,
            ).pipe(
                map((resp: any) => {
                    this.props.additionalUnavailableDates?.forEach(date => {
                        const target = resp?.[date.getFullYear()]?.[date.getMonth() + 1]?.[date.getDate()];
                        if (isNullOrUndefined(target)) {
                            return;
                        }
                        const unavailableTime = date.getTime();
                        Object.keys(target).forEach(key => {
                            const entryStartsAt = new Date(target[key].starts_at).getTime();
                            if (entryStartsAt === unavailableTime) {
                                target[key].is_free = false;
                            }
                        });
                    });

                    this.setState({isLoading: false, availableConsultationSlots: resp});
                }),
                catchError((error: any) => {
                    this.setState({isLoading: false});
                    this.alertManager.handleApiError(error);

                    return of();
                })
            ).subscribe()
        );
    }

    private newValueFromProps = () => {
        return this.props.selectedDate ?
            {
                date: {
                    name: 'selectedDate',
                    value: this.props.selectedDate?.toISOString(),
                }
            } :
            null;
    };
}

export default connect(
    (state: RootState) => ({
        slotsToBlock: consultationSlotsToBlockSelector(state),
        authToken: authTokenSelector(state),
    }),
    {}
)(MentorWeekCalendar);
