import React from 'react';
import styles from './../styles.module.scss';
import {CustomCard, FieldOfStudyCardList, StudyFieldsListType, Translation, UserRole} from 'educat-common-web';
import {Link} from 'react-router-dom';

interface IExternalSchoolDetailsProps {
    readonly schoolStudyFields: any;
    readonly toggleReviewState: (currentState: typeof StudyFieldsListType | null, schoolStudyFieldId: string) => void;
}

interface ISchoolDetailsProps extends IExternalSchoolDetailsProps {
}

interface ISchoolDetailsState {
}

class SchoolDetails extends React.Component<ISchoolDetailsProps, ISchoolDetailsState> {
    render() {
        return (
            <CustomCard showLocalLoader={false}>
                <CustomCard.Header>
                    <h2 className="custom-card-title">
                        <Translation text="dashboard.fieldOfStudy.title"/>
                    </h2>
                </CustomCard.Header>
                <CustomCard.Body>
                    <div className="pb-5">
                        <Translation text="dashboard.fieldOfStudy.description"/>
                    </div>

                    <div className="row">
                        <FieldOfStudyCardList fieldOfStudyArray={this.props.schoolStudyFields}
                                              toggleReviewState={this.props.toggleReviewState}
                                              userRole={UserRole.APPLICANT}/>
                    </div>
                    <div className={`button-wrapper ${styles.btnWrapper}`}>
                        <Link className="btn btn-theme" to="/panel/field-of-study-search">
                            <Translation text="buttons.searchRedirect"/>
                        </Link>
                    </div>
                </CustomCard.Body>
            </CustomCard>
        );
    }
}

export default SchoolDetails;
