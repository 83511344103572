import {authTokenSelector, changeBreadcrumbs, CustomCard, Translation} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {of, Subscription} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {getTestResultAPI} from '../../../api/getTestResult';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import {IAlertManagerService} from '../../../service/alertManagerService';
import {RootState} from '../../../store/reducers';
import CompetenceTestSection from './CompetenceTestSection';
import RatingLegendComponent from './RatingLegendComponent';

interface IConnectedCompetenceTestHostProps {
    readonly authToken: string;
    changeBreadcrumbs: typeof changeBreadcrumbs;
}

interface ICompetenceTestHostProps extends IConnectedCompetenceTestHostProps, RouteComponentProps {
}

interface ICompetenceTestHostState {
    isProcessing: boolean;
    competenceTestResult: any;
    selectedSectionId: string | null;
}

class CompetenceTestResult extends React.Component<ICompetenceTestHostProps, ICompetenceTestHostState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: ICompetenceTestHostProps) {
        super(props);

        this.state = {
            isProcessing: false,
            competenceTestResult: null,
            selectedSectionId: null,
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        const routeState: any = this.props.location.state;
        let id: string | null | undefined;
        if (routeState) {
            this.changeBreadcrumbs(routeState.name);
            id = routeState.id;
        } else {
            const matches = /\/panel\/.+\/(.+)$/.exec(this.props.location.pathname);
            id = matches?.[1];
        }

        this.subscriptions.push(
            getTestResultAPI(this.props.authToken, (id as string))
                .pipe(
                    tap((resp: any) => {
                        this.setState({competenceTestResult: resp});
                        this.changeBreadcrumbs(resp.serviceInstance.serviceDefinition.name);
                    }),
                    catchError((error: any) => {
                        this.alertManager?.handleApiError(error.response);
                        return of(error);
                    })
                )
                .subscribe()
        );
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        if (!this.state.competenceTestResult) {
            return null;
        }
        const sections = this.state.competenceTestResult.testRealisationQuestions,
            testName = this.state.competenceTestResult.serviceInstance.serviceDefinition.name,
            ratingDescriptions = this.state.competenceTestResult.serviceSubjectTest.percentageRangeDescriptions.percentageRangeDescriptions,
            currentRating = this.state.competenceTestResult.resultPercentage,
            currentRatingDescription = this.state.competenceTestResult.currentResultDescription;

        return (
            <section className="row section competence-test-result ">
                <div className="col-md-12 col-xl-8">
                    <CustomCard showLocalLoader={false}>
                        <CustomCard.Header>
                            <div className="header-section">
                                <h1 className="header-title">
                                    <Translation text="competence.competenceTestResult.title"/>
                                    <span className="fw-bolder ms-2"> {testName}</span>
                                </h1>
                                <p className="header-description">
                                    <span>
                                        <Translation text={'competence.competenceTestResult.description.yourResult'}/>
                                        <span className="highlighted-rating">{currentRating}</span>
                                        <Translation text={'competence.competenceTestResult.description.yourResultPoints'}/>
                                    </span>
                                    <span>
                                        <Translation text={'competence.competenceTestResult.description.yourRate'}/>
                                        <span className="highlighted-rating">{currentRatingDescription}</span>
                                    </span>
                                    <Translation
                                        text={'competence.competenceTestResult.description.descriptionContent'}
                                        config={{testName: testName, result: currentRating, resultDescription: currentRatingDescription}}
                                    />
                                </p>
                            </div>
                        </CustomCard.Header>
                    </CustomCard>

                    {this.renderTestSections(sections)}
                </div>
                <div className="col-md-12 col-xl-4">
                    <CustomCard showLocalLoader={false}>
                        <CustomCard.Header>
                            <h2 className="view-subtitle">
                                <Translation text="competence.competenceTestResult.sidebarTitle"/>
                            </h2>
                        </CustomCard.Header>
                        <CustomCard.Body>
                            <ul className="test-section-list">{this.renderNavItems(sections)}</ul>
                        </CustomCard.Body>
                    </CustomCard>
                    <RatingLegendComponent ratingDescriptionArray={ratingDescriptions} currentRating={currentRating}/>
                </div>
            </section>
        );
    }

    private renderTestSections(sections: any[]) {
        if (!sections || !sections.length) {
            return null;
        }

        return sections.map((section: any) => <CompetenceTestSection key={`section_${section.sectionId}`} section={section}/>);
    }

    private renderNavItems(sections: any[]) {
        return sections.map((sectionItem: any) => (
            <li key={sectionItem.sectionId}
                className={`test-section-item ${sectionItem.sectionId === this.state.selectedSectionId ? 'active' : ''}`}>
                <a className="test-section-link" href={`#${sectionItem.sectionId}`} onClick={() => this.selectSectionId(sectionItem.id)}>
                    {sectionItem.sectionName}
                </a>
            </li>
        ));
    }

    private selectSectionId(id: string) {
        if (this.state.selectedSectionId === id) {
            return null;
        }
        this.setState({selectedSectionId: id});
    }

    private changeBreadcrumbs = (name: string): void => {
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
            {labelKey: 'breadcrumbs.competence', path: '/panel/diagnostics'},
            {labelKey: 'breadcrumbs.competenceResult', path: '/panel/diagnostics'},
            {labelKey: name, path: ''},
        ]);
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
    }),
    {
        changeBreadcrumbs,
    }
)(withRouter(CompetenceTestResult));
