import {FieldOfStudyStudyLevel, isSameValue, Price, Translation} from 'educat-common-web';
import React from 'react';
import {WithTranslation, withTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import {getTuitionFeesPrice, IFieldOfStudy} from '../../../../FieldOfStudy/FieldOfStudyProfile/types';
import {FieldOfStudySearchRoute} from '../../../MainSearch';
import ActionButtons from '../../ActionButtons';
import styles from "./styles.module.scss";


interface IConnectedFieldOfStudyListItemProps {
}

interface IExternalFieldOfStudyListItemProps {
    fieldOfStudyItem: IFieldOfStudy;
    itemType: FieldOfStudySearchRoute;
}

interface IFieldOfStudyListItemProps extends IConnectedFieldOfStudyListItemProps,
    IExternalFieldOfStudyListItemProps,
    WithTranslation {
}

interface IFieldOfStudyListItemState {

}


class FieldOfStudyListItem extends React.Component<IFieldOfStudyListItemProps, IFieldOfStudyListItemState> {

    constructor(props: IFieldOfStudyListItemProps) {
        super(props);
        this.state = {}
    }

    componentDidMount(): void {
    }

    componentDidUpdate(
        prevProps: Readonly<IFieldOfStudyListItemProps>,
        prevState: Readonly<IFieldOfStudyListItemState>,
        snapshot?: any
    ): void {
        if (!isSameValue(this.props.fieldOfStudyItem, prevProps.fieldOfStudyItem)) {
            this.setState({fieldOfStudy: this.props.fieldOfStudyItem});
        }
    }

    render() {
        const fieldOfStudy = this.props.fieldOfStudyItem,
            tuitionFeePrice = getTuitionFeesPrice(fieldOfStudy);

        return (
            <li className={`${styles.fieldOfStudyListItemWrapper} ${this.props.fieldOfStudyItem.markedToRemove ? styles.removeAnimation : ''}`}>
                <div className={`${styles.imageWrapper} col-xl-5`}>
                    {fieldOfStudy.school.logo &&
                    <img src={fieldOfStudy.school.logo.mediumThumb} className={styles.fieldOfStudyImage}
                         alt={'School logo'}/>
                    }
                </div>
                <div className={`${styles.fieldOfStudyDetailsWrapper} col-xl-6`}>
                    <NavLink to={`/panel/field-of-study-profile/${fieldOfStudy.id}`} style={{textDecoration: 'none'}}>
                        <p className={styles.fieldOfStudyCategory}>{fieldOfStudy.studyField.name}</p>
                    </NavLink>
                    <h3 className={styles.fieldOfStudyTitle}>{fieldOfStudy.school.name}</h3>
                    <div className={styles.fieldOfStudyInformationWrapper}>
                        <span>
                            <i className={`${styles.markerIcon} feather icon-map-pin`}/>
                            <span className={styles.markerIcon}/>
                            {fieldOfStudy.school.city ? (<span>{fieldOfStudy.school.city}, </span>) : null}
                            <span>
                                {fieldOfStudy.school.countries[0] ?
                                    (<Translation
                                        text={`country.${fieldOfStudy.school.countries[0].id}`}/>) :
                                    null
                                }
                            </span>
                        </span>
                        <span className={styles.details}>
                            <span>
                                <Translation text={`fieldOfStudy.fieldOfStudyItem.studyMode.${fieldOfStudy.mode}`}/>
                            </span>
                            {fieldOfStudy.duration.length ?
                                (<span>
                                    <Translation
                                        text={`fieldOfStudy.fieldOfStudyItem.${fieldOfStudy.duration.unit === "months" ? "durationMonth" : "durationYear"}`}
                                        config={{duration: fieldOfStudy.duration.length}}/>
                                </span>) : null
                            }
                            <span>{
                                fieldOfStudy.levels.levels
                                    .filter((levelItem: any) => levelItem.name === FieldOfStudyStudyLevel.BACHELOR || levelItem.name === FieldOfStudyStudyLevel.MASTER || levelItem.name === FieldOfStudyStudyLevel.PREPARATION)
                                    .map((levelItem: any) => <Translation key={levelItem['@id']} text={`fieldOfStudy.fieldOfStudyItem.studyLevel.${levelItem.name}`}/>)
                            }</span>
                        </span>
                    </div>
                    {tuitionFeePrice ?
                        (<div className={styles.price}>
                            <span className={styles.priceValue}>
                                <Price price={tuitionFeePrice} separateWithNbsp={true}/>
                            </span>
                            <Translation text="fieldOfStudy.fieldOfStudyItem.forYear"/>
                        </div>) :
                        null}
                </div>
                <div className={`${styles.fieldOfStudyActionButtonsWrapper} col-xl-1`}>
                    <ActionButtons fieldOfStudyItem={this.props.fieldOfStudyItem}
                                   itemType={this.props.itemType}/>
                    {fieldOfStudy.school.rankingPlace ?
                        (<div className={`${styles.rankWrapper} rank-wrapper`}>
                            <p className="rank-number">
                                <span className="rank-place">
                                    <Translation text="fieldOfStudy.fieldOfStudyItem.rankingNo"/>
                                </span>
                                {fieldOfStudy.school.rankingPlace}
                            </p>
                            <span className="rank-text">
                                <Translation text="fieldOfStudy.fieldOfStudyItem.rankingPosition"/>
                            </span>
                        </div>) : null}
                </div>
            </li>
        );
    }
}

export default withTranslation()(FieldOfStudyListItem);
