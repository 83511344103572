
const pl = {
  topBar: {
    balance: 'Twoje saldo:',
    cart: 'Twój koszyk'
  },
  navMenu: {
    profile: 'Profil aplikanta',
    payments: 'Płatności',
    logout: 'Wyloguj'
  },
  auth: {
    authFooter: {
      srTitle: 'Stopka strony zawierająca dane kontaktowe, nawigację po stronie i zapisy do newslettera',
      addressColumn: {
        title: 'Educat Sp. z o.o.',
        address1: 'ul. Zygmunta Krasińskiego 2/55',
        address2: '20-709 Lublin, Polska',
        nip: 'NIP: 9662136157',
        regon: 'REGON: 385301298'
      },
      contactColumn: {
        title: 'Kontakt',
        correspondenceAddressTitle: 'Adres korespondencyjny:',
        address1: 'Al. KEN 93 (iDID) ',
        address2: '02-777 Warszawa'
      },
      linksColumn: {
        title: 'Educat',
        aboutUs: 'O nas',
        career: 'Kariera',
        cooperation: 'Współpraca',
        contact: 'Kontakt',
        policies: 'Regulamin i polityka prywatności',
        safePayments: 'Bezpieczne płatności',
      },
      newsletterColumn: {
        title: 'Bądź na bieżąco',
        subscribeInfo: 'Subskrybuj nasz newsletter',
        newsletterLabelSR: 'Wpisz swój adres email aby otrzymac newsletter',
        newsletterSubmitButtonSR: 'Przycisk zatwierdzania formularza subskrypcji Newslettera'
      },
      copyright: 'Educat {{ year }} © Wszystkie prawa zastrzeżone.',
      socialIcons: {
        facebook: 'Link prowadzący do profilu na Facebooku',
        instagram: 'Link prowadzący do profilu na Instagramie',
        linkedin: 'Link prowadzący do profilu na Linkedin',
        youtube: 'Link prowadzący do kanalu Youtube',
      }
    },
    formControls: {
      placeholder: 'Wpisz tutaj',
      email: 'Email',
      password: 'Hasło',
      newPassword: 'Nowe hasło',
      repeatPassword: 'Powtórz nowe hasło',
      phone: 'Telefon',
      firstName: 'Imię',
      lastName: 'Nazwisko',
    },
    register: {
      title: 'Zarejestruj się',
      subtitle: 'Platforma EduCat to  miejsce ułatwiające proces przygotowania do studiów. Wypełnij poniższy formularz, aby założyć swój profil.',
      haveAccount: 'Masz już konto w naszym serwisie? ',
      logIn: 'Zaloguj się.',
      registerWith: {
        title: 'lub zarejestruj się bezpośrednio za pomocą swojego konta na:',
        facebook: 'Zarejestruj się przez Facebook',
        google: 'Zarejestruj się przez Google'
      }
    },
    confirmRegistration: {
      title: "Potwierdź rejestrację",
      subtitle: "Aby zakończyć rejestrację nacisnij przycisk poniżej."
    },
    login: {
      title: 'Zaloguj się',
      subtitle: 'Spełniaj swoje marzenia razem z nami na Platformie EduCat.',
      haveAccount: 'Nie masz jeszcze konta w naszym serwisie? ',
      register: 'Zarejestruj się.',
      resetPassword: 'Nie pamiętasz hasła?',
      loginWith: {
        title: 'lub zaloguj się bezpośrednio za pomocą swojego konta na:',
        facebook: 'Zaloguj się przez Facebook',
        google: 'Zaloguj się przez Google'
      }
    },
    resetPassword: {
      title: 'Zresetuj hasło',
      subtitle: 'Nie martw się, że hasło Ci umknęło :) Wpisz adres email, który został użyty przy rejestracji. Wyślemy na niego dalsze instrukcje.'
    },
    newPassword: {
      title: 'Zmień hasło',
      subtitle: 'Wpisz nowe hasło i kontynuuj przygodę na Platformie EduCat'
    },
    alert: {
      loginSuccess: "Logowanie zakończone pomyślnie.",
      loginFailed: "Niepoprawny login lub hasło. Spróbuj jeszcze raz.",
      logout: "Zostałeś wylogowany.",
      registrationSuccess: "Dziękujemy za rejestrację. Na adres podany w rejestracji wysłaliśmy wiadomość z linkiem aktywującym konto. Wejdź w niego i zaloguj się na Platformę EduCat",
      confirmRegistrationSuccess: "Rejestracja została potwierdzona, zaloguj się za pomocą maila i swojego hasła.",
      sendResetPasswordMailSuccess: "Na twój adres email wysłaliśmy wiadomość z linkiem resetującym hasło.",
      confirmResetPasswordSuccess: "Twoje hasło zostało zmienione, możesz zalogować się za jego pomocą.",
      invalidResetPasswordURL: "Nie poprawny link potwierdzający zmianę hasła.",
      authenticationError: 'Musisz być zalogowany aby mieć dostęp do danych.'
    }
  },
  navBar: {
    balance: 'Twoje saldo: ',
    cart: 'Twój koszyk'
  },
  sidebar: {
    dashboard: "Kokpit",
    competence: "Diagnostyka",
    fieldOfStudy: "Wyszukiwarka uczelni",
    mentors: "Mentorzy",
    tutors: "Korepetytorzy",
    application: "Aplikacja na studia",
    contentFiles: "Materiały aplikanta",
    marketplace: "Marketplace",
    insurances: "Ubezpieczenia",
  },
  application: {
    srTitle: 'Strona zawierająca informacje na temat aplikacji na studia.',
    chosenApplications: {
      title: 'Wybrane aplikacje',
      noData: 'Nie posiadasz aktualnie żadnych aplikacji',
      goToSearch: 'Idź do wyszukiwarki',
      chosenApplication: {
        status: 'Status: ',
        statusMock: 'Lorem ipsum',
        rankingNumber: 'nr ',
        rankingAdditional: 'w rankingu',
      }
    },
    yourTasks: {
      title: 'Twoje zadania',
      allTasks: 'Wszystkie',
      privateTasks: 'Prywatne',
      addNewTask: 'Dodaj nowe',
      noTasks: "Brak przydzielonych zadań",
      table: {
        taskName: 'Nazwa zadania',
        deadline: 'Deadline',
        status: 'Status',
        mark: 'Ocena',
        private: 'Prywatne',
        statusTypes: {
          cancelled: 'Przerwane',
          during: 'W trakcie',
          not_completed: 'Nie zakończone',
          finished: 'Zakończone',
          new: 'Nowe',
          noStatus: 'Bez statusu'
        },
        changeTaskStatusSuccess: 'Zmiana statusu zadania zakończona powodzeniem.',
        seeMoreTasks: 'Zobacz więcej zadań',
        seeLessTasks: 'Zobacz mniej zadań',
        srLabelCheckboxChecked: 'Zadanie zaznaczone jako prywatne',
        srLabelCheckbox: 'Zadanie zaznaczone jako publiczne',
      }
    },
    yourMeetings: {
      title: 'Twoje spotkania',
      table: {
        meetingDueDate: 'Termin spotkania',
        userHours: 'Wykorzystane godziny',
        deadlineData: '{{date}} godz. {{hour}}'
      },
      join: 'Dołącz',
      buyMore: 'Kup więcej',
      seeMoreMeetings: 'Zobacz więcej spotkań.',
      seeLessMeetings: 'Zobacz mniej spotkań.',
      srEditButton: 'Przycisk edycji spotkania.',
      noMeetings: 'Nie masz umówionych żadnych spotkań.',
      noConsultationDate: 'Brak terminu konsultacji'
    },
    yourTimetable: {
      title: 'Twój Harmonogram',
      today: 'Dziś',
      week: 'Tydzień',
      month: 'Miesiąc',
      year: 'Rok',
      applications: 'Aplikacje',
      showAll: 'Pokaż wszystkie',
      buyApplication: 'Wykup aplikację'
    }
  },
  profile: {
    srTitle: 'Profil Użytkownika',
    profileForm: {
      title: 'Twoje dane do aplikacji',
      applicantSurveyTitle: 'Powiedz nam coś o sobie',
      selectPlaceholder: 'Wybierz z listy',
      autocompletePlaceholder: 'Zacznij wpisywać nazwę',
      controls: {
        highSchool: 'Liceum',
        studySubject: 'Co chcesz studiować?',
        whereToStudy: 'Gdzie chcesz studiować?',
        whatCollege: 'Na jakiej uczelni chcesz studiować?',
        studyLevel: 'Jaki poziom studiów Cię interesuje?',
        customStudyLevel: 'Poziom studiów',
        maturityExamType: 'Jaką maturę zdajesz?',
        customMaturityExamType: 'Rodzaj matury',
        maturityExamSubjects: 'Przedmioty zdawane na maturze',
        addSubject: '+ Dodaj kolejny przedmiot',
        studyStart: 'Kiedy zaczynasz studia?',
        whereFrom: 'Jak trafiłeś na Educat?',
        noOptionCheckbox: 'Nie wiem',
        checkbox: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.`,
      },
      submitQuestionnaire: 'Prześlij ankietę',
      save: 'Zapisz zmiany',
      send: 'Prześlij ankietę',
      profileUpdated: "Dane konta zostały pomyślnie zaktualizowane.",
      applicationUpdated: "Dane do aplikacji zostały pomyślnie zapisane."
    },
    changePasswordForm: {
      title: 'Zmień hasło',
      controls: {
        currentPassword: 'Twoje obecne hasło',
        newPassword: 'Nowe hasło',
        repeatNewPassword: 'Powtórz nowe hasło',
        placeholder: 'Wpisz tutaj'
      },
      passwordChanged: "Twoje hasło zostało pomyślnie zmienione."
    },
    profileInfo: {
      srTitle: 'Karta detali profilu',
      phonePlaceholder: 'Numer telefonu',
      birthDatePlaceholder: 'Data urodzenia'
    }
  },
  dashboard: {
    srTitle: "Kokpit Użytkownika",
    header: {
      title: "Hej",
      description: "Witaj na naszej Platformie EduCat. Cieszymy się, że z nami jesteś i życzymy Ci powodzenia w Twojej wybranej ścieżce edukacyjnej. Platforma EduCat została stworzona, aby pomóc Ci w wyborze właściwego kierunku studiów oraz w przygotowaniu do aplikacji. Razem z nami spełnisz swoje marzenia :)",
      signature: "Zespół EduCat"
    },
    applicationProgress: {
      title: "Twoja ścieżka rozwoju",
      description: "Aby dostać się na wymarzone studia, musisz spełnić po drodze kilka kroków. Przeprowadzimy Cię przez każdy z nich.",
      steps: {
        direction: {
          title: "Określ kierunek",
          description: "a także swoje predyspozycje naukowe"
        },
        school: {
          title: "Wybierz uczelnie",
          description: "na które złożysz swoją aplikację"
        },
        application: {
          title: "Przygotuj się do aplikacji",
          description: "pod okiem twojego mentora i korepetytorów"
        },
        documents: {
          title: "Przygotuj dokumenty",
          description: "wymagane przez uczelnię i złóż swoją aplikację"
        },
        study: {
          title: "Rozpocznij studia",
          description: "na swojej wymarzonej uczelni"
        }
      }
    },
    registrationDataForm: {
      title: 'Powiedz nam coś o sobie',
      selectPlaceholder: 'Wybierz z listy',
      controls: {
        studySubject: 'Co chcesz studiować?',
        whereToStudy: 'Gdzie chcesz studiować?',
        studyLevel: 'Jaki poziom studiów Cię interesuje?',
        maturityExamType: 'Jaką maturę zdajesz?',
        studyStart: 'Kiedy zaczynasz studia?',
        whereFrom: 'Jak trafiłeś na Educat?',
        checkbox: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.`,
      },
      submitQuestionnaire: 'Prześlij ankietę'
    },
    tests: {
      title: "Testy",
      description: 'Aby określić, jaki kierunek jest dla Ciebie najlepszy, zrób test Morrisby, który zbada Twoje predyspozycje i podpowie dziedziny, które warto wziąć pod uwagę.\n' +
          'Jeśli chcesz sprawdzić, na jakim jesteś obecnie poziomie, jeśli chodzi o zakres przedmiotów, które realizujesz w liceum i jak dużo pracy przed Tobą, aby osiągnąć jak najlepsze wyniki na egzaminie maturalnym, rozwiąż przygotowane przez naszych nauczycieli testy przedmiotowe. \n',
      testDetails: {
        price: 'cena z VAT',
        points: '/100 pkt'
      }
    },
    tutors: {
      title: "Umów korepetycje",
      description: "W procesie rekrutacyjnym ważne dla uczelni mogą być zarówno Twoje oceny w szkole jak i wyniki z egzaminów końcowych. Jeśli potrzebujesz pomocy w przerobieniu materiału wymaganego w liceum, umów się z naszym korepetytorem na lekcję online i popraw swoje oceny."
    },
    fieldOfStudy: {
      title: "Wybrane uniwersytety",
      description: "Wybór uczelni, na którą złożysz aplikację, jest jednym z najważniejszych kroków procesu aplikacyjnego. Przygotowaliśmy dla Ciebie bazę kierunków anglojęzycznych - wykorzystaj ją, aby wybrać najlepszą dla Ciebie uczelnię.",
      schoolDetails: {
        rankingNo: "nr",
        ranking: "w rankingu"
      },
      noListItems: 'Brak wybranych uniwersytetów',
      seeLess: 'Ukryj listę uniwersytetów',
      seeMore: 'Zobacz więcej uniwersytetów',
    },
    mentors: {
      title: "Poznaj swojego mentora",
      description: "Mentorzy EduCat to studenci lub absolwenci uczelni wyższych z całego świata. Pomogą Ci w merytorycznym przygotowaniu do aplikacji - podpowiedzą, co musisz przygotować, aby Twoja aplikacja została doceniona przez rekruterów i pomogą przygotować wszystkie wymagane dokumenty. Znajdź swojego mentora!",
    },
    schedule: {
      title: "Twój harmonogram",
      description: "Najskuteczniejszym sposobem na osiągnięcie sukcesu jest zaplanowanie najważniejszych kroków Twojej aplikacji. Podczas pracy z mentorem ustalicie wszystkie elementy procesu rekrutacyjnego, a następnie wspólnie przejdziecie przez jego etapy.",
      noApplication: "Gdy wykupisz swoją pierwszą aplikację, w tym miejscu zobaczysz stworzony dla Ciebie harmonogram",
      noSchedule: "Już prawie gotowe! Gdy Twój mentor przygotuje dla Ciebie harmonogram zobaczysz go w tym miejscu",
      controls: {
        week: "Tydzień",
        month: "Miesiąc",
        year: "Rok"
      }
    },
    eventCalendar: {
      title: "Twoje wydarzenia",
      noEvents: 'Nie masz planów na ten dzień'
    }
  },
  footer: {
    copyright: "Educat {{ year }} © Wszelkie prawa zastrzeżone"
  },
  sortingForm: {
    form: {
      label: 'Sortowanie',
      placeholder: 'Wszystkie',
      options: {
        titleAsc: 'Nazwa rosnąco',
        titleDesc: 'Nazwa malejąco',
        priceAsc: 'Cena rosnąco',
        priceDesc: 'Cena malejąco'
      }
    }
  },
  viewTaskModal: {
    title: 'Zadanie: {{title}}',
    description: 'Opis zadania',
    attachments: 'Załączniki',
    deadline: 'Deadline',
    noContent: 'Zadanie nie posiada opisu',
    noAttachments: 'Do zadania nie są przypisane żadne załączniki',
    noDeadline: 'Nie wyznaczono terminu'
  },
  tutors: {
    title: "Znajdź swojego korepetytora",
    subtitle: "Nasi korepetytorzy to nauczyciele, o których mówi się, że są tymi z powołania. Nie tylko w perfekcyjny sposób przekazują wiedzę, ale robią to z ogromną pasją, która sprawi, że żaden egzamin nie będzie dla Ciebie przeszkodą. Wybierz przedmiot, nad którym chcesz pracować i znajdź najbardziej odpowiadającego Ci nauczyciela. Możesz umówić się na pojedynczą lekcję lub wykupić pakiet godzin z konkretną osobą. Do dzieła!",
    tutorItem: {
      calendar: 'Kalendarz Korepetytora',
      video: 'Wideo prezentacja Korepetytora'
    },
    tutorList: {
      srTitle: 'Lista korepetytorów spełniających kryteria wyszukiwania',
      noTutors: 'Nie znaleziono żadnych korepetytorów spełniających kryteria wyszukiwania.'
    }
  },
  filters: {
    selectedFilters: 'Aktywne filtry',
    removeAll: 'x Usuń wszystkie',
    remove: 'x',
    form: {
      labels: {
        country: 'Kraj',
        realm: 'Dziedzina',
        mode: 'Tryb studiowania',
        level: 'Poziom',
        duration: 'Czas trwania nauki',
        school: 'Uczelnia',
        price: 'Koszt',
        availability: 'Dostępność',
        language: 'Język',
        assistance: 'Zakres pomocy'
      },
      placeholders: {
        allOptions: 'Wszystkie',
        duration: 'Czas trwania nauki',
        availability: 'Wybierz datę'
      }
    }
  },
  mentors: {
    title: 'Znajdź swojego mentora',
    subtitle: 'Nasi mentorzy to studenci i absolwenci zagranicznych uczelni. Opowiedzą Ci o swoich wrażeniach na temat studiów oraz swoich doświadczeniach z nimi związanych. Pomogą Ci też w kompleksowym przygotowaniu aplikacji na studia. Wyszukaj mentora, z którym chcesz pracować, zapoznaj się z jego profilem oraz wybierz sposób współpracy. Możesz umówić się na pojedyncze spotkanie, wybrać pakiet godzin lub pakiet aplikacyjny. W profilu mentora znajdziesz informacje, jakie usługi wchodzą w zakres jego pomocy.',
    searchBar: {
      title: 'wyszukiwanie mentora',
      topicPlaceholder: 'Kierunek|DYSCYPLINA',
      countryPlaceholder: 'Kraj',
      searchButton: 'Szukaj'
    },
    filter: {
      title: 'Filtrowanie',
      country: 'Kraj',
      price: 'Cena',
      rating: 'Ocena',
      availability: 'Dostępność',
      topic: 'Kierunek',
      university: 'Uczelnia',
      field: 'Dziecina (poddziedzina)',
      helpRange: 'Zares pomocy',
      language: 'Język'
    },
    mentorItem: {
      calendar: 'Kalendarz Mentora',
      video: 'Wideo prezentacja Mentora',
      noVideo: 'Brak wideo prezentacji',
      readMore: 'Czytaj więcej',
      applyForConsultation: 'Umów się na konsultację',
      readLess: 'Skróć opis',
      seeMoreDates: 'Zobacz więcej godzin'
    },
    mentorList: {
      srTitle: 'Lista mentorów spełniających kryteria wyszukiwania',
      noMentors: 'Nie znaleziono żadnych mentorów spełniających kryteria wyszukiwania.'
    }
  },
  tutorProfile: {
    tutorDetails: {
      languages: 'Języki: ',
      applyForConsultation: 'Umów się na konsultację',
      hourFee: 'Stawka godzinowa ',
      trialLesson: 'Konsultacja wstępna ',
      language: {
        en: 'angielski',
        pl: 'polski',
        de: 'niemiecki'
      }
    },
    tutorCalendar: {
      title: 'Kalendarz korepetytora',
      applyForConsultation: 'Umów się na konsultację'
    },
    tutorVideo: {
      title: 'Wideo prezentacja korepetytora'
    },
  },
  mentorProfile: {
    mentorDetails: {
      languages: 'Języki: ',
      fields: 'Dziedziny',
      workStyle: 'Mój styl pracy',
      applyForConsultation: 'Umów się na konsultację',
      hourFee: 'Stawka godzinowa ',
      trialLesson: 'Konsultacja wstępna ',
      showMore: 'Dowiedz się więcej',
      showLess: 'Pokaż mniej',
      mentorType: {
        mentor: 'Mentor ',
        scholar: 'Korepetytor ',
        /*Hidden in current version*/
        // mentorScholar: 'Mentor-Korepetytor '
        mentorScholar: 'Mentor '
      },
      language: {
        en: 'angielski',
        pl: 'polski',
        de: 'niemiecki'
      },
      studyLevel: {
        master: 'Magister',
        bachelor: 'Licencjat',
        other: 'Inny'
      },
      mentorGraduationTitle: {
        student: 'Student',
        graduate: 'Absolwent'
      }
    },
    mentorCalendar: {
      title: 'Kalendarz mentora',
      applyForConsultation: 'Umów się na konsultację',
      buyPackage: 'Wykup pakiet i zaoszczędź'
    },
    mentorVideo: {
      title: 'Wideo prezentacja mentora'
    },
    mentorConsultationPackages: {
      consultationTitle: 'Pakiety konsultacji mentorskich',
      applicationElementsTitle: 'Elementy aplikacji',
      whatInPackage: 'Co jest zawarte w pakiecie: ',
      lessonDetails: {
        lessonDuration: 'Lekcja trwa {{duration}} minut',
        lessonQuantity: '{{quantity}} lekcji w pakiecie'
      },
      packageSubmitButton: 'Dodaj do koszyka',
      alerts: {
        addedToBasket: 'Produkt został dodany do koszyka',
        removedFromBasket: 'Produkt został usunięty z koszyka',
        quantityUpdated: 'Ilość została zaktualiozowana',
        consultationDateUpdated: 'Data zapisana',
        fieldOfStudyUpdated: 'Kierunek zapisany'
      }
    },
    studentOpinions: {
      title: 'Opinie uczniów',
      hadLessons: 'Miałeś zajęcia z ',
      leaveOpinionInfo: 'Zostaw opinię, na pewno się ucieszy :)',
      leaveOpinion: 'Chcę zostawić opinię'
    }
  },
  competence: {
      title: 'Diagnostyka',
      description: 'Zrób z nami test predyspozycji zawodowych lub sprawdź poziom swojej wiedzy z poszczególnych przedmiotów. Poniżej znajdziesz listę testów, które pomogą Ci w sprawdzeniu swojego poziomu wiedzy z poszczególnych przedmiotów szkolnych. Wybierz klasę, która Cię interesuje oraz poziom przedmiotu. Jeśli zastanawiasz się nad tym, jakie studia wybrać, w wyborze pomoże Ci wypełnienie testu predyspozycji Morrisby.\n' +
          'Nie czekaj i zacznij już dzisiaj.',
      yourResults: {
          title: 'Twoje wyniki',
          noResults: 'Nie ma wyników Twoich testów',
          points: '/100 pkt'
      },
      predispositionDiagnostics: {
        title: 'Diagnostyka predyspozycji',
        morrisbyTest: 'Test predyspozycji Morrisby ',
        testPrice: 'Cena'
      },
      subjectTests: {
          title: {
            polish: 'Testy przedmiotowe - matura polska',
            international: 'Testy przedmiotowe - matura międzynarodowa (IB)'
          },
          classes: {
            pl: {
                class_pl_1: 'Klasa I',
                class_pl_2: 'Klasa II',
                class_pl_3: 'Klasa III',
                class_pl_4: 'Klasa IV'
            },
            international: {
                class_ib_1: 'Klasa IB - 1',
                class_ib_2: 'Klasa IB - 2',
            }
          },
          level: {
              extended: 'Poziom rozszerzony',
              basic: 'Poziom podstawowy',
              any: 'Każdy poziom'
          },
          maturityType: {
            polish_baccalaureate: 'Polska matura',
            international_baccalaureate: 'Matura międzynarodowa',
            custom: 'Inny rodzaj matury'
          }
      },
      competenceTest: {
          title: 'Test: ',
          description: `Przystępujesz do testu {{testName}}, po udzieleniu odpowiedzi na pytania naciśnij przycisk "Wyślij". Przy próbie wyjścia z testu, zostaną wysłane aktualne odpowiedzi.`,
          sectionTitle: 'Wypełniasz sekcję: ',
          sidebarTitle: 'Lista sekcji testu',
          noData: 'Nie znalezio danych',
          timer: 'Czas trwania testu',
          maximumTime: ' / {{maximumTime}} minut',
          notificationModal: {
              title: 'Czy na pewno chcesz wysłać odpowiedzi?',
              description: 'Wygląda na to że nie udzielono odpowiedzi na część pytań. Czy na pewno chcesz wysłać test do sprawdzenia? Nie zaznaczone odpowiedzi będa traktowane jako błędne.'
          },
        pageReloadMessage: 'Zmiany nie są zapisane. Czy na pewno chcesz ponownie załadować stronę?'
      },
      competenceTestResult: {
          title: 'Wyniki testu: ',
          description: {
              yourResult: 'Twój wynik: ',
              yourResultPoints: '%',
              yourRate: ' Twoja ocena: ',
              descriptionContent: ' Poniżej znajdują się pytania, Twoje odpowiedzi są zaznaczone za pomocą niebieskiej ramki, prawidłowe odpowiedzi są oznaczone zielonym tłem. Ikonka znaku zapytania zawiera opis rozwiązania zadania.',
          },
          sectionTitle: 'Pytania i odpowiedzi z sekcji: ',
          sidebarTitle: 'Lista sekcji testu',
          rangeDescription: {
              title: 'Kryteria ocen:',
              fromTo: 'Od {{from}} do {{to}}%',
              rating: '{{rating}}'
          },
          solution: "Rozwiązanie:"
      },
      addToCart: 'Dodaj do koszyka',
      doTheTest: 'Zrób test',
      alerts: {
          competenceTestSubmitted: 'Test przesłany prawidłowo. Proszę, zapoznaj się z wynikami.'
      },
    buyTest: {
      buyCompetenceTest: 'Kup pakiet diagnostyczny',
      buySubjectTest: 'Kup pakiet testów przedmiotowych',
      buyTestModal: {
        details: 'Pakiet diagnostyczny posłuży Ci do sprawdzenia na jakim jesteś poziomie z wybranych przedmiotów licealnych. W pakiecie zyskasz nieograniczony dostęp do trzech różnych testów, a dzięki wynikom dowiesz się, jakie działy wymagają od Ciebie więcej pracy. Wybierając pakiet z testem Morrisby zyskasz pełną diagnostykę, która pozwoli Ci z większą pewnością wybrać odpowiedni dla Ciebie kierunek studiów. Do dzieła!',
        takeTest: 'Zrób test przedmiotowy, aby sprawdzić, w jakim stopniu materiał został przez Ciebie opanowany. Każdy z testów obejmuje materiał z obecnej klasy oraz z klas z lat poprzednich.',
        testContent: 'Testy zostały opracowane przez wykwalifikowanych nauczycieli i dostosowane do wymogów CKE. Możesz spodziewać się pytań z każdego z przerabianych działów.',
        testDescription: 'Test składa się z pytań zamkniętych z jedną odpowiedzią poprawną. Po jego ukończeniu uzyskasz wynik procentowy oraz dostęp do poprawnych odpowiedzi, aby zweryfikować swoje błędy.',
        morrisbyRemark: 'Sprawdź się! Uwaga! Test Morrisby jest przeprowadzany w języku angielskim.',
        buyNow: 'Kup teraz'
      }
    },
    morrisbyTest: {
      descriptionTitle: 'Opis',
      description: 'Na jaki idziesz kierunek? Co będziesz studiować? Nie wiesz? Nie jesteś jedyną osobą, która zmierza się z tym dylematem :) Dlatego test Morrisby będzie dla Ciebie dobrym wyborem. Test Morrisby już od ponad 50 lat pomaga uczniom wybrać odpowiednią ścieżkę edukacyjną. Na podstawie Twoich predyspozycji werbalnych, numerycznych i abtrakcyjnych oraz Twoich preferencji podpowie Ci na jakie dziedziny kierunków warto zwrócić uwagę.\n' +
          'Po zrobieniu testu otrzymasz dostęp do raportu Twoich wyników i materiałów, gdzie znajdziesz informacje na temat przyszłej ścieżki kariery po poszczególnych kierunkach.\n' +
          'Dlaczego warto?',
      report: 'otrzymujesz spersonalizowany raport Twoich umiejętności wraz z dobranymi zawodami',
      platformAccess: 'dostajesz dostęp do Platformy Morrisby, gdzie znajdziesz opisy ścieżek kariery',
      selectedField: 'zyskujesz pewność że wybrany kierunek to właśnie ten jedyny i możesz zacząć planować kolejne kroki, aby spełnić swoje marzenia',
      benefits: 'za wykonanie testu Fundacja Morrisby posadzi jedno drzewo w ramach akcji Plant Your Future! Dzięki testowi Morrisby Twoja kariera nie będzie przypadkowa. Zacznij planowanie swojej ścieżki już dzisiaj z testem Morrisby.',
      testDiscussion: 'Omówienie wyników testu'
    }
  },
  contentFiles: {
    srTitle: 'Dostępne materiały aplikanta oraz pole dodawania nowych plików',
    applicantMaterials: {
      title: 'Materiały Aplikanta',
      description: 'Poniżej znajdziesz listę dokumentów związanych ze współpracą na Platformie EduCat. Są to materiały, nad którymi pracujesz razem z Mentorem lub Korepetytorem. Dokumenty edytuj w formie online, aby zmiany zapisywały się automatycznie.',
      table: {
        documentName: 'Nazwa dokumentu',
        creationDate: 'Data dodania',
        addingUser: 'Dodający',
        editRemove: 'Edytuj/Usuń',
        actionButtons: {
          edit: 'Edytuj plik',
          remove: 'Usuń plik',
          download: 'Pobierz plik'
        },
        noData: 'Aplikant nie posiada żadnych materiałów',
        retrieveDataError: 'Wystąpił błąd przy pobieraniu materiałów, spróbuj ponownie.',
        fileRemoveSuccess: 'Plik został usunięty',
        fileRemoveError: 'Wystąpił błąd podczas usuwania pliku, spróbuj ponownie.'
      }
    },
    addMaterials: {
      title: 'Dodaj nowy',
      dropHere: 'Upuść plik tutaj lub kliknij przycisk',
      uploadSuccess: 'Wysyłanie pliku zakończone sukcesem, aby przesłać kolejny przeciągnij go tu, lub użyj przycisku poniżej. ',
      addNew: 'Dodaj nowy',
      uploadingMessage: 'Ładowanie pliku na serwer, proszę czekać...',
      uploadProgress: 'Przesłano {{progressValue}} ze 100 procent'
    }
  },
  fieldOfStudy: {
    title: 'Znajdź uczelnię dla siebie',
    subtitle: 'Sprawdź, gdzie możesz studiować Twój wymarzony kierunek! Dodaj go do listy ulubionych, a następnie porównaj z innymi oferowanymi na całym świecie. Kiedy już zdecydujesz, że to właśnie na tę uczelnię chcesz złożyć aplikację i spróbować swoich sił jako przyszły student lub studentka - dodaj go na listę uczelni, do których aplikujesz.',
    footerCopyrightInfo: 'Educat {{ year }} © Wszelkie prawa zastrzeżone',
    favouriteList: {
      title: 'Twoje ulubione uczelnie',
      subtitle: {
        l1: "Poniżej znajdziesz uczelnie, które wstępnie Cię zainteresowały. Przed ostatecznym wybraniem tych, na które zaaplikujesz zastanów się na odpowiedzią na poniższe pytania:",
        p1: "jak ułożony jest program studiów na wybranej uczelni?",
        p2: "czy uczelnia oferuje wymiany studenckie?",
        p3: "czy uczelnia oferuje praktyki?",
        p4: "czy uczelnia ma kampus? Jest w dużym czy małym mieście?",
        l2: "Odpowiedź na powyższe pytania pomoże Ci zdecydować, jaka uczelnia będzie dla Ciebie najlepsza. W końcu to właśnie na niej spędzisz 3 lub więcej lat Twoich studiów - niech to będzie miejsce, w którym zdobędziesz wiedzę najlepiej odpowiadającą Twoim zainteresowaniom oraz preferencjom."
      },
      breadcrumb: 'Ulubione uczelnie'
    },
    noData: 'Nie znaleziono kierunków spełniających wymagania',
    appliedList: {
      title: 'Uczelnie, do których aplikujesz',
      subtitle: 'Poniżej znajdziesz uczelnie, które zostały przez Ciebie wybrane jako te, na które chcesz aplikować. Przed Tobą dokładne sprawdzenie wymagań i terminów oraz przygotowanie się do procesu aplikacyjnego. Pamiętaj, że nasi mentorzy mogą pomóc Ci w każdym jego etapie. Jesteśmy tu po to, aby Ci pomóc!',
      breadcrumb: 'Wybrane uczelnie',

    },
    search: 'Wyszukiwarka uczelni',
    form: {
      controls: {
        whatToStudy: 'Co chcesz studiować?',
        whereToStudy: 'Gdzie chcesz studiować?',
      },
      labels: {
        realm: 'Dziedzina',
        subRealm: 'Poddziedzina',
        studyField: 'Kierunek',
        country: 'Kraj',
        school: 'Uczelnia',
        medicalAndHealth: 'Medical and Health',
        social: 'Social',
        natural: 'Natural',
        agricultural: 'Agricultural',
        humanities: 'Humanities',
        engineeringAndTechnology: 'Engineering and Technology',
        titleAsc: 'Nazwa rosnąco',
        titleDesc: 'Nazwa malejąco'
      },
      placeholder: 'Wyszukaj',
      rangeUnit: {
        currency: 'zł',
        year: 'lat'
      }
    },
    fieldOfStudyItem: {
      studyMode: {
        fulltime: 'Stacjonarne',
        parttime: 'Zaoczne'
      },
      durationMonth: '{{duration}}-miesięczne',
      durationYear: 'Roczne',
      durationYears: '{{duration}}-letnie',
      forYear: 'za rok',
      rankingNo: 'nr',
      rankingPosition: 'w rankingu',
      studyLevel: {
        master: 'Magister',
        bachelor: 'Licencjat',
        preparation: 'Przygotowanie',
        other: 'Inny'
      },
      actionButton: {
        favourite: {
          popup: 'Ulubione',
          srTitle: 'Przycisk dodający szkołę do listy ulubionych szkół',
          requestMessages: {
            added: 'Dodano kierunek do listy ulubionych',
            removed: 'Usunięto kierunek z listy ulubionych',
            error: 'Zmiana statusu kierunku nie powiodła się, wystąpił błąd'
          }
        },
        application: {
          popup: 'Chcę aplikować',
          srTitle: 'Przycisk dodający szkołę do listy szkół do których aplikujesz',
          requestMessages: {
            added: 'Dodano kierunek do listy aplikowanych',
            removed: 'Usunięto kierunek z listy aplikowanych',
            error: 'Zmiana statusu kierunku nie powiodła się, wystąpił błąd'
          }
        },
        remove: {
          popup: 'Usuń z listy',
          srTitle: 'Przycisk usuwający szkołę z listy',
          requestMessages: {
            removed: 'Usunięto kierunek z listy',
            error: 'Usunięcie kierunku nie powiodło się, wystąpił błąd'
          }
        }
      }
    },
    fieldOfStudyProfile: {
      rankingNo: "nr {{rankNumber}}",
      ranking: "w rankingu",
      fields: "Dziedziny",
      subjectRequirements: "Wymagania przedmiotowe",
      noRequirements: "Uczelnia nie określiła wymagań",
      noRealm: "Wybrany kierunek nie ma dziedzin",
      languageRequirements: {
        title: "Wymagania językowe",
        ielts: "IELTS: ",
        pte: "PTE: ",
        toeflInternet: "TOEFL - internet: ",
        toeflPaper: "TOEFL - pisany: ",
      },
      sidebar: {
        application: "Chcesz aplikować na tę uczelnię?",
        makeAppointment: "Umów się z mentorem i przygotujcie razem aplikację!",
        findMentor: "Znajdź swojego mentora",
        price: "Cena ",
        priceFor: {
          year: 'za rok:',
          semester: 'za semestr:',
          month: 'za miesiąc:',
          full: 'za całość:'
        },
        applicationDeadline: "Deadline aplikacji:",
        semesterBeginning: "Termin rozpoczęcia:",
        noDeadlines: "Nie ma ustalonych deadline.",
        noStartDate: "Nie ma ustalonego terminu rozpoczęcia.",
        noTuitionFees: "Nie ma ustalonej ceny."
      }
    }
  },
  marketplace: {
    title: 'Marketplace',
    description: 'EduCat oferuje pomoc w zakresie aplikacji na studia od momentu diagnostyki predyspozycji i pomocy w wyborze odpowiedniego dla Ciebie kierunku studiów, przez merytoryczne przygotowanie do aplikacji razem z naszymi mentorami, aż po samą pomoc w złożeniu dokumentów i przygotowanie do wyjazdu. Sprawdź naszą ofertę!',
    testsTitle: 'Testy diagnostyczne',
    applicationTitle: 'Pomoc w Aplikacji',
    mentorsTitle: 'Poznaj mentorów',
    tutorsTitle: 'Korepetycje',
    testsDescription: 'Nie wiesz, jakie studia wybrać? Zrób z nami test predyspozycji Morrisby, aby dowiedzieć się więcej o Twoich predyspozycjach i potencjalnym kierunku studiów. Zrób testy przedmiotowe, aby sprawdzić poziom Twojej wiedzy z przedmiotów maturalnych.',
    applicationDescription: 'Szukasz pomocy w aplikacji na studia? Potrzebujesz pomocy od początku do końca? Sprawdź nasze pakiety aplikacyjne, które bezproblemowo przeprowadzą Cię przez proces rekrutacji. Zobacz, co mamy do zaoferowania.',
    mentorsDescription: 'Nasi mentorzy to studenci i absolwenci zagranicznych uczelni. Umów się na spotkanie, żeby porozmawiać o ich doświadczeniach związanych ze studiowaniem.',
    tutorsDescription: 'Potrzebujesz pomocy w przerobieniu materiału ze szkoły lub przygotowujesz się do olimpiady i potrzebujesz pomocy w omówieniu pozaszkolnego materiału? Umów się na korepetycje z naszymi nauczycielami.',
    addToCart: 'Dodaj do koszyka',
    selectMentor: 'Wybierz mentora',
    countrySelect: {
      title: 'Wybierz kraj',
      description: 'Wybierz kraj, w którym chciałbyś/chciałabyś studiować.'
    },
    realmSelect: {
      title: 'Wybierz dyscyplinę',
      description: 'Wybierz dyscyplinę kierunku, na który chcesz aplikować.'
    },
    mentorSelect: {
      title: 'Wybierz mentora',
      description: 'Poniżej przedstawimy listę mentorów, którzy mogą pomóc Ci w aplikacji na wybrane przez Ciebie kraj i dziedzinę kierunku.',
      languages: 'Mówię w języku ',
      noMentors: 'Nie znaleziono mentorów spełniających wymagania wyszukiwania.',
      form: {
        labels: {
          price: 'Cena',
          rating: 'Ocena',
          availability: 'Dostępność',
          realm: 'Kierunek',
          school: 'Uczelnia'
        },
        placeholders: {
          allOptions: 'Wszystkie',
          typeOption: 'Zacznij wpisywać nazwę',
          selectDate: 'Wybierz datę'
        }
      }
    },
    applications: {
      title: 'Pakiety aplikacyjne',
      description: 'Poniżej znajdziesz pakiety aplikacyjne zaprojektowane tak, aby pokryć wszelkie potrzeby związane z aplikacją na studia. Wybierz Pakiet Premium, jeśli nie wiesz jeszcze, jaki kierunek chcesz studiować - zawiera elementy diagnostyczne, które pomogą Ci w wyborze odpowiedniego kierunku. Wybierz Pakiet Basic, jeśli już masz wybrany kierunek studiów. Pakiety zawierają wszystkie elementy, które pozwolą Ci przejść przez proces aplikacji na studia zagraniczne bez przeszkód wraz z wybranym przez Ciebie mentorem. Do dzieła!',
      noApplications: 'Nie znaleziono pakietów aplikacyjnych',
      platformAccess: 'Dostęp do funkcjonalności Platformy: videocall, zadania aplikanta, materiały aplikantów',
      testPackage: 'Test diagnostyczny Morrisby'
    },
    tests: {
      title: 'Testy diagnostyczne',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.',
    },
    tutors: {
      title: 'Korepetycje',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.',
      noConsultationPackages: 'Nie znaleziono pakietów aplikacyjnych',
      table: {
        title: 'Nazwa pakietu',
        hoursAmount: 'Ilość godzin'
      }
    }
  },
  insurances: {
    title: 'Ubezpieczenia',
    newInsurance: 'Nowe ubezpieczenie',
    table: {
      insuranceNumber: 'Numer polisy',
      insuranceDate: 'Data wygenerowania wniosku',
      paymentDate: 'Data zakupu',
      insuranceDuration: 'Okres polisy (rozpoczęcie i koniec)',
      status: 'Status',
      actions: 'Akcje',
    },
    insuranceDownloaded: 'Ubezpieczenie zostało pobrane.',
    insurancePreviewDownloaded: 'Ubezpieczenie zostało otwarte w nowej karcie',
    insuranceUpdated: 'Szczegóły ubezpieczenia zostały zmienione.',
    offerStatus: {
      status_paid: 'Paid',
      status_active: 'Active',
      status_expired: 'Expired',
      status_expired_draft: 'Expired draft',
      status_modified: 'Modified',
      status_draft: 'Draft',
    },
    tooltips: {
      edit: 'Edytuj ubezpieczenie',
      view: 'Podgląd ubezpieczenia',
      download: 'Pobierz ubezpieczenie',
      resume: 'Wróć do wniosku',
    },
    noData: 'Nie dodano żadnych ubezpieczeń.',
    editInsuranceModal: {
      title: 'Edytuj ubezpieczenie',
      formControls: {
        labels: {
          firstName: 'Imię',
          lastName: 'Nazwisko',
          email: 'Email',
          birthDate: 'Data urodzenia',
          status: 'Typ studiów',
          school: 'Uczelnia',
        },
        placeholders: {
          firstName: 'Wpisz tutaj',
          lastName: 'Wpisz tutaj',
          birthDate: 'Wybierz datę',
          email: 'Wpisz tutaj',
          school: 'Wpisz nazwę',
        },
        statuses: {
          bachelor_degree_studies: 'Licencjackie',
          engineering_studies: 'Inżynierskie',
          master_degree_studies: 'Magisterskie',
          doctoral_studies: 'Doktoranckie',
          mba_studies: 'MBA',
          high_school_abroad: 'Liceum za granicą',
          erasmus_or_exchange: 'Erasmus lub wymiana',
          other: 'Inne',
        },
        buttons: {
          cancel: 'Anuluj',
          save: 'Zapisz',
        }
      }
    }
  },
  cart: {
    title: "Twój koszyk",
    chooseConsultationDate: "Wybierz datę i godzinę spotkania",
    cartDetails: {
      meeting: "Spotkanie z {{name}}",
      trial: "Konsultacja wstępna z {{name}}",
      unknown: "Usługa z {{name}}",
      serviceApplicationElement: "{{serviceName}} z {{name}}",
      serviceApplicationPackage: "{{serviceName}} z {{name}}",
      meetingDates: "Terminy spotkań",
      dateNotSelected: "nie wybrano",
      selectDate: "Wybierz datę i godzinę",
      amount: "Liczba",
      price: "Cena",
      selectStudyField: "Wybierz kierunek"
    },
    paymentSummary: {
      title: 'Podsumowanie i płatność',
      paymentMethod: {
        title: 'Wybierz formę płatności',
        proceed: 'Przejdź do płatności'
      },
      backToCart: 'Wróć do koszyka',
      table: {
        serviceName: 'Nazwa usługi',
        quantity: 'Ilość',
        netPrice: 'Kwota netto',
        vatRate: 'VAT 23%',
        grossSum: 'Suma brutto'
      }
    },
    summary: {
      title: "Razem do zapłaty",
      paymentRedirect: "Przejdź do podsumowania"
    },
    addPayment: {
      title: "Opłać Koszyk",
      addCard: "Dodaj nową kartę płatniczą",
      paymentSummary: "Podsumowanie",
      grossPrice: "Kwota brutto:",
      totalPrice: "Kwota netto:",
      tax: "Podatek VAT:",
      form: {
        labels: {
          cardNumber: "Dane karty",
          date: "Data ważności",
          code: "Kod bezpieczeństwa",
          cardholderName: "Imię i nazwisko posiadacza karty"
        },
        placeholders: {
          cardholderName: "Wpisz tutaj",
          cardNumber: "Numer karty",
        }
      },
      pay: "Zapłać",
      cartPaid: "Koszyk został opłacony"
    },
    paymentCompleted: {
      title: 'Płatność zrealizowana',
      message: 'Gratulacje! Twoje zamówienie zostało opłacone.',
      nextStep: 'Co chcesz teraz zrobić?',
      goToPayments: 'Przejdź do historii płatności, gdzie zobaczysz zakupione usługi i umówisz daty konsultacji.',
      goToDashboard: 'Przejdź do kokpitu.',
      buttons: {
        payments: 'Historia płatności',
        dashboard: 'Kokpit'
      }
    }
  },
  payments: {
      srTitle: "Płatności",
      cards: {
          title: 'Twoje karty płatnicze',
          cardListItem: {
            cardNo: 'Numer karty',
            expiryDate: 'Data ważności',
            fullName: 'Imię i nazwisko posiadacza karty',
          },
          addNewCard: 'Dodaj nową kartę'
      },
      operationHistory: {
        title: 'Historia operacji',
        table: {
            package: 'Pakiet',
            services: 'Usługi',
            purchaseDate: 'Data zakupu',
            operationName: 'Operacja',
            operationWithServicesName: 'Operacja zawierająca usługi',
            paymentStatus: {
                title: 'Status płatności',
                status_new: 'Nowa',
                status_paid: 'Opłacona',
                status_expired: 'Wygasła',
                status_failed: 'Nieudana',
                status_requires_payment_method: 'Wymaga metody płatności',
                status_requires_user_action: 'Wymaga akcji użytkownika',
                undefined: '--',
                pending: 'W trakcie',
                completed: 'Zrealizowana'
            },
            realization: {
                title:'Realizacja',
                open: 'Otwarta',
                completed: 'Zakończona',
                awaits_payment: 'Nieopłacona',
                tooltipInfo: 'Wykorzystano {{usedAmount}} z {{usedLimit}} wykupionych spotkań',
                usedHours: '{{usedAmount}}/{{usedLimit}} h',
                usedTests: '{{usedAmount}}/{{usedLimit}} testów',
                tooltipLink: 'kliknij, aby umówić'
            },
            price: 'Cena',
            noOperations: 'Brak historycznych operacji'
        },
        filters: {
            operationDateFrom: 'Data operacji od',
            operationDateTo: 'Data operacji do',
            forPeriod: {
                title: 'Za okres',
                week: 'Tydzień',
                month: 'Miesiąc',
                year: 'Rok',
                placeholder: 'Wybierz okres'
            },
        }
      },
      paymentSummary: {
          title: 'Bieżące saldo',
          paymentAmount: 'Kwota do wpłaty',
          placeholder: 'Wpisz tutaj',
          prePayButton: 'Zrób nadpłatę'
      },
    selectServiceHour: {
      title: 'Wybierz terminy spotkań',
      consultationScheduled: 'Spotkanie zostało pomyślnie zarezerwowane',
      consultationRescheduled: 'Data spotkania została pomyślnie zmieniona',
      consultationCancelled: 'Spotkanie zostało odwołane'
    }
  },
  videoChat: {
    consultationStart: "Twoja konsultacja zaczyna się o ",
    consultationTimeZone: "Strefa czasowa: środkowoeuropejska",
    wailTillConsultationStarts: "Poczekaj, aż mentor cię wpuści.",
    noAccess: "Nie możesz dołączyć do tej konsultacji.",
    contactAdmin: "Sprawdź swój link URL lub skontaktuj się z administratorem.",
    consultationEnded: "Spotkanie już się skończyło",
    rescheduleConsultation: "W przypadku nieobecności na spotkaniu prosimy o kontakt z adminem.",
    settings: {
      title: "Ustawienia",
      select: "Wybierz",
      selectCamera: "Wybierz kamerę",
      selectSpeaker: "Wybierz głośnik",
      selectMicrophone: "Wybierz mikrofon",
      testMicrophone: "Powiedz coś żeby sprawdzić mikrofon",
      poor: "poor",
      excellent: "excellent",
      saveChanges: "Zapisz ustawienia"
    },
    chat: {
      placeholder: "Napisz wiadomość",
      noMessages: "Nie masz żadnych wiadomości",
      status: {
        online: 'Online'
      }
    }
  },
  chat: {
    noNodeConnection: "Serwer czatu jest niedostępny. Sprawdź ponownie później",
    noConnectionReasons: "Możliwe przyczyny są wymienione poniżej:",
    not_authorized: "Podano nieprawidłowy token. Spróbuj zalogować się ponownie",
    'websocket error': "Możliwe są inne powody",
    already_online: "Inny użytkownik zalogował się na to konto. Wyloguj się na innych urządzeniach",
    no_token: "Nie podano tokena. Spróbuj zalogować się ponownie",
    expired_token: "Dostarczono wygasły token. Spróbuj zalogować się ponownie",
  },
  breadcrumbs: {
    dashboard: "Dashboard",
    competence: "Diagnostyka",
    competenceResult: "Wyniki testu",
    schools: "Wyszukiwarka uczelni",
    mentors: "Mentorzy",
    tutors: "Korepetytorzy",
    application: "Aplikacja na studia",
    contentFiles: "Materiały aplikanta",
    marketplace: "Marketplace",
    payments: "Płatności",
    applicationPackages: "Pakiety aplikacyjne",
    marketplaceTests: "Testy diagnostyczne",
    marketplaceTutors: "Korepetycje",
    fields: "Kierunki",
    favouriteFields: "Ulubione uczelnie",
    appliedFields: "Wybrane uczelnie",
    videoChat: "VideoChat",
    cart: 'Twój koszyk',
    predisposition: 'Diagnostyka predyspozycji',
    morrisbyTest: 'Test Morrisby wraz z omówieniem'

  },
  buttons: {
    more: "Zobacz więcej",
    book: "Rezerwuj",
    searchRedirect: "Idź do wyszukiwarki",
    buyApplication: "Wykup aplikację",
    takeTest: "Wykonaj test",
    buyTest: "Kup test",
    downloadCertificate: "Pobierz certyfikat",
    send: "Wyślij",
    login: "Zaloguj się",
    changePassword: "Zmień hasło",
    register: "Zarejestruj się",
    seeApplicantData: 'Zobacz materiały aplikanta',
    startConsultation: 'Rozpocznij',
    next: "Przejdź dalej",
    back: 'Powrót',
    sendRegistration: "Wyślij zgłoszenie",
    returnToHomepage: "Przejdź do strony głównej",
    confirm: "Potwierdź",
    endTest: 'Zakończ test',
    cancel: 'Przerwij'
  },
  defaultList: {
    noItems: 'Brak elementów',
    seeLess: "Pokaż mniej",
    seeMore: "Pokaż więcej",
  },
  consultations: {
    consultationTimeTooltip: 'Zegar odlicza czas do końca spotkania.'
  },
  countries: {
    deu: 'Niemcy',
    pol: 'Polska',
    usa: 'Stany Zjednoczone Ameryki',
    are: 'Zjednoczone Emiraty Arabskie',
    aus: 'Australia',
    aut: 'Austria',
    can: 'Kanada',
    che: 'Szwajcaria',
    cyp: 'Cypr',
    cze: ' Czechy',
    fin: ' Finlandia',
    gbr: 'Wielka Brytania',
    ita: 'Włochy',
    jam: ' Jamajka',
    jpn: 'Japonia',
    kaz: ' Kazachstan',
    lux: 'Luksemburg',
    nld: 'Holandia',
    rus: 'Rosja',
    swe: 'Szwecja',
    zaf: 'Afryka Południowa'
  },
  profileImageComponent: {
    buttons: {
      change: "Zmień",
      add: "Dodaj",
    },
    srButtons: {
      edit: 'Edytuj zdjęcie',
      add: 'Dodaj zdjęcie',
      saveProfileImage: 'Zatwierdź zmianę zdjęcia profilowego',
      cancelProfileImage: 'Anuluj zmianę  zdjęcia profilowego',
      deleteProfileImage: 'Usuń zdjęcie profilowe',
      imageTooBig: 'Zdjęcie profilowe musi mieć wielkość poniżej 1MB.',
    }
  },
  formValidation: {
    errors: {
      minLength: 'Wartość jest zbyt krótka',
      isEmailValid: 'Adres email jest nieprawidłowy',
      isRequired: 'Pole wymagane',
      isCheckedTrue: 'Musi być zaznaczone',
      isTrue: 'Musi być zaznaczone',
      isPasswordSame: 'Hasła nie mogą być różne',
      isStartDateValid: 'Data musi być w przyszłości',
      isStartDateGreaterOrEvenValid: 'Data musi być w przyszłości lub dziś',
      isEndDateValid: 'Data powrotu musi być po dacie wylotu',
      isNumber: 'Wartość musi być liczbą',
      isPhoneValid: 'Podaj poprawny numer telefonu',
      isPositiveNumberOrNull: "Wpisana wartość musi być dodatnia ",
      stripeError: 'Stripe: płatność nie powiodła się.',
      payuError: 'PayU: płatność nie powiodła się.'
    }
  },
  multiselectGroupedLabels: {
    realms: 'Dziedziny',
    subRealms: 'Poddziedziny',
    studyFields: 'Kierunek',
    countries: 'Kraje',
    schools: 'Uczelnie'
  },
  dateRangePicker: {
    config: {
      previousMonth: 'Poprzedni miesiac',
      lastWeek: 'Ostatnie 7 dni',
      today: 'Dziś',
      currentMonth: 'Obecny miesiąc'
    }
  },
  applicants: {
    applicantView: {
      tasks: {
        addNew: "Dodaj nowe",
        addTaskModal: {
          title: "Dodaj nowe zadanie",
          form: {
            labels: {
              taskTitle: "Temat zadania",
              taskDescription: "Opis zadania",
              addDocument: "+ Dodaj dokument",
              deadline: "Deadline",
              needsReview: "BEZ OCENY"
            },
            placeholders: {
              inputPlaceholder: "Wpisz tutaj",
              datePlaceholder: "DD / MM / RRRR"
            }
          },
          addTask: "Dodaj zadanie",
          taskAdded: "Zadanie zostało pomyślnie dodane"
        }
      },
    },
  },
  taskStatusTypes: {
    cancelled: 'Przerwane',
    during: 'W trakcie',
    not_completed: 'Nie zakończone',
    finished: 'Zakończone',
    new: 'Nowe',
    noStatus: 'Bez statusu'
  },
  country: {
		AFG: "Afganistan",
		ALB: "Albania",
		DZA: "Algieria",
		AND: "Andora",
		AGO: "Angola",
		AIA: "Anguilla",
		ATA: "Antarktyka",
		ATG: "Antigua i Barbuda",
		SAU: "Arabia Saudyjska",
		ARG: "Argentyna",
		ARM: "Armenia",
		ABW: "Aruba",
		AUS: "Australia",
		AUT: "Austria",
		AZE: "Azerbejdżan",
		BHS: "Bahamy",
		BHR: "Bahrajn",
		BGD: "Bangladesz",
		BRB: "Barbados",
		BEL: "Belgia",
		BLZ: "Belize",
		BEN: "Benin",
		BMU: "Bermudy",
		BTN: "Bhutan",
		BLR: "Białoruś",
		BOL: "Boliwia",
		BES: "Bonaire, Sint Eustatius i Saba",
		BIH: "Bośnia i Hercegowina",
		BWA: "Botswana",
		BRA: "Brazylia",
		BRN: "Brunei",
		IOT: "Brytyjskie Terytorium Oceanu Indyjskiego",
		VGB: "Brytyjskie Wyspy Dziewicze",
		BGR: "Bułgaria",
		BFA: "Burkina Faso",
		BDI: "Burundi",
		CHL: "Chile",
		CHN: "Chiny",
		HRV: "Chorwacja",
		CUW: "Curaçao",
		CYP: "Cypr",
		TCD: "Czad",
		MNE: "Czarnogóra",
		CZE: "Czechy",
		UMI: "Dalekie Wyspy Mniejsze Stanów Zjednoczonych",
		DNK: "Dania",
		COD: "Demokratyczna Republika Konga",
		DMA: "Dominika",
		DOM: "Dominikana",
		DJI: "Dżibuti",
		EGY: "Egipt",
		ECU: "Ekwador",
		ERI: "Erytrea",
		EST: "Estonia",
		ETH: "Etiopia",
		FLK: "Falklandy",
		FJI: "Fidżi",
		PHL: "Filipiny",
		FIN: "Finlandia",
		FRA: "Francja",
		ATF: "Francuskie Terytoria Południowe i Antarktyczne",
		GAB: "Gabon",
		GMB: "Gambia",
		SGS: "Georgia Południowa i Sandwich Południowy",
		GHA: "Ghana",
		GIB: "Gibraltar",
		GRC: "Grecja",
		GRD: "Grenada",
		GRL: "Grenlandia",
		GEO: "Gruzja",
		GUM: "Guam",
		GGY: "Guernsey",
		GUF: "Gujana Francuska",
		GUY: "Gujana",
		GLP: "Gwadelupa",
		GTM: "Gwatemala",
		GNB: "Gwinea Bissau",
		GNQ: "Gwinea Równikowa",
		GIN: "Gwinea",
		HTI: "Haiti",
		ESP: "Hiszpania",
		NLD: "Holandia",
		HND: "Honduras",
		HKG: "Hongkong",
		IND: "Indie",
		IDN: "Indonezja",
		IRQ: "Irak",
		IRN: "Iran",
		IRL: "Irlandia",
		ISL: "Islandia",
		ISR: "Izrael",
		JAM: "Jamajka",
		JPN: "Japonia",
		YEM: "Jemen",
		JEY: "Jersey",
		JOR: "Jordania",
		CYM: "Kajmany",
		KHM: "Kambodża",
		CMR: "Kamerun",
		CAN: "Kanada",
		QAT: "Katar",
		KAZ: "Kazachstan",
		KEN: "Kenia",
		KGZ: "Kirgistan",
		KIR: "Kiribati",
		COL: "Kolumbia",
		COM: "Komory",
		COG: "Kongo",
		KOR: "Korea Południowa",
		PRK: "Korea Północna",
		CRI: "Kostaryka",
		CUB: "Kuba",
		KWT: "Kuwejt",
		LAO: "Laos",
		LSO: "Lesotho",
		LBN: "Liban",
		LBR: "Liberia",
		LBY: "Libia",
		LIE: "Liechtenstein",
		LTU: "Litwa",
		LUX: "Luksemburg",
		LVA: "Łotwa",
		MKD: "Macedonia",
		MDG: "Madagaskar",
		MYT: "Majotta",
		MAC: "Makau",
		MWI: "Malawi",
		MDV: "Malediwy",
		MYS: "Malezja",
		MLI: "Mali",
		MLT: "Malta",
		MNP: "Mariany Północne",
		MAR: "Maroko",
		MTQ: "Martynika",
		MRT: "Mauretania",
		MUS: "Mauritius",
		MEX: "Meksyk",
		FSM: "Mikronezja",
		MMR: "Mjanma",
		MDA: "Mołdawia",
		MCO: "Monako",
		MNG: "Mongolia",
		MSR: "Montserrat",
		MOZ: "Mozambik",
		NAM: "Namibia",
		NRU: "Nauru",
		NPL: "Nepal",
		DEU: "Niemcy",
		NER: "Niger",
		NGA: "Nigeria",
		NIC: "Nikaragua",
		NIU: "Niue",
		NFK: "Norfolk",
		NOR: "Norwegia",
		NCL: "Nowa Kaledonia",
		NZL: "Nowa Zelandia",
		OMN: "Oman",
		PAK: "Pakistan",
		PLW: "Palau",
		PSE: "Palestyna",
		PAN: "Panama",
		PNG: "Papua-Nowa Gwinea",
		PRY: "Paragwaj",
		PER: "Peru",
		PCN: "Pitcairn",
		PYF: "Polinezja Francuska",
		POL: "Polska",
		PRI: "Portoryko",
		PRT: "Portugalia",
		ZAF: "Południowa Afryka",
		CAF: "Republika Środkowoafrykańska",
		CPV: "Republika Zielonego Przylądka",
		REU: "Reunion",
		RUS: "Rosja",
		ROU: "Rumunia",
		RWA: "Rwanda",
		ESH: "Sahara Zachodnia",
		KNA: "Saint Kitts i Nevis",
		LCA: "Saint Lucia",
		VCT: "Saint Vincent i Grenadyny",
		BLM: "Saint-Barthélemy",
		MAF: "Saint-Martin",
		SPM: "Saint-Pierre i Miquelon",
		SLV: "Salwador",
		ASM: "Samoa Amerykańskie",
		WSM: "Samoa",
		SMR: "San Marino",
		SEN: "Senegal",
		SRB: "Serbia",
		SYC: "Seszele",
		SLE: "Sierra Leone",
		SGP: "Singapur",
		SXM: "Sint Maarten",
		SVK: "Słowacja",
		SVN: "Słowenia",
		SOM: "Somalia",
		LKA: "Sri Lanka",
		USA: "Stany Zjednoczone",
		SWZ: "Eswatini",
		SDN: "Sudan",
		SSD: "Sudan Południowy",
		SUR: "Surinam",
		SJM: "Svalbard i Jan Mayen",
		SYR: "Syria",
		CHE: "Szwajcaria",
		SWE: "Szwecja",
		TJK: "Tadżykistan",
		THA: "Tajlandia",
		TWN: "Tajwan",
		TZA: "Tanzania",
		TLS: "Timor Wschodni",
		TGO: "Togo",
		TKL: "Tokelau",
		TON: "Tonga",
		TTO: "Trynidad i Tobago",
		TUN: "Tunezja",
		TUR: "Turcja",
		TKM: "Turkmenistan",
		TCA: "Turks i Caicos",
		TUV: "Tuvalu",
		UGA: "Uganda",
		UKR: "Ukraina",
		URY: "Urugwaj",
		UZB: "Uzbekistan",
		VUT: "Vanuatu",
		WLF: "Wallis i Futuna",
		VAT: "Watykan",
		VEN: "Wenezuela",
		HUN: "Węgry",
		GBR: "Wielka Brytania",
		VNM: "Wietnam",
		ITA: "Włochy",
		CIV: "Wybrzeże Kości Słoniowej",
		BVT: "Wyspa Bouveta",
		CXR: "Wyspa Bożego Narodzenia",
		IMN: "Wyspa Man",
		SHN: "Wyspa Świętej Heleny, Wyspa Wniebowstąpienia i Tristan da Cunha",
		ALA: "Wyspy Alandzkie",
		COK: "Wyspy Cooka",
		VIR: "Wyspy Dziewicze Stanów Zjednoczonych",
		HMD: "Wyspy Heard i McDonalda",
		CCK: "Wyspy Kokosowe",
		MHL: "Wyspy Marshalla",
		FRO: "Wyspy Owcze",
		SLB: "Wyspy Salomona",
		STP: "Wyspy Świętego Tomasza i Książęca",
		ZMB: "Zambia",
		ZWE: "Zimbabwe",
		ARE: "Zjednoczone Emiraty Arabskie",
	},
  language: {
    de: 'niemiecki',
    pl: 'polski',
    en: 'angielski'
  },
  schoolStudyLevel: {
    bachelor: "Licencjat",
    master: "Magister",
},
  maintenance: {
    pageNotFound: 'Błąd - nie znaleziono strony'
  },
  alerts: {
    baseError: 'Coś poszło nie tak. Spróbuj ponownie później',
    noAccessError: "Nie posiadasz odpowiednich uprawnień aby zalogować się do panelu.",
    authError: "Niepoprawny login lub hasło. Sprawdź poprawność wpisanych danych i spróbuj jeszcze raz",
    loginError: "Podczas logowania wystąpił błąd:",
    tryAgain: "Spróbuj jeszcze raz.",
    tokenRefreshed: "Twoja sesja została odnowiona",
    unknown: "Wystąpił nieznany błąd."
  }
};

export default pl;
