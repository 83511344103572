import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';

export interface ICreateOnlineConsultation {
    serviceInstanceId: string;
    onlineConsultations: IOnlineConsultationInstance[]
}

interface IOnlineConsultationInstance {
    onlineConsultationId: string;
    startsAt: string;
    secret: string;
    notes: string | null;
}

export function createOnlineConsultationAPI(authToken: string | null, payload: ICreateOnlineConsultation): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.post(
        `online_consultations`,
        payload,
        new RestQueryParams(),
        headers
    );
}
