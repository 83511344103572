import {authTokenSelector, changeBreadcrumbs, getDisplayName, IModelMentor, isNotNullOrUndefined} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {of, Subscription} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {getMentorAPI} from '../../../api/getMentor';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import {IAlertManagerService} from '../../../service/alertManagerService';
import CalendarCard from '../Common/CalendarCard';
import ConsultationPackagesCard from '../Common/ConsultationPackagesCard';
// import StudentOpinionCard from '../Common/StudentOpinionsCard';
import VideoPresentationCard from '../Common/VideoPresentationCard';
import MentorDetailsCard from './MentorDetailsCard';
import {RootState} from '../../../store/reducers';
import {getMentorByShortIdAPI} from "../../../api/getMentorByShort";

interface IConnectedMentorProfileProps {
    readonly authToken: string;
    changeBreadcrumbs: typeof changeBreadcrumbs;
}

interface IExternalMentorProfileProps {
}

interface IMentorProfileProps extends IConnectedMentorProfileProps,
    IExternalMentorProfileProps,
    RouteComponentProps {
}

interface IMentorProfileState {
    profile: typeof IModelMentor | null;
}


class MentorProfile extends React.Component<IMentorProfileProps, IMentorProfileState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IMentorProfileProps) {
        super(props);

        this.state = {
            profile: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.getMentor();
        if (sessionStorage.getItem('redirectUrl')){
            sessionStorage.removeItem('redirectUrl');
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        if (!this.state.profile) {
            return null;
        }

        return (
            <section className={`row`}>
                <div className="col-md-12 col-xl-8">
                    <MentorDetailsCard mentor={this.state.profile}/>
                    <ConsultationPackagesCard mentorId={this.state.profile.id}
                                              mentorServiceDefinitions={this.state.profile.mentorServiceDefinitions}/>

                    {/*Hidden in current version*/}
                    {/*<StudentOpinionCard mentorName={getDisplayName(this.state.profile.account)}/>*/}
                </div>
                <div className="col-md-12 col-xl-4">
                    {this.renderSideContent()}
                </div>
            </section>
        );
    }

    private renderSideContent = () => {
        return (
            <div className="school-profile-sidebar">
                <CalendarCard mentor={this.state.profile}/>

                {this.renderVideoPresentation()}
            </div>
        );
    };

    private renderVideoPresentation() {
        return isNotNullOrUndefined(this.state.profile?.videoLink) ?
            <VideoPresentationCard videoUrl={this.state.profile.videoLink}/> :
            null;
    };

    private getMentor = () => {
        const routeState: any = this.props.location.state;
        let id: string | null | undefined;
        let isIdHash = false;
        if (routeState) {
            this.changeBreadcrumbs(routeState.name);
            id = routeState.id;
        } else {
            let matches = /\/panel\/.+\/(.+)\/(.+)$/.exec(this.props.location.pathname);
            if (!matches){
                matches = /\/panel\/.+\/(.+)$/.exec(this.props.location.pathname);
                isIdHash = true;
            }
            id = matches?.[1];
        }

        if (isIdHash){
            this.subscriptions.push(
                getMentorAPI(this.props.authToken, id as string).pipe(
                    tap((resp: any) => {
                        if (!resp?.account?.user?.active){
                            this.props.history.push('/panel/mentors')
                        }
                        this.changeBreadcrumbs(getDisplayName(resp.account));
                        this.setState({profile: resp});
                    }),
                    catchError((error: any) => {
                        this.alertManager?.handleApiError(error.response);
                        return of(error);
                    })
                ).subscribe(()=>{
                    const href = window.location.href;
                    const newHref = href.substr(0, href.lastIndexOf('/')) +'/'+ this.state.profile.shortId +'/'+ this.state.profile.account.displayName;
                    window.history.pushState('data', 'title', newHref);
                })
            )
        } else {
            this.subscriptions.push(
                getMentorByShortIdAPI(this.props.authToken, id as string).pipe(
                    tap((resp: any) => {
                        resp = resp["hydra:member"][0];
                        if (!resp?.account?.user?.active){
                            this.props.history.push('/panel/mentors')
                        }
                        this.changeBreadcrumbs(getDisplayName(resp.account));
                        this.setState({profile: resp});
                    }),
                    catchError((error: any) => {
                        this.alertManager?.handleApiError(error.response);
                        return of(error);
                    })
                ).subscribe()
            )
        }
    };

    private changeBreadcrumbs = (name: string): void => {
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
            {labelKey: 'breadcrumbs.mentors', path: '/panel/mentors'},
            {labelKey: name, path: ''}
        ]);
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {
        changeBreadcrumbs
    }
)(withRouter(MentorProfile));
