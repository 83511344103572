import React from 'react';
import {BehaviorSubject, Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {IAlertManagerService} from '../../service/alertManagerService';
import {
    deepCloneObject,
    Form,
    FormControlChangeType,
    IFormConfig,
    IMultiselectOption, isNotNullOrUndefined,
    isSameValue,
    RestQueryParams,
    SchoolType
} from 'educat-common-web';
import {filter, map, tap} from 'rxjs/operators';
import styles from './styles.module.scss';
import {getSchoolsAPI} from '../../api/getSchools';
import {connect} from 'react-redux';
import {RootState} from '../../store/reducers';
import {studyFieldsFiltersSelector} from '../../store/selectors/studyFieldsSearchSelectors';
import {IStudyFieldFilters} from '../../store/reducers/studyFieldsSearchSlice';
import FilterModel from '../../models/filters';
import {mentorFiltersSelector} from "../../store/selectors/mentorsSearchSelectors";
import {IMentorFilters} from "../../store/reducers/mentorsSearchSlice";


interface IConnectedFilterProps {
    readonly studyFieldsFilters: IStudyFieldFilters | null;
    readonly mentorFilters: IMentorFilters | null;
}

interface IExternalFilterProps {
    readonly changeFilters: (filters: any) => void;
    readonly authToken: string;
    readonly formConfigFactory: (collegeValues: typeof IMultiselectOption[], handleCollegeValueChange: (value: any, controlName: string) => void) => typeof IFormConfig;
}

interface IFilterProps extends IConnectedFilterProps,
    IExternalFilterProps {
}

interface IFilterState {
    formConfig: typeof IFormConfig;
    value: any;
    collegeValues: typeof IMultiselectOption[] | [];
}

class Filter extends React.Component<IFilterProps, IFilterState> {
    private readonly filter = new FilterModel();
    private readonly subscriptions: Subscription[] = [];
    private readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IFilterProps) {
        super(props);

        this.state = {
            formConfig: null,
            value: null,
            collegeValues: []
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onFormValueChange(data.value)),
            ).subscribe()
        );

        this.setFormConfig();
    }

    componentDidUpdate(
        prevProps: Readonly<IFilterProps>,
        prevState: Readonly<IFilterState>,
        snapshot?: any
    ): void {
        if (!isSameValue(this.state.collegeValues, prevState.collegeValues) ||
            this.props.formConfigFactory !== prevProps.formConfigFactory) {
            this.setFormConfig();
        }
        if (null !== this.props.studyFieldsFilters &&
            !isSameValue(this.props.studyFieldsFilters, prevProps.studyFieldsFilters)) {
            this.setState({value: this.filter.mapStudyFieldFiltersToForm(this.props.studyFieldsFilters)});
        }

        if (null !== this.props.mentorFilters &&
            !isSameValue(this.props.mentorFilters, prevProps.mentorFilters)) {
            this.setState({value: this.filter.mapMentorFiltersToForm(this.props.mentorFilters)});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <div className={styles.filterContainer}>
                {this.state.formConfig && <Form config={this.state.formConfig}
                                                onValueStateChange={this.onValueStateChange}
                                                value={this.state.value}
                                                controlName={'filterForm'}/>}
            </div>
        );
    }


    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onFormValueChange = (value: any) => {
        // studyField is missing from form value, as there is no such field, so let's add it manually
        const clone = deepCloneObject(value);
        if (isNotNullOrUndefined(this.props.studyFieldsFilters?.studyField?.id)) {
            clone.studyField = (this.props.studyFieldsFilters as any).studyField.id;
        }

        this.props.changeFilters(clone);
    };

    private setFormConfig = (): void => {
        const formConfig = this.props.formConfigFactory(
            this.state.collegeValues,
            (value: any, controlName: string) => this.handleMultiselectInputChange(value, controlName)
        );
        this.setState({formConfig});
    };

    private handleMultiselectInputChange = (value: string, controlName: string) => {
        if (value.length >= 3) {
            let queryParams = new RestQueryParams();

            if (controlName === 'school') {
                queryParams = queryParams.add('name', value);
                queryParams = queryParams.add('type', SchoolType.College);
                this.loadSchools(queryParams, schools => this.setState({collegeValues: schools}));
            }
        }
    };

    private loadSchools = (queryParams: typeof RestQueryParams, onSuccess: (schools: typeof IMultiselectOption[]) => void) => {
        return this.subscriptions.push(
            getSchoolsAPI(this.props.authToken, queryParams).pipe(
                map((resp: any) => resp['hydra:member']
                    .map((school: { [key: string]: any }) => ({
                        value: school.id,
                        label: school.name
                    }))),
                tap(onSuccess)
            ).subscribe()
        );
    };
}

export default connect(
    (state: RootState) => ({
        studyFieldsFilters: studyFieldsFiltersSelector(state),
        mentorFilters: mentorFiltersSelector(state)
    }),
)(Filter);
