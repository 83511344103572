import {
    AccountImageHost,
    accountLoadingSelector,
    accountSelector,
    CustomCard,
    Datepicker,
    IAccount,
    IAccountData,
    InputBasic,
    InputType,
    Translation,
    updateAccount,
    usernameSelector,
    UserRole
} from 'educat-common-web';
import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import styles from './styles.module.scss';


interface IConnectedProfileInfoCardProps {
    readonly account: typeof IAccount;
    readonly username: string;
    readonly isAccountLoading: boolean;
    readonly updateAccount: typeof updateAccount;
}

interface IExternalProfileInfoCardProps {
    notificationCount?: number;
}

interface IProfileInfoCardProps extends IConnectedProfileInfoCardProps,
    IExternalProfileInfoCardProps,
    WithTranslation {
}

interface IProfileInfoCardState {
    phoneValue: string;
    dateBirthValue: string;
    isPhoneEditable: boolean;
    isPhoneValid: boolean;
    isDateBirthEditable: boolean;
}


class ProfileInfoCard extends React.Component<IProfileInfoCardProps, IProfileInfoCardState> {

    constructor(props: IProfileInfoCardProps) {
        super(props);
        this.state = {
            phoneValue: this.props.account && this.props.account.phone ? this.props.account.phone : null,
            dateBirthValue: this.props.account && this.props.account.birthDate ? this.props.account.birthDate : null,
            isPhoneEditable: false,
            isPhoneValid: true,
            isDateBirthEditable: false,
        };
    }

    render() {
        const username = this.props.username ? this.props.username : null,
            name = this.props.account ? `${this.props.account.firstName} ${this.props.account.lastName}` : username;

        return (
            <CustomCard showLocalLoader={this.props.isAccountLoading}>
                <CustomCard.Body>
                    <h2 className="sr-only">
                        <Translation text={'profile.profileInfo.srTitle'}/>
                    </h2>
                    <div className={styles.profileInfoWrapper}>

                        <div className="user-img">
                            <div className="image-placeholder">
                                <AccountImageHost notificationCount={this.props.notificationCount} userRole={UserRole.APPLICANT}
                                                  onImageChange={() => null}/>
                            </div>
                        </div>
                        <div className={styles.profileInfoDetails}>
                            <p className={styles.profileTitle}>{name}</p>
                            <p className={styles.profileMail}>{this.props.username}</p>
                            {this.renderPhoneInput()}
                            {this.renderBirthDateInput()}
                        </div>
                    </div>
                </CustomCard.Body>
            </CustomCard>
        );
    }

    private renderPhoneInput = () => {
        const {t} = this.props;
        return (
            <div className={styles.controlWrapper}>
                <div className={`${styles.control}
                                 ${this.state.isPhoneEditable ? styles.editable : ''}
                                 ${!this.state.isPhoneValid ? styles.error : ''}`}>
                    <div className={styles.inputWrapper}>
                        <InputBasic value={this.state.phoneValue}
                                    name={'phoneNumber'}
                                    placeholder={t('profile.profileInfo.phonePlaceholder')}
                                    type={InputType.TEL}
                                    handleChange={(e: any) => {
                                        this.setState({
                                            phoneValue: e.target.value,
                                            isPhoneValid: this.isPhoneValid(e.target.value)
                                        })
                                    }}
                                    inputStyles={styles.input}
                                    disabled={!this.state.isPhoneEditable}
                                    autoComplete="off"/>
                    </div>
                    {this.renderEditBtn(
                        this.state.isPhoneEditable,
                        () => this.setState({isPhoneEditable: !this.state.isPhoneEditable})
                    )}
                </div>

                {!this.state.isPhoneValid ?
                    (<p className={styles.errorMessage}><Translation text={'formValidation.errors.isPhoneValid'}/></p>) :
                    null
                }
            </div>
        );
    };

    private renderBirthDateInput = () => {
        const {t} = this.props;
        return (
            <div className={styles.controlWrapper}>
                <div className={`${styles.control}
                                account-control
                                ${this.state.isDateBirthEditable ? 'editable' : ''}
                                ${this.state.isDateBirthEditable ? styles.editable : ''}`}>
                    <Datepicker
                        value={this.state.dateBirthValue ? new Date(this.state.dateBirthValue) : null}
                        id={'birthDate'}
                        name={'birthDate'}
                        inputStyles={'input'}
                        dateFormat={'dd/MM/yyyy'}
                        placeholderText={t('profile.profileInfo.birthDatePlaceholder')}
                        handleChange={(e: any) => this.setState({dateBirthValue: e.target.value.toISOString()})}
                        disabled={!this.state.isDateBirthEditable}
                        showMonthDropdown={true}
                        showYearDropdown={true}
                        maxDate={new Date()}
                    />
                    {this.renderEditBtn(
                        this.state.isDateBirthEditable,
                        () => this.setState({isDateBirthEditable: !this.state.isDateBirthEditable})
                    )}
                </div>
            </div>
        );
    };

    private renderEditBtn = (isEditable: boolean, toggleView: () => void) => {
        return (
            <div className={styles.btnContainer}>
                {isEditable ?
                    (<>
                            <button onClick={() => toggleView()}>
                                <i className="feather icon-x"/>
                            </button>
                            <button onClick={() => {
                                toggleView();
                                this.updateAccountData();
                            }}>
                                <i className="feather icon-check"/>
                            </button>
                        </>
                    ) :
                    (<button onClick={() => toggleView()}>
                        <i className="feather icon-edit-2"/>
                    </button>)
                }
            </div>
        );
    };

    private updateAccountData = () => {
        if (this.state.phoneValue && !this.isPhoneValid(this.state.phoneValue)) return;

        const account = this.props.account,
            payload: typeof IAccountData = {
                id: account.id,
                firstName: account.firstName,
                lastName: account.lastName,
                birthDate: this.state.dateBirthValue,
                phone: this.state.phoneValue
            };

        this.props.updateAccount(payload);
    };

    private isPhoneValid = (value: string): boolean => {
        let phoneReg = new RegExp('^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\\s./0-9]*$');
        return phoneReg.test(String(value).toLowerCase());
    };
}

export default withTranslation()(connect(
    (state: RootState) => ({
        account: accountSelector(state),
        username: usernameSelector(state),
        isAccountLoading: accountLoadingSelector(state)
    }),
    {
        updateAccount
    }
)(ProfileInfoCard));
