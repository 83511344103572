import {IPaginationParams, IStudyFieldFilters, SortingParams} from '../store/reducers/studyFieldsSearchSlice';
import {IMentorFilters} from '../store/reducers/mentorsSearchSlice';
import {isNotNullOrUndefined, StudyFieldsListType} from "educat-common-web";


class FilterModel {
    filters: IStudyFieldFilters | IMentorFilters | null;
    sorting: SortingParams | null;
    pagination: IPaginationParams;
    listType: typeof StudyFieldsListType | null;

    constructor(filters: IStudyFieldFilters | IMentorFilters | null = null,
                sorting: SortingParams | null = null,
                pagination: IPaginationParams = {page: 1, itemsPerPage: 10},
                listType: typeof StudyFieldsListType | null = null) {
        this.filters = filters;
        this.sorting = sorting;
        this.pagination = pagination;
        this.listType = listType;
    }

    withFilters = (filters: IStudyFieldFilters | IMentorFilters | null) => {
        return new FilterModel(
            filters,
            this.sorting,
            this.pagination,
            this.listType,
        );
    };

    withStudyFieldFiltersFromForm = (formValues: { [key: string]: any }) => {
        return this.withFilters({
            price: isNotNullOrUndefined(formValues.price) ? formValues.price : null,
            duration_filter: this.getInputValueFromForm(formValues.duration),
            mode: this.getInputValueFromForm(formValues.mode),
            school: {
                id: this.getInputValueFromForm(formValues.school, true)
            },
            countries: {
                id: this.getInputValueFromForm(formValues.country)
            },
            studyField: {
                id: this.getInputValueFromForm(formValues.studyField, true),
            },
            realm: {
                id: this.getInputValueFromForm(formValues.subRealm, true)
            },
            level_filter: this.getInputValueFromForm(formValues.level)
        });
    };

    mapStudyFieldFiltersToForm = (filters: IStudyFieldFilters | null): { [key: string]: any } => {
        return {
            price: filters?.price,
            duration: filters?.duration_filter,
            mode: filters?.mode ? filters.mode : [],
            school: filters?.school?.id ? filters.school.id : [],
            country: filters?.countries?.id ? filters.countries.id : [],
            studyField: filters?.studyField?.id ? filters.studyField.id : [],
            subRealm: filters?.realm?.id ? filters.realm.id : [],
            level: filters?.level_filter ? filters.level_filter : [],
        };
    };

    mapMentorFiltersToForm = (filters: IMentorFilters | null): { [key: string]: any } => {
        return {
            country: filters?.countries?.id ? filters.countries.id : [],
            school: filters?.mentorSchoolStudyFields?.school?.id,
            languages: filters?.languages?.id ? filters.languages.id : [],
            subRealm: filters?.realms?.id ? filters.realms.id : [],
            studyField : filters?.studyFields?.id,
            price: filters?.basePrice ? filters.basePrice.split('..') : [],
            availability: filters?.free_terms ? filters.free_terms.split('to') : [],
            assistance: filters?.mentorTaskTypes?.id
        };
    };

    withMentorsFiltersFromForm = (formValues: { [key: string]: any }) => {
        return this.withFilters({
            countries: {
                id: this.getInputValueFromForm(formValues.country)
            },
            mentorSchoolStudyFields: {
                school: {
                    id: this.getInputValueFromForm(formValues.school, true)
                }
            },
            languages: {
                id: this.getInputValueFromForm(formValues.languages)
            },
            realms: {
                id: this.getInputValueFromForm(formValues.subRealm, true)
            },
            studyFields: {
                id: this.getInputValueFromForm(formValues.studyField, true)
            },
            basePrice: this.getInputValueFromForm(formValues.price, false, false, true),
            free_terms: this.getInputValueFromForm(formValues.availability, false, true),
            mentorTaskTypes: {
                id: this.getInputValueFromForm(formValues.assistance, true)
            }
        });
    };

    withSorting = (sortingParam: SortingParams): FilterModel => {
        return new FilterModel(
            this.filters,
            sortingParam,
            this.pagination,
            this.listType,
        );
    };

    withPagination = (page: number, itemsPerPage: number): FilterModel => {
        return new FilterModel(
            this.filters,
            this.sorting,
            {
                page: page,
                itemsPerPage: itemsPerPage
            },
            this.listType,
        );
    };

    withListType = (listType: typeof StudyFieldsListType): FilterModel => {
        return new FilterModel(
            this.filters,
            this.sorting,
            this.pagination,
            listType,
        );
    };

    private getInputValueFromForm = (value: any, isOptionSingle?: boolean, isOptionDateRange?: boolean, isOptionRange?: true): any => {
        let result: any = [];
        if (value && Array.isArray(value)) {
            if (isOptionSingle) {
                value.length > 0 ? result = value[0].value : result = null;
            } else if (isOptionDateRange && value.length) {
                result = `${new Date(value[0]).toISOString()}to${new Date(value[1]).toISOString()}`;
            } else if (isOptionRange && isNotNullOrUndefined(value[0] && isNotNullOrUndefined([value[1]]))) {
                result = `${value[0]}..${value[1]}`;
            } else {
                value.length > 0 ? value.map((option: { [key: string]: any }) => result.push(option)) : result = null;
            }
        } else {
            result = isNotNullOrUndefined(value) ? value : null;
        }

        return result;
    };
}

export default FilterModel;
