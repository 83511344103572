import {changeBreadcrumbs} from 'educat-common-web';
import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import MainSearchList from "./MainSearchList";
import {connect} from "react-redux";
import {
    applyStudyFieldsFilters,
    changeStudyFieldsCountryFilter,
    changeStudyFieldsFieldFilter,
    changeStudyFieldsFilters,
    changeStudyFieldsRealmFilter,
    changeStudyFieldsSchoolFilter,
    changeStudyFieldsSorting,
    resetStudyFieldsFiltersToDefaultAccountFilters
} from '../../../store/reducers/studyFieldsSearchSlice';


export enum FieldOfStudySearchRoute {
    SEARCH = "/panel/field-of-study-search",
    FAVOURITE = "/panel/field-of-study-search/favourite",
    APPLIED = "/panel/field-of-study-search/applied",
    MENTOR = "/panel/mentors"
}

interface IConnectedMainSearchProps {
    readonly changeStudyFieldsRealmFilter: typeof changeStudyFieldsRealmFilter;
    readonly changeStudyFieldsFieldFilter: typeof changeStudyFieldsFieldFilter;
    readonly changeStudyFieldsCountryFilter: typeof changeStudyFieldsCountryFilter;
    readonly changeStudyFieldsSchoolFilter: typeof changeStudyFieldsSchoolFilter;
    readonly changeStudyFieldsFilters: typeof changeStudyFieldsFilters;
    readonly changeStudyFieldsSorting: typeof changeStudyFieldsSorting;
    readonly applyStudyFieldsFilters: typeof applyStudyFieldsFilters;
    readonly resetStudyFieldsFiltersToDefaultAccountFilters: typeof resetStudyFieldsFiltersToDefaultAccountFilters;
}

interface IExternalMainSearchProps {
    readonly changeBreadcrumbs: typeof changeBreadcrumbs;
}

interface IMainSearchProps extends IConnectedMainSearchProps,
    IExternalMainSearchProps,
    RouteComponentProps {
}

interface IMainSearchState {
}


class MainSearch extends React.Component<IMainSearchProps, IMainSearchState> {

    componentDidMount(): void {
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
            {labelKey: 'breadcrumbs.fields', path: '/panel/field-of-study-search'}
        ]);
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <MainSearchList />
        );
    }
}

export default connect(
    () => ({}),
    {
        changeStudyFieldsRealmFilter,
        changeStudyFieldsFieldFilter,
        changeStudyFieldsCountryFilter,
        changeStudyFieldsSchoolFilter,
        changeStudyFieldsFilters,
        changeStudyFieldsSorting,
        applyStudyFieldsFilters,
        resetStudyFieldsFiltersToDefaultAccountFilters,
    }
)(withRouter(MainSearch));
