import {deepCloneObject, isNullOrUndefined, Price, RestQueryParams, Translation} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router';
import {Subscription} from 'rxjs';
import OperationTableItemRow from './OperationTableItemRow';
import OperationTableItemDate from './OperationTableItemDate';

interface IOperationHistoryItemExternalProps {
    readonly operationHistoryItem: any | null;
    readonly operationHistoryItemIndex: number;
    readonly refreshPaymentList: (params?: typeof RestQueryParams) => void;
}

interface IOperationHistoryItemProps extends IOperationHistoryItemExternalProps, RouteComponentProps {
}

interface IOperationHistoryItemState {
    shownServicesArray: string[];
}

class OperationHistoryItem extends React.Component<IOperationHistoryItemProps, IOperationHistoryItemState> {
    readonly subscriptions: Subscription[] = [];

    constructor(props: IOperationHistoryItemProps) {
        super(props);

        this.state = {
            shownServicesArray: [],
        };
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        const item = this.props.operationHistoryItem;

        return (
            <React.Fragment key={item.id}>
                <tr>
                    <td colSpan={2} className="highlight-cell">
                        {this.renderPackageButton(item)}
                    </td>
                    <td className="highlight-cell">
                        <OperationTableItemDate date={item.createdAt}/>
                    </td>
                    <td className="highlight-cell">
                        {isNullOrUndefined(item.status) ?
                            '--' :
                            <span className="default-status-badge">
                                <Translation text={`payments.operationHistory.table.paymentStatus.${item.status}`}/>
                            </span>}
                    </td>
                    <td className="highlight-cell">--</td>
                    <td className="highlight-cell"><Price price={item.money}/></td>
                </tr>
                {this.renderServiceRows(item.relations, item.createdAt, item.id, item.status)}
            </React.Fragment>
        );
    }

    private renderServiceRows(serviceList: any[], creationDate: string | null, id: string, paymentStatus: string) {
        if (!serviceList || serviceList.length === 0 || !this.state.shownServicesArray.some((openedItemId: any) => openedItemId === id)) {
            return null;
        }
        const serviceItems = serviceList[0].relatedObject.serviceInstances;
        if (isNullOrUndefined(serviceItems)) {
            return null;
        }

        return serviceItems
            .filter((service: any) => service.packageElement === false)
            .map((service: any) => <OperationTableItemRow key={service.id} service={service} date={creationDate} refreshPaymentList={this.props.refreshPaymentList} paymentStatus={paymentStatus}/>);
    }

    private renderPackageButton(item: any) {
        if (isNullOrUndefined(item.relations) || item.relations.length === 0) {
            return <span><Translation text={'payments.operationHistory.table.operationName'}/></span>;
        }

        return <button onClick={() => this.toggleServicesShown(item.id)}
                       type="button"
                       className={`default-expand-list-button operation-history ${this.state.shownServicesArray.some((id: string) => id === item.id) ? 'long-list' : ''}`}>
            <Translation text={'payments.operationHistory.table.operationWithServicesName'}/>
        </button>;
    }

    private toggleServicesShown(id: string) {
        const serviceToToggleIndex = this.state.shownServicesArray.findIndex((openedItemId: string) => id === openedItemId),
            updatedShownServicesArray = deepCloneObject(this.state.shownServicesArray);
        serviceToToggleIndex === -1 ? updatedShownServicesArray.push(id) : updatedShownServicesArray.splice(serviceToToggleIndex, 1);

        this.setState({shownServicesArray: updatedShownServicesArray});
    }
}

export default connect(() => ({}), {})(withRouter(OperationHistoryItem));
