import {getDisplayName, IModelMentor, ItemDescription, Translation, UserDisplayName,} from 'educat-common-web';
import React from 'react';
// import ReactStars from 'react-rating-stars-component';
import {NavLink} from 'react-router-dom';
import BasePrice from '../../../Common/BasePrice';
import MentorItemSchoolDetails from '../MentorItemSchoolDetails';

interface IConnectedMentorItemGeneralInfoProps {
}

interface IExternalMentorItemGeneralInfoProps {
    readonly mentor: typeof IModelMentor;
    readonly isTutor?: boolean;
}

interface IMentorItemGeneralInfoProps extends IConnectedMentorItemGeneralInfoProps, IExternalMentorItemGeneralInfoProps {
}

interface IMentorItemGeneralInfoState {
}

class MentorItemGeneralInfo extends React.Component<IMentorItemGeneralInfoProps, IMentorItemGeneralInfoState> {
    render() {
        const mentor = this.props.mentor,
            profileLink = this.props.isTutor ? `/panel/tutors/${mentor.id}` : `/panel/mentors/${mentor.shortId}/${mentor.account.firstName}`;

        return (
            <div className="col-md-4 col-12 mentor-details-wrapper">
                {/*Hidden in current version*/}
                {/*<ReactStars*/}
                {/*    count={5}*/}
                {/*    value={this.props.mentor.rating}*/}
                {/*    edit={false}*/}
                {/*    emptyIcon={<i className="star-icon empty"> </i>}*/}
                {/*    filledIcon={<i className="star-icon full"> </i>}*/}
                {/*    size={24}*/}
                {/*    activeColor="#fa9504"*/}
                {/*/>*/}
                <h3>
                    <NavLink to={{
                        pathname: profileLink,
                        state: {id: mentor.shortId, name: getDisplayName(mentor.account)},
                    }} className="mentor-link">
                        <UserDisplayName account={this.props.mentor.account}/>
                    </NavLink>
                </h3>
                <MentorItemSchoolDetails mentor={this.props.mentor}/>
                <p className="paragraph">
                    <Translation text={'mentorProfile.mentorDetails.languages'}/>
                    {this.props.mentor.languages && this.props.mentor.languages.length
                        ? this.props.mentor.languages.map((language: { [key: string]: any }, index: number) => (
                            <React.Fragment key={language.id}>
                                <Translation text={`language.${language.id}`}/>
                                {this.props.mentor.languages.length === (index + 1) ? '' : ', '}
                            </React.Fragment>
                        ))
                        : null}
                </p>
                <div className="sub-paragraph">
                    <ItemDescription description={this.props.mentor.longDescription}/>
                </div>

                <BasePrice mentor={this.props.mentor} isListPrice={true}/>
            </div>
        );
    }
}

export default MentorItemGeneralInfo;
