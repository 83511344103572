import React from "react";
import {CustomCard, isNullOrUndefined, Pagination, RestQueryParams, Translation} from "educat-common-web";
import OperationHistoryTable from "./OperationHistoryTable";
import OperationHistoryFilters from "./OperationHistoryFilters";
import {IFiltersListMetadata, IPaginationParams} from "../../../store/reducers/studyFieldsSearchSlice";

interface IExternalOperationHistoryCardProps {
    readonly operationHistoryList: any[] | null;
    readonly listMetadata: IFiltersListMetadata | null;
    readonly filtersChange: (params?: typeof RestQueryParams) => void;
    readonly changeStudyFieldsPagination: (params: typeof RestQueryParams) => void;
}

interface IOperationHistoryCardProps extends IExternalOperationHistoryCardProps {
}

class OperationHistoryCard extends React.Component<IOperationHistoryCardProps> {
    render() {
        return (
            <CustomCard showLocalLoader={false}>
                <CustomCard.Body>
                    <div className="mb-5">
                        <h2 className="secondary-title">
                            <Translation text={"payments.operationHistory.title"}/>
                        </h2>
                    </div>
                    <OperationHistoryFilters filtersChange={this.props.filtersChange}/>
                    <OperationHistoryTable operationHistoryList={this.props.operationHistoryList} refreshPaymentList={this.props.filtersChange}/>
                </CustomCard.Body>
                <CustomCard.Footer>
                    <Pagination listMetadata={this.props.listMetadata} changePage={this.changeStudyFieldsPagination}/>
                </CustomCard.Footer>
            </CustomCard>
        );
    }

    private changeStudyFieldsPagination = (params: IPaginationParams) => {
        let restParams = new RestQueryParams();
        Object.keys(params).forEach(() => {
            if (isNullOrUndefined(params.itemsPerPage || params.page)) {
                return;
            }
            restParams = restParams.add('itemsPerPage', params.itemsPerPage).add('page', params.page);
        });
        this.props.changeStudyFieldsPagination(restParams);
    }
}

export default OperationHistoryCard;
