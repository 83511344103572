import React from 'react';
import {authTokenSelector, getItemsCount, hasTrialConsultation, isSameValue, Translation, IModelMentorServiceDefinition} from 'educat-common-web';
import styles from './styles.module.scss';
import {addItemToCart} from '../../../../../store/reducers/cartSlice';
import {connect} from 'react-redux';
import {RootState} from '../../../../../store/reducers';
import {isCartLoadingSelector} from '../../../../../store/selectors/cartSelectors';
import ConsultationPackageItemPrice from '../ConsultationPackageItemPrice';
import {checkAvailableFreeConsultationAPI} from '../../../../../api/checkAvailableFreeConsultation';
import {catchError, tap} from 'rxjs/operators';
import {lazyInject} from '../../../../../ioc';
import {IAlertManagerService} from '../../../../../service/alertManagerService';
import {of, Subscription} from 'rxjs';


interface IConnectedConsultationPackageItemProps {
    readonly authToken: string | null;
    readonly addItemToCart: typeof addItemToCart;
    readonly isCartLoading: boolean;
}

interface IExternalConsultationPackageItemProps {
    consultation: typeof IModelMentorServiceDefinition;
    mentorId?: string;
}

interface IConsultationPackageItemProps extends IExternalConsultationPackageItemProps,
    IConnectedConsultationPackageItemProps {
}

interface IConsultationPackageItemState {
    isTrialConsultationAvailable: boolean;
}


class ConsultationPackageItem extends React.Component<IConsultationPackageItemProps, IConsultationPackageItemState> {
    readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IConsultationPackageItemProps) {
        super(props);

        this.state = {
            isTrialConsultationAvailable: false
        }
    }

    componentDidMount(): void {
        this.checkAvailableFreeConsultations();
    }

    componentDidUpdate(prevProps: Readonly<IConsultationPackageItemProps>, prevState: Readonly<IConsultationPackageItemState>, snapshot?: any): void {
        if (!isSameValue(this.props.isCartLoading, prevProps.isCartLoading) && !this.props.isCartLoading) {
            this.checkAvailableFreeConsultations();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        const consultation = this.props.consultation,
            isTrialConsultation = hasTrialConsultation(consultation),
            isFreePackage = consultation?.serviceApplicationPackage?.paidRange === false,
            addToCartDisabled = this.props.isCartLoading ||
                !this.props.consultation.active ||
                (isTrialConsultation && !this.state.isTrialConsultationAvailable);

        if (isFreePackage) {
            return null;
        }

        return (
            <details className={styles.consultationPackageWrapper}>
                <summary className={styles.consultationPackageSummary}>

                    <h3 className={styles.title}>{consultation.name}</h3>
                    <div className={styles.infoPart}>
                        <ConsultationPackageItemPrice consultation={consultation}/>

                        <button className={`${styles.packageSubmitButton} btn btn-theme`}
                                disabled={addToCartDisabled}
                                onClick={() => this.addToCart()}>
                            <Translation text={'mentorProfile.mentorConsultationPackages.packageSubmitButton'}/>
                        </button>
                    </div>

                </summary>
                <div className={styles.consultationPackageDetailsWrapper}>
                    <h4 className={styles.packageDetailsTitle}>
                        <Translation text={'mentorProfile.mentorConsultationPackages.whatInPackage'}/>
                    </h4>
                    <div className={`${styles.consultationPackageDetails} row`}>
                        <p className={`${styles.packageContents} col-xl-7`}
                           dangerouslySetInnerHTML={{__html: this.props.consultation.description}}>
                        </p>
                        <div className={`${styles.packageInfoWrapper} col-xl-5`}>
                            <div className={styles.lessonInfoWrapper}>
                                <div className={styles.lessonInfoContainer}>
                                    <span className={`${styles.lessonInfoIcon} ${styles.duration}`}/>
                                    <span className={styles.lessonInfoText}>
                                        <Translation
                                            text={'mentorProfile.mentorConsultationPackages.lessonDetails.lessonDuration'}
                                            config={{duration: '60'}}/>
                                    </span>
                                </div>
                                <div className={styles.lessonInfoContainer}>
                                    <span className={`${styles.lessonInfoIcon} ${styles.quantity}`}>
                                    </span>
                                    <span className={styles.lessonInfoText}>
                                        <Translation
                                            text={'mentorProfile.mentorConsultationPackages.lessonDetails.lessonQuantity'}
                                            config={{quantity: getItemsCount(consultation)}}/>
                                    </span>
                                </div>
                            </div>
                            <button className={`${styles.packageSubmitButton} btn btn-theme`}
                                    disabled={addToCartDisabled}
                                    onClick={() => this.addToCart()}>
                                <Translation text={'mentorProfile.mentorConsultationPackages.packageSubmitButton'}/>
                            </button>
                        </div>
                    </div>

                </div>
            </details>
        );
    }

    private addToCart = (): void => {
        if (this.props.isCartLoading) {
            return;
        }

        this.props.addItemToCart(this.props.consultation);
    };

    private checkAvailableFreeConsultations = () => {
        const isTrialConsultation = hasTrialConsultation(this.props.consultation);

        if (!this.props.mentorId || !isTrialConsultation) {
            return;
        }

        this.subscriptions.push(checkAvailableFreeConsultationAPI(this.props.authToken, this.props.mentorId).pipe(
            tap((resp: { [key: string]: any }) => this.setState({isTrialConsultationAvailable: resp.accessible})),
            catchError((error: any) => {
                this.alertManager.handleApiError(error);
                return of();
            })
        ).subscribe());
    };
}

export default connect(
    (state: RootState) => ({
        isCartLoading: isCartLoadingSelector(state),
        authToken: authTokenSelector(state)
    }),
    {
        addItemToCart
    }
)(ConsultationPackageItem);
