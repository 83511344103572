import {FormControlType, IFormConfig, isNotNullOrUndefined} from 'educat-common-web';

export const cartQuantityFormConfig: typeof IFormConfig = (disabled: boolean, maxValue?: number) => ({
    controlType: 'group',
    class: '',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'price',
            class: 'row',
            controls: {
                quantity: {
                    controlType: 'control',
                    formControlType: FormControlType.QUANTITY_INPUT,
                    hostClass: 'col-xl-12',
                    isLabelHidden: true,
                    minValue: 0,
                    maxValue: isNotNullOrUndefined(maxValue) ? maxValue : null,
                    disabled,
                }
            }
        }
    ]
});
