import {
    getMentorConsultationService,
    IModelMentor,
    isEmpty,
    isNullOrUndefined,
    isSameValue,
    Translation,
    YouTubeVideo
} from 'educat-common-web';
import React from 'react';
import CalendarAddItemToCart from '../../../Common/CalendarAddItemToCart';


interface IConnectedMentorItemSpecificInfoProps {
}

interface IExternalMentorItemSpecificInfoProps {
    readonly mentor: typeof IModelMentor;
    readonly isTutor?: boolean;
}

interface IMentorItemSpecificInfoProps
    extends IConnectedMentorItemSpecificInfoProps,
        IExternalMentorItemSpecificInfoProps {
}

interface IMentorItemSpecificInfoState {
    readonly isCalendarInfo: boolean;
    readonly canHaveCalendarInfo: boolean;
}

class MentorItemSpecificInfo extends React.Component<IMentorItemSpecificInfoProps, IMentorItemSpecificInfoState> {

    constructor(props: IMentorItemSpecificInfoProps) {
        super(props);

        this.state = {
            isCalendarInfo: true,
            canHaveCalendarInfo: true,
        };
    }

    componentDidMount() {
        this.updateTabs();
    }

    componentDidUpdate(prevProps: Readonly<IMentorItemSpecificInfoProps>, prevState: Readonly<IMentorItemSpecificInfoState>, snapshot?: any) {
        if (!isSameValue(this.props.mentor, prevProps?.mentor)) {
            this.updateTabs();
        }
    }

    render() {
        const calendarVideoButtonTranslation = this.props.isTutor
            ? 'tutors.tutorItem'
            : 'mentors.mentorItem';

        return (
            <div className={`col-md-6 col-12 mentor-calendar-column ${(this.isVideoTab && !this.canDisplayVideo) ? 'no-video' : ''}`}>
                <button type="button" className="calendar-toggler-button" onClick={this.toggleDescriptionType}>
                    <span className={`toggler-button-option ${this.isCalendarTab ? 'active' : ''}`}>
                        <Translation text={`${calendarVideoButtonTranslation}.calendar`}/>
                    </span>

                    <span className={`toggler-button-option ${this.isVideoTab ? 'active' : ''}`}>
                        <Translation text={`${calendarVideoButtonTranslation}.video`}/>
                    </span>
                </button>
                {this.renderMentorCalendar()}
            </div>
        );
    }

    private get isCalendarTab(): boolean {
        return this.state.canHaveCalendarInfo && this.state.isCalendarInfo;
    }

    private get isVideoTab(): boolean {
        return !this.isCalendarTab;
    }

    private get canDisplayVideo(): boolean {
        return !isEmpty(this.props.mentor.videoLink);
    }

    private renderMentorCalendar = () => {
        if (this.isVideoTab) {
            return this.canDisplayVideo ? (
                    <div className="video-wrapper">
                        <div className="video">
                            <YouTubeVideo youtubeLink={this.props.mentor.videoLink}/>
                        </div>
                    </div>
                ) :
                <div className="no-video-notice">
                    <Translation text="mentors.mentorItem.noVideo"/>
                </div>;
        }

        return <CalendarAddItemToCart mentor={this.props.mentor}
                                      buttonRenderer={(disabled, addToCart) =>
                                          <button className={`apply-button btn btn-theme ${disabled ? 'btn-disabled' : ''}`}
                                                  disabled={disabled}
                                                  onClick={addToCart}>
                                              <Translation text="mentors.mentorItem.applyForConsultation"/>
                                          </button>}/>;
    };

    private toggleDescriptionType = (): void => {
        if (!this.state.canHaveCalendarInfo) {
            return;
        }
        this.setState({
            isCalendarInfo: !this.state.isCalendarInfo
        });
    };

    private updateTabs = (): void => {
        const consultation = getMentorConsultationService(this.props?.mentor);
        if (isNullOrUndefined(consultation)) {
            this.setState({
                canHaveCalendarInfo: false,
                isCalendarInfo: false,
            });
        }
    };
}

export default MentorItemSpecificInfo;
