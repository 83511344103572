import React, {Component} from 'react';
import {RouteComponentProps} from "react-router";
import {authTokenSelector, changeBreadcrumbs, getOnlineConsultationAPI, VideoChat, UserRole, isNotNullOrUndefined} from 'educat-common-web';
import {connect} from "react-redux";
import {RootState} from "../../store/reducers";
import {Subscription} from "rxjs";
import {map} from "rxjs/operators";


interface IConnectedVideoChatHostProps {
    readonly authToken: string | null;
    readonly changeBreadcrumbs: typeof changeBreadcrumbs;
}

interface IExternalVideoChatHostProps {}

interface IVideoChatHostProps extends
    IConnectedVideoChatHostProps,
    IExternalVideoChatHostProps,
    RouteComponentProps {}

interface IVideoChatHostState {
    onlineConsultation: {[key: string]: any} | null;
    userSecret: string | null;
    userId: string | null;
    userToken: string | null;
}

class VideoChatHost extends Component<IVideoChatHostProps, IVideoChatHostState> {
    readonly subscriptions: Subscription[] = [];

    constructor(props: IVideoChatHostProps) {
        super(props);

        this.state = {
            onlineConsultation: null,
            userSecret: null,
            userId: null,
            userToken: null,
        }
    }


    componentDidMount(): void {
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
            {labelKey: 'breadcrumbs.videoChat', path: '/panel/consultations'}
        ]);

        const consultationId = (this.props.match.params as any).id;
        if (consultationId) {
            this.getConsultationDetails(consultationId);
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription => subscription.unsubscribe()));
    }

    render() {
        return (
            <VideoChat onlineConsultation={this.state.onlineConsultation}
                       userSecret={this.state.userSecret}
                       userRole={UserRole.APPLICANT}
                       userId={this.state.userId}
                       userToken={this.state.userToken}/>
        );
    }

    private getConsultationDetails = (consultationId: string) => {
        return this.subscriptions.push(
            getOnlineConsultationAPI(consultationId, null, this.props.authToken).pipe(
                map((resp: any) => {
                    const applicantData = resp.participants.filter((participant: {[key: string]: any}) => participant.role === UserRole.APPLICANT),
                        userSecret = applicantData[0].accessSecret,
                        userId = isNotNullOrUndefined(applicantData[0].numericId) ? applicantData[0].numericId : applicantData[0].id,
                        userToken = applicantData[0].agoraToken;

                    this.setState({
                        onlineConsultation: resp,
                        userSecret: userSecret,
                        userId: userId,
                        userToken: userToken,
                    })
                })
            ).subscribe()
        )
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {
        changeBreadcrumbs
    }
)(VideoChatHost);
