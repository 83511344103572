import {
    authTokenSelector,
    changeBreadcrumbs,
    CustomCard,
    deepCloneObject,
    isNotNullOrUndefined,
    Loader,
    LoaderType,
    Translation
} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {of, Subscription} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {getTestRealisationAPI} from '../../../api/getTestRealizations';
import {submitCompetenceFormAPI} from '../../../api/submitCompetenceForm';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import {IAlertManagerService} from '../../../service/alertManagerService';
import {RootState} from '../../../store/reducers';
import CompetenceForm from './CompetenceTestForm';
import CompetenceTestSideNav from './CompetenceTestSideNav';
import CompetenceTestTimer from './CompetenceTestTimer';
import NotificationModal from './NotificationModal';
import {WithTranslation, withTranslation} from "react-i18next";

interface IConnectedCompetenceTestHostProps {
    readonly authToken: string;
    readonly changeBreadcrumbs: typeof changeBreadcrumbs;
}

interface ICompetenceTestHostProps extends IConnectedCompetenceTestHostProps, RouteComponentProps, WithTranslation {
}

interface ICompetenceTestHostState {
    isProcessing: boolean;
    isFormValid: boolean;
    competenceTest: any;
    selectedTestSectionIndex: number;
    rawFormValue: any;
    isNotificationModalVisible: boolean;
    formSubmittedProperly: boolean;
}

class CompetenceTestHost extends React.Component<ICompetenceTestHostProps, ICompetenceTestHostState> {
    private subscriptions: Subscription[] = [];
    private authToken: string | null = null;
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: ICompetenceTestHostProps) {
        super(props);

        this.state = {
            isProcessing: false,
            isFormValid: true,
            competenceTest: null,
            selectedTestSectionIndex: 0,
            rawFormValue: {},
            isNotificationModalVisible: false,
            formSubmittedProperly: false,
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.authToken = this.props.authToken;
        window.onbeforeunload = this.handleWindowBeforeUnload;
        const routeState: any = this.props.location.state;
        let id: string | null | undefined;
        if (routeState && isNotNullOrUndefined(routeState.name) && isNotNullOrUndefined(routeState.id)) {
            this.changeBreadcrumbs(routeState.name);
            id = routeState.id;
        } else {
            const matches = /\/panel\/.+\/(.+)$/.exec(this.props.location.pathname);
            id = matches?.[1];
        }

        this.subscriptions.push(
            getTestRealisationAPI(this.props.authToken, id as string)
                .pipe(
                    tap((resp: any) => {
                        this.setState({competenceTest: resp});
                        this.changeBreadcrumbs(resp.serviceInstance.serviceDefinition.name);
                    }),
                    catchError((error: any) => {
                        this.alertManager?.handleApiError(error.response);
                        return of(error);
                    })
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<ICompetenceTestHostProps>) {
        if (this.props.authToken !== prevProps?.authToken) {
            this.authToken = this.props.authToken;
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        if (!this.state.formSubmittedProperly) {
            this.submitTest(true);
        }
        window.onbeforeunload = null;
    }

    render() {
        if (!this.state.competenceTest) {
            return null;
        }
        const sections = this.state.competenceTest.testRealisationQuestions,
            testName = this.state.competenceTest.serviceInstance.serviceDefinition.name;

        return (
            <section className="row section competence-test ">
                <Loader type={LoaderType.Global} showLoader={this.state.isProcessing}/>
                <div className="col-md-12 col-xl-8">
                    <CustomCard showLocalLoader={false}>
                        <CustomCard.Header>
                            <div className="header-section">
                                <h1 className="header-title">
                                    <Translation text="competence.competenceTest.title"/>
                                    {testName}
                                </h1>
                                <p className="header-description">
                                    <Translation text={'competence.competenceTest.description'} config={{testName: testName}}/>
                                </p>
                            </div>
                        </CustomCard.Header>
                        <CustomCard.Body>{this.renderFormSection(sections[this.state.selectedTestSectionIndex])}</CustomCard.Body>
                        <CustomCard.Footer>{this.renderNavButtons(this.state.selectedTestSectionIndex, sections.length - 1)}</CustomCard.Footer>
                    </CustomCard>
                </div>
                <div className="col-md-12 col-xl-4">
                    <CompetenceTestTimer startedAt={this.state.competenceTest.createdAt}
                                         maximumTime={this.state.competenceTest.serviceSubjectTest.maximumTime}/>
                    <CompetenceTestSideNav
                        sections={sections}
                        selectedSectionIndex={this.state.selectedTestSectionIndex}
                        selectedSectionIndexChange={(index: number) => this.selectedSectionIndexChange(index)}
                    />
                </div>
                {this.state.isNotificationModalVisible ? (
                    <NotificationModal
                        isModalVisible={this.state.isNotificationModalVisible}
                        closeModal={() => this.closeNotificationModal()}
                        confirmSubmit={() => this.submitConfirmed()}
                    />
                ) : null}
            </section>
        );
    }

    private renderFormSection(section: any) {
        if (this.state.isProcessing) {
            return <div className="loading-dummy-page"/>;
        }
        if (!section || section.length === 0) {
            return null;
        }

        return (
            <CompetenceForm updateStepValue={this.updateRawFormValue} section={section}
                            stepData={this.isolatedStepValue()}/>
        );
    }

    private renderNavButtons(currentIndex: number, lastIndex: number) {
        const isLastPage = currentIndex === lastIndex,
            isFirstPage = currentIndex === 0;

        return (
            <div className={`d-flex justify-content-${isFirstPage ? 'end' : 'between'}`}>
                {!isFirstPage && (
                    <button type="button" onClick={() => this.changeStep(false)} className="btn btn-theme btn-back">
                        <Translation text="buttons.back"/>
                    </button>
                )}
                {isLastPage ? (
                    <button type="submit" onClick={() => this.submitTestWithNotification()} className="btn btn-theme btn-rounded">
                        <Translation text="buttons.endTest"/>
                    </button>
                ) : (
                    <button type="button" onClick={() => this.changeStep(true)} className="btn btn-theme btn-rounded">
                        <Translation text="buttons.next"/>
                    </button>
                )}
            </div>
        );
    }

    private submitTestWithNotification() {
        if (!this.state.rawFormValue || !this.state.rawFormValue.competenceTest || this.state.rawFormValue.competenceTest.length === 0) {
            return null;
        }
        let allQuestionsNumber = 0;
        const selectedAnswersNumber = Object.keys(this.state.rawFormValue.competenceTest).length;
        this.state.competenceTest.testRealisationQuestions.forEach(
            (section: any) => (allQuestionsNumber = allQuestionsNumber + section.testRealisationQuestions.length)
        );
        if (selectedAnswersNumber < allQuestionsNumber) {
            return this.setState({isNotificationModalVisible: true});
        }
        this.submitTest();
    }

    private closeNotificationModal() {
        this.setState({isNotificationModalVisible: false});
    }

    private submitConfirmed() {
        this.setState({isNotificationModalVisible: false});
        this.submitTest();
    }

    private handleWindowBeforeUnload = (): string => {
        this.submitTest(true);

        const {t} = this.props;
        return t('competence.competenceTest.pageReloadMessage');
    };

    private submitTest(onClose?: boolean): void {
        if (!this.state.rawFormValue || !this.state.rawFormValue.competenceTest || this.state.rawFormValue.competenceTest.length === 0 || !this.authToken) {
            return;
        }
        const formValues = deepCloneObject(this.state.rawFormValue.competenceTest);
        let answerArray: any[] = [];
        Object.entries(formValues).forEach(([key, value]) => {
            if (isNotNullOrUndefined(value)) {
                answerArray.push({
                    testRealisationQuestionId: key,
                    answerId: value,
                });
            }
            return;
        });

        if (!onClose) {
            this.setState({isProcessing: true});
        }
        this.subscriptions.push(
            submitCompetenceFormAPI(this.authToken, this.state.competenceTest.id, answerArray)
                .pipe(
                    tap((resp: any) => {
                        if (!onClose) {
                            this.alertManager?.addAlert('competence.alerts.competenceTestSubmitted');
                            this.setState({isProcessing: false, formSubmittedProperly: true});
                            this.props.history.push(`/panel/diagnostics/result/${resp.id}`);
                        }
                    }),
                    catchError((error: any) => {
                        if (!onClose) {
                            this.setState({isProcessing: false});
                            this.alertManager?.handleApiError(error.response);
                        }
                        return of();
                    })
                )
                .subscribe()
        );
    }

    private selectedSectionIndexChange(index: number) {
        if (this.state.selectedTestSectionIndex === index) {
            return null;
        }
        this.setState({selectedTestSectionIndex: index});
    }

    private isolatedStepValue = (): any => {
        const value = this.state?.rawFormValue?.['competenceTest'];

        return isNotNullOrUndefined(value) ? deepCloneObject(value) : null;
    };

    private updateRawFormValue = (sectionId: string, stepValue: any, formValid: boolean) => {
        const updatedRawFormValue = deepCloneObject(this.state.rawFormValue);
        updatedRawFormValue['competenceTest'] = deepCloneObject(stepValue);

        this.setState({rawFormValue: updatedRawFormValue, isFormValid: formValid});
    };

    private changeStep = (next: boolean) => {
        const updatedStepIndex = next ? this.state.selectedTestSectionIndex + 1 : this.state.selectedTestSectionIndex - 1;
        this.setState(
            {
                selectedTestSectionIndex: updatedStepIndex,
            },
            () => {
                window.scrollTo(0, 0);
            }
        );
    };

    private changeBreadcrumbs = (name: string): void => {
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
            {labelKey: 'breadcrumbs.competence', path: '/panel/diagnostics'},
            {labelKey: name, path: ''},
        ]);
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
    }),
    {
        changeBreadcrumbs,
    }
)(withRouter(withTranslation()(CompetenceTestHost)));
