import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export function submitCompetenceFormAPI(authToken: string, testRealisationId: string, answers: any): Observable<any> {
    const payload = {chosenAnswers: answers}
    return educatAPI.put(
        `test_realisations/${testRealisationId}/chose_answers`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
