import React from 'react';
import parentStyles from '../styles.module.scss';
import styles from './styles.module.scss';
import {CustomCard, IModelMentor, isNotNullOrUndefined, Translation} from 'educat-common-web';
import {Link, NavLink} from 'react-router-dom';
import MentorCard from '../MentorCard';

interface IExternalMentorsProps {
    readonly isTutor: boolean;
    readonly mentors: typeof IModelMentor[] | null;
}

interface IMentorsProps extends IExternalMentorsProps {
}

interface IMentorsState {
}

class Mentors extends React.Component<IMentorsProps, IMentorsState> {
    render() {
        if (this.props.isTutor) {
            // Hidden in current version
            return null;
        }
        const linkTo = this.props.isTutor ? '/panel/tutors' : '/panel/mentors';

        return (<CustomCard showLocalLoad r={false}>
                <CustomCard.Header>
                    <h2 className="custom-card-title">
                        <NavLink to={linkTo} className="card-title-link" exact={true}>
                            <Translation text={this.props.isTutor ? 'dashboard.tutors.title' : 'dashboard.mentors.title'}/>
                        </NavLink>
                    </h2>
                </CustomCard.Header>
                <CustomCard.Body>
                    <div className="pb-5">
                        <Translation text={this.props.isTutor ? 'dashboard.tutors.description' : 'dashboard.mentors.description'}/>
                    </div>
                    <div className={styles.mentorsGrid}>
                        {isNotNullOrUndefined(this.props.mentors) && (this.props.mentors as typeof IModelMentor[]).map(mentor =>
                            <MentorCard isTutor={this.props.isTutor} mentor={mentor} key={mentor.id}/>
                        )}
                    </div>
                    <div className={`button-wrapper ${parentStyles.btnWrapper}`}>
                        <Link className="btn btn-theme" to={linkTo}>
                            <Translation text="buttons.book"/>
                        </Link>
                    </div>
                </CustomCard.Body>
            </CustomCard>
        );
    }
}

export default Mentors;
