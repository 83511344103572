import {
    accountLoadingSelector,
    accountSelector,
    addApplicantSurvey,
    authTokenSelector,
    CustomCard,
    CustomCardType,
    deepCloneObject,
    diffKeys,
    filterValuesSelector,
    Form,
    FormControlChangeType,
    IAccount,
    IApplicantBaccalaureateSubject,
    IApplicantBaccalaureateSubjectDTO,
    IFormConfig,
    IMultiselectOption,
    isNotEmpty,
    isNotNullOrUndefined,
    isNullOrUndefined,
    isSameCollection,
    isSameValue,
    IUpdateApplicationData,
    removeExtraValueKeys,
    RestQueryParams,
    SchoolType,
    Translation,
    updateApplicationData
} from 'educat-common-web';
import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {BehaviorSubject, forkJoin, Observable, of, Subscription} from 'rxjs';
import {catchError, filter, map, tap} from 'rxjs/operators';
import {getSchoolAPI} from '../../../api/getSchool';
import {getSchoolsAPI} from '../../../api/getSchools';
import {getSchoolStudyFieldAPI} from '../../../api/getSchoolStudyField';
import {getSchoolStudyFieldsAPI} from '../../../api/getSchoolStudyFields';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import {IAlertManagerService} from '../../../service/alertManagerService';
import {RootState} from '../../../store/reducers';
import {profileFormConfig, VOLATILE_PROFILE_FORM_KEYS} from './profileFormConfig';


export enum StudyLevel {
    BACHELOR = 'bachelor',
    MASTER = 'master',
    PREPARATION = 'preparation',
    OTHER = 'other'
}

export enum BaccalaureateType {
    POLISH_BACCALAUREATE = 'polish_baccalaureate',
    INTERNATIONAL_BACCALAUREATE = 'international_baccalaureate',
    CUSTOM = 'custom'
}

export enum BaccalaureateSubjectType {
    PRIMARY = 'primary',
    EXTENDED = 'extended'
}

interface IChoicesCollectionUpdateMetadata<T = any> {
    readonly collection: T[];
    readonly changed: boolean | null;
}

interface IConnectedProfileFormProps {
    readonly authToken: string;
    readonly filterValues: any;
    readonly account: typeof IAccount;
    readonly isLoading: boolean;
    readonly updateApplicationData: typeof updateApplicationData;
    readonly addApplicantSurvey: typeof addApplicantSurvey;
}

interface IExternalProfileFormProps {
    subjects: typeof IMultiselectOption[] | [];
    isProfileFormShortened?: boolean;
}

interface IProfileFormProps extends IConnectedProfileFormProps,
    IExternalProfileFormProps,
    WithTranslation {
}

interface IProfileFormState {
    value: any;
    formConfig: typeof IFormConfig | null;
    baccalaureateSubjectsNumber: number;
    isConfigLoading: boolean;
    isDataLoading: boolean | null;
    schoolValues: typeof IMultiselectOption[];
    collegeValues: typeof IMultiselectOption[];
    schoolStudyFieldValues: typeof IMultiselectOption[];
    preloadedSchoolValue: typeof IMultiselectOption | null;
    preloadedCollegeValue: typeof IMultiselectOption | null;
    preloadedSchoolStudyFieldValue: typeof IMultiselectOption | null;
}


class ProfileForm extends React.Component<IProfileFormProps, IProfileFormState> {
    private subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IProfileFormProps) {
        super(props);
        this.state = {
            value: null,
            formConfig: null,
            baccalaureateSubjectsNumber: 1,
            isConfigLoading: true,
            isDataLoading: null,
            schoolValues: [],
            collegeValues: [],
            schoolStudyFieldValues: [],
            preloadedSchoolValue: null,
            preloadedCollegeValue: null,
            preloadedSchoolStudyFieldValue: null,
        };
        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        if (this.props.account) {
            this.createFormValueFromState();
        }
        this.preloadFormValues();

        this.setFormConfig();

        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.setState({value: data.value})),
            ).subscribe()
        );

        this.configureForm();
    }

    componentDidUpdate(
        prevProps: Readonly<IProfileFormProps>,
        prevState: Readonly<IProfileFormState>,
        snapshot?: any): void {
        this.subscriptions = this.subscriptions.filter(subscription => !subscription.closed);

        if (this.props.account && !isSameValue(this.props.account, prevProps.account)) {
            this.createFormValueFromState();
        }
        this.preloadFormValues();

        this.updateFormConfigIfNecessary(prevProps, prevState);

        this.configureForm(prevState);
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <CustomCard type={this.props.isProfileFormShortened ? CustomCardType.MODAL_CARD : null}
                        showLocalLoader={!this.state.formConfig || this.state.isConfigLoading || (false !== this.state.isDataLoading) || this.props.isLoading}>
                <CustomCard.Body>
                    <h2 className={`secondary-title ${this.props.isProfileFormShortened ? 'big-margin' : ''}`}>
                        {this.props.isProfileFormShortened ?
                            <Translation text={'profile.profileForm.applicantSurveyTitle'}/> :
                            <Translation text={'profile.profileForm.title'}/>
                        }
                    </h2>
                    {this.state.formConfig && <Form config={this.state.formConfig}
                                                    onValueStateChange={this.onValueStateChange}
                                                    value={this.state.value}
                                                    submitForm={this.updateProfileData}
                                                    controlName={'profileForm'}/>}
                </CustomCard.Body>
            </CustomCard>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private configureForm = (prevState?: IProfileFormState) => {
        const hasCountry = isNotEmpty(this.state?.value?.country);
        const updates: ((state: { value: any }) => any)[] = [];

        if (hasCountry && isNotNullOrUndefined(prevState?.value) && this.state.value.country !== (prevState as any).value?.country) {
            updates.push(state => {
                const value = Object.assign({}, state.value);
                value.school = null;
                value.schoolStudyField = null;

                return {
                    collegeValues: [],
                    schoolStudyFieldValues: [],
                    preloadedCollegeValue: null,
                    preloadedSchoolStudyFieldValue: null,
                    value
                };
            });
        }

        const hasSchool = isNotEmpty(this.state?.value?.school);
        if (hasSchool && (!prevState || this.state.value.school !== prevState.value?.school)) {
            let queryParams = new RestQueryParams();
            queryParams = queryParams.add('school.id', this.state.value.school);
            this.getMultiselectsData(getSchoolStudyFieldsAPI(this.props.authToken, queryParams), 'schoolStudyFieldValues');
        }

        if (BaccalaureateType.CUSTOM !== this.state.value?.baccalaureateType &&
            prevState?.value?.baccalaureateType !== this.state.value?.baccalaureateType) {
            updates.push(state => {
                const value = Object.assign({}, state.value);
                delete value['customBaccalaureateType'];

                return {value};
            });
        }
        if (StudyLevel.OTHER !== this.state.value?.studyLevel &&
            prevState?.value?.studyLevel !== this.state.value?.studyLevel) {
            updates.push(state => {
                const value = Object.assign({}, state.value);
                delete value['customStudyLevel'];

                return {value};
            });
        }
        if (updates.length > 0) {
            this.setState(state => {
                let result: any = null;
                let handled = false;
                updates.forEach(update => {
                    result = handled ? update(result) : update(state);
                    handled = true;
                });

                return result;
            });
        }
    };

    private updateFormConfigIfNecessary = (prevProps: Readonly<IProfileFormProps>, prevState: Readonly<IProfileFormState>): void => {
        let newFormConfigNecessary = this.props.filterValues && this.props.filterValues !== prevProps.filterValues;

        if (!newFormConfigNecessary && this.props.subjects && this.props.subjects !== prevProps.subjects) {
            newFormConfigNecessary = true;
        }

        if (!newFormConfigNecessary && !isSameValue(this.state.value, prevState.value)) {
            const diff = diffKeys(this.state.value, prevState.value);
            const changedVolatileKeys = VOLATILE_PROFILE_FORM_KEYS.filter(item => diff.indexOf(item) > -1);
            if (changedVolatileKeys) {
                newFormConfigNecessary = true;
            }
        }

        const schools = this.hasChoicesCollectionChanged('schoolValues', prevState, newFormConfigNecessary);
        if (schools.changed) {
            newFormConfigNecessary = true;
        }

        const colleges = this.hasChoicesCollectionChanged('collegeValues', prevState, newFormConfigNecessary);
        if (colleges.changed) {
            newFormConfigNecessary = true;
        }

        const schoolStudyFields = this.hasChoicesCollectionChanged('schoolStudyFieldValues', prevState, newFormConfigNecessary);
        if (schoolStudyFields.changed) {
            newFormConfigNecessary = true;
        }

        if (!newFormConfigNecessary && !isSameValue(this.state.baccalaureateSubjectsNumber, prevState.baccalaureateSubjectsNumber)) {
            newFormConfigNecessary = true;
        }

        if (newFormConfigNecessary) {
            this.setFormConfig(schools.collection, colleges.collection, schoolStudyFields.collection);
        }
    };

    private setFormConfig = (schools: typeof IMultiselectOption[] = [],
                             colleges: typeof IMultiselectOption[] = [],
                             schoolStudyFields: typeof IMultiselectOption[] = []): void => {
        if (!this.props.filterValues || !this.props.subjects || !this.props.filterValues?.country) {
            return;
        }

        const formConfig = profileFormConfig(
            this.state.value,
            {
                baccalaureateSubjectsNumber: this.state.baccalaureateSubjectsNumber,
                onAdd: () => {
                    this.setState((state) => {
                        // zakomentowane na życzenie klienta, ma być dowolna liczba przedmiotów zdawanych na maturze
                        // if (this.state.baccalaureateSubjectsNumber >= 5) {
                        //     return null;
                        // }

                        return {baccalaureateSubjectsNumber: state.baccalaureateSubjectsNumber + 1};
                    });
                },
                onRemove: (index: number) => {
                    this.setState((state) => {
                        if (state.baccalaureateSubjectsNumber < 2) {
                            return null;
                        }
                        const value = Object.assign({}, state.value);
                        removeExtraValueKeys(value, 'baccalaureateSubject', index);
                        removeExtraValueKeys(value, 'subjectType', index);

                        return {
                            baccalaureateSubjectsNumber: state.baccalaureateSubjectsNumber - 1,
                            value,
                        };
                    });
                },
            },
            this.translateCountries(this.props.filterValues.country),
            this.props.subjects,
            schools,
            colleges,
            schoolStudyFields,
            (value: any, controlName: string) => this.handleMultiselectInputChange(value, controlName),
            this.props.isProfileFormShortened
        );

        this.setState({isConfigLoading: false, formConfig});
    };


    private createFormValueFromState = () => {
        const account = this.props.account,
            applicantSchoolStudyField = account?.profileSchoolStudyFields,
            hasBaccalaureateSubjects = Array.isArray(account.applicantBaccalaureateSubjects),
            value: any = {
                highSchool: account.schools ? account.schools[0]?.id : null,
                country: account.countries ? account.countries[0]?.id : null,
                schoolStudyField: applicantSchoolStudyField?.id,
                school: applicantSchoolStudyField?.school.id,
                studyLevel: account.studyLevel,
                baccalaureateType: account.baccalaureateType,
                studyStartYear: account.studyStartYear,
                origin: account.origin,
                customBaccalaureateType: account.customBaccalaureateType,
            };
        let baccalaureateSubjectsNumber = 1;
        if (hasBaccalaureateSubjects) {
            account.applicantBaccalaureateSubjects.forEach((baccalaureateSubject: typeof IApplicantBaccalaureateSubject, index: number) => {
                value[`baccalaureateSubject${index}`] = baccalaureateSubject.subject.id;
                value[`subjectType${index}`] = baccalaureateSubject.level;
            });
            baccalaureateSubjectsNumber = account.applicantBaccalaureateSubjects.length > 0 ? account.applicantBaccalaureateSubjects.length : 1;
        }

        this.setState({
            baccalaureateSubjectsNumber,
            value,
        });
    };

    private updateProfileData = (): void => {
        const value = this.state.value,
            schools = [],
            countries = [];

        if (value.highSchool) {
            schools.push(value.highSchool);
        }
        if (value.country) {
            countries.push(value.country);
        }

        const payload: typeof IUpdateApplicationData = {
            schools: schools,
            customHighSchool: null,
            countries: countries,
            profileSchoolStudyFieldsId: value.schoolStudyField,
            studyLevel: value.studyLevel,
            baccalaureateType: value.baccalaureateType,
            customBaccalaureate: value.customBaccalaureateType,
            applicantBaccalaureateSubjects: this.getBaccalaureateSubjects(value),
            studyStartYear: value.studyStartYear,
            origin: value.origin
        };

        this.props.isProfileFormShortened ? this.props.addApplicantSurvey(payload) : this.props.updateApplicationData(payload);
    };

    private getBaccalaureateSubjects = (value: any): typeof IApplicantBaccalaureateSubjectDTO[] => {
        const prefix = 'baccalaureateSubject';

        return Object.keys(value)
            .filter(key => key.startsWith(prefix))
            .map(key => {
                const index = key.substr(prefix.length);

                return {
                    subjectId: value[`baccalaureateSubject${index}`],
                    level: value[`subjectType${index}`]
                };
            })
            .filter(entry => isNotNullOrUndefined(entry.subjectId));
    };

    private getMultiselectsData = (api: Observable<any>, stateMultiselectOptions: string) => {
        return this.subscriptions.push(
            api.pipe(
                tap((resp: any) => {
                    const multiselectOptions: { [key: string]: any }[] = (resp['hydra:member'] || [])
                        .map((option: { [key: string]: any }) => ({
                            value: option.id,
                            label: option.studyField.name,
                        }));
                    let update: any = {};
                    update[stateMultiselectOptions] = multiselectOptions;
                    this.setState(update);
                }),
                catchError((error: any) => {
                    this.alertManager?.handleApiError(error);
                    return of(error);
                })
            ).subscribe()
        );
    };

    private handleMultiselectInputChange = (value: string, controlName: string) => {
        if (value.length < 3) {
            return;
        }
        let queryParams = new RestQueryParams();
        queryParams = queryParams.add('name', value);

        if (controlName === 'highSchool') {
            queryParams = queryParams.add('type', SchoolType.HighSchool);

            this.loadSchools(queryParams, schools => this.setState({schoolValues: schools}));
        }

        if (controlName === 'college') {
            queryParams = queryParams.add('countries.id', this.state.value.country);
            queryParams = queryParams.add('type', SchoolType.College);

            this.loadSchools(queryParams, schools => this.setState({collegeValues: schools}));
        }
    };

    private loadSchools = (queryParams: typeof RestQueryParams, onSuccess: (schools: typeof IMultiselectOption[]) => void) => {
        return this.subscriptions.push(
            getSchoolsAPI(this.props.authToken, queryParams).pipe(
                map((resp: any) => resp['hydra:member']
                    .map((school: { [key: string]: any }) => ({
                        value: school.id,
                        label: school.name
                    }))),
                tap(onSuccess)
            ).subscribe()
        );
    };

    private preloadFormValues = (): void => {
        if (null !== this.state.isDataLoading || !this.state.value) {
            return;
        }

        const requests = {
            preloadedSchoolValue: this.state.value.highSchool ?
                getSchoolAPI(this.state.value.highSchool, this.props.authToken).pipe(
                    map((data: any) => ({value: data.id, label: data.name})),
                ) :
                of(null),
            preloadedCollegeValue: this.state.value.school ?
                getSchoolAPI(this.state.value.school, this.props.authToken).pipe(
                    map((data: any) => ({value: data.id, label: data.name})),
                ) :
                of(null),
            preloadedSchoolStudyFieldValue: this.state.value.schoolStudyField ?
                getSchoolStudyFieldAPI(this.state.value.schoolStudyField, this.props.authToken).pipe(
                    map((data: any) => ({value: data.id, label: data.studyField.name})),
                ) :
                of(null)
        };

        this.setState({isDataLoading: true});
        this.subscriptions.push(
            forkJoin(requests).pipe(
                tap((result: any) => {
                    result.isDataLoading = false;

                    this.setState(result);
                })
            )
                .subscribe()
        );
    };

    /**
     * Checks whether a given collection with form choices from State has changed.
     */
    private hasChoicesCollectionChanged<T = any>(stateKey: 'schoolValues' | 'collegeValues' | 'schoolStudyFieldValues', prevState: Readonly<IProfileFormState> | undefined | null, changedCheckRedundant: boolean): IChoicesCollectionUpdateMetadata<T> {

        const extract = (s: Readonly<IProfileFormState> | undefined | null) => {
            const collection: any[] = [];
            if (isNullOrUndefined(s)) {
                return collection;
            }

            const state: any = s;
            collection.push(...(s as any)[stateKey]);
            const preloadedStateKey = `preloaded${stateKey.charAt(0).toUpperCase()}${stateKey.slice(1, stateKey.length - 1)}`;
            if (state[preloadedStateKey] && !collection.some(entry => entry.value === state[preloadedStateKey].value)) {
                collection.push(state[preloadedStateKey]);
            }

            return collection;
        };

        const current = extract(this.state),
            previous = extract(prevState);

        let changed = null;
        if (!changedCheckRedundant) {
            changed = !isSameCollection(current, previous);
        }

        return {
            collection: current,
            changed,
        };
    };

    private translateCountries(countries: typeof IMultiselectOption[]) {
        if (!countries || countries.length === 0) {
            return [];
        }
        const {t} = this.props;
        return deepCloneObject(countries).map((country: typeof IMultiselectOption) => {
            country.label = t(`country.${country.label}`);
            return country;
        })
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        filterValues: filterValuesSelector(state),
        account: accountSelector(state),
        isLoading: accountLoadingSelector(state)
    }),
    {
        updateApplicationData,
        addApplicantSurvey
    }
)(withTranslation()(ProfileForm));
