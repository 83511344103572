import React from 'react';
import {Link} from 'react-router-dom';
import {applicantAppliedListCountSelector, applicantFavouriteListCountSelector, Translation} from 'educat-common-web';
import styles from "./styles.module.scss";
import {FieldOfStudySearchRoute} from '../../MainSearch';
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";


interface IConnectedFieldOfStudyNavigationProps {
    readonly favouriteListCount: number | null;
    readonly appliedListCount: number | null;
}

interface IExternalFieldOfStudyNavigationProps {
    readonly schoolSearchType: FieldOfStudySearchRoute;
}

interface IFieldOfStudyNavigationProps extends IConnectedFieldOfStudyNavigationProps,
    IExternalFieldOfStudyNavigationProps {
}

interface IFieldOfStudyNavigationState {
}


class FieldOfStudyNavigation extends React.Component<IFieldOfStudyNavigationProps, IFieldOfStudyNavigationState> {

    constructor(props: IFieldOfStudyNavigationProps) {
        super(props);

        this.state = {
        }
    }

    render() {
        const favouriteListCount = this.props.favouriteListCount ? this.props.favouriteListCount : 0,
            appliedListCount = this.props.appliedListCount ? this.props.appliedListCount : 0;
        return (
            <div className={`${styles.utilButtonsWrapper} col-xl-12`}>
                {this.props.schoolSearchType !== FieldOfStudySearchRoute.SEARCH &&

                    <Link className={styles.utilButton} to={'/panel/field-of-study-search'}>
                        <i className={`${styles.buttonIcon} feather icon-search`} />
                        <p className={styles.buttonText}>
                            <Translation text="fieldOfStudy.search" />
                        </p>
                    </Link>
                }
                {(this.props.schoolSearchType !== FieldOfStudySearchRoute.FAVOURITE) &&
                    <Link className={styles.utilButton} to={'/panel/field-of-study-search/favourite'}>
                        <i className={`${styles.buttonIcon} ${favouriteListCount > 0 ? styles.activeHeartIcon : 'feather icon-heart'}`} />
                        <p className={styles.buttonText}>
                            <Translation text="fieldOfStudy.favouriteList.title" />
                            <span className={styles.counter}>({favouriteListCount})</span>
                        </p>
                    </Link>
                }
                {(this.props.schoolSearchType !== FieldOfStudySearchRoute.APPLIED) &&
                    <Link className={styles.utilButton} to={'/panel/field-of-study-search/applied'}>
                        <i className={`${styles.buttonIcon} ${appliedListCount > 0 ? styles.activeStarIcon : 'feather icon-star'}`} />
                        <p className={styles.buttonText}>
                            <Translation text="fieldOfStudy.appliedList.title" />
                            <span className={styles.counter}>({appliedListCount})</span>
                        </p>
                    </Link>
                }
            </div>
        );
    }
}

export default connect(
    (state: RootState) => ({
        favouriteListCount: applicantFavouriteListCountSelector(state),
        appliedListCount: applicantAppliedListCountSelector(state),
    }),
    {}
)(FieldOfStudyNavigation);
