import {Observable} from "rxjs";
import {educatAPI, RestQueryParams} from "educat-common-web";

export function createPayuInstallments(paymentId: string, authToken: string | null): Observable<any> {
    let headers: any = {
        accept: 'application/ld+json',
        'content-type': 'application/json',
    };

    if (authToken) {
        headers['Authorization'] = `Bearer ${authToken}`;
    }

    return educatAPI.post(`payment/${paymentId}/payu/installments?XDEBUG_SESSION_START=PHPSTORM`, {}, new RestQueryParams(), headers);
}
