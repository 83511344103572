import {educatAPI, RestQueryParams} from 'educat-common-web';
import {Observable} from 'rxjs';


export function getBookedServiceInstancesAPI(authToken: string | null, serviceInstanceId: string): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return educatAPI.get(
        `service_instances/${serviceInstanceId}/online_consultations`,
        new RestQueryParams(),
        headers
    );
}
