import React, {Component} from 'react';
import {changeBreadcrumbs, CustomCard, IModelServiceConsultationPackage, Translation} from "educat-common-web";
import styles from "../styles.module.scss";
import {getServiceConsultationPackagesAPI} from "../../../api/getServiceConsultationPackages";
import {catchError, map} from "rxjs/operators";
import { of, Subscription } from 'rxjs';
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import ConsultationPackagesTable from "./ConsultationPackagesTable";
import SelectApplicationModal from "../MarketplaceApplications/SelectApplicationModal";
import {MarketplaceServiceType} from "../MarketplaceApplications/SelectApplicationModal/MentorSelect";

interface IExternalMarketplaceTutorsProps {
    readonly authToken: string | null;
    changeBreadcrumbs: typeof changeBreadcrumbs;
}

interface IMarketplaceTutorsProps extends IExternalMarketplaceTutorsProps {
}

interface IMarketplaceTutorsState {
    isLoading: boolean;
    consultationPackages: typeof IModelServiceConsultationPackage[] | [];
    isModalOpen: boolean;
    consultationsAmount: number;
}

class MarketplaceTutors extends Component<IMarketplaceTutorsProps, IMarketplaceTutorsState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IMarketplaceTutorsProps) {
        super(props);

        this.state = {
            isLoading: true,
            consultationPackages: [],
            isModalOpen: false,
            consultationsAmount: 0
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
            {labelKey: 'breadcrumbs.marketplace', path: '/panel/marketplace'},
            {labelKey: 'breadcrumbs.marketplaceTutors', path: '/panel/marketplace/tutors'}
        ]);

        this.subscriptions.push(this.getConsultationPackages());
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <div className="mentor-host">
                <div className="col-xl-10">
                    <header className="mentor-header">
                        <h1 className="mentor-title">
                            <Translation text={'marketplace.tutors.title'}/>
                        </h1>
                        <p className="mentor-subtitle">
                            <Translation text={'marketplace.tutors.description'}/>
                        </p>
                    </header>
                    <div className={styles.marketplaceDetails}>
                        <CustomCard showLocalLoader={this.state.isLoading}>
                            <CustomCard.Body>
                                <ConsultationPackagesTable consultations={this.state.consultationPackages}
                                                           setConsultationAmount={(amount: number) => this.setState({consultationsAmount: amount})}
                                                           toggleModal={() => this.setState({isModalOpen: !this.state.isModalOpen})}/>
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </div>

                {this.state.isModalOpen ?
                    <SelectApplicationModal isModalShown={this.state.isModalOpen}
                                            toggleModal={() => this.setState({isModalOpen: !this.state.isModalOpen})}
                                            consultationsAmount={this.state.consultationsAmount}
                                            type={MarketplaceServiceType.SERVICE_CONSULTATION_PACKAGE}
                    />
                    : null
                }
            </div>
        );
    }

    private getConsultationPackages = () => {
        return getServiceConsultationPackagesAPI(this.props.authToken).pipe(
            map((resp: any) => {
                this.setState({isLoading: false, consultationPackages: resp['hydra:member'] || []});
            }),
            catchError((error: any) => {
                this.setState({isLoading: false});
                this.alertManager?.handleApiError(error);
                return of();
            })
        ).subscribe()
    }
}

export default MarketplaceTutors;
