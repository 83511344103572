import React from 'react';
import {CustomCard, Translation} from 'educat-common-web';
import ApplicationWizard from '../ApplicationWizard';

interface IExternalApplicationProgressProps {
}

interface IApplicationProgressProps extends IExternalApplicationProgressProps {
}

interface IApplicationProgressState {
}

class ApplicationProgress extends React.Component<IApplicationProgressProps, IApplicationProgressState> {
    render() {
        return (
            <CustomCard showLocalLoader={false}>
                <CustomCard.Header>
                    <h2 className="custom-card-title">
                        <p className="card-title">
                            <Translation text="dashboard.applicationProgress.title"/>
                        </p>
                    </h2>
                </CustomCard.Header>
                <CustomCard.Body>
                    <div className="pb-5">
                        <Translation text="dashboard.applicationProgress.description"/>
                    </div>
                    <ApplicationWizard/>
                </CustomCard.Body>
            </CustomCard>
        );
    }
}

export default ApplicationProgress;
