import {CustomCard, IModelMentor, Translation, UserDisplayName,} from 'educat-common-web';
import React from 'react';
// import ReactStars from 'react-rating-stars-component';
import parse from 'html-react-parser';
import BasePrice from '../../Common/BasePrice';
import {MentorType} from '../../MentorsList';
import MentorProfileSchoolDetails from '../MentorProfileSchoolDetails';
import AccountImage from '../../../Common/AccountImage';


export enum MentorStatus {
    GRADUATE = 'graduate',
    STUDENT = 'student'
}

interface IExternalMentorDetailsCardProps {
    mentor: typeof IModelMentor;
}

interface IMentorDetailsCardProps extends IExternalMentorDetailsCardProps {
}

interface IMentorDetailsCardState {
    isFullDetails: boolean;
}


class MentorDetailsCard extends React.Component<IMentorDetailsCardProps, IMentorDetailsCardState> {

    constructor(props: IMentorDetailsCardProps) {
        super(props);
        this.state = {
            isFullDetails: false
        };
    }

    render() {
        const mentor = this.props.mentor,
            realms: any = [];

        if (mentor.realms && mentor.realms.length) {
            mentor.realms.map((realm: { [key: string]: any }) => {
                return realms.push(realm.name);
            })
        }

        return (
            <CustomCard>
                <div className="mentor-details-section-wrapper row">
                    <div className="mentor-img-wrapper col-xl-3">
                        <AccountImage account={mentor.account} type={'mediumThumb'} />
                    </div>
                    <div className="mentor-details-wrapper col-xl-9">
                        <MentorProfileSchoolDetails mentor={mentor}/>
                        <div className="main-title">
                            <div className="name-wrapper name-rating">
                                {this.getMentorType(mentor.type)}
                                <UserDisplayName account={mentor.account}/>
                            </div>
                            {/*Hidden in current version*/}
                            {/*<div className="rating">*/}
                            {/*    <ReactStars*/}
                            {/*        count={5}*/}
                            {/*        value={this.props.mentor.rating}*/}
                            {/*        edit={false}*/}
                            {/*        emptyIcon={<i className="star-icon empty"/>}*/}
                            {/*        filledIcon={<i className="star-icon full"/>}*/}
                            {/*        size={24}*/}
                            {/*        activeColor="#fa9504"*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </div>

                        <p className="paragraph">
                            <Translation text={'mentorProfile.mentorDetails.languages'}/>
                            {mentor.languages && mentor.languages.length ?
                                mentor.languages.map((language: { [key: string]: any }, index: number) => (
                                    <React.Fragment key={language.id}>
                                        <Translation text={`language.${language.id}`}/>
                                        {this.props.mentor.languages.length === (index + 1) ? '' : ', '}
                                    </React.Fragment>
                                )) :
                                null}
                            {this.renderMentorStatus()}
                        </p>
                        <div className="sub-paragraph">
                            {parse(mentor.longDescription)}
                            <button className={`toggle-details-button ${this.state.isFullDetails ? 'd-none' : ''}`}
                                    type="button"
                                    onClick={() => this.toggleDetailsShown()}>
                                <Translation text={`mentorProfile.mentorDetails.showMore`}/>
                            </button>
                        </div>

                        <div className={`more-details-wrapper ${this.state.isFullDetails ? '' : 'd-none'}`}>
                            <p className="paragraph">
                                <Translation text={`mentorProfile.mentorDetails.fields`}/>
                            </p>
                            <div className="subjects">
                                {realms.length ? (realms.map((item: string, index: number) => {
                                    return (
                                        <div className="subject-item" key={index}>
                                            <p>{item}</p>
                                        </div>
                                    )
                                })) : null}
                            </div>

                            <p className="paragraph">
                                <Translation text={`mentorProfile.mentorDetails.workStyle`}/>
                            </p>
                            <div className="sub-paragraph">
                                {parse(mentor.programme)}
                                <button className={`toggle-details-button ${this.state.isFullDetails ? '' : 'd-none'}`}
                                        type="button"
                                        onClick={() => this.toggleDetailsShown()}>
                                    <Translation text={`mentorProfile.mentorDetails.showLess`}/>
                                </button>
                            </div>
                        </div>

                        <BasePrice mentor={this.props.mentor} isListPrice={false}/>
                    </div>
                </div>
            </CustomCard>
        );
    }

    private renderMentorStatus = () => {
        let mentor = this.props.mentor;
        if (!mentor) {
            return;
        }

        let status: string | null = mentor.status,
            statusDescription: string | null = mentor.statusDescription;

        return (
            <React.Fragment>
                {status ?
                    (<>
                        <span className="divider"/>
                        <Translation text={`mentorProfile.mentorDetails.mentorGraduationTitle.${status}`}/>
                    </>) :
                    null
                }
                {status === MentorStatus.STUDENT && statusDescription ? ` - ${statusDescription}` : null}
            </React.Fragment>
        )
    };

    private toggleDetailsShown = () => {
        const toggledDetailsState = !this.state.isFullDetails;
        this.setState({isFullDetails: toggledDetailsState});
    };

    private getMentorType = (type: MentorType) => {
        switch (type) {
            case MentorType.MENTOR:
                return <Translation text={`mentorProfile.mentorDetails.mentorType.mentor`}/>;
            case MentorType.MENTOR_SCHOLAR:
                return <Translation text={`mentorProfile.mentorDetails.mentorType.mentorScholar`}/>;
            case MentorType.SCHOLAR:
                return <Translation text={`mentorProfile.mentorDetails.mentorType.scholar`}/>;

            default:
                return;
        }
    }
}

export default MentorDetailsCard;
