import {
    authTokenSelector,
    getMentorConsultationService,
    IModelCartOnlineConsultation,
    IModelMentor,
    isNotNullOrUndefined,
    isNullOrUndefined
} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import MentorWeekCalendar from '../../MentorsList/MentorItem/MentorWeekCalendar';
import {RootState} from '../../../../store/reducers';
import {isCartProcessingSelector} from '../../../../store/selectors/cartSelectors';
import {addItemToCart} from '../../../../store/reducers/cartSlice';


interface IConnectedCalendarAddItemToCartProps {
    readonly authToken?: string;
    readonly isCartProcessing: boolean;
    readonly addItemToCart: typeof addItemToCart;
}

interface IExternalCalendarAddItemToCartProps {
    readonly mentor: typeof IModelMentor;
    readonly buttonRenderer: (disabled: boolean, addToCart: () => void) => React.ReactNode;
}

interface ICalendarAddItemToCartProps
    extends IConnectedCalendarAddItemToCartProps,
        IExternalCalendarAddItemToCartProps {
}

interface ICalendarAddItemToCartState {
    readonly startDate: Date;
    readonly selectedDate: Date | null;
}

class CalendarAddItemToCart extends React.Component<ICalendarAddItemToCartProps, ICalendarAddItemToCartState> {

    constructor(props: ICalendarAddItemToCartProps) {
        super(props);

        this.state = {
            startDate: new Date(),
            selectedDate: null,
        };
    }

    render() {
        const calendarId = this.props.mentor?.account?.calendars?.[0]?.id;
        const calendarDetails = this.props.mentor?.account?.calendars[0];
        if (isNullOrUndefined(calendarId)) {
            return null;
        }

        return (
            <>
                <div>
                    <MentorWeekCalendar startDate={this.state.startDate}
                                        calendarId={calendarId}
                                        onDateSelected={this.onCalendarDateSelected}
                                        calendarDetails={calendarDetails}/>
                </div>
                {this.props.buttonRenderer(!this.canAddToCart, this.addToCart)}
            </>
        );
    }

    private get canAddToCart(): boolean {
        if (this.props.isCartProcessing || isNullOrUndefined(this.state.selectedDate)) {
            return false;
        }
        const consultation = getMentorConsultationService(this.props?.mentor);

        return isNotNullOrUndefined(consultation) && consultation.active;
    }

    private onCalendarDateSelected = (date?: Date): void => {
        this.setState({
            selectedDate: isNotNullOrUndefined(date) ? date as Date : null,
        });
    };

    private addToCart = (): void => {
        const date = this.state.selectedDate;
        if (!this.canAddToCart) {
            return;
        }

        const consultation = getMentorConsultationService(this.props.mentor);
        const consultationDate: typeof IModelCartOnlineConsultation = {
            from: (date as Date).toISOString(),
            to: moment(date).add(1, 'hour').toISOString(),
        };
        this.props.addItemToCart(
            consultation,
            {
                onlineConsultationDates: [
                    consultationDate
                ]
            }
        );
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        isCartProcessing: isCartProcessingSelector(state),
    }),
    {
        addItemToCart
    }
)(CalendarAddItemToCart);
