import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {PayloadAction} from '@reduxjs/toolkit';
import {
    addAlert,
    AlertType,
    authTokenSelector,
    deepCloneObject,
    getServiceDefinitionType,
    IModelCart,
    isNotNullOrUndefined,
    isNullOrUndefined,
    ModelPurchasableService,
    ModelServiceDefinitionType
} from 'educat-common-web';
import {
    addItemToCart,
    changeIsCartLoading,
    getCartData,
    IAddItemToCart,
    IRemoveCartItem,
    IUpdateCartApplicationPackageSchoolStudyFields,
    IUpdateCartItemQuantity,
    IUpdateCartOnlineConsultationDates,
    removeCartItem,
    setCartState,
    setCartStateFailure,
    updateCartApplicationPackageSchoolStudyFields,
    updateCartItemQuantity,
    updateCartOnlineConsultationDates
} from '../reducers/cartSlice';
import {getCurrentCartAPI} from '../../api/getCurrentCart';
import {addCartItemsAPI, ICartItemDefinition, ICartPayloadItem} from '../../api/addCartItems';
import {cartSelector} from '../selectors/cartSelectors';
import {removeCartItemsAPI} from '../../api/removeCartItems';
import {changeCartItemQuantityAPI} from '../../api/changeCartItemQuantity';
import {updateCartItemExtraDataAPI} from '../../api/changeCartItemConsultationDates';

const processWithCart = <T = any>(state$: StateObservable<any>, onSuccess: (cart: typeof IModelCart) => Observable<T>) => {
    const authToken = authTokenSelector(state$.value),
        cart = cartSelector(state$.value),
        cart$ = isNullOrUndefined(cart) ?
            getCurrentCartAPI(authToken) :
            of(cart);

    return cart$.pipe(
        switchMap(cart => {
            if (isNullOrUndefined(cart)) {
                throw new Error('Could not retrieve cart.');
            }

            return onSuccess(cart as typeof IModelCart);
        }),
    );
};

const getCart = (authToken: string) => {
    return getCurrentCartAPI(authToken).pipe(
        mergeMap((cart: typeof IModelCart) => {
            return of(
                changeIsCartLoading(false),
                setCartState(cart)
            );
        }),
        catchError((error: any) => {
            const errorMessage = getErrorMessage(error);

            return of(
                changeIsCartLoading(false),
                setCartStateFailure(errorMessage),
                addAlert({message: errorMessage, type: AlertType.WARNING})
            );
        })
    );
};

const prepareAddItemPayload = (items: IAddItemToCart[]): ICartItemDefinition => {
    const itemDefinitions: ICartPayloadItem[] = items.map((item: IAddItemToCart) => {
        const service: typeof ModelPurchasableService = item.service,
            definitionType = getServiceDefinitionType(service),
            itemDefinition: ICartPayloadItem = {
                definitionId: service.id,
                definitionType
            };
        if (definitionType === ModelServiceDefinitionType.MentorDefinition && isNotNullOrUndefined(item.extraData)) {
            itemDefinition.mentorServiceExtraData = deepCloneObject(item.extraData);
        }

        if (isNotNullOrUndefined(item.genericServiceExtraData)) {
            itemDefinition.genericServiceExtraData = deepCloneObject(item.genericServiceExtraData);
        }

        return itemDefinition;
    });

    return {
        itemDefinitions,
    };
};

const getErrorMessage = (error: any) => {
    let errorMessage;
    if (error.response && error.response.message) {
        errorMessage = error.response.message;
    } else if (error.response && error.response['hydra:description']) {
        errorMessage = error.response['hydra:description'];
    } else {
        errorMessage = 'alerts.baseError';
    }

    return errorMessage;
};

const getCartDetails: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(getCartData.type),
        switchMap((): any => {
            return getCart(authTokenSelector(state$.value));
        })
    );

const updateOnlineConsultationDates: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(updateCartOnlineConsultationDates.type),
        switchMap((action: PayloadAction<IUpdateCartOnlineConsultationDates>) => updateCartItemExtraDataAPI(
            authTokenSelector(state$.value),
            action.payload.serviceInstanceId,
            {
                onlineConsultationDates: action.payload.onlineConsultationDates,
            }
        )),
        mergeMap(cart => {
            return of(
                changeIsCartLoading(false),
                setCartState(cart),
                addAlert({message: 'mentorProfile.mentorConsultationPackages.alerts.consultationDateUpdated'})
            );
        }),
        catchError((error: any) => {
            const errorMessage = getErrorMessage(error);

            return of(
                changeIsCartLoading(false),
                setCartStateFailure(errorMessage),
                addAlert({message: errorMessage, type: AlertType.WARNING})
            );
        }),
    );

const removeItem: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(removeCartItem.type),
        switchMap((action: PayloadAction<IRemoveCartItem>) => {
            return processWithCart(state$, cart => removeCartItemsAPI(
                authTokenSelector(state$.value),
                (cart as typeof IModelCart).id,
                action.payload.serviceInstanceIds
            ));
        }),
        mergeMap(cart => {
            return of(
                changeIsCartLoading(false),
                setCartState(cart),
                addAlert({message: 'mentorProfile.mentorConsultationPackages.alerts.removedFromBasket'})
            );
        }),
        catchError((error: any) => {
            const errorMessage = getErrorMessage(error);
            return of(
                changeIsCartLoading(false),
                setCartStateFailure(errorMessage),
                addAlert({message: errorMessage, type: AlertType.WARNING})
            );
        }),
    );

const addItem: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(addItemToCart.type),
        switchMap((action: PayloadAction<IAddItemToCart>) => {
            return processWithCart(state$, cart => addCartItemsAPI(
                authTokenSelector(state$.value),
                (cart as typeof IModelCart).id,
                prepareAddItemPayload([action.payload])
            )).pipe(
                map(cart => [
                    changeIsCartLoading(false),
                    setCartState(cart),
                    addAlert({message: 'mentorProfile.mentorConsultationPackages.alerts.addedToBasket'}),
                ]),
                catchError((error: any) => {
                    const errorMessage = getErrorMessage(error);

                    return of([
                        changeIsCartLoading(false),
                        setCartStateFailure(errorMessage),
                        addAlert({message: errorMessage, type: AlertType.WARNING}),
                    ]);
                }),
            );
        }),
        mergeMap(actions => of(...actions)),
    );

const updateCartItemQuantityEpic: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(updateCartItemQuantity.type),
        switchMap((action: PayloadAction<IUpdateCartItemQuantity>) => changeCartItemQuantityAPI(
            authTokenSelector(state$.value),
            action.payload.serviceInstanceId,
            action.payload.quantity
        )),
        mergeMap(cart => {
            return of(
                changeIsCartLoading(false),
                setCartState(cart),
                addAlert({message: 'mentorProfile.mentorConsultationPackages.alerts.quantityUpdated'})
            );
        }),
        catchError((error: any) => {
            const errorMessage = getErrorMessage(error);

            return of(
                changeIsCartLoading(false),
                setCartStateFailure(errorMessage),
                addAlert({message: errorMessage, type: AlertType.WARNING})
            );
        }),
    );

const updateCartApplicationPackageSchoolStudyFieldEpic: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(updateCartApplicationPackageSchoolStudyFields.type),
        switchMap((action: PayloadAction<IUpdateCartApplicationPackageSchoolStudyFields>) => updateCartItemExtraDataAPI(
            authTokenSelector(state$.value),
            action.payload.serviceInstanceId,
            {
                schoolStudyFieldsId: action.payload.schoolStudyFieldId,
            }
        )),
        mergeMap(cart => {
            return of(
                changeIsCartLoading(false),
                setCartState(cart),
                addAlert({message: 'mentorProfile.mentorConsultationPackages.alerts.fieldOfStudyUpdated'})
            );
        }),
        catchError((error: any) => {
            const errorMessage = getErrorMessage(error);

            return of(
                changeIsCartLoading(false),
                setCartStateFailure(errorMessage),
                addAlert({message: errorMessage, type: AlertType.WARNING})
            );
        }),
    );

const cartEpic = combineEpics(
    getCartDetails,
    updateOnlineConsultationDates,
    removeItem,
    addItem,
    updateCartItemQuantityEpic,
    updateCartApplicationPackageSchoolStudyFieldEpic
);

export default cartEpic;
