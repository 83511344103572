import React from 'react';
import styles from './styles.module.scss';
import {CustomCard, IModelMentor, isNotNullOrUndefined, Translation} from 'educat-common-web';
import CalendarAddItemToCart from '../CalendarAddItemToCart';

interface IExternalCalendarCardProps {
    isScholar?: boolean;
}

interface ICalendarCardProps extends IExternalCalendarCardProps {
    mentor: typeof IModelMentor;
}

interface ICalendarCardState {
}


class CalendarCard extends React.Component<ICalendarCardProps, ICalendarCardState> {

    render() {
        const titleTranslationKey = this.props.isScholar ? 'tutorProfile.tutorCalendar.title' : 'mentorProfile.mentorCalendar.title';

        return (
            <CustomCard showLocalLoader={false}>
                <CustomCard.Body>
                    <h2 className="secondary-title">
                        <Translation text={titleTranslationKey}/>
                    </h2>

                    <CalendarAddItemToCart mentor={this.props.mentor}
                                           buttonRenderer={(disabled, addToCart) =>
                                               <div className={`button-wrapper centered ${styles.btnWrapper}`}>
                                                   <button className={`btn btn-theme ${disabled ? 'btn-disabled' : ''}`}
                                                           disabled={disabled}
                                                           onClick={addToCart}>
                                                       <Translation text="mentorProfile.mentorCalendar.applyForConsultation"/>
                                                   </button>
                                                   {this.hasPackages && <a href="#consultationPackages" className={styles.link}>
                                                       <Translation text="mentorProfile.mentorCalendar.buyPackage"/>
                                                   </a>}
                                               </div>}/>
                </CustomCard.Body>
            </CustomCard>
        );
    }

    private get hasPackages(): boolean {
        const serviceDefinitions = this.props.mentor?.mentorServiceDefinitions;
        if (!Array.isArray(serviceDefinitions) || 0 === serviceDefinitions.length) {
            return false;
        }

        return 0 !== serviceDefinitions.filter(item => isNotNullOrUndefined(item.serviceConsultationPackage)).length ||
            0 !== serviceDefinitions.filter(item => isNotNullOrUndefined(item.serviceApplicationPackage)).length ||
            0 !== serviceDefinitions.filter(item => isNotNullOrUndefined(item.serviceConsultation)).length;
    }
}

export default CalendarCard;
