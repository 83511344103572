import {
    accountSelector,
    authTokenSelector,
    changeApplicantSchoolStudyFields,
    changeBreadcrumbs,
    CustomCard,
    getScheduleInstancesAPI,
    IModelApplication,
    IModelMentor,
    IModelScheduleInstance,
    Loader,
    StudyFieldsListType,
    Translation
} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {Link, NavLink, RouteComponentProps, withRouter} from 'react-router-dom';
import {Observable, of, Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {IAlertManagerService} from '../../service/alertManagerService';
import {RootState} from '../../store/reducers';
import styles from './styles.module.scss';
import SchoolDetails from './SchoolDetails';
import {changeSchoolStudyFieldListAPI, IChangeSchoolStudyFieldListType} from '../../api/changeSchoolStudyFieldListType';
import {catchError, tap} from 'rxjs/operators';
import Calendar from './Calendar';
import TimetableDetails from './TimetableDetails';
import Greeting from './Greeting';
import ApplicationProgress from './ApplicationProgress';
import Mentors from './Mentors';
import {getMyMentorsAPI} from '../../api/getMyMentors';
import {getMyScholarsAPI} from '../../api/getMyScholars';
import {getApplicationsAPI} from '../../api/getApplications';
import Tests from './Tests';

interface IConnectedDashboardProps {
    readonly account: { [key: string]: any };
    readonly authToken: string;
    readonly changeApplicantSchoolStudyFields: typeof changeApplicantSchoolStudyFields;
    readonly changeBreadcrumbs: typeof changeBreadcrumbs;
}

interface IExternalDashboardProps {
}

interface IDashboardProps extends IConnectedDashboardProps, IExternalDashboardProps, RouteComponentProps {
}

interface IDashboardState {
    readonly isProcessing: boolean;
    readonly mentors: typeof IModelMentor[] | null;
    readonly scholars: typeof IModelMentor[] | null;
    readonly applications: typeof IModelApplication[] | null;
    readonly scheduleInstances: typeof IModelScheduleInstance[] | null;
}

class Dashboard extends React.Component<IDashboardProps, IDashboardState> {
    private readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IDashboardProps) {
        super(props);

        this.state = {
            isProcessing: false,
            mentors: null,
            scholars: null,
            applications: null,
            scheduleInstances: null,
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.props.changeBreadcrumbs([{labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'}]);

        this.loadData(getMyMentorsAPI(this.props.authToken), 'mentors');
        this.loadData(getMyScholarsAPI(this.props.authToken), 'scholars');
        this.loadData(getApplicationsAPI(this.props.authToken), 'applications');
        this.loadData(getScheduleInstancesAPI(this.props.authToken), 'scheduleInstances');
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <section className={styles.dashboard}>
                <Loader showLoader={this.isLoading}/>

                <h1 className="sr-only">
                    <Translation text="dashboard.srTitle"/>
                </h1>

                <div className="row">
                    <div className="col-xl-8">
                        <Greeting name={this.props.account.firstName}/>
                        <div className="mobile-hidden">
                            <ApplicationProgress/>
                        </div>
                        {this.renderCompetenceDetails()}
                        <Mentors isTutor={true} mentors={this.state.scholars}/>
                        <SchoolDetails toggleReviewState={this.toggleReviewState}
                                       schoolStudyFields={this.props.account && this.props.account.applicantSchoolStudyFields && this.props.account.applicantSchoolStudyFields.length
                                           ? this.props.account.applicantSchoolStudyFields
                                           : null}/>
                        <Mentors isTutor={false} mentors={this.state.mentors}/>
                        <TimetableDetails applications={this.state.applications}
                                          scheduleInstances={this.state.scheduleInstances}/>
                    </div>
                    <div className="col-xl-4">
                        <Calendar/>
                    </div>
                </div>
            </section>
        );
    }

    private get isLoading(): boolean {
        return this.state.isProcessing ||
            null === this.state.mentors ||
            null === this.state.scholars ||
            null === this.state.applications ||
            null === this.state.scheduleInstances;
    }

    private renderCompetenceDetails = () => {
        return (
            <CustomCard showLocalLoader={false}>
                <CustomCard.Header>
                    <h2 className="custom-card-title">
                        <NavLink to="dashboard/tests" className="card-title-link" exact={true}>
                            <Translation text="dashboard.tests.title"/>
                        </NavLink>
                    </h2>
                </CustomCard.Header>
                <CustomCard.Body>
                    <div className="pb-5">
                        <Translation text="dashboard.tests.description"/>
                    </div>
                    <div className="container">
                        <Tests/>
                    </div>
                    <div className={`button-wrapper ${styles.btnWrapper}`}>
                        <Link className="btn btn-theme" to="/panel/diagnostics">
                            <Translation text="buttons.more"/>
                        </Link>
                    </div>
                </CustomCard.Body>
            </CustomCard>
        )
    };

    private toggleReviewState = (currentState: typeof StudyFieldsListType | null, schoolStudyFieldId: string): void => {
        if (!this.props.authToken || !this.props.account.applicantId) {
            return;
        }
        const payload: IChangeSchoolStudyFieldListType = {
            schoolStudyFieldsId: schoolStudyFieldId,
            listType: currentState === StudyFieldsListType.FAVOURITE ? null : StudyFieldsListType.FAVOURITE,
        };
        this.setState({isProcessing: true});
        this.subscriptions.push(
            changeSchoolStudyFieldListAPI(this.props.authToken, this.props.account.applicantId, payload)
                .pipe(
                    tap((resp: any) => {
                        const message: string =
                            currentState === StudyFieldsListType.FAVOURITE ?
                                'fieldOfStudy.fieldOfStudyItem.actionButton.favourite.requestMessages.removed' :
                                'fieldOfStudy.fieldOfStudyItem.actionButton.favourite.requestMessages.added';

                        this.alertManager?.addAlert(message);
                        this.props.changeApplicantSchoolStudyFields(resp.applicantSchoolStudyFields);
                        this.setState({
                            isProcessing: false,
                        });
                    }),
                    catchError((error: any) => {
                        this.alertManager?.handleApiError(error);
                        this.setState({isProcessing: false});
                        return of(error);
                    })
                )
                .subscribe()
        );
    };

    private loadData = <T extends unknown>(data$: Observable<T>, stateKey: 'mentors' | 'scholars' | 'applications' | 'scheduleInstances'): void => {
        this.subscriptions.push(
            data$
                .pipe(
                    tap((resp: any) => {
                        this.setState({[stateKey]: resp['hydra:member']} as Pick<IDashboardState, keyof IDashboardState>);
                    }),
                    catchError((error: any) => {
                        this.alertManager?.handleApiError(error);
                        this.setState({[stateKey]: []} as any);
                        return of(error);
                    })
                )
                .subscribe()
        );
    };
}

export default connect(
    (state: RootState) => ({
        account: accountSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        changeBreadcrumbs,
        changeApplicantSchoolStudyFields,
    }
)(withRouter(Dashboard));
