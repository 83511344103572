import {
    accountSelector,
    authTokenSelector,
    changeApplicantSchoolStudyFields,
    changeBreadcrumbs,
    getScheduleInstancesAPI,
    getTasksAPI,
    isNotNullOrUndefined,
    Loader,
    RestQueryParams,
    StudyFieldsListType,
    Translation
} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {forkJoin, Observable, of, Subscription} from 'rxjs';
import {catchError, filter, map, tap} from 'rxjs/operators';
import {changeSchoolStudyFieldListAPI, IChangeSchoolStudyFieldListType} from '../../api/changeSchoolStudyFieldListType';
import {getApplicationsAPI} from '../../api/getApplications';
import {getOnlineConsultationsAPI} from '../../api/getOnlineConsultations';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {IAlertManagerService} from '../../service/alertManagerService';
import {RootState} from '../../store/reducers';
import ChosenApplicationCard from './ChosenApplicationsCard';
import EventCalendarCard from './EventCalendarCard';
import MeetingsCard from './MeetingsCard';
import TasksCard from './TasksCard';
import TimetableCard from './TimetableCard';


interface IConnectedApplicationProps {
    readonly account: { [key: string]: any };
    readonly authToken: string;
    readonly changeApplicantSchoolStudyFields: typeof changeApplicantSchoolStudyFields;
    readonly changeBreadcrumbs: typeof changeBreadcrumbs;
}

interface IExternalApplicationProps {
}

interface IApplicationProps extends IConnectedApplicationProps, IExternalApplicationProps, RouteComponentProps {
}

interface IApplicationState {
    isProcessing: boolean;
    taskList: any;
    onlineConsultationList: any;
    scheduleList: any;
    applicationList: any;
}

class Application extends React.Component<IApplicationProps, IApplicationState> {
    private readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IApplicationProps) {
        super(props);

        this.state = {
            isProcessing: false,
            taskList: null,
            onlineConsultationList: null,
            scheduleList: null,
            applicationList: null,
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
            {labelKey: 'breadcrumbs.application', path: '/panel/application'},
        ]);
        this.fetchApplicationData();
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <section className="row application-section">
                <Loader showLoader={this.state.isProcessing}/>
                <h1 className="sr-only">
                    <Translation text="application.srTitle"/>
                </h1>
                <div className="col-md-12 col-xl-8">
                    <ChosenApplicationCard applications={this.state.applicationList}
                                           toggleReviewState={this.toggleReviewState}/>
                    <TasksCard taskList={this.state.taskList}
                               applicantId={this.props.account?.applicantId}
                               taskStatusChanged={() => this.fetchListAfterChange(getTasksAPI(this.props.authToken), 'taskList')}/>
                    <MeetingsCard onlineConsultationList={this.state.onlineConsultationList}
                                  fetchListAfterChange={() => this.fetchListAfterChange(
                                      getOnlineConsultationsAPI(
                                          this.props.authToken,
                                          new RestQueryParams().add('status[]', 'scheduled').add('status[]', 'draft').add('status[]', 'finished'),
                                      ),
                                      'onlineConsultationList'
                                  )}/>
                    <TimetableCard schedules={this.state.scheduleList} hasApplication={this.hasApplication}/>
                </div>
                <div className="col-md-12 col-xl-4">
                    <EventCalendarCard/>
                </div>
            </section>
        );
    }

    private fetchApplicationData() {
        if (!this.props.authToken) {
            return null;
        }
        const applicationDataApiArray = [
            this.retrieveListData(getApplicationsAPI(this.props.authToken), 'applicationList'),
            this.retrieveListData(getTasksAPI(this.props.authToken), 'taskList'),
            this.retrieveListData(getScheduleInstancesAPI(this.props.authToken), 'scheduleList'),
            this.retrieveListData(
                getOnlineConsultationsAPI(
                    this.props.authToken,
                    new RestQueryParams().add('status[]', 'scheduled').add('status[]', 'draft').add('status[]', 'finished').add('status[]', 'started')),
                'onlineConsultationList'
            ),
        ];
        this.setState({isProcessing: true});
        this.subscriptions.push(
            forkJoin(applicationDataApiArray)
                .pipe(
                    tap((response: any) => {
                        let updatedState = {isProcessing: false};
                        response.forEach((updatedStateItem: any) => updatedState = Object.assign(updatedState, {...updatedStateItem}));
                        this.setState(updatedState);
                    })
                )
                .subscribe()
        );
    }


    private retrieveListData(api: Observable<any>, listName: string) {
        return api.pipe(
            filter((response) => !!response || !!response['hydra:member']),
            map((resp: any) => ({[listName]: resp['hydra:member'] || []})),
            catchError((err: any) => {
                this.alertManager?.handleApiError(err);
                this.setState({isProcessing: false});
                return of();
            })
        );
    }

    private fetchListAfterChange = (api: Observable<any>, listName: string) => {
        if (!this.props.authToken) {
            return null;
        }
        this.setState({isProcessing: true});
        this.subscriptions.push(
            this.retrieveListData(api, listName)
                .pipe(
                    tap((updatedState: any) => {
                        updatedState.isProcessing = false;
                        this.setState(updatedState);
                    })
                )
                .subscribe()
        );
    };


    private toggleReviewState = (currentState: typeof StudyFieldsListType | null, schoolStudyFieldId: string) => {
        if (!this.props.authToken || !this.props.account.applicantId) {
            return null;
        }
        const payload: IChangeSchoolStudyFieldListType = {
            schoolStudyFieldsId: schoolStudyFieldId,
            listType: currentState === StudyFieldsListType.FAVOURITE ? null : StudyFieldsListType.FAVOURITE
        };
        this.setState({isProcessing: true});
        this.subscriptions.push(
            changeSchoolStudyFieldListAPI(this.props.authToken, this.props.account.applicantId, payload)
                .pipe(
                    tap(() => {
                        const message: string = currentState === StudyFieldsListType.FAVOURITE ?
                            'fieldOfStudy.fieldOfStudyItem.actionButton.favourite.requestMessages.removed'
                            :
                            'fieldOfStudy.fieldOfStudyItem.actionButton.favourite.requestMessages.added';
                        this.alertManager?.addAlert(message);
                        this.fetchListAfterChange(getApplicationsAPI(this.props.authToken), 'applicationList');
                        this.setState({
                            isProcessing: false,
                        });
                    }),
                    catchError((error: any) => {
                        this.alertManager?.handleApiError(error);
                        this.setState({isProcessing: false});
                        return of(error);
                    })
                )
                .subscribe()
        );
    };

    private get hasApplication(): boolean {
        return isNotNullOrUndefined(this.state.applicationList) && this.state.applicationList.length > 0;
    }
}

export default connect(
    (state: RootState) => ({
        account: accountSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        changeBreadcrumbs,
        changeApplicantSchoolStudyFields
    }
)(withRouter(Application));

