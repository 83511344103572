import {FormControlType, IFormConfig} from 'educat-common-web';
import {TimeSlotsType} from 'educat-common-web';


export const mentorCalendarFormConfig = (startDate: Date,
                                         availableConsultationSlots: { [key: string]: any } | null,
                                         daysNumberDisplay: number,
                                         calendarDetails?: {[key: string]: any} | null): typeof IFormConfig => {
    return {
        controlType: 'form',
        class: '',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);

            return previousStateSnapshot;
        },
        controls: [
            {
                controlType: 'group',
                key: 'date',
                class: 'form-row',
                controls: {
                    date: {
                        hostClass: 'col-100',
                        controlType: 'control',
                        defaultValue: null,
                        placeholder: '',
                        formControlType: FormControlType.WEEK_CALENDAR,
                        isLabelHidden: true,
                        checkboxSlots: false,
                        validationRules: [],
                        startDate: startDate,
                        timeSlotsType: TimeSlotsType.WEEK_CALENDAR,
                        availableConsultationSlots,
                        daysNumberDisplay,
                        calendarDetails,
                        inputDataMapper: (data: any) => data?.value,
                    }
                }
            },
        ]
    };
};
