import {
    applicantSurveyCompletedSelector,
    authTokenSelector,
    Breadcrumbs,
    changeFilterValuesStart,
    changeBreadcrumbs,
    FilterListType,
    ContentFiles,
    initLogout,
    NavBar,
    NotFound,
    Sidebar,
    Toast,
    Translation,
    UserRole,
    IModelWallet,
    ImodelMediaObject,
    accountUsernameSelector,
    accountWalletSelector,
    accountAvatarSelector,
    impersonatorRefreshTokenSelector,
    Insurances,
    getCurrentYear,
} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {Redirect, Route, RouteComponentProps, Switch, withRouter} from 'react-router-dom';
import {Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {IAlertManagerService} from '../../service/alertManagerService';
import {RootState} from '../../store/reducers';
import Application from '../Application';
import Competence from '../Competence';
import Dashboard from '../Dashboard';
import FieldOfStudyProfile from '../FieldOfStudy/FieldOfStudyProfile';
import FieldOfStudySearch from '../FieldOfStudySearch';
import Marketplace from '../Marketplace';
import MentorHost from '../MentorHost';
import MentorProfile from '../MentorHost/MentorProfile';
import TutorProfile from '../MentorHost/TutorProfile';
import Profile from '../Profile';
import navigation from './menu-items';
import Cart from '../Cart';
import VideoChatHost from '../VideoChatHost';
import Payments from '../Payments';
import MarketplaceApplications from '../Marketplace/MarketplaceApplications';
import MarketplaceTutors from '../Marketplace/MarketplaceTutors';
import CompetenceTest from '../Competence/CompetenceTestHost';
import CompetenceTestResult from '../Competence/CompetenceTestResult';
import MorrisbyTest from "../Competence/MorrisbyTest";
import {WithTranslation, withTranslation} from "react-i18next";
import PaymentSummary from "../Cart/PaymentSummary";
import PaymentCompleted from "../Cart/PaymentCompleted";


interface IConnectedPanelHostProps {
    readonly authToken: string | null;
    readonly username: string | null;
    readonly wallet: typeof IModelWallet | null;
    readonly avatar: typeof ImodelMediaObject | null;
    readonly surveyCompleted: boolean;
    readonly initLogout: typeof initLogout;
    readonly changeFilterValuesStart: typeof changeFilterValuesStart;
    readonly changeBreadcrumbs: typeof changeBreadcrumbs;
    readonly impersonatorToken: string | null;
}

interface IExternalPanelHostProps {
    fullWidthLayout?: any;
}

interface IPanelHostProps extends IConnectedPanelHostProps,
    IExternalPanelHostProps,
    RouteComponentProps,
    WithTranslation {
}

interface IPanelHostState {
    isSidebarCollapsed: boolean;
}


class PanelHost extends React.Component<IPanelHostProps, IPanelHostState> {
    private navMenu: any[];
    private subscription: Subscription | null = null;
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IPanelHostProps) {
        super(props);

        this.state = {
            isSidebarCollapsed: false,
        };

        const {t} = this.props;
        this.navMenu = [
            {
                url: '/panel/profile',
                icon: 'icon-user',
                title: t('navMenu.profile'),
            },
            {
                url: '/panel/payments',
                icon: 'icon-credit-card',
                title: t('navMenu.payments'),
            },
            {
                url: '#',
                icon: 'icon-power',
                title: t('navMenu.logout'),
                onClick: this.onLogout,
            },
        ];
        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.props.changeFilterValuesStart(FilterListType.STUDY_FIELDS);
    }

    componentWillUnmount() {
        if (null !== this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        const collapsed = this.state.isSidebarCollapsed ? 'collapsed' : '';

        return (
            <div className="dashboard-view">
                <div className="main-container-wrapper">
                    <Sidebar navigation={navigation} collapseMenu={this.state.isSidebarCollapsed}/>

                    <div className={`main-container ${collapsed}`}>
                        <NavBar
                            onToggleNavigation={this.toggleNavigation}
                            onLogout={this.onLogout}
                            isDropdownVisible={false}
                            isSearchVisible={false}
                            generalNotificationsDisplayed={false}
                            profileNotificationsDisplayed={false}
                            navMenu={this.navMenu}
                            username={this.props.username}
                            userImageUrl={this.props.avatar?.tinyThumb}
                            accountProgress={60}
                            notificationCount={3}
                            userRole={UserRole.APPLICANT}
                            accountBalance={this.props.wallet}
                            navigation={navigation.items}
                            collapseMenu={this.state.isSidebarCollapsed}
                            impersonatorToken={this.props.impersonatorToken}
                        />
                        <div className="content-wrapper">
                            <main className="content">
                                <Breadcrumbs/>
                                <Switch>
                                    <Route path="/panel/dashboard" component={Dashboard} exact key="dashboard"/>
                                    <Route path="/panel/diagnostics" component={Competence} exact key="diagnostics"/>
                                    <Route path="/panel/diagnostics/morrisby-test" component={MorrisbyTest} exact key="morrisby-test"/>
                                    <Route path="/panel/diagnostics/test/:id" component={CompetenceTest} exact key="diagnostics-test"/>
                                    <Route path="/panel/diagnostics/result/:id" component={CompetenceTestResult} exact key="diagnostics-test-result"/>
                                    <Route path="/panel/field-of-study-search" component={FieldOfStudySearch} key="field-of-study-search"/>
                                    <Route path="/panel/field-of-study-profile/:id" component={FieldOfStudyProfile} exact
                                           key="field-of-study-profile"/>
                                    <Route path="/panel/mentors" component={() => <MentorHost isTutor={false}/>} exact key="mentors"/>
                                    <Route path="/panel/mentors/:id/:name" component={MentorProfile} exact key="mentor-profile"/>
                                    <Route path="/panel/mentors/:id" component={MentorProfile} exact key="mentor-profile"/>
                                    <Route path="/panel/tutors" component={() => <MentorHost isTutor={true}/>} exact key="tutors"/>
                                    <Route path="/panel/tutors/:id" component={TutorProfile} exact key="tutor-profile"/>
                                    <Route path="/panel/application" component={Application} exact key="application"/>
                                    <Route path="/panel/content-files" component={() => <ContentFiles userRole={UserRole.APPLICANT}/>} exact key="content-files"/>
                                    <Route path="/panel/marketplace" component={Marketplace} exact key="marketplace"/>
                                    <Route path="/panel/payments" component={Payments} exact key="payments"/>
                                    <Route path="/panel/marketplace/tests"
                                           component={() => <Redirect to="/panel/diagnostics"/>}
                                           exact
                                           key="marketplace-test"/>
                                    <Route path="/panel/marketplace/applications"
                                           component={() => <MarketplaceApplications authToken={this.props.authToken}
                                                                                     changeBreadcrumbs={this.props.changeBreadcrumbs}/>}
                                           exact
                                           key="marketplace-applications"/>
                                    <Route path="/panel/marketplace/tutors"
                                           component={() => <MarketplaceTutors authToken={this.props.authToken}
                                                                               changeBreadcrumbs={this.props.changeBreadcrumbs}/>}
                                           exact
                                           key="marketplace-tutors"/>
                                    <Route path="/panel/profile" component={Profile} exact key="profile"/>
                                    <Route path="/panel/cart" component={Cart} exact key="cart"/>
                                    <Route path="/panel/cart/payment" component={PaymentSummary} exact key="payment-summary"/>
                                    <Route path="/panel/cart/payment/success" component={PaymentCompleted} exact key="payment-completed"/>
                                    <Route path="/panel/consultations/:id" component={VideoChatHost} exact key="video-chat"/>
                                    <Route path="/panel/insurances" component={Insurances} exact key="insurances" />
                                    <Route path="/panel" component={() => <Redirect to="/panel/dashboard"/>} exact key="panel"/>
                                    <Route key="not-found" component={NotFound}/>
                                </Switch>
                            </main>
                            <footer className="footer">
                                <Translation text="footer.copyright" config={{year: getCurrentYear()}}/>
                            </footer>
                        </div>
                    </div>
                </div>
                <Toast/>
            </div>
        );
    }

    toggleNavigation = (): void => {
        this.setState({isSidebarCollapsed: !this.state.isSidebarCollapsed});
    };

    private onLogout = () => {
        this.props.initLogout();
        this.alertManager?.logoutSuccess();
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        username: accountUsernameSelector(state),
        wallet: accountWalletSelector(state),
        avatar: accountAvatarSelector(state),
        impersonatorToken: impersonatorRefreshTokenSelector(state),
        surveyCompleted: applicantSurveyCompletedSelector(state)
    }),
    {
        initLogout,
        changeFilterValuesStart,
        changeBreadcrumbs
    }
)(withRouter(withTranslation()(PanelHost)));
