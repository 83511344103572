import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export function createTestFromPackageAPI(
    authToken: string | null,
    serviceInstanceId: string,
    subjectDefinitionId: string): Observable<any> {
    return educatAPI.put(
        `service_instances/${serviceInstanceId}/create_child_subject_test`,
        {
            subjectDefinitionId: subjectDefinitionId
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
