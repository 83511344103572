import {IStudyFieldFilters} from '../store/reducers/studyFieldsSearchSlice';
import {IMentorFilters} from "../store/reducers/mentorsSearchSlice";

export interface ISelectedFilters {
    country: any;
    subRealm: any;
    studyField: any;
    school: any;
    mode?: any;
    price?: any;
    level?: any;
    duration?: any;
    languages?: any;
    availability?: any;
    assistance?: any;
}

export type DisplayedFilters = {[key: string]: DisplayedFilter[]};
export type DisplayedFilter = { value: any, label: string };

export type StudySelectData = {
    readonly studySubject?: {
        readonly value: string;
        readonly label: string | null;
    };
    readonly whereToStudy?: {
        readonly value: string;
        readonly label: string | null;
    };
    readonly country?: {
        readonly value: string;
        readonly label: string | null;
    }[];
};

export const createSelectedFiltersFromStudyFieldFilters = (filters: IStudyFieldFilters): ISelectedFilters => ({
    country: filters?.countries?.id,
    subRealm: filters?.realm?.id,
    studyField: filters?.studyField?.id,
    mode: filters?.mode,
    price: filters?.price,
    level: filters?.level_filter,
    duration: filters?.duration_filter,
    school: filters?.school?.id
});

export const createSelectedFiltersFromMentorsFilters = (filters: IMentorFilters): ISelectedFilters => ({
    country: filters?.countries?.id,
    subRealm: filters?.realms?.id,
    studyField: filters?.studyFields?.id,
    price: filters?.basePrice,
    school: filters?.mentorSchoolStudyFields?.school?.id,
    languages: filters?.languages?.id,
    availability: filters?.free_terms,
    assistance: filters?.mentorTaskTypes?.id,
});
