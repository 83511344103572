import {
    FormControlType,
    IFormConfig, IMultiselectOption,
    multiCollectionInputDataMapper,
    multiCollectionOutputDataMapper,
    MultiselectMenuPlacement,
    MultiSelectType
} from 'educat-common-web';

export const mentorFiltersFormConfig = (
    countries: typeof IMultiselectOption[] = [],
    realms: typeof IMultiselectOption[] = [],
    languages: typeof IMultiselectOption[] = [],
    assistance: typeof IMultiselectOption[] = []
): typeof IFormConfig => {
    return {
        controlType: 'group',
        class: 'fields-filters',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);

            return previousStateSnapshot;
        },
        controls: [
            {
                controlType: 'group',
                key: 'country',
                class: 'row',
                controls: {
                    country: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.DEFAULT,
                        validationRules: [],
                        placeholder: 'filters.form.placeholders.allOptions',
                        label: 'filters.form.labels.country',
                        multiselectOptions: countries,
                        inputDataMapper: multiCollectionInputDataMapper,
                        outputDataMapper: multiCollectionOutputDataMapper,
                        hostClass: 'col-xl-12',
                        menuPlacement: MultiselectMenuPlacement.TOP,
                        isCustomMultiValueContainer: true
                    }
                }
            },
            {
                controlType: 'group',
                key: 'realm',
                class: 'row',
                controls: {
                    subRealm: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.GROUPED,
                        validationRules: [],
                        placeholder: 'filters.form.placeholders.allOptions',
                        label: 'filters.form.labels.realm',
                        multiselectOptions: realms,
                        inputDataMapper: multiCollectionInputDataMapper,
                        outputDataMapper: multiCollectionOutputDataMapper,
                        hostClass: 'col-xl-12',
                        menuPlacement: MultiselectMenuPlacement.TOP,
                        isGroupedComponentCustom: true,
                    }
                }
            },
            {
                controlType: 'group',
                key: 'price',
                class: 'row',
                controls: {
                    price: {
                        controlType: 'control',
                        defaultValue: null,
                        formControlType: FormControlType.RANGE,
                        label: 'filters.form.labels.price',
                        hostClass: 'col-xl-12',
                        rangeStep: 1,
                        rangeMinValue: 0,
                        rangeMaxValue: 1000,
                        rangeValueUnit: 'fieldOfStudy.form.rangeUnit.currency',
                        isLabelHidden: true
                    }
                },
            },
            {
                controlType: 'group',
                key: 'availability',
                class: 'row',
                controls: {
                    availability: {
                        controlType: 'control',
                        defaultValue: null,
                        formControlType: FormControlType.DATE_RANGE_PICKER,
                        validationRules: [],
                        placeholder: 'filters.form.placeholders.availability',
                        label: 'filters.form.labels.availability',
                        hostClass: 'col-xl-12',
                    }
                }
            },
            {
                controlType: 'group',
                key: 'languages',
                class: 'row',
                controls: {
                    languages: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.DEFAULT,
                        validationRules: [],
                        placeholder: 'filters.form.placeholders.allOptions',
                        label: 'filters.form.labels.language',
                        multiselectOptions: languages,
                        inputDataMapper: multiCollectionInputDataMapper,
                        outputDataMapper: multiCollectionOutputDataMapper,
                        hostClass: 'col-xl-12',
                        menuPlacement: MultiselectMenuPlacement.TOP,
                        isCustomMultiValueContainer: true
                    }
                }
            },
            {
                controlType: 'group',
                key: 'assistance',
                class: 'row',
                controls: {
                    assistance: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.GROUPED,
                        validationRules: [],
                        placeholder: 'filters.form.placeholders.allOptions',
                        label: 'filters.form.labels.assistance',
                        multiselectOptions: assistance,
                        inputDataMapper: multiCollectionInputDataMapper,
                        outputDataMapper: multiCollectionOutputDataMapper,
                        hostClass: 'col-xl-12',
                        menuPlacement: MultiselectMenuPlacement.TOP,
                        isGroupedComponentCustom: true
                    }
                }
            },
        ]
    };
};
