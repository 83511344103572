import React, {Component} from 'react';
import {authTokenSelector, getCountriesAPI, IModelCountry, Translation} from "educat-common-web";
import styles from "./styles.module.scss";
import {connect} from "react-redux";
import {RootState} from "../../../../../store/reducers";
import {catchError, map} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {fixInjectedProperties, lazyInject} from "../../../../../ioc";
import {IAlertManagerService} from "../../../../../service/alertManagerService";
import ReactCountryFlag from "react-country-flag";
const getCountryISO2 = require("country-iso-3-to-2");


interface IConnectedCountrySelectProps {
    authToken: string | null;
}

interface IExternalCountrySelectProps {
    selectCountry: (countryId: string) => void;
}

interface ICountrySelectProps extends IConnectedCountrySelectProps, IExternalCountrySelectProps {
}

interface ICountrySelectState {
    isLoading: boolean;
    countryList: string[] | [];
}

class CountrySelect extends Component <ICountrySelectProps, ICountrySelectState> {
    private subscriptions: Subscription[] = [];
    @lazyInject("AlertManagerService") private alertManager: IAlertManagerService | undefined;

    constructor(props: ICountrySelectProps) {
        super(props);
        this.state = {
            isLoading: true,
            countryList: []
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.subscriptions.push(this.getCountryList());
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <div className={styles.countryList}>
                {this.state.countryList.length ?
                    this.state.countryList.map((country: string) => {
                        return (
                            <div className={styles.country} key={country} onClick={() => this.props.selectCountry(country)}>
                                <div className={styles.countryFlag}>
                                    <ReactCountryFlag countryCode={getCountryISO2(country.toUpperCase())}
                                                      style={{
                                                          width: '100%',
                                                          height: '100%',
                                                      }}
                                                      svg />
                                </div>
                                <p className={styles.countryName}><Translation text={`country.${country.toUpperCase()}`}/></p>
                            </div>
                        )
                    }) : null
                }
            </div>
        );
    }

    private getCountryList = () => {
        return getCountriesAPI(this.props.authToken).pipe(
            map((resp: any) => {
                const countries = (resp['hydra:member'] || []).map((country: typeof IModelCountry) => (country.id).toLowerCase());
                this.setState({
                    isLoading: false,
                    countryList: countries
                });
            }),
            catchError((error: any) => {
                this.setState({isLoading: false});
                this.alertManager?.handleApiError(error);
                return of();
            })
        ).subscribe();
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {}
)(CountrySelect);
