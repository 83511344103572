import React from 'react';
import {
    authTokenSelector,
    Form,
    FormControlChangeType,
    IFormConfig,
    getCalendarEventsAPI,
    IAccount,
    accountSelector,
    Translation,
    DateComponent
} from 'educat-common-web';
import {calendarFormConfig} from "./calendarFormConfig";
import moment from "moment";
import {BehaviorSubject, Subscription} from "rxjs";
import {distinctUntilChanged, filter, map, tap} from "rxjs/operators";
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {RouteComponentProps, withRouter} from "react-router";
import {Task} from 'educat-common-web';
import {ViewTaskModal} from 'educat-common-web';


export enum CalendarEventType {
    ONLINE_CONSULTATION = 'online_consultation',
    TASK = 'task'
}

interface IConnectedEventCalendarProps {
    readonly authToken: string | null;
    readonly account: typeof IAccount;
}

interface IExternalEventCalendarProps { }

interface IEventCalendarProps extends
    IConnectedEventCalendarProps,
    IExternalEventCalendarProps,
    RouteComponentProps { }

interface IEventCalendarState {
    formConfig: typeof IFormConfig;
    value: any;
    calendarEvents: {[key: string]: any}[];
    taskSelected: typeof Task | null;
}

class EventCalendar extends React.Component<IEventCalendarProps, IEventCalendarState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(props: IEventCalendarProps) {
        super(props);
        this.state = {
            formConfig: calendarFormConfig([]),
            value: null,
            calendarEvents: [],
            taskSelected: null
        };
    }

    componentDidMount() {
        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                distinctUntilChanged(),
                tap((data: any) => this.setState({value: data.value})),
            ).subscribe()
        );

        this.getCalendarEvents();
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription => subscription.unsubscribe()));
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <Form config={this.state.formConfig}
                            controlName={'calendarForm'}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.value} />
                    </div>

                    <div className="col-xl-12">
                        {this.renderEventDetails()}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderEventDetails = () => {
        if (!this.state.value) {
            return;
        }

        let events = this.getSelectedEvents(
            this.state.value.date.value,
            this.state.calendarEvents
        );

        events = events.sort((a: {[key: string]: any}, b: {[key: string]: any}) => {
            return new Date(a.startsAt).getTime() > new Date(b.startsAt).getTime() ? 1 : -1;
        });

        return (
            <div className="event-container">
                {!events || !events.length ?
                    (<p className="no-events"><Translation text="dashboard.eventCalendar.noEvents" /></p>) :
                    events.map((item: {[key: string]: any}) => {
                        return (
                            <React.Fragment key={`event_calendar_event_item_${item.key}`}>
                                <div className="event-details">
                                    <div className="date">
                                        <p>
                                            <DateComponent date={item.startsAt} format="DD.MM.YYYY" />
                                        </p>
                                        <span />
                                        <p>
                                            <DateComponent date={item.startsAt} format="HH:mm" />
                                        </p>
                                    </div>
                                    <div className="details">
                                        {this.findEventName(item)}
                                    </div>
                                    {this.renderConsultationButton(item)}
                                </div>
                                {this.state.taskSelected ?
                                    (<ViewTaskModal isModalVisible={this.state.taskSelected}
                                        task={this.state.taskSelected}
                                        toggleModal={() => this.closeViewTaskModal()} />) : null
                                }
                            </React.Fragment>
                        )
                    })
                }
            </div>
        )
    };

    private findEventName(item: any): string | null | undefined {
        const task = item.relatesTo?.relations[0]?.relatedObject;
        const found = (item?.name?.eventNames || [])
            .find((candidate: any) => candidate.accountId !== this.props.account.id);

        return item.type === CalendarEventType.TASK ? <button className="event-name" onClick={() => this.selectTask(task)}>{found?.name}</button> : found?.name;
    }

    private closeViewTaskModal() {
        if (this.state.taskSelected) this.setState({taskSelected: null})
    }

    private selectTask(task: typeof Task) {
        this.setState({taskSelected: task});
    }

    private getSelectedEvents(selectedDate: Date, eventsList: {[key: string]: any}[]) {
        let recommendationDate = moment(selectedDate).format('YYYY-MM-DD');

        let events;
        events = eventsList.filter((event => {
            let date = moment(event.startsAt).utcOffset(0).format('YYYY-MM-DD');

            return date === recommendationDate;
        }));

        return events;
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private getRecommendationOccurrence(data: any) {
        if (!data || !data.length) {
            return null;
        }

        const map = data
            .reduce((acc: any, e: any) => acc.set(
                e.startsAt.split('T')[0].replace(/-0/g, '-'),
                (acc.get(e.startsAt.split('T')[0].replace(/-0/g, '-')) || 0) + 1
            ), new Map());

        return [...map.entries()];
    }

    private renderConsultationButton(item: {[key: string]: any}) {
        if (!item || item.type !== CalendarEventType.ONLINE_CONSULTATION) {
            return;
        }

        const consultationTags = item.tags.tags.filter((tag: {[key: string]: any}) => tag.tagName.startsWith('online_consultation:')),
            consultationId = consultationTags.length ? consultationTags[0].tagName.replace('online_consultation:', '') : null;

        if (consultationId) {
            return (
                <button className="applicant-data-button btn btn-theme"
                    onClick={() => this.props.history.push(`/panel/consultations/${consultationId}`)}>
                    <Translation text='buttons.startConsultation' />
                    <i className="feather icon-video" />
                </button>
            )
        }

        return null;
    }

    private getCalendarEvents = () => {
        this.subscriptions.push(
            getCalendarEventsAPI(this.props.authToken).pipe(
                map((resp: any) => {
                    const eventDates = this.getRecommendationOccurrence(resp['hydra:member']);
                    this.setState({
                        calendarEvents: resp['hydra:member'] || [],
                        formConfig: calendarFormConfig(eventDates),
                    });
                })
            ).subscribe()
        )
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        account: accountSelector(state),
    }),
    {}
)(withRouter(EventCalendar));
