import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export function getFieldOfStudyAPI(authToken: string | null, schoolStudyFieldId: string): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.get(
        `school_study_fields/${schoolStudyFieldId}`,
        new RestQueryParams(),
        headers
    );
}
