import {GoogleMap, Marker, withGoogleMap, withScriptjs} from "react-google-maps";
import locationIcon from "../../../../assets/images/pin.svg";

export const Map = withScriptjs(withGoogleMap((props: any) =>

    <GoogleMap
        defaultZoom={15}
        defaultCenter={props.position}
        defaultOptions={{
            fullscreenControl: false,
            zoomControl: false,
            keyboardShortcuts: false
        }}
    >
        {props.isMarkerShown &&
            <Marker position={props.position} icon={{url: locationIcon}}/>
        }
    </GoogleMap>
));
