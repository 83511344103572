
  const navigation = {
      items: [
    {
      id: 'Dashboard',
      title: 'Insurance Cover',
      type: 'group',
      icon: '',
      children: [
          {
              id: 'kokpit',
              title: 'sidebar.dashboard',
              type: 'item',
              url: '/panel/dashboard',
              icon: 'menu-icon dashboard',
          },
          {
              id: 'competence',
              title: 'sidebar.competence',
              type: 'item',
              url: '/panel/diagnostics',
              icon: 'menu-icon diagnostics',
          },
          {
              id: 'fieldOfStudy',
              title: 'sidebar.fieldOfStudy',
              type: 'item',
              url: '/panel/field-of-study-search',
              icon: 'menu-icon field-of-study-search',
          },
          {
              id: 'mentors',
              title: 'sidebar.mentors',
              type: 'item',
              url: '/panel/mentors',
              icon: 'menu-icon mentors',
          },
          // Hidden in current version
          // {
          //     id: 'tutors',
          //     title: 'sidebar.tutors',
          //     type: 'item',
          //     url: '/panel/tutors',
          //     icon: 'menu-icon tutors',
          // },
          {
              id: 'application',
              title: 'sidebar.application',
              type: 'item',
              url: '/panel/application',
              icon: 'menu-icon application',
          },
          {
              id: 'contentFiles',
              title: 'sidebar.contentFiles',
              type: 'item',
              url: '/panel/content-files',
              icon: 'menu-icon contents',
          },
          {
              id: 'marketplace',
              title: 'sidebar.marketplace',
              type: 'item',
              url: '/panel/marketplace',
              icon: 'menu-icon marketplace',
          },
          {
              id: 'insurances',
              title: 'sidebar.insurances',
              type: 'item',
              url: '/panel/insurances',
              icon: 'menu-icon briefcase',
          },
      ]
    },
  ]
};
  export default navigation;
