import React from 'react';
import {CustomCard, Translation} from 'educat-common-web';
import EventCalendar from '../EventCalendar';

interface IExternalCalendarProps {
}

interface ICalendarProps extends IExternalCalendarProps {
}

interface ICalendarState {
}

class Calendar extends React.Component<ICalendarProps, ICalendarState> {
    render() {
        return (
            <CustomCard showLocalLoader={false}>
                <CustomCard.Header>
                    <h2 className="custom-card-title">
                        <Translation text="dashboard.eventCalendar.title"/>
                    </h2>
                </CustomCard.Header>
                <CustomCard.Body>
                    <EventCalendar/>
                </CustomCard.Body>
            </CustomCard>
        );
    }
}

export default Calendar;
