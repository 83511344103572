import {educatAPI} from 'educat-common-web';
import {RestQueryParams} from 'educat-common-web';
import {Observable} from 'rxjs';



export function getPaymentsAPI(authToken: string, params: typeof RestQueryParams): Observable<any> {
    return educatAPI.get(
        'payment_transactions',
        params,
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
