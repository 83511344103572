import React from 'react';
import {
    getMentorName,
    getMentorSchool,
    getMentorStudyField,
    hasTrialConsultation,
    IMentor,
    IModelMentorServiceDefinition,
    isNotNullOrUndefined,
    isNullOrUndefined,
    Translation
} from 'educat-common-web';
import styles from './styles.module.scss';
import AccountImage from '../../../Common/AccountImage';

interface IConnectedMentorItemServiceDetailsProps {
}

interface IExternalMentorItemServiceDetailsProps {
    readonly mentor: typeof IMentor;
    readonly mentorServiceDefinitions: typeof IModelMentorServiceDefinition;
    readonly useTwoSpaces: boolean;
}

interface IMentorItemServiceDetailsProps extends IConnectedMentorItemServiceDetailsProps,
    IExternalMentorItemServiceDetailsProps {
}

interface IMentorItemServiceDetailsState {
}


class MentorItemServiceDetails extends React.Component<IMentorItemServiceDetailsProps, IMentorItemServiceDetailsState> {
    render() {
        return (
            <div className={`${styles.details} col-xxl-${this.props.useTwoSpaces ? '9' : '5'}`}>

                <div className={styles.img}>
                    <AccountImage account={this.props.mentor?.account} type={'mediumThumb'}/>
                </div>

                <div className={styles.mentorDetails}>
                    <p className={styles.name}>
                        <Translation text={`cart.cartDetails.${this.translationKey}`}
                                     config={{
                                         'name': getMentorName(this.props.mentor),
                                         'serviceName': this.props.mentorServiceDefinitions.name
                                     }}/>
                    </p>
                    <p className={styles.studyField}>{getMentorSchool(this.props.mentor)}</p>
                    <p className={styles.school}>{getMentorStudyField(this.props.mentor)}</p>
                </div>
            </div>
        );
    }

    private get translationKey(): string {
        if (isNullOrUndefined(this.props.mentorServiceDefinitions)) {
            return 'unknown';
        }
        if (isNotNullOrUndefined(this.props.mentorServiceDefinitions.serviceApplicationElement)) {
            return 'serviceApplicationElement';
        }
        if (isNotNullOrUndefined(this.props.mentorServiceDefinitions.serviceApplicationPackage)) {
            return 'serviceApplicationPackage';
        }
        if (hasTrialConsultation(this.props.mentorServiceDefinitions)) {
            return 'trial';
        }
        if (isNotNullOrUndefined(this.props.mentorServiceDefinitions.serviceConsultationPackage) ||
            isNotNullOrUndefined(this.props.mentorServiceDefinitions.serviceConsultation)) {
            return 'meeting';
        }

        return 'unknown';
    }
}

export default MentorItemServiceDetails;
