import {Loader, LoaderType, RestQueryParams, Translation} from 'educat-common-web';
import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import {Subscription} from 'rxjs';
import OperationTableItem from './OperationTableItem';

interface IOperationHistoryTableExternalProps {
    readonly operationHistoryList: any[] | null;
    readonly refreshPaymentList: (params?: typeof RestQueryParams) => void;
}

interface IOperationHistoryTableProps extends IOperationHistoryTableExternalProps, RouteComponentProps {
}

interface IOperationHistoryTableState {
    isLoading: boolean;
}

class OperationHistoryTable extends React.Component<IOperationHistoryTableProps, IOperationHistoryTableState> {
    readonly subscriptions: Subscription[] = [];

    constructor(props: IOperationHistoryTableProps) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        if (
            !this.props.operationHistoryList ||
            !Array.isArray(this.props.operationHistoryList) ||
            (this.props.operationHistoryList.length === 0 && !this.state.isLoading)
        ) {
            return (
                <p>
                    <Translation text={'payments.operationHistory.table.noOperations'}/>
                </p>
            );
        }
        return (
            <div className="table-wrapper">
                <Loader type={LoaderType.Local} showLoader={this.state.isLoading}/>
                <table className="data-table default-table operation-history mb-5">
                    <thead>
                    <tr>
                        <th className="highlight-cell greyed">
                            <Translation text={'payments.operationHistory.table.package'}/>
                        </th>
                        <th className="highlight-cell greyed">
                            <Translation text={'payments.operationHistory.table.services'}/>
                        </th>
                        <th className="highlight-cell greyed">
                            <Translation text={'payments.operationHistory.table.purchaseDate'}/>
                        </th>
                        <th className="highlight-cell greyed">
                            <Translation text={'payments.operationHistory.table.paymentStatus.title'}/>
                        </th>
                        <th className="highlight-cell greyed">
                            <Translation text={'payments.operationHistory.table.realization.title'}/>
                        </th>
                        <th className="highlight-cell greyed">
                            <Translation text={'payments.operationHistory.table.price'}/>
                        </th>
                    </tr>
                    </thead>
                    <tbody>{this.renderTableRows(this.props.operationHistoryList)}</tbody>
                </table>
            </div>
        );
    }

    private renderTableRows(operationHistoryList: any[]) {
        if (!operationHistoryList) {
            return null;
        }
        return operationHistoryList.map((item: any, index: number) =>
            <OperationTableItem key={`${item.id}_${index}`} operationHistoryItem={item} operationHistoryItemIndex={index}
                                refreshPaymentList={this.props.refreshPaymentList}/>
        );
    }
}

export default withRouter(OperationHistoryTable);
