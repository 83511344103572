import React from 'react';
import {Subscription} from 'rxjs';
import {isNullOrUndefined, Loader, LoaderType, Translation, UserDisplayName, UserRole} from 'educat-common-web';
import moment from 'moment';
import {IOnlineConsultationItem} from '../../types';
import {RouteComponentProps, withRouter} from 'react-router';
import ServiceHourSelectionModal from "../../../Payments/ServiceHourSelectionModal";
import {Link} from "react-router-dom";


interface IMeetingsTableExternalProps {
    onlineConsultationList: IOnlineConsultationItem[] | null;
    fetchListAfterChange: () => void;
}

interface IMeetingsTableProps extends IMeetingsTableExternalProps, RouteComponentProps {
}

interface IMeetingsTableState {
    isLoading: boolean;
    isLongListShown: boolean;
    isModalVisible: boolean;
    selectedConsultation: any;
}

class MeetingsTable extends React.Component<IMeetingsTableProps, IMeetingsTableState> {
    readonly subscriptions: Subscription[] = [];

    constructor(props: IMeetingsTableProps) {
        super(props);

        this.state = {
            isLoading: false,
            isLongListShown: false,
            isModalVisible: false,
            selectedConsultation: null
        };
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        if (
            !this.props.onlineConsultationList ||
            !Array.isArray(this.props.onlineConsultationList) ||
            (this.props.onlineConsultationList.length === 0 && !this.state.isLoading)
        ) {
            return (
                <p>
                    <Translation text={'application.yourMeetings.noMeetings'}/>
                </p>
            );
        }
        return (
            <React.Fragment>
                <Loader type={LoaderType.Local} showLoader={this.state.isLoading}/>
                <table className="data-table default-table mb-5">
                    <thead>
                    <tr>
                        <th className="highlight-cell greyed" />
                        <th className="highlight-cell greyed">
                            <Translation text={'application.yourMeetings.table.meetingDueDate'}/>
                        </th>
                        <th className="highlight-cell greyed">
                            <Translation text={'application.yourMeetings.table.userHours'}/>
                        </th>
                    </tr>
                    </thead>
                    <tbody>{this.renderTableRows(this.props.onlineConsultationList)}</tbody>
                </table>
                {this.renderShowMoreButton()}

                {this.state.isModalVisible &&
                    (<ServiceHourSelectionModal isModalVisible={this.state.isModalVisible}
                                                serviceInstance={this.state.selectedConsultation.serviceInstance}
                                                toggleModal={this.toggleModal} />)
                }
            </React.Fragment>
        );
    }

    private renderTableRows(onlineConsultationList: any[]) {
        if (!this.props.onlineConsultationList) {
            return null;
        }
        let currentList: any[] = onlineConsultationList;

        if (!this.state.isLongListShown && currentList.length > 5) {
            currentList = currentList.slice(0, 5);
        }
        return currentList.map((item: any) => (
            <tr key={item.id}>
                <td className="highlight-cell">{this.renderMentorLink(item.participants)}</td>
                <td>
                    <div className="cell-with-button">{this.renderConsultationDate(item)}</div>
                </td>
                <td>
                    <div className="cell-with-button">
                        {this.renderUsedHours(item.serviceInstance)}
                        {this.renderRedirectButton(item.participants)}
                    </div>
                </td>
            </tr>
        ));
    }

    private renderShowMoreButton() {
        if (this.state.isLongListShown && this.props.onlineConsultationList && this.props.onlineConsultationList.length > 5) {
            return (
                <button className={`default-expand-list-button ${this.state.isLongListShown ? 'long-list' : ''}`}
                        type="button"
                        onClick={() => this.toggleLongList()}>
                    <Translation text={'application.yourMeetings.seeLessMeetings'}/>
                </button>
            );
        }
        if (!this.state.isLongListShown && this.props.onlineConsultationList && this.props.onlineConsultationList.length > 5) {
            return (
                <button className="default-expand-list-button" type="button" onClick={() => this.toggleLongList()}>
                    <Translation text={'application.yourMeetings.seeMoreMeetings'}/>
                </button>
            );
        }
        return null;
    }

    private toggleLongList() {
        this.setState({isLongListShown: !this.state.isLongListShown});
    }

    private renderUsedHours(serviceInstance: any) {
        return (
            <span>
                {isNullOrUndefined(serviceInstance?.finishedConsultationCount) ?
                    ` -- / -- h` :
                    `${serviceInstance.finishedConsultationCount}/${serviceInstance.totalFreeSlots} h`
                }
            </span>
        );
    }

    private renderMentorLink(participants: any[]) {
        if (isNullOrUndefined(participants) || participants.length === 0) {
            return '--';
        }
        const mentorData = participants.find((participant: any) => participant.role !== UserRole.APPLICANT);

        return mentorData?.account?.mentor ? (
            <Link to={`/panel/${mentorData.role === UserRole.MENTOR ? 'mentors' : 'tutors'}/${mentorData.account.mentor.id}`}
                  className="item-link">
                <UserDisplayName account={mentorData.account}/>
            </Link>
        ) : (
            '--'
        );
    }

    private renderRedirectButton(participants: any[]) {
        if (isNullOrUndefined(participants) || participants.length === 0) {
            return null;
        }

        const mentorData = participants.find((participant: any) => participant.role !== UserRole.APPLICANT);
        return mentorData?.account?.mentor ? (
            <Link className="btn btn-theme btn-small"
                  to={`/panel/${mentorData.role === UserRole.MENTOR ? 'mentors' : 'tutors'}/${mentorData.account.mentor.id}`}>
                <Translation text={'application.yourMeetings.buyMore'}/>
            </Link>
        ) : null;
    }

    private renderConsultationDate(item: any) {
        return isNullOrUndefined(item.startsAt) ? (
            <span>
				<Translation text={'application.yourMeetings.noConsultationDate'}/>
			</span>
        ) : (
            <>
				<span>
					<Translation
                        text={'application.yourMeetings.table.deadlineData'}
                        config={{date: moment(item.startsAt).format('DD/MM/YYYY'), hour: moment(item.startsAt).format('HH:mm')}}
                    />
				</span>

               <button className="btn"
                       onClick={() => {
                           this.setState({selectedConsultation: item});
                           this.toggleModal();
                       }} >
					<span className="sr-only">
						<Translation text={"application.yourMeetings.srEditButton"} />
					</span>
                   <i className="feather icon-edit-2"/>
				</button>
                <button className="btn btn-theme btn-small" onClick={() => {
                    window.open(`/panel/consultations/${item.id}`)
                }}>
                    <Translation text={'application.yourMeetings.join'}/>
                </button>
            </>
        );
    }

    private toggleModal = () => {
        this.setState({isModalVisible: !this.state.isModalVisible}, () => {
            if (!this.state.isModalVisible) {
                this.props.fetchListAfterChange();
            }
        });
    };
}

export default withRouter(MeetingsTable);
