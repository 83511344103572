import {combineEpics} from 'redux-observable';
import studyFieldsSearchEpic from "./studyFieldsSearchEpic";
import {
    accountEpic,
    changePasswordEpic,
    filterValuesEpic,
    loginEpic,
    reauthenticateEpic,
    renewAuthTokenEpic,
    insurancesEpic
} from "educat-common-web";
import cartEpic from "./cartEpic";
import mentorsSearchEpic from "./mentorSearchEpic";
import sentryUserContextEpic from "./sentryUserContextEpic";

export const rootEpic = combineEpics(
    studyFieldsSearchEpic,
    filterValuesEpic,
    mentorsSearchEpic,
    loginEpic,
    renewAuthTokenEpic,
    reauthenticateEpic,
    changePasswordEpic,
    accountEpic,
    cartEpic,
    sentryUserContextEpic,
    insurancesEpic
);
