import {CustomCard, Translation} from 'educat-common-web';
import React from 'react';

interface IRatingLegendComponentProps {
    ratingDescriptionArray: any[];
    currentRating: number;
}

class RatingLegendComponent extends React.Component<IRatingLegendComponentProps> {
    render() {
        if (!this.props.ratingDescriptionArray) {
            return null;
        }

        return (
            <CustomCard showLocalLoader={false}>
                <CustomCard.Header>
                    <h2 className="view-subtitle">
                        <Translation text="competence.competenceTestResult.rangeDescription.title"/>
                    </h2>
                </CustomCard.Header>
                <CustomCard.Body>
                    <ul className="rating-description-list">
                        {this.props.ratingDescriptionArray.map((ratingDescription: any) => (
                            <li
                                key={ratingDescription.description}
                                className={`rating-description-item ${(this.props.currentRating >= ratingDescription.from && this.props.currentRating <= ratingDescription.to) ? 'current' : ''}`}
                            >
                                <span>
                                    <Translation
                                        text="competence.competenceTestResult.rangeDescription.fromTo"
                                        config={{from: ratingDescription.from, to: ratingDescription.to}}/>
                                </span>
                                <span className="rating-description-rating">
                                    <Translation
                                        text="competence.competenceTestResult.rangeDescription.rating"
                                        config={{rating: ratingDescription.description}}/>
                                </span>
                            </li>
                        ))}
                    </ul>
                </CustomCard.Body>
            </CustomCard>
        );
    }
}

export default RatingLegendComponent;
