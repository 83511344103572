import {changeBreadcrumbs} from 'educat-common-web';
import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import FieldOfStudyList from '../Common/FieldOfStudyList';
import {FieldOfStudySearchRoute} from '../MainSearch';
import {connect} from "react-redux";
import {studyFieldsListSelector} from "../../../store/selectors/studyFieldsSearchSelectors";
import {RootState} from "../../../store/reducers";


interface IConnectedFavouriteListProps {
    readonly fieldOfStudyList: any[];
}

interface IExternalFavouriteListProps {
    readonly changeBreadcrumbs: typeof changeBreadcrumbs;
}

interface IFavouriteListProps extends IConnectedFavouriteListProps,
    IExternalFavouriteListProps,
    RouteComponentProps {
}

interface IFavouriteListState {
}


class FavouriteList extends React.Component<IFavouriteListProps, IFavouriteListState> {

    componentDidMount(): void {
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
            {labelKey: 'breadcrumbs.fields', path: '/panel/field-of-study-search'},
            {labelKey: 'breadcrumbs.favouriteFields', path: '/panel/field-of-study-search/favourite'},
        ]);
    }

    render() {
        return (
            <FieldOfStudyList fieldOfStudyList={this.props.fieldOfStudyList}
                              itemType={FieldOfStudySearchRoute.FAVOURITE}/>
        );
    }
}

export default connect(
    (state: RootState) => ({
        fieldOfStudyList: studyFieldsListSelector(state),
    }),
    {}
)(withRouter(FavouriteList));
