import React from 'react';
import {CustomCard, Translation} from "educat-common-web";
import MeetingsTable from './MeetingsTable';
import {IOnlineConsultationItem} from '../types';


interface IExternalMeetingsCardProps {
    onlineConsultationList: IOnlineConsultationItem[] | null;
    fetchListAfterChange: () => void;
}

interface IMeetingsCardProps extends IExternalMeetingsCardProps {
}

interface IMeetingsCardState {
}


class MeetingsCard extends React.Component<IMeetingsCardProps, IMeetingsCardState> {
    render() {
        return (
            <CustomCard showLocalLoader={false}>
                <CustomCard.Body>
                    <div className="tasks-header">
                        <h2 className="secondary-title">
                            <Translation text={'application.yourMeetings.title'}/>
                        </h2>
                    </div>
                    <div className="table-wrapper">
                        <MeetingsTable onlineConsultationList={this.props.onlineConsultationList}
                                       fetchListAfterChange={this.props.fetchListAfterChange}/>
                    </div>
                </CustomCard.Body>
            </CustomCard>
        );
    }
}

export default MeetingsCard;
