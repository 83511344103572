import {CustomCard, Translation} from 'educat-common-web';
import React from 'react';
import CompetenceTestQuestion from './CompetenceTestQuestion';

type Props = {
    section: any;
};

class CompetenceTestSection extends React.Component<Props, any> {
    render() {
        const section = this.props.section;

        return (
            <CustomCard showLocalLoader={false}>
                <CustomCard.Header>
                    <div id={section.sectionId} className="header-section">
                        <h2 className="header-subtitle">
                            <Translation text="competence.competenceTestResult.sectionTitle"/>
                            <span className="highlight">{section.sectionName}</span>
                        </h2>
                    </div>
                </CustomCard.Header>
                <CustomCard.Body>{this.renderQuestionList(section.testRealisationQuestions)}</CustomCard.Body>
            </CustomCard>
        );
    }

    private renderQuestionList(realisationQuestions: any[]) {
        if (!realisationQuestions || realisationQuestions.length === 0) {
            return null;
        }
        return realisationQuestions.map((realisationQuestion: any) => (
            <CompetenceTestQuestion key={realisationQuestion.id} realisationQuestion={realisationQuestion}/>
        ));
    }
}

export default CompetenceTestSection;
