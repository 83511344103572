import React, {Component} from 'react';
import {catchError, map} from "rxjs/operators";
import {getServiceApplicationPackagesAPI} from "../../../api/getServiceApplicationPackages";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {of, Subscription} from 'rxjs';
import {getServiceApplicationElementsAPI} from '../../../api/getServiceApplicationElements';
import {
    changeBreadcrumbs,
    CustomCard,
    IModelServiceApplicationElement,
    IModelServiceApplicationPackage,
    Translation
} from "educat-common-web";
import styles from "../styles.module.scss";
import ApplicationsTable from "./ApplicationsTable";
import SelectApplicationModal from "./SelectApplicationModal";
import {MarketplaceServiceType} from "./SelectApplicationModal/MentorSelect";


interface IExternalMarketplaceApplicationsProps {
    readonly authToken: string | null;
    readonly changeBreadcrumbs: typeof changeBreadcrumbs;
}

interface IMarketplaceApplicationsProps extends IExternalMarketplaceApplicationsProps {
}

interface IMarketplaceApplicationsState {
    isLoading: boolean;
    applications: typeof IModelServiceApplicationPackage[] | [];
    applicationItems: typeof IModelServiceApplicationElement[] | [];
    isModalOpen: boolean;
}

class MarketplaceApplications extends Component<IMarketplaceApplicationsProps, IMarketplaceApplicationsState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IMarketplaceApplicationsProps) {
        super(props);

        this.state = {
            isLoading: true,
            applications: [],
            applicationItems: [],
            isModalOpen: false
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
            {labelKey: 'breadcrumbs.marketplace', path: '/panel/marketplace'},
            {labelKey: 'breadcrumbs.applicationPackages', path: '/panel/marketplace/applications'}
        ]);

        this.subscriptions.push(this.getApplications());
        this.subscriptions.push(this.getServiceApplicationElements());
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <div className="mentor-host application-packages">
                <div className="col-xl-10">
                    <header className="mentor-header">
                        <h1 className="mentor-title">
                            <Translation text={'marketplace.applications.title'}/>
                        </h1>
                        <p className="mentor-subtitle">
                            <Translation text={'marketplace.applications.description'}/>
                        </p>
                    </header>
                    <div className={styles.marketplaceDetails}>
                        <CustomCard showLocalLoader={this.state.isLoading}>
                            <CustomCard.Body>
                                <ApplicationsTable applications={this.state.applications}
                                                   applicationItems={this.state.applicationItems}
                                                   toggleModal={() => this.setState({isModalOpen: !this.state.isModalOpen})} />
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </div>

                {this.state.isModalOpen ?
                    <SelectApplicationModal isModalShown={this.state.isModalOpen}
                                            type={MarketplaceServiceType.SERVICE_APPLICATION_PACKAGE}
                                            toggleModal={() => this.setState({isModalOpen: !this.state.isModalOpen})}/>
                    : null
                }
            </div>
        );
    }

    private getApplications = () => {
        return getServiceApplicationPackagesAPI(this.props.authToken).pipe(
            map((resp: any) => {
                this.setState({isLoading: false, applications: resp['hydra:member'] || []});
            }),
            catchError((error: any) => {
                this.setState({isLoading: false});
                this.alertManager?.handleApiError(error);
                return of();
            })
        ).subscribe()
    };

    private getServiceApplicationElements = () => {
        return getServiceApplicationElementsAPI(this.props.authToken).pipe(
            map((resp: any) => {
                this.setState({applicationItems: resp['hydra:member'] || []});
            }),
            catchError((error: any) => {
                this.setState({isLoading: false});
                this.alertManager?.handleApiError(error);
                return of();
            })
        ).subscribe()
    }
}

export default MarketplaceApplications;
