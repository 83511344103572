import React from 'react';
import {Form, FormControlChangeType, IFormConfig, IMultiselectOption, isSameValue} from 'educat-common-web';
import {sortFormConfig} from './sortFormConfig';
import {filter, tap} from "rxjs/operators";
import {BehaviorSubject, Subscription} from "rxjs";


interface IConnectedSortingFormProps {
}

interface IExternalSortingFormProps {
    sortingValues: typeof IMultiselectOption;
    sort: (value: any) => void;
}

interface ISortingFormProps extends IConnectedSortingFormProps,
    IExternalSortingFormProps {
}

interface ISortingFormState {
    formConfig: typeof IFormConfig;
    value: any;
}


class SortingForm extends React.Component<ISortingFormProps, ISortingFormState> {
    private subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(props: ISortingFormProps) {
        super(props);

        this.state = {
            formConfig: null,
            value: null
        }

    }

    componentDidMount(): void {
        this.setFormConfig();
        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any)  => this.onFormValueChange(data.value)),
            ).subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<ISortingFormProps>, prevState: Readonly<ISortingFormState>, snapshot?: any): void {
        if (!isSameValue(this.props.sortingValues, prevProps.sortingValues)) {
            this.setFormConfig();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <div>
                <Form config={this.state.formConfig}
                      value={this.state.value}
                      onValueStateChange={this.onValueStateChange}
                      controlName={'sortFormConfig'} />
            </div>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onFormValueChange = (value: any) => {
        this.props.sort(value.sort);
        this.setState({value: value});
    };

    private setFormConfig = (): void => {
        const formConfig = sortFormConfig(
                this.props.sortingValues
            );

        this.setState({formConfig});
    };
}

export default SortingForm;
