import {
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    MultiSelectType,
    multiCollectionInputDataMapper,
    multiCollectionOutputDataMapper
} from 'educat-common-web';


export const studySelectFormConfig: typeof IFormConfig = (
    countriesList?: typeof IMultiselectOption[] | [],
    studySubjectsList?: typeof IMultiselectOption[] | [],
    handleMultiselectInputChange?: any) => {
    return {
        class: 'school-search-form',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);

            return previousStateSnapshot;
        },
        controls: [
            {
                controlType: 'group',
                key: 'search_school',
                class: 'row w-100 school-search-row',
                controls: {
                    studySubject: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.GROUPED,
                        multiselectOptions: studySubjectsList ? studySubjectsList : [],
                        validationRules: [],
                        placeholder: 'fieldOfStudy.form.placeholder',
                        label: 'fieldOfStudy.form.controls.whatToStudy',
                        hostClass: 'col-xl-6 form-row study-form-control',
                        inputDataMapper: multiCollectionInputDataMapper,
                        outputDataMapper: multiCollectionOutputDataMapper,
                        isGroupedLabelTranslated: true,
                        openMenuOnClick: false,
                        hideValueOnFocus: true,
                        handleMultiselectInputChange: (value: any) => {
                            if (handleMultiselectInputChange) {
                                return handleMultiselectInputChange(value, 'studySubject');
                            }
                        },
                    },
                    whereToStudy: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.GROUPED,
                        multiselectOptions: countriesList ? countriesList : [],
                        validationRules: [],
                        placeholder: 'fieldOfStudy.form.placeholder',
                        label: 'fieldOfStudy.form.controls.whereToStudy',
                        hostClass: 'col-xl-6 form-row study-form-control',
                        inputDataMapper: multiCollectionInputDataMapper,
                        outputDataMapper: multiCollectionOutputDataMapper,
                        openMenuOnClick: false,
                        hideValueOnFocus: true,
                        handleMultiselectInputChange: (value: any) => {
                            if (handleMultiselectInputChange) {
                                return handleMultiselectInputChange(value, 'whereToStudy');
                            }
                        },
                        isGroupedLabelTranslated: true
                    }
                }
            }
        ]
    }
};
