import {authTokenSelector, IModelCartServiceInstance, Price, Translation} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';
import {RootState} from '../../../../../store/reducers';
import {addItemToCart} from '../../../../../store/reducers/cartSlice';
import {isCartLoadingSelector} from '../../../../../store/selectors/cartSelectors';
import styles from './styles.module.scss';
import {catchError, tap} from "rxjs/operators";
import {fixInjectedProperties, lazyInject} from "../../../../../ioc";
import {IAlertManagerService} from "../../../../../service/alertManagerService";
import {of, Subscription} from "rxjs";
import {createTestFromPackageAPI} from "../../../../../api/createTestFromPackage";


export interface IConnectedSubjectTestCardProps {
    readonly authToken: string | null;
    readonly isCartProcessing: boolean;
    readonly addItemToCart: typeof addItemToCart;
}

export interface IExternalSubjectTestCardProps {
    readonly subjectTest: any;
    readonly packageServiceInstances?: typeof IModelCartServiceInstance[] | [];
    readonly onSubmit?: (isLoading: boolean) => void;
}

export interface ISubjectTestCardProps extends
    IConnectedSubjectTestCardProps,
    IExternalSubjectTestCardProps,
    RouteComponentProps {}

class SubjectTestCard extends React.Component<ISubjectTestCardProps, any> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: ISubjectTestCardProps) {
        super(props);

        this.state = {};

        fixInjectedProperties(this);
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        const subjectTest = this.props.subjectTest,
            price = subjectTest.finalGrossPrice;

        return (
            <div className={`col-xl-3 col-lg-6 ${styles.subjectTestCardContainer}`}>
                <div className={styles.subjectTestCard}>
                    <div
                        style={subjectTest.serviceSubjectTest.mediaObject?.smallThumb ? {backgroundImage: `url(${subjectTest.serviceSubjectTest.mediaObject?.mediumThumb})`} : undefined}
                        className={styles.subjectTestLogo}/>
                    <div className={styles.details}>
                        <div className={styles.name}> {subjectTest.name} </div>
                        {this.renderTestLevel(subjectTest.serviceSubjectTest.level)}
                        <p className={styles.priceInfo}>
                            <Price price={price} separateWithNbsp={true}/>
                        </p>
                        <div className={styles.buttonWrapper}>{this.renderActionButton(subjectTest)}</div>
                    </div>
                </div>
            </div>
        );
    }

    private renderTestLevel(level: any) {
        if (!level) {
            return null;
        }

        return <span>
            <Translation text={`competence.subjectTests.level.${level}`}/>
        </span>;
    }

    private renderActionButton(item: any) {
        const isPackageTestAvailable = undefined !== this.props.packageServiceInstances && this.props.packageServiceInstances.length,
            btnText = isPackageTestAvailable ? 'competence.doTheTest' : 'competence.addToCart',
            btnStyle = isPackageTestAvailable ? 'btn-theme-outline' : 'btn-theme';

        return item.currentApplicantServiceInstance ? (
            <Link className="btn btn-theme-outline competence"
                  to={{
                      pathname: `/panel/diagnostics/test/${item.currentApplicantServiceInstance.id}`,
                      state: {id: item.currentApplicantServiceInstance.id, name: item.name},
                  }}
                  style={{textDecoration: 'none'}}>
                <span>
                    <Translation text={`competence.doTheTest`}/>
                </span>
            </Link>
        ) : (
            <button className={`btn competence ${btnStyle}`} onClick={() => !isPackageTestAvailable ? this.addToCart(item) : this.redirectToTest(item)}>
                <span>
                    <Translation text={btnText}/>
                </span>
            </button>
        );
    }

    private addToCart = (item: any): void => {
        if (this.props.isCartProcessing) {
            return;
        }

        this.props.addItemToCart(item);
    };

    private redirectToTest = (item: any): void => {
        if (undefined === this.props.packageServiceInstances || !this.props.packageServiceInstances.length) {
            return;
        }

        if (this.props.onSubmit) {
            this.props.onSubmit(true);
        }
        const serviceInstanceId = this.props.packageServiceInstances[0].id;
        this.subscriptions.push(
            createTestFromPackageAPI(this.props.authToken, serviceInstanceId, item.id)
                .pipe(
                    tap((resp: typeof IModelCartServiceInstance) => {
                        if (this.props.onSubmit) {
                            this.props.onSubmit(false);
                        }
                        this.props.history.push(`/panel/diagnostics/test/${resp.id}`, {name: resp.name, id: resp?.serviceInstance?.serviceDefinition?.name});
                    }),
                    catchError((error: any) => {
                        if (this.props.onSubmit) {
                            this.props.onSubmit(false);
                        }
                        this.alertManager?.handleApiError(error.response);
                        return of();
                    })
                )
                .subscribe()
        );
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        isCartProcessing: isCartLoadingSelector(state),
    }),
    {
        addItemToCart,
    }
)(withRouter(SubjectTestCard));
