import {
    Form,
    FormControlChangeType,
    IFormConfig,
    isNotNullOrUndefined,
    isNullOrUndefined,
    isSameValue,
    RestQueryParams
} from "educat-common-web";
import moment from "moment";
import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {BehaviorSubject, Subscription} from "rxjs";
import {debounceTime, filter, tap} from "rxjs/operators";
import {PeriodFilterType} from "../OperationHistoryTable/types";
import {operationHistoryFiltersConfig} from "./operationHistoryFiltersConfig";

interface IExternalOperationHistoryFiltersProps {
    readonly filtersChange: (params: typeof RestQueryParams) => void;
}

interface IOperationHistoryFiltersProps
    extends IExternalOperationHistoryFiltersProps,
        WithTranslation {
}

interface IOperationHistoryFiltersState {
    formConfig: typeof IFormConfig;
    value: any;
}

class OperationHistoryFilters extends React.Component<IOperationHistoryFiltersProps, IOperationHistoryFiltersState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IOperationHistoryFiltersProps) {
        super(props);

        this.state = {
            formConfig: operationHistoryFiltersConfig,
            value: null,
        };
    }

    componentDidMount(): void {
        const {t} = this.props,
            defaultOptions = [
                {
                    value: PeriodFilterType.WEEKS,
                    label: t(`payments.operationHistory.filters.forPeriod.week`),
                    isDisabled: false,
                },
                {
                    value: PeriodFilterType.MONTHS,
                    label: t(`payments.operationHistory.filters.forPeriod.month`),
                    isDisabled: false,
                },
                {
                    value: PeriodFilterType.YEARS,
                    label: t(`payments.operationHistory.filters.forPeriod.year`),
                    isDisabled: false,
                },
            ];

        let config = Object.assign({}, this.state.formConfig);
        config.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === "period") {
                        control.controls[key].multiselectOptions = defaultOptions;
                    }
                    return control.controls[key];
                });
            }

            return control;
        });

        this.setState({formConfig: config});

        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    debounceTime(500),
                    tap((data: any) => this.onFormValueChange(data.value))
                )
                .subscribe()
        );
    }

    componentDidUpdate(
        prevProps: Readonly<IOperationHistoryFiltersProps>,
        prevState: Readonly<IOperationHistoryFiltersState>,
        snapshot?: any
    ): void {
        if (!isSameValue(this.state.value, prevState.value)) {
            let params = new RestQueryParams();
            Object.keys(this.state.value).forEach(key => {
                if (isNullOrUndefined(this.state.value[key])) {
                    return;
                }
                const dateValue = key === 'createdAt[before]' ?
                    moment(this.state.value[key]).endOf('day').toString() :
                    moment(this.state.value[key]).startOf('day').toString();
                params = params.add(key, dateValue);
            });
            return this.props.filtersChange(params);
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <Form
                config={this.state.formConfig}
                value={this.state.value}
                onValueStateChange={this.onValueStateChange}
                controlName={"operationHistoryFiltersConfig"}
            />
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onFormValueChange = (value: any) => {
        if (isNotNullOrUndefined(value.period)) {
            const from = moment(new Date())
                    .subtract(1, value.period)
                    .toDate(),
                to = new Date();
            return this.setState({value: {[`createdAt[after]`]: from, [`createdAt[before]`]: to}});
        }
        this.setState({value: value});
    };
}

export default withTranslation()(OperationHistoryFilters);
