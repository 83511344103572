import {deepCloneObject, FormControlType, IFormConfig, InputType, isNullOrUndefined, ValidationRules} from 'educat-common-web';


export const competenceFormFormConfig: typeof IFormConfig = (value: any, section: any) => {
    const testRealisationQuestions = section.testRealisationQuestions;

    let controls: any[] = [];
    if (testRealisationQuestions.length > 0) {
        controls = createFormControlsFromQuestion(value, testRealisationQuestions);
    }


    return {
        controlType: 'form',
        class: 'default-form',
        dataAccessor: (data: any) => {
            if (isNullOrUndefined(data)) {
                return data;
            }
            return data;
        },
        inputDataMapper: (data: any) => {
            return data;
        },
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);

            return previousStateSnapshot;
        },
        controls: [
            {
                key: `competenceTest`,
                controlType: 'group',
                fieldsetTitle: null,
                class: 'row help-range-form-group',
                controls: controls
            }
        ]
    };
};

const createFormControlsFromQuestion = (value: any, testRealisationQuestions: any[]) => {
    const controls: any = {};
    testRealisationQuestions.forEach((testRealisationQuestion: any) => {
        const answerArray = deepCloneObject(testRealisationQuestion.question.answers).map((answer: any) => {
            return {
                value: answer.id, displayValue: answer.content
            }
        });
        controls[testRealisationQuestion.id] = {
            hostClass: ' form-row  required mb-4',
            controlType: 'control',
            // defaultValue: null,
            formControlType: FormControlType.RADIO,
            options: answerArray,
            validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
            placeholder: 'profile.profileForm.selectPlaceholder',
            richTextLabel: testRealisationQuestion.question.content,
            type: InputType.RADIO,
        };
    });

    return controls;
};
