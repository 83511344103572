import {CustomCard, isNotEmpty, Translation} from 'educat-common-web';
import React from 'react';
import styles from '../styles.module.scss';

interface IRealmAndRequirementsCardProps {
    realm: any;
    requirements: any;
    languageRequirements: any;
}

class RealmAndRequirementsCard extends React.Component<IRealmAndRequirementsCardProps> {
    render() {
        return (
            <CustomCard showLocalLoader={false}>
                <CustomCard.Body>
                    <h2 className="secondary-title">
                        <Translation text="fieldOfStudy.fieldOfStudyProfile.fields"/>
                    </h2>
                    <div className="subjects">{this.renderRealms(this.props.realm)}</div>
                    <div className="mt-5 row">
                        <div className="col-xl-6">
                            <h3 className="secondary-title">
                                <Translation text="fieldOfStudy.fieldOfStudyProfile.subjectRequirements"/>
                            </h3>
                            {this.renderRequirements(this.props.requirements.html)}
                        </div>
                        <div className="col-xl-6">
                            <h3 className="secondary-title">
                                <Translation text="fieldOfStudy.fieldOfStudyProfile.languageRequirements.title"/>
                            </h3>
                            <div
                                className={styles.requirementsWrapper}>{this.renderLanguageRequirements(this.props.languageRequirements)}</div>
                        </div>
                    </div>
                </CustomCard.Body>
            </CustomCard>
        );
    }

    private renderRealms(realm: any) {
        return realm ? (
            <div className="subject-item" key={realm.id}>
                <p>{realm.name}</p>
            </div>
        ) : (
            <div>
                <Translation text="fieldOfStudy.fieldOfStudyProfile.noRealms"/>
            </div>
        );
    }

    private renderRequirements(requirements: any) {
        return requirements ? (
            <div
                className={styles.requirementsWrapper}
                dangerouslySetInnerHTML={{
                    __html: requirements,
                }}
            />
        ) : (
            <div>
                <Translation text="fieldOfStudy.fieldOfStudyProfile.noRequirements"/>
            </div>
        );
    }

    private renderLanguageRequirements(languageRequirements: any) {
        if (!languageRequirements) {
            return (
                <div>
                    <Translation text="fieldOfStudy.fieldOfStudyProfile.noRequirements"/>
                </div>
            );
        }
        return <ul className={styles.languageRequirementsList}>{this.generateRequirementsFromKeys(languageRequirements)}</ul>;
    }

    private generateRequirementsFromKeys(languageRequirements: any) {
        return Object.keys(languageRequirements)
            .filter(requirementItemKey => isNotEmpty(languageRequirements[requirementItemKey]))
            .map((requirementItemKey: any, index: number) => (
                <li key={`${requirementItemKey}_${index}`} className={styles.languageRequirement}>
                    <span className={styles.requirementName}>
                        <Translation text={`fieldOfStudy.fieldOfStudyProfile.languageRequirements.${requirementItemKey}`}/>
                    </span>
                    <span>{isNaN(Number(languageRequirements[requirementItemKey])) ? '--' : languageRequirements[requirementItemKey]}</span>
                </li>
            ));
    }
}

export default RealmAndRequirementsCard;
