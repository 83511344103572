import { createSelector } from "@reduxjs/toolkit";
import {RootState} from "../reducers";
import {IStudyFieldSearchState} from "../reducers/studyFieldsSearchSlice";


export const selectStudyFieldsSearch = (state: RootState): IStudyFieldSearchState => {
    return state.studyFieldsSearch;
};

export const showStudyFieldsSearchSelector = createSelector(
    [selectStudyFieldsSearch],
    (state: IStudyFieldSearchState) => state.showStudyFieldsSearch
);

export const studyFieldsFiltersSelector = createSelector(
    [selectStudyFieldsSearch],
    (state: IStudyFieldSearchState) => state.filters
);

export const studyFieldsSortingSelector = createSelector(
    [selectStudyFieldsSearch],
    (state: IStudyFieldSearchState) => state.sorting
);

export const studyFieldsPaginationSelector = createSelector(
    [selectStudyFieldsSearch],
    (state: IStudyFieldSearchState) => state.pagination
);

export const studyFieldsListTypeSelector = createSelector(
    [selectStudyFieldsSearch],
    (state: IStudyFieldSearchState) => state.listType
);

export const studyFieldsListSelector = createSelector(
    [selectStudyFieldsSearch],
    (state: IStudyFieldSearchState) => state.fieldsList.list
);

export const studyFieldsListMetadataSelector = createSelector(
    [selectStudyFieldsSearch],
    (state: IStudyFieldSearchState) => state.fieldsList.metadata
);

export const isMainSearchLoadingSelector = createSelector(
    [selectStudyFieldsSearch],
    (state: IStudyFieldSearchState) => state.isLoading
);
