import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export function getSchoolStudyFieldsAPI(authToken: string | null, params?: typeof RestQueryParams): Observable<any> {
    let queryParams = params ? params : new RestQueryParams(),
        headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.get(
        'school_study_fields',
        queryParams,
        headers
    );
}
