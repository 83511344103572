import React from 'react';
import {Pagination} from 'educat-common-web';
import styles from "./styles.module.scss";
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {studyFieldsListMetadataSelector} from "../../../../store/selectors/studyFieldsSearchSelectors";
import {changeStudyFieldsPagination, IFiltersListMetadata} from "../../../../store/reducers/studyFieldsSearchSlice";

interface IConnectedFieldOfStudyFooterProps {
    readonly listMetadata: IFiltersListMetadata | null;
    readonly changeStudyFieldsPagination: typeof changeStudyFieldsPagination;
}

interface IExternalFieldOfStudyFooterProps {
    currentPage?: number;
    lastPage?: number;
}

interface IFieldOfStudyFooterProps extends IConnectedFieldOfStudyFooterProps,
    IExternalFieldOfStudyFooterProps,
    RouteComponentProps {
}

interface IFieldOfStudyFooterState {
}

class FieldOfStudyFooter extends React.Component<IFieldOfStudyFooterProps, IFieldOfStudyFooterState> {
    render() {
        return (
            <footer className={styles.sectionFooter}>
                <Pagination listMetadata={this.props.listMetadata} changePage={this.props.changeStudyFieldsPagination}/>
            </footer>
        );
    }
}

export default connect(
    (state: RootState) => ({
        listMetadata: studyFieldsListMetadataSelector(state)
    }),
    {
        changeStudyFieldsPagination
    }
)(withRouter(FieldOfStudyFooter));
