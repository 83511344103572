import React from 'react';
import styles from './styles.module.scss';
import {Translation, Price, IModelCart, isNullOrUndefined} from 'educat-common-web';
import {Link} from "react-router-dom";

interface IConnectedCartSummaryCardProps {
}

interface IExternalCartSummaryCardProps {
    readonly cart: typeof IModelCart | null;
}

interface ICartSummaryCardProps extends IConnectedCartSummaryCardProps,
    IExternalCartSummaryCardProps {
}

interface ICartSummaryCardState {
    isPaymentModalVisible: boolean;
}


class CartSummaryCard extends React.Component<ICartSummaryCardProps, ICartSummaryCardState> {
    constructor(props: ICartSummaryCardProps) {
        super(props);

        this.state = {
            isPaymentModalVisible: false
        }
    }

    render() {
        if (!this.props.cart) {
            return null;
        }

        return (
            <React.Fragment>
                <div className={styles.cartTotal}>
                    <p className={styles.title}>
                        <Translation text="cart.summary.title"/>
                    </p>
                    <p className={styles.price}><Price price={this.props.cart?.finalGrossPrice}/></p>
                    <div className="button-wrapper justify-content-center">
                        <Link to="/panel/cart/payment" className={"btn btn-theme btn-rounded" + (this.paymentBlocked ? " disabled" : "")}>
                            <Translation text={'cart.summary.paymentRedirect'}/>
                        </Link>
                    </div>
                </div>

            </React.Fragment>
        );
    }

    private get paymentBlocked(): boolean {
        return isNullOrUndefined(this.props.cart) || 0 === this.props.cart.serviceInstances.length;
    }

}

export default CartSummaryCard;
