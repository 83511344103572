import React from 'react';
import styles from '../styles.module.scss';

interface IFieldLogoCardProps {
    logo: any;
}

interface IFieldLogoCardState {
}

class FieldLogoCard extends React.Component<IFieldLogoCardProps, IFieldLogoCardState> {
    render() {
        const logoUrl = this.props.logo ? this.props.logo.mediumThumb : '';

        return (
            <div className={styles.schoolBg}>
                <div className={styles.schoolLogo} style={{backgroundImage: `url(${logoUrl})`}} />
            </div>
        );
    }
}

export default FieldLogoCard;
