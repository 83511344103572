import React from 'react';
import {
    changePasswordStart,
    CustomCard,
    IFormConfig,
    Form,
    Translation,
    passwordLoadingSelector
} from "educat-common-web";
import {changePasswordFormConfig} from './changePasswordFormConfig';
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";


interface IConnectedChangePasswordProps {
    readonly isLoading: boolean;
    readonly changePasswordStart: typeof changePasswordStart;
}
interface IExternalChangePasswordCardProps {
}

interface IChangePasswordCardProps extends
    IConnectedChangePasswordProps,
    IExternalChangePasswordCardProps {
}

interface IChangePasswordCardState {
    value: any;
    formConfig: typeof IFormConfig;
}


class ChangePasswordCard extends React.Component<IChangePasswordCardProps, IChangePasswordCardState> {
    constructor(props: IChangePasswordCardProps) {
        super(props);
        this.state = {
            value: null,
            formConfig: changePasswordFormConfig
        };
    }

    render() {
        return (
            <CustomCard showLocalLoader={this.props.isLoading}>
                <CustomCard.Body>
                    <h2 className="secondary-title">
                        <Translation text={'profile.changePasswordForm.title'} />
                    </h2>
                    <Form config={this.state.formConfig}
                          submitForm={this.changePassword}
                          value={this.state.value}
                          controlName={'changePasswordForm'} />
                </CustomCard.Body>
            </CustomCard>
        );
    }

    private changePassword = (event: any, value: any, valid: boolean, touched: boolean): void => {
        if (!valid || !touched) {
            return;
        }

        this.props.changePasswordStart(value.currentPassword, value.password);
    }
}

export default connect(
    (state: RootState) => ({
        isLoading: passwordLoadingSelector(state)
    }),
    {
        changePasswordStart
    }
)(ChangePasswordCard);
