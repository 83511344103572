import {changeBreadcrumbs} from 'educat-common-web';
import React from 'react';
import { connect } from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import FieldOfStudyList from '../Common/FieldOfStudyList';
import {FieldOfStudySearchRoute} from '../MainSearch';
import {studyFieldsListSelector} from "../../../store/selectors/studyFieldsSearchSelectors";
import {RootState} from "../../../store/reducers";


interface IConnectedAppliedListProps {
    readonly fieldOfStudyList: any[];
}

interface IExternalAppliedListProps {
    readonly changeBreadcrumbs: typeof changeBreadcrumbs;
}

interface IAppliedListProps extends IConnectedAppliedListProps,
    IExternalAppliedListProps,
    RouteComponentProps {
}

interface IAppliedListState {
}


class AppliedList extends React.Component<IAppliedListProps, IAppliedListState> {

    componentDidMount(): void {
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
            {labelKey: 'breadcrumbs.fields', path: '/panel/field-of-study-search'},
            {labelKey: 'breadcrumbs.appliedFields', path: '/panel/field-of-study-search/applied'},
        ]);
    }

    render() {
        return (
            <FieldOfStudyList fieldOfStudyList={this.props.fieldOfStudyList} itemType={FieldOfStudySearchRoute.APPLIED}/>
        );
    }
}

export default connect(
    (state: RootState) => ({
        fieldOfStudyList: studyFieldsListSelector(state),
    }),
    {}
)(withRouter(AppliedList));
