import {IModelCartGenericServiceExtraData, Price, Translation} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {addItemToCart} from '../../../../store/reducers/cartSlice';
import {isCartLoadingSelector} from '../../../../store/selectors/cartSelectors';
import styles from './styles.module.scss';
import {NavLink} from "react-router-dom";

interface ICompetenceTestCardProps {
    readonly competenceTest: any;
    readonly isCartProcessing: boolean;
    readonly addItemToCart: typeof addItemToCart;
    readonly isBuyTestModal?: boolean;
    readonly isMorrisbyDetailsView?: boolean;
    readonly genericServiceExtraData?: typeof IModelCartGenericServiceExtraData;
}

class CompetenceTestCard extends React.Component<ICompetenceTestCardProps, any> {
    render() {
        const competenceTest = this.props.competenceTest,
            price = competenceTest.finalGrossPrice,
            logo = this.props.isBuyTestModal ?
                competenceTest.serviceTestPackage?.mediaObject?.mediumThumb :
                competenceTest.serviceCompetenceTest?.mediaObject?.mediumThumb,
            isModalView = this.props.isBuyTestModal;

        return (
            <div className={`container ${styles.competenceTestCardContainer}`}>
                <div className={`row ${styles.competenceTestCard} ${this.props.isMorrisbyDetailsView ? styles.competenceDetailsCard : ''}`}>
                    {logo ? (<div className={`col-xl-3 ${styles.competenceTestLogo}`}
                         style={logo ? {backgroundImage: `url(${logo})`} : undefined}/>) : null
                    }
                    <div className={`${!logo ? 
                        isModalView ? 'col-xl-7' : 'col-xl-8' : 
                        isModalView ? 'col-xl-4' : 'col-xl-6'} ${styles.name}`}>
                        {this.props.isMorrisbyDetailsView || isModalView ?
                            (<span className={styles.fieldOfStudyName} style={{textDecoration: 'none'}}>
                                {isModalView ? competenceTest.name : <Translation text="competence.predispositionDiagnostics.morrisbyTest"/>}
                                {/*Test name is changed in current version, uncomment below and remove above*/}
                                {/*{competenceTest.name}*/}
                            </span>) :
                            (<NavLink to="diagnostics/morrisby-test" className={styles.fieldOfStudyName}
                                      style={{textDecoration: 'none'}} exact={true}>
                                {isModalView ? competenceTest.name : <Translation text="competence.predispositionDiagnostics.morrisbyTest"/>}
                                {/*Test name is changed in current version, uncomment below and remove above*/}
                                {/*{competenceTest.name}*/}
                            </NavLink>)
                        }
                    </div>
                    <div className={`${!logo ? 
                        isModalView ? 'col-xl-5' : 'col-xl-4' : 
                        isModalView ? 'col-xl-5' : 'col-xl-3'} ${styles.details} ${this.props.isBuyTestModal ? styles.modalTest : ''}`}>
                        <div className={styles.priceDetails}>
                            <p className={styles.priceLabel}><Translation text={'competence.predispositionDiagnostics.testPrice'}/></p>
                            <p className={styles.priceInfo}>
                                <Price price={price} separateWithNbsp={true}/>
                            </p>
                        </div>
                        <div className={styles.buttonWrapper}>{this.renderActionButton(competenceTest)}</div>
                    </div>
                </div>
            </div>
        );
    }

    private renderActionButton(item: any) {
        if (this.props.isBuyTestModal) {
            return (
                <button className="btn btn-theme competence" onClick={() => this.addToCart(item)}>
                    <span>
                        <Translation text={`competence.buyTest.buyTestModal.buyNow`}/>
                    </span>
                </button>
            );
        }

        return (
            <button className="btn btn-theme competence" onClick={() => this.addToCart(item)}>
                <span>
                    <Translation text={`competence.addToCart`}/>
                </span>
            </button>
        );
    }

    private addToCart = (item: any): void => {
        if (this.props.isCartProcessing) {
            return;
        }

        this.props.addItemToCart(item, undefined, this.props.genericServiceExtraData);
    };
}

export default connect(
    (state: RootState) => ({
        isCartProcessing: isCartLoadingSelector(state),
    }),
    {
        addItemToCart,
    }
)(CompetenceTestCard);
