import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export function getSchoolStudyFieldAPI(id: string, authToken: string): Observable<any> {
    return educatAPI.get(
        `school_study_fields/${id}`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
