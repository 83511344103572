import {combineEpics, Epic, ofType, StateObservable} from "redux-observable";
import {concatMap, debounceTime, distinctUntilChanged, filter, map, mergeMap, switchMap, tap} from "rxjs/operators";
import {BehaviorSubject, of} from "rxjs";
import {
    accountSelector,
    addAlert,
    AlertType,
    authTokenSelector,
    deepCloneObject,
    flattenObj,
    IAccount,
    isNotNullOrUndefined,
    isNullOrUndefined,
    isSameValue,
    RestQueryParams
} from "educat-common-web";
import {getMentorsAPI} from "../../api/getMentors";
import {
    applyMentorsFilters,
    changeMentorSearchLoading,
    changeMentorsFilters,
    changeMentorsList,
    changeMentorsPagination,
    changeMentorsSorting,
    IMentorFilters,
    MentorHostType,
    resetMentorsFiltersToDefaultAccountFilters
} from "../reducers/mentorsSearchSlice";
import {RootState} from "../reducers";
import {
    mentorFiltersSelector,
    mentorHostTypeSelector,
    mentorsPaginationSelector,
    mentorsSortingSelector
} from "../selectors/mentorsSearchSelectors";
import {IFiltersListMetadata, SortingParams} from "../reducers/studyFieldsSearchSlice";
import {FetchAction, getAction, getErrorMessage, getList} from "./studyFieldsSearchEpic";


const mentorTypeParams = {
    type: ['mentor', 'mentor_scholar']
};

const tutorTypeParams = {
    type: ['scholar', 'mentor_scholar']
};


const resetMentorsFiltersToDefaultAccountFiltersEpic: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(resetMentorsFiltersToDefaultAccountFilters.type),
        map(() => accountSelector(state$.value)),
        mergeMap((account: typeof IAccount): any => {
            const disabledFilters = localStorage.getItem('disabledDefaultFilters');
            const filters: IMentorFilters | null = {
                countries: null,
                mentorSchoolStudyFields: {
                    school: {
                        id: !disabledFilters?.includes('school') ? account?.profileSchoolStudyFields?.school?.id : null
                    }
                },
                realms: null,
                studyFields: {
                    id: !disabledFilters?.includes('studyField') ? account?.profileSchoolStudyFields?.studyField?.id : null
                },
                basePrice: null,
                free_terms: null,
                languages: null,
                mentorTaskTypes: null
            };

            return of(
                changeMentorsFilters(filters),
            );
        })
    );

const applyMentorsSearchFilters: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(action$, state$, applyMentorsFilters, doFetch);

const changeMentorsSearchSorting: Epic = (action$, state$: StateObservable<any>) =>
    getAction(action$, state$, changeMentorsSorting, doFetch);


const changeMentorsSearchPagination: Epic = (action$, state$: StateObservable<any>) =>
    getAction(action$, state$, changeMentorsPagination, doFetch);

const fetchSubject = new BehaviorSubject<FetchAction>({token: null, flattenedParams: null});
const resultsSubject = new BehaviorSubject<any>(null);
fetchSubject.asObservable().pipe(
    debounceTime(250),
    distinctUntilChanged(isSameValue),
    switchMap(fetch => {
        if (isNullOrUndefined(fetch.token)) {
            return of(null);
        }

        return getMentorsList(fetch.token as string, new RestQueryParams(fetch.flattenedParams));
    }),
    tap(action => resultsSubject.next(action))
).subscribe(); // subscription with same lifetime as the application, no need to unsubscribe

const doFetch = (state: RootState) => {
    const authToken = authTokenSelector(state),
        paginationParams = mentorsPaginationSelector(state),
        filters = deepCloneObject(mentorFiltersSelector(state)),
        sorting = mentorsSortingSelector(state),
        mentorHostType = mentorHostTypeSelector(state),
        typeParams = mentorHostType === MentorHostType.MENTOR ? mentorTypeParams : tutorTypeParams;
    filters.hidden = false;
    if (isNotNullOrUndefined(filters?.mentorSchoolStudyFields?.school?.id)) {
        filters.mentorSchoolStudyFields = {
            schoolStudyFields: {
                school: {
                    id: filters.mentorSchoolStudyFields.school.id,
                },
            },
        };
    }
    if (isNotNullOrUndefined(filters?.studyFields?.id)) {
        filters.mentorSchoolStudyFields = {
            schoolStudyFields: {
                ...filters?.mentorSchoolStudyFields?.schoolStudyFields,
                studyField: {
                    id: filters.studyFields.id,
                },
            },
        };
        delete filters.studyFields;
    }
    if (isNotNullOrUndefined(filters?.basePrice)) {
        const parts = filters.basePrice.split('..');
        filters['basePrice[between]'] = `${parts[0] * 100}..${parts[1] * 100}`;
        delete filters.basePrice;
    }

    const filterObj = {...filters, ...paginationParams, ...typeParams},
        flattened = flattenObj(filterObj);

    switch (sorting) {
        case SortingParams.TITLE_ASC:
            flattened.push({path: 'order[account.displayName]', val: 'ASC'});
            break;

        case SortingParams.TITLE_DESC:
            flattened.push({path: 'order[account.displayName]', val: 'DESC'});
            break;
    }

    fetchSubject.next({token: authToken, flattenedParams: flattened});

    return resultsSubject.asObservable().pipe(
        filter((action: any) => null !== action),
        concatMap(action => of(action, changeMentorSearchLoading(false))),
    );
};

function getMentorsList(authToken: string, params?: typeof RestQueryParams) {
    return getList(getMentorsAPI(authToken, params), updateListSuccessActions, updateListErrorActions);
}

const updateListSuccessActions = (list: any[], metadata: IFiltersListMetadata | null): any[] => {
    return [
        changeMentorSearchLoading(false),
        changeMentorsList({
            list: list,
            metadata: metadata,
        })
    ]
};

const updateListErrorActions = (error: any): any[] => {
    return [
        changeMentorSearchLoading(false),
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
    ]
};

const mentorsSearchEpic = combineEpics(
    applyMentorsSearchFilters,
    changeMentorsSearchPagination,
    changeMentorsSearchSorting,
    resetMentorsFiltersToDefaultAccountFiltersEpic
);

export default mentorsSearchEpic;
