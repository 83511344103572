import React, {Component} from 'react';
import {
    authTokenSelector,
    BasicModal,
    CustomCard,
    CustomCardType,
    Form,
    FormControlChangeType,
    IFormConfig,
    IModelCart,
    IPrice,
    Price,
    refreshAccountStateData,
    Translation
} from 'educat-common-web';
import {CardNumberElement} from '@stripe/react-stripe-js';
import {StripeCardElement} from '@stripe/stripe-js';
import {fixInjectedProperties, lazyInject} from '../../../../ioc';
import {IAlertManagerService} from '../../../../service/alertManagerService';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {catchError, filter, switchMap, tap} from 'rxjs/operators';
import {paymentFormConfig} from './paymentFormConfig';
import styles from './styles.module.scss';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {getPaymentSecret} from '../../../../api/getPaymentSecret';
import {confirmPaymentAPI} from '../../../../api/confirmPayment';
import {createPaymentAPI} from '../../../../api/createPayment';
import {resetCart} from '../../../../store/reducers/cartSlice';
import {WithTranslation, withTranslation} from "react-i18next";


interface IConnectedPaymentModalProps {
    readonly authToken: string | null;
    readonly resetCart: typeof resetCart;
    readonly refreshAccountStateData: typeof refreshAccountStateData;
}

interface IExternalPaymentModalProps {
    isModalVisible: boolean;
    closeModal: () => void;
    cart: typeof IModelCart | null;
}

interface IPaymentModalProps extends IConnectedPaymentModalProps,
    IExternalPaymentModalProps,
    RouteComponentProps,
    WithTranslation {
}

interface IPaymentModalState {
    isLoading: boolean;
    formConfig: typeof IFormConfig;
    value: any;
    isPaymentProcessed: boolean;
    isPaymentProcessing: boolean;
}

class PaymentModal extends Component<IPaymentModalProps, IPaymentModalState> {
    private subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private stripe: any;
    private elements: any;
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IPaymentModalProps) {
        super(props);

        this.state = {
            isLoading: false,
            formConfig: null,
            value: null,
            isPaymentProcessed: false,
            isPaymentProcessing: false
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.setFormConfig();

        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onFormValueChange(data.value)),
            ).subscribe()
        );
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.setState({value: null, formConfig: null});
    }

    render() {
        return (
            <React.Fragment>
                <BasicModal isModalShown={this.props.isModalVisible}
                            closeModal={this.closeModal}>
                    <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                        {this.state.isPaymentProcessed ? this.renderPaymentSuccess() : this.renderPaymentContent()}
                    </CustomCard>
                </BasicModal>
            </React.Fragment>
        );
    }

    private renderPaymentContent = () => {
        return (
            <React.Fragment>
                <CustomCard.Header>
                    <div className={styles.closeModal}>
                        <button onClick={() => this.closeModal()}>
                            <span className="feather icon-x"/>
                        </button>
                    </div>
                    <div>
                        <h2 className={styles.paymentTitle}>
                            <Translation text="cart.addPayment.title"/>
                        </h2>
                    </div>
                </CustomCard.Header>
                <CustomCard.Body>
                    <h2 className={styles.summaryTitle}>
                        <Translation text="cart.addPayment.paymentSummary"/>
                    </h2>
                    <div className={styles.paymentSummary}>
                        <div className={styles.summaryItem}>
                            <p><Translation text="cart.addPayment.grossPrice"/></p>
                            <Price price={this.props.cart?.finalGrossPrice}/>
                        </div>
                        <div className={styles.summaryItem}>
                            <p><Translation text="cart.addPayment.totalPrice"/></p>
                            <Price price={this.props.cart?.finalPrice}/>
                        </div>
                        <div className={styles.summaryItem}>
                            <p><Translation text="cart.addPayment.tax"/></p>
                            <Price price={this.getTaxValue(this.props.cart?.finalGrossPrice, this.props.cart?.finalPrice)}/>
                        </div>
                    </div>
                    <Form config={this.state.formConfig}
                          controlName={'paymentForm'}
                          onValueStateChange={this.onValueStateChange}
                          value={this.state.value}
                          submitForm={this.addPayment}/>
                </CustomCard.Body>
            </React.Fragment>
        )
    };

    private renderPaymentSuccess = () => {
        return (
            <React.Fragment>
                <CustomCard.Header>
                    <div className={styles.closeModal}>
                        <button onClick={() => this.closeModal()}>
                            <span className="feather icon-x"/>
                        </button>
                    </div>
                </CustomCard.Header>
                <CustomCard.Body>
                    <h2 className={styles.summaryPaid}>
                        <Translation text="cart.addPayment.cartPaid"/>
                    </h2>
                </CustomCard.Body>
            </React.Fragment>
        )
    };

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onFormValueChange = (value: any) => {
        const mappedValue: any = {};
        Object.keys(value).forEach((key: string) => {
            let fieldValue = value[key];
            if (undefined === fieldValue || null === fieldValue) {
                mappedValue[key] = null;

                return;
            }
            if (key === 'stripecard') {
                fieldValue = Object.assign({}, fieldValue);
                this.stripe = fieldValue['stripe'];
                this.elements = fieldValue['elements'];
                delete fieldValue['stripe'];
                delete fieldValue['elements'];
            } else {
                mappedValue[key] = fieldValue;
            }
        });
        this.setState({value: mappedValue});
    };

    private setFormConfig = (): void => {
        const formConfig = paymentFormConfig();

        this.setState({formConfig});
    };

    private closeModal = () => {
        if (this.state.isPaymentProcessing) {
            return;
        }

        this.props.closeModal();

        if (this.state.isPaymentProcessed) {
            this.props.history.push('/panel/dashboard');
        }
    };


    private addPayment = () => {
        this.setState({
            isLoading: true,
            isPaymentProcessing: true
        });
        return this.subscriptions.push(this.confirmPaymentFlow().subscribe());
    };

    private getTaxValue = (grossPrice: typeof IPrice | null, totalPrice: typeof IPrice | null): typeof IPrice | null => {
        if (!grossPrice || !totalPrice) {
            return null;
        }

        return {
            amount: grossPrice.amount - totalPrice.amount,
            currency: {
                code: totalPrice.currency.code
            }
        };
    };

    private confirmPaymentFlow = (): Observable<any> => {
        const cartId = this.props.cart.id,
            {t} = this.props;

        return createPaymentAPI(this.props.authToken, cartId, 'card').pipe(
            switchMap((response: any) => {
                return getPaymentSecret(response.id, this.props.authToken).pipe(
                    switchMap((getPaymentSecretResp: any) => {
                        const confirmCardPaymentPayload = {
                            payment_method: {
                                card: this.elements.getElement(CardNumberElement) as StripeCardElement,
                                billing_details: {
                                    name: this.state.value.cardholderName,
                                },
                            }
                        };
                        return this.stripe.confirmCardPayment(
                            getPaymentSecretResp.client_secret,
                            confirmCardPaymentPayload
                        )
                    }),
                    catchError((error: any) => {
                        this.setState({isLoading: false, isPaymentProcessing: false});
                        this.alertManager?.handleApiError(error);
                        return of();
                    }),

                    switchMap((paymentResponse: any) => {
                        if (paymentResponse.error) {
                            let errorMessage = paymentResponse.error && paymentResponse.error.message ? paymentResponse.error.message :
                                t('formValidation.errors.stripeError');
                            this.alertManager.handleApiError(errorMessage);
                            this.setState({isLoading: false, isPaymentProcessing: false});
                            return of();
                        } else {
                            return confirmPaymentAPI(response.id, this.props.authToken).pipe(
                                catchError((error: any) => {
                                    this.setState({isLoading: false, isPaymentProcessing: false});
                                    this.alertManager?.handleApiError(error);

                                    return of();
                                }),
                                tap(() => {
                                    this.alertManager.addAlert(t('cart.addPayment.cartPaid'));
                                    this.props.closeModal();
                                    this.props.resetCart();
                                    this.props.refreshAccountStateData();
                                    this.props.history.push('/panel/dashboard');
                                    this.setState({isLoading: false, isPaymentProcessing: false, isPaymentProcessed: true});
                                })
                            )
                        }
                    })
                );
            }),
            catchError((error: any) => {
                this.setState({isLoading: false, isPaymentProcessing: false});
                this.alertManager.handleApiError(error);
                return of();
            })
        );
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {
        resetCart,
        refreshAccountStateData
    }
)(withRouter(withTranslation()(PaymentModal)));
