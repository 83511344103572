import {authTokenSelector, IModelCartServiceInstance, Loader, LoaderType, Translation} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {of, Subscription} from 'rxjs';
import {catchError, filter, tap} from 'rxjs/operators';
import {getUserTestsAPI} from '../../../api/getUserTests';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import {IAlertManagerService} from '../../../service/alertManagerService';
import {RootState} from '../../../store/reducers';
import {isCartLoadingSelector} from '../../../store/selectors/cartSelectors';
import TestCard from './TestCard';
import {getServiceInstanceTestPackagesAPI} from "../../../api/getServiceInstanceTestPackages";

interface IConnectedTestsProps {
    readonly authToken: string;
    readonly isCartProcessing: boolean;
}

interface ITestsProps extends IConnectedTestsProps {
}

interface ITestsState {
    testList: any[];
    isLoading: boolean;
    packageServiceInstances: typeof IModelCartServiceInstance[] | [];
}

class Tests extends React.Component<ITestsProps, ITestsState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: ITestsProps) {
        super(props);
        this.state = {
            testList: [],
            isLoading: false,
            packageServiceInstances: []
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.fetchTestData();
        this.getServiceInstancePackages();
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        if (!this.state.testList || this.state.testList.length === 0) {
            return <p>
                <Translation text={'defaultList.noItems'}/>
            </p>;
        }
        return (
            <div className="row">
                <Loader showLoader={this.state.isLoading || this.props.isCartProcessing} loaderType={LoaderType.Local}/>
                {this.state.testList.map((testListItem: any) => <TestCard
                    key={testListItem.serviceInstance ? testListItem.serviceInstance.id : testListItem.suggestedTest.id}
                    test={testListItem}
                    packageServiceInstances={this.state.packageServiceInstances}/>)}
            </div>
        );
    }


    private fetchTestData() {
        this.setState({isLoading: true});
        this.subscriptions.push(
            getUserTestsAPI(this.props.authToken).pipe(
                filter((response) => !!response),
                tap((resp: any) => {
                    if (resp.tests) {
                        this.setState({testList: resp.tests, isLoading: false});
                    }
                }),
                catchError((err: any) => {
                    this.alertManager?.handleApiError(err);
                    return of();
                })
            ).subscribe()
        )
    }

    private getServiceInstancePackages() {
        this.subscriptions.push(
            getServiceInstanceTestPackagesAPI(this.props.authToken).pipe(
                tap((resp: any) => {
                    this.setState({packageServiceInstances: resp['hydra:member']})
                }),
                catchError((err: any) => {
                    this.alertManager?.handleApiError(err);
                    return of();
                })
            ).subscribe()
        )
    }
}

export default connect(() => (state: RootState) => ({
    authToken: authTokenSelector(state),
    isCartProcessing: isCartLoadingSelector(state)
}), {})(Tests);
