import React from 'react';
import CartItemQuantity from './../CartItemQuantity';
import MentorItemCalendar, {ModalCoordinates} from '../MentorItemCalendar';
import MentorItemServiceDetails from '../MentorItemServiceDetails';
import {
    hasApplicationPackage,
    hasTrialConsultation,
    IModelCartServiceInstance,
    IModelMentorServiceDefinition,
    isNullOrUndefined
} from 'educat-common-web';
import ApplicationPackageStudyField from "../ApplicationPackageStudyField";


interface IConnectedMentorItemProps {
}

interface IExternalMentorItemProps {
    readonly serviceInstance: typeof IModelCartServiceInstance;
    readonly mentorServiceDefinitions: typeof IModelMentorServiceDefinition;
    readonly allServiceInstances: typeof IModelCartServiceInstance[];
    readonly openCalendarModal: (onModalDateSelected: (date: Date) => void,
                                 calendarId: string,
                                 calendarModal: {[key: string]: any},
                                 additionalUnavailableDates: Date[],
                                 startDate: Date,
                                 modalCoordinates: ModalCoordinates | null,
                                 selectedDate?: Date | null) => void;
}

interface IMentorItemProps extends IConnectedMentorItemProps,
    IExternalMentorItemProps {
}

interface IMentorItemState {
}


class MentorItem extends React.Component<IMentorItemProps, IMentorItemState> {
    render() {
        const hasCalendar = this.hasCalendar,
            hasServiceTrialConsultation = hasTrialConsultation(this.props.mentorServiceDefinitions),
            hasServiceApplicationPackage = hasApplicationPackage(this.props.mentorServiceDefinitions);

        return (
            <React.Fragment>
                <MentorItemServiceDetails mentorServiceDefinitions={this.props.mentorServiceDefinitions}
                                          mentor={this.props.mentorServiceDefinitions.mentor}
                                          useTwoSpaces={!hasCalendar}/>

                {hasCalendar && <MentorItemCalendar serviceInstance={this.props.serviceInstance}
                                                    mentorServiceDefinitions={this.props.mentorServiceDefinitions}
                                                    allServiceInstances={this.props.allServiceInstances}
                                                    openCalendarModal={this.props.openCalendarModal}/>}

                <CartItemQuantity servicePrice={this.props.serviceInstance.finalGrossPrice}
                                  quantity={this.props.serviceInstance.quantity}
                                  itemId={this.props.serviceInstance.id}
                                  maxValue={hasServiceTrialConsultation ? 1 : null}
                                  disabled={hasServiceTrialConsultation}/>

                {hasServiceApplicationPackage && <ApplicationPackageStudyField mentorId={this.props.mentorServiceDefinitions?.mentor?.id} serviceInstance={this.props.serviceInstance}/>}
            </React.Fragment>
        );
    }

    private get hasCalendar(): boolean {
        if (isNullOrUndefined(this.props.mentorServiceDefinitions)) {
            return false;
        }

        return this.props.mentorServiceDefinitions.items > 0;
    }
}

export default MentorItem;
