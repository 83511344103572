import React from 'react';
import {isNotNullOrUndefined, isNullOrUndefined, RestQueryParams, Translation} from 'educat-common-web';
import ServiceHourSelectionModal from "../../../../ServiceHourSelectionModal";

interface IExternalOperationTableItemUsedHoursCellProps {
    readonly item: any;
    readonly refreshPaymentList: (params?: typeof RestQueryParams) => void;
}

interface IOperationTableItemUsedHoursCellProps extends IExternalOperationTableItemUsedHoursCellProps {
}

interface IOperationTableItemUsedHoursCellState {
    isTooltipShown: boolean;
    isModalVisible: boolean;
}

class OperationTableItemUsedHoursCell extends React.Component<IOperationTableItemUsedHoursCellProps, IOperationTableItemUsedHoursCellState> {
    private readonly statusRef: any;

    constructor(props: IOperationTableItemUsedHoursCellProps) {
        super(props);

        this.state = {
            isTooltipShown: false,
            isModalVisible: false
        };
        this.statusRef = React.createRef();
    }

    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    render() {
        const item = this.props.item,
            remainingConsultationCount = isNotNullOrUndefined(item.serviceDefinition?.consultationCount) && item.serviceDefinition?.consultationCount > 0 ?
                item.serviceDefinition.consultationCount : null;

        if (isNullOrUndefined(item) || isNullOrUndefined(item.usedAmount) || isNullOrUndefined(item.totalFreeSlots) ||
            (this.isServiceDefinition && isNullOrUndefined(remainingConsultationCount))) {
            return null;
        }

        return (
            <>
                <div className="tooltip-wrapper">
                    <div className={`tooltip-content top ${this.state.isTooltipShown ? '' : 'd-none'}`} ref={this.statusRef}>
                        <Translation
                            text={'payments.operationHistory.table.realization.tooltipInfo'}
                            config={{usedAmount: item.usedAmount, usedLimit: item.totalFreeSlots}}
                        />
                        {this.renderRedirectButton()}
                    </div>
                    <button className="tooltip-limit-toggle-button"
                        onClick={() => this.toggleTooltipShown()}>
                        <Translation
                            text={this.isServiceDefinition && isNotNullOrUndefined(remainingConsultationCount) ? 'payments.operationHistory.table.realization.usedTests' : 'payments.operationHistory.table.realization.usedHours'}
                            config={{usedAmount: item.usedAmount, usedLimit: item.totalFreeSlots}}/>
                    </button>
                </div>
                {this.state.isModalVisible &&
                    (<ServiceHourSelectionModal isModalVisible={this.state.isModalVisible}
                                                serviceInstance={item}
                                                toggleModal={this.toggleModal} />)
                }
            </>
        );
    }

    private get isServiceDefinition(): boolean {
        return isNotNullOrUndefined(this.props.item?.serviceDefinition);
    }

    private renderRedirectButton() {
        return (
            <button className="btn btn-theme-no-outline" onClick={() => this.toggleModal()}>
                <Translation text={'payments.operationHistory.table.realization.tooltipLink'}/>
            </button>
        )
    }

    private toggleTooltipShown() {
        this.setState({isTooltipShown: !this.state.isTooltipShown});
    }

    private handleOutsideClick = (event: any) => {
        if (this.statusRef && this.statusRef.current && !this.statusRef.current.contains(event.target)) {
            this.setState({isTooltipShown: false});
        }
    };

    private toggleModal = () => {
        // if (this.isServiceDefinition) {
        //     return;
        // }

        this.setState({isModalVisible: !this.state.isModalVisible}, () => {
            if (!this.state.isModalVisible) {
                this.props.refreshPaymentList();
            }
        });
    };
}

export default OperationTableItemUsedHoursCell;
