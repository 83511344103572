import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {deepCloneObject, isSameValue, StudyFieldsListType} from 'educat-common-web';


export enum SortingParams {
    TITLE_ASC = "title_asc",
    TITLE_DESC = "title_desc",
    PRICE_ASC = "price_asc",
    PRICE_DESC = "price_desc"
}

export interface IPaginationParams {
    page: number;
    itemsPerPage: number;
}

export interface IFiltersListMetadata {
    'hydra:first': string;
    'hydra:last': string;
    'hydra:next'?: string;
    'hydra:previous'?: string;
}

export interface IFiltersFieldsList {
    list: {[key: string]: any}[] | [];
    metadata: IFiltersListMetadata | null;
}

export interface IStudyFieldFilters {
    price: string[] | null;
    duration_filter: string[] | null;
    mode: string[] | null;
    school: {
        id: string | null;
    } | null;
    countries: {
        id: string[];
    } | null;
    studyField: {
        id: string | null;
    } | null;
    realm: {
        id: string[] | null;
    } | null;
    level_filter: string[] | null;
}

export interface IStudyFieldSearchState {
    showStudyFieldsSearch: boolean;
    filters: IStudyFieldFilters | null;
    sorting: SortingParams | null;
    pagination: IPaginationParams | null;
    listType: typeof StudyFieldsListType | null;
    fieldsList: IFiltersFieldsList;
    isLoading: boolean;
}

export interface IChangeShowStudyFieldsSearch {
    showStudyFieldsSearch: boolean;
}

export interface IChangeStudyFieldsFilters {
    filters: IStudyFieldFilters;
}

export interface IChangeStudyFieldsSorting {
    sorting: SortingParams;
}

export interface IChangeStudyFieldsPagination {
    pagination: IPaginationParams;
}

export interface IChangeListType {
    listType: typeof StudyFieldsListType | null;
}

export interface IChangeStudyFieldsList {
    list: {[key: string]: any}[];
    metadata: IFiltersListMetadata | null;
}

export interface IChangeFilterOptionId {
    id: string;
}

export interface IChangeFilterOptionIdOptional {
    id: string|null;
}

export interface IChangeMainSearchLoading {
    isLoading: boolean;
}

const initialState: IStudyFieldSearchState = {
    showStudyFieldsSearch: false,
    filters: {
        price: null,
        duration_filter: null,
        mode: null,
        school: null,
        countries: null,
        studyField: null,
        realm: null,
        level_filter: null
    },
    sorting: null,
    pagination: {
        page: 1,
        itemsPerPage: 10
    },
    listType: null,
    fieldsList: {
        list: [],
        metadata: null
    },
    isLoading: true
};

const studyFiledsSearchSlice = createSlice({
    name: "search",
    initialState: deepCloneObject(initialState),
    reducers: {
        changeShowFieldsSearch: {
            reducer: (state: IStudyFieldSearchState, action: PayloadAction<IChangeShowStudyFieldsSearch>) => {
                return {
                    showStudyFieldsSearch: action.payload.showStudyFieldsSearch,
                    filters: state.filters,
                    sorting: state.sorting,
                    pagination: state.pagination,
                    listType: state.listType,
                    fieldsList: state.fieldsList,
                    isLoading: state.isLoading
                }
            },
            prepare(showStudyFieldsSearch: boolean) {
                return {
                    payload: { showStudyFieldsSearch: showStudyFieldsSearch }
                };
            }
        },
        applyStudyFieldsFilters: (state: IStudyFieldSearchState) => {
            return {
                ...state,
                isLoading: true
            }
        },
        resetStudyFieldsFiltersToDefaultAccountFilters: (state: IStudyFieldSearchState) => state,
        changeStudyFieldsFilters: {
            reducer: (state: IStudyFieldSearchState, action: PayloadAction<IChangeStudyFieldsFilters>) => {
                if (isSameValue(action.payload.filters, state.filters)) {
                    return state;
                }

                return {
                    showStudyFieldsSearch: state.showStudyFieldsSearch,
                    filters: action.payload.filters,
                    sorting: state.sorting,
                    pagination: state.pagination,
                    listType: state.listType,
                    fieldsList: state.fieldsList,
                    isLoading: state.isLoading
                }
            },
            prepare(filters: IStudyFieldFilters) {
                return {
                    payload: {
                        filters: {
                            price: filters.price,
                            duration_filter: filters.duration_filter,
                            mode: filters.mode,
                            school: filters.school,
                            countries: filters.countries,
                            studyField: filters.studyField,
                            realm: filters.realm,
                            level_filter: filters.level_filter
                        }
                    }
                };
            }
        },
        changeStudyFieldsRealmFilter: {
            reducer: (state: IStudyFieldSearchState, action: PayloadAction<IChangeFilterOptionId>) => {
                return {
                    showStudyFieldsSearch: state.showStudyFieldsSearch,
                    filters: {
                        price: state.filters ? state.filters.price : null,
                        duration_filter: state.filters ? state.filters.duration_filter : null,
                        mode: state.filters ? state.filters.mode : null,
                        school: state.filters ? state.filters.school : null,
                        countries: state.filters ? state.filters.countries : null,
                        studyField: state.filters ? state.filters.studyField : null,
                        realm: {
                            id: action.payload.id
                        },
                        level_filter: state.filters ? state.filters.level_filter : null
                    },
                    sorting: state.sorting,
                    pagination: state.pagination,
                    listType: state.listType,
                    fieldsList: state.fieldsList,
                    isLoading: true
                };
            },
            prepare(id: string) {
                return {
                    payload: {
                        id: id,
                    }
                };
            }
        },
        changeStudyFieldsFieldFilter: {
            reducer: (state: IStudyFieldSearchState, action: PayloadAction<IChangeFilterOptionId>) => {
                if (state.filters?.studyField?.id === action.payload.id) {
                    return state;
                }

                return {
                    showStudyFieldsSearch: state.showStudyFieldsSearch,
                    filters: {
                        price: state.filters ? state.filters.price : null,
                        duration_filter: state.filters ? state.filters.duration_filter : null,
                        mode: state.filters ? state.filters.mode : null,
                        school: state.filters ? state.filters.school : null,
                        countries: state.filters ? state.filters.countries : null,
                        studyField: {
                            id: action.payload.id,
                        },
                        realm: state.filters ? state.filters.realm : null,
                        level_filter: state.filters ? state.filters.level_filter : null
                    },
                    sorting: state.sorting,
                    pagination: state.pagination,
                    listType: state.listType,
                    fieldsList: state.fieldsList,
                    isLoading: true
                };
            },
            prepare(id: string) {
                return {
                    payload: {
                        id: id,
                    }
                };
            }
        },
        changeStudyFieldsCountryFilter: {
            reducer: (state: IStudyFieldSearchState, action: PayloadAction<IChangeFilterOptionId>) => {
                let countries = [];
                countries.push(action.payload.id);
                return {
                    showStudyFieldsSearch: state.showStudyFieldsSearch,
                    filters: {
                        price: state.filters ? state.filters.price : null,
                        duration_filter: state.filters ? state.filters.duration_filter : null,
                        mode: state.filters ? state.filters.mode : null,
                        school: {
                            id: state.filters && state.filters.school ? state.filters.school.id : null,
                        },
                        countries: {
                            id: countries
                        },
                        studyField: state.filters ? state.filters.studyField : null,
                        realm: state.filters ? state.filters.realm : null,
                        level_filter: state.filters ? state.filters.level_filter : null
                    },
                    sorting: state.sorting,
                    pagination: state.pagination,
                    listType: state.listType,
                    fieldsList: state.fieldsList,
                    isLoading: true
                };
            },
            prepare(id: string) {
                return {
                    payload: { id: id }
                };
            }
        },
        changeStudyFieldsSchoolFilter: {
            reducer: (state: IStudyFieldSearchState, action: PayloadAction<IChangeFilterOptionIdOptional>) => {
                if (state.filters?.school?.id === action.payload.id) {
                    return state;
                }

                return {
                    showStudyFieldsSearch: state.showStudyFieldsSearch,
                    filters: {
                        price: state.filters ? state.filters.price : null,
                        duration_filter: state.filters ? state.filters.duration_filter : null,
                        mode: state.filters ? state.filters.mode : null,
                        school: {
                            id: action.payload.id,
                        },
                        countries: state.filters && state.filters ? state.filters.countries : null,
                        studyField: state.filters ? state.filters.studyField : null,
                        realm: state.filters ? state.filters.realm : null,
                        level_filter: state.filters ? state.filters.level_filter : null
                    },
                    sorting: state.sorting,
                    pagination: state.pagination,
                    listType: state.listType,
                    fieldsList: state.fieldsList,
                    isLoading: true
                };
            },
            prepare(id: string|null) {
                return {
                    payload: { id: id }
                };
            }
        },
        changeStudyFieldsSorting: {
            reducer: (state: IStudyFieldSearchState, action: PayloadAction<IChangeStudyFieldsSorting>) => {
                return {
                    showStudyFieldsSearch: state.showStudyFieldsSearch,
                    filters: state.filters,
                    sorting: action.payload.sorting,
                    pagination: state.pagination,
                    listType: state.listType,
                    fieldsList: state.fieldsList,
                    isLoading: true
                }
            },
            prepare(sortingParams: SortingParams) {
                return {
                    payload: {
                        sorting: sortingParams,
                    }
                };
            }
        },
        changeStudyFieldsPagination: {
            reducer: (state: IStudyFieldSearchState, action: PayloadAction<IChangeStudyFieldsPagination>) => {
                return {
                    showStudyFieldsSearch: state.showStudyFieldsSearch,
                    filters: state.filters,
                    sorting: state.sorting,
                    pagination: action.payload.pagination,
                    listType: state.listType,
                    fieldsList: state.fieldsList,
                    isLoading: true
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: { pagination: pagination }
                };
            }
        },
        changeListType: {
            reducer: (state: IStudyFieldSearchState, action: PayloadAction<IChangeListType>) => {
                return {
                    showStudyFieldsSearch: state.showStudyFieldsSearch,
                    filters: state.filters,
                    sorting: state.sorting,
                    pagination: state.pagination,
                    listType: action.payload.listType,
                    fieldsList: state.fieldsList,
                    isLoading: state.isLoading
                };
            },
            prepare(listType: typeof StudyFieldsListType | null) {
                return {
                    payload: { listType: listType }
                };
            }
        },
        changeFieldsList: {
            reducer: (state: IStudyFieldSearchState, action: PayloadAction<IChangeStudyFieldsList>) => {
                return {
                    showStudyFieldsSearch: state.showStudyFieldsSearch,
                    filters: state.filters,
                    sorting: state.sorting,
                    pagination: state.pagination,
                    listType: state.listType,
                    fieldsList: {
                        list: action.payload.list,
                        metadata: action.payload.metadata
                    },
                    isLoading: state.isLoading
                };
            },
            prepare(fieldsList: IFiltersFieldsList) {
                return {
                    payload: {
                        list: fieldsList.list,
                        metadata: fieldsList.metadata
                    }
                };
            }
        },
        changeMainSearchLoading: {
            reducer: (state: IStudyFieldSearchState, action: PayloadAction<IChangeMainSearchLoading>) => {
                return {
                    showStudyFieldsSearch: state.showStudyFieldsSearch,
                    filters: state.filters,
                    sorting: state.sorting,
                    pagination: state.pagination,
                    listType: state.listType,
                    fieldsList: state.fieldsList,
                    isLoading: action.payload.isLoading
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: { isLoading: isLoading }
                };
            }
        },
        resetAllStudyFieldsFilters: (state: IStudyFieldSearchState) => {
            return {
                ...state,
                filters: deepCloneObject(initialState.filters),
            }
        },
    }
});

export const {
    changeShowFieldsSearch,
    changeStudyFieldsFilters,
    changeStudyFieldsRealmFilter,
    changeStudyFieldsFieldFilter,
    changeStudyFieldsCountryFilter,
    changeStudyFieldsSchoolFilter,
    changeStudyFieldsSorting,
    changeStudyFieldsPagination,
    changeListType,
    changeFieldsList,
    changeMainSearchLoading,
    applyStudyFieldsFilters,
    resetStudyFieldsFiltersToDefaultAccountFilters,
    resetAllStudyFieldsFilters
} = studyFiledsSearchSlice.actions;

export default studyFiledsSearchSlice.reducer;
