import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {IAlertManagerService} from "../../service/alertManagerService";
import {changeBreadcrumbs, Translation} from 'educat-common-web';
import {connect} from "react-redux";
import styles from "./styles.module.scss";


interface IConnectedMarketplaceProps {
    changeBreadcrumbs: typeof changeBreadcrumbs;
}

interface IExternalMarketplaceProps {}

interface IMarketplaceProps extends IConnectedMarketplaceProps,
    IExternalMarketplaceProps,
    RouteComponentProps {
}

interface IMarketplaceState {}


class Marketplace extends React.Component<IMarketplaceProps, IMarketplaceState> {
    private subscription: Subscription | null = null;
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IMarketplaceProps) {
        super(props);

        this.state = {};

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
            {labelKey: 'breadcrumbs.marketplace', path: '/panel/marketplace'}
        ])
    }

    componentWillUnmount() {
        if (null !== this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        return (
            <article className="mentor-host marketplace">
                <div className="col-xl-10">
                    <header className="mentor-header">
                        <h1 className="mentor-title">
                            <Translation text={'marketplace.title'}/>
                        </h1>
                        <p className="mentor-subtitle">
                            <Translation text={'marketplace.description'}/>
                        </p>
                    </header>
                    <div className={styles.marketplaceDetails}>
                        <div className="row mb-4">
                            <div className="col-6">
                                <div className={`${styles.marketplaceItem}  ${styles.tests}`}>
                                    <div className={styles.itemDescription}>
                                        <p><Translation text={'marketplace.testsDescription'}/></p>
                                        <button className="btn btn-theme"
                                                onClick={() => this.props.history.push("/panel/marketplace/tests")}>
                                            <Translation text={'marketplace.testsTitle'}/>
                                        </button>
                                    </div>

                                    <div className={styles.itemTitle}>
                                        <Translation text={'marketplace.testsTitle'}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className={`${styles.marketplaceItem}  ${styles.application}`}>
                                    <div className={styles.itemDescription}>
                                        <p><Translation text={'marketplace.applicationDescription'}/></p>
                                        <button className="btn btn-theme"
                                                onClick={() => this.props.history.push("/panel/marketplace/applications")}>
                                            <Translation text={'marketplace.applicationTitle'}/>
                                        </button>
                                    </div>

                                    <div className={styles.itemTitle}>
                                        <Translation text={'marketplace.applicationTitle'}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <div className={`${styles.marketplaceItem}  ${styles.mentors}`}>
                                    <div className={styles.itemDescription}>
                                        <p><Translation text={'marketplace.mentorsDescription'}/></p>
                                        <button className="btn btn-theme"
                                                onClick={() => this.props.history.push("/panel/mentors")}>
                                            <Translation text={'marketplace.mentorsTitle'}/>
                                        </button>
                                    </div>

                                    <div className={styles.itemTitle}>
                                        <Translation text={'marketplace.mentorsTitle'}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className={`${styles.marketplaceItem}  ${styles.tutors}`}>
                                    <div className={styles.itemDescription}>
                                        <p><Translation text={'marketplace.tutorsDescription'}/></p>
                                        <button className="btn btn-theme"
                                                onClick={() => this.props.history.push("/panel/tutors")}>
                                            <Translation text={'marketplace.tutorsTitle'}/>
                                        </button>
                                    </div>

                                    <div className={styles.itemTitle}>
                                        <Translation text={'marketplace.tutorsTitle'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        );
    }
}

export default connect(
    () => ({}),
    {
        changeBreadcrumbs
    }
)(withRouter(Marketplace));
