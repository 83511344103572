import React from 'react';
import {Translation} from 'educat-common-web';

interface IExternalGreetingProps {
    readonly name: string;
}

interface IGreetingProps extends IExternalGreetingProps {
}

interface IGreetingState {
}

class Greeting extends React.Component<IGreetingProps, IGreetingState> {
    render() {

        return (
            <header className="header-section">
                <h1 className="header-title">
                    <Translation text="dashboard.header.title"/> {this.props.name}!
                </h1>
                <p className="header-description">
                    <Translation text="dashboard.header.description"/>
                    <br/>
                    <i>
                        <Translation text="dashboard.header.signature"/>
                    </i>
                </p>
            </header>
        );
    }
}

export default Greeting;
