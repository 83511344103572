import {IModelCartServiceInstance, IModelServiceDefinition, isNullOrUndefined, Translation, getTestDefinition} from 'educat-common-web';
import React from 'react';
import {InternationalMaturityClass} from '../../../../api/provider/serviceSubjectTestProvider';
import CartItemQuantity from '../CartItemQuantity';
import styles from './styles.module.scss';

interface IConnectedServiceItemProps {
}

interface IExternalServiceItemProps {
    serviceInstance: typeof IModelCartServiceInstance;
    serviceDefinition: typeof IModelServiceDefinition;
}

interface IServiceItemProps extends IConnectedServiceItemProps, IExternalServiceItemProps {
}

interface IServiceItemState {
}

class ServiceItem extends React.Component<IServiceItemProps, IServiceItemState> {
    render() {
        const serviceAdditionalData = getTestDefinition(this.props.serviceDefinition),
            logo = serviceAdditionalData?.mediaObject?.mediumThumb;

        return (
            <React.Fragment>
                <div className={`${styles.details} col-xxl-9`}>
                    <div className={`${styles.img}`}
                         style={logo ? {backgroundImage: `url(${logo})`, backgroundColor: 'transparent'} : undefined}/>

                    <p className={styles.testTitle}>{this.props.serviceDefinition.name}</p>
                    {this.renderSubjectServiceData(serviceAdditionalData)}
                </div>

                <CartItemQuantity
                    servicePrice={this.props.serviceInstance.finalGrossPrice}
                    quantity={this.props.serviceInstance.quantity}
                    itemId={this.props.serviceInstance.id}
                    disabled={true}
                />
            </React.Fragment>
        );
    }

    private renderSubjectServiceData(serviceDetails: any) {
        if (isNullOrUndefined(serviceDetails)) {
            return null;
        }
        const internationalMaturityExam = Object.keys(InternationalMaturityClass).includes(serviceDetails.class);

        return (
            <div className={styles.serviceAdditionalInformation}>
                {serviceDetails.level && (
                    <span>
                      <Translation text={`competence.subjectTests.level.${serviceDetails.level}`}/>
                    </span>
                )}
                {serviceDetails.class && (
                    <span>
                      <Translation
                          text={`competence.subjectTests.classes.${internationalMaturityExam ? 'international' : 'pl'}.${serviceDetails.class}`}
                      />
                    </span>
                )}
                {serviceDetails.baccalaureateType && (
                    <span>
                      <Translation text={`competence.subjectTests.maturityType.${serviceDetails.baccalaureateType}`}/>
                    </span>
                )}
            </div>
        );
    }
}

export default ServiceItem;
