
export enum PaymentStatus {
    STATUS_NEW = 'status_new',
    STATUS_PAID = 'status_paid',
    STATUS_EXPIRED = 'status_expired',
    STATUS_REQUIRES_PAYMENT_METHOD = 'status_requires_payment_method',
    STATUS_REQUIRES_USER_ACTION = 'status_requires_user_action'
}

export enum RealizationStatus {
    OPEN = 'open',
    COMPLETED = 'completed',
    AWAITS_PAYMENT = 'awaits_payment'
}


export enum PeriodFilterType {
    WEEKS = 'weeks',
    MONTHS = 'months',
    YEARS = 'years',
}