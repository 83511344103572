import {
    collectionInputDataMapper,
    collectionOutputDataMapper,
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    MultiSelectType,
} from 'educat-common-web';


export const studyFieldFormConfig: typeof IFormConfig = (schoolStudyFieldList?: typeof IMultiselectOption[] | []) => {
    return {
        class: 'profile-form',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);

            return previousStateSnapshot;
        },
        controls: [
            {
                controlType: 'group',
                key: 'school_study_field',
                class: '',
                controls: {
                    schoolStudyField: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SINGLE,
                        multiselectOptions: schoolStudyFieldList ? schoolStudyFieldList : [],
                        validationRules: [],
                        placeholder: 'profile.profileForm.selectPlaceholder',
                        label: '',
                        hostClass: 'col-6',
                        inputDataMapper: collectionInputDataMapper,
                        outputDataMapper: collectionOutputDataMapper,
                    },
                }
            },
        ]
    };
};
