import React from 'react';
import {IModelServiceApplicationElement} from 'educat-common-web';

const applicationPackageIconWhiteList = ['aperture', 'check-circle', 'file', 'folder', 'message-square', 'monitor', 'application_check', 'harmo', 'materials', 'diagnostics'];

interface IApplicationsTableRowConnectedProps {
}

interface IApplicationsTableRowExternalProps {
    readonly item: typeof IModelServiceApplicationElement;
    readonly presentInApplications: boolean[];
}

interface IApplicationsTableRowProps extends IApplicationsTableRowConnectedProps,
    IApplicationsTableRowExternalProps {
}

interface IApplicationsTableRowState {
}

class ApplicationsTableRow extends React.Component<IApplicationsTableRowProps, IApplicationsTableRowState> {
    render() {
        const item = this.props.item,
            hasIcon = applicationPackageIconWhiteList.includes(item.icon);

        return (
            <tr>
                <th>
                    <div className="th-content with-icon">
                        {hasIcon ?
                            <img src={require(`../../../../assets/images/${item.icon}.svg`).default} alt=""/> :
                            <i className={`feather icon-${item.icon}`}/>
                        }
                        <p>{item.name}</p>
                    </div>
                </th>
                {this.props.presentInApplications.map((present: boolean, index: number) => {
                    return (
                        <td key={`application_element_${index}`}>
                            {present ? <i className="feather icon-check"/> : <i className="feather icon-x"/>}
                        </td>
                    )
                })}
            </tr>
        )
    }
}

export default ApplicationsTableRow;
