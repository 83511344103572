import React from 'react';
import {IModelMentor, isNullOrUndefined, Price, Translation} from 'educat-common-web';


interface IExternalBasePriceProps {
    mentor: typeof IModelMentor;
    readonly isListPrice: boolean;
}

interface IBasePriceProps extends IExternalBasePriceProps {
}

interface IBasePriceState {
}


class BasePrice extends React.Component<IBasePriceProps, IBasePriceState> {

    render() {
        const basePrice = this.props.mentor?.basePrice;
        if (isNullOrUndefined(basePrice)) {
            return null;
        }
        const price = {
            amount: basePrice,
            currency: {
                code: 'PLN',
            },
        };

        return (
            <div className={`price-wrapper ${this.props.isListPrice ? 'mentors-list-price' : ''}`}>
                <span className="price">
                    <Translation text={`mentorProfile.mentorDetails.hourFee`} />
                    <span className="price-value">
                        <Price price={price}
                               amountComponent={(amount: number) => <span className="price-amount">{amount}</span>}/>
                    </span>
                </span>
            </div>
        );
    }
}

export default BasePrice;
