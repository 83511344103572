import {CustomCard, Translation} from 'educat-common-web';
import React from 'react';
import styles from './styles.module.scss';
import {Link} from "react-router-dom";

interface ITestResultsCardProps {
    resultList: any[];
}

class TestResultsCard extends React.Component<ITestResultsCardProps> {
    render() {
        return (
            <CustomCard showLocalLoader={false}>
                <CustomCard.Header>
                    <h2 className="subheader competence">
                        <Translation text={'competence.yourResults.title'}/>
                    </h2>
                </CustomCard.Header>
                <CustomCard.Body>{this.renderResultsList(this.props.resultList)}</CustomCard.Body>
            </CustomCard>
        );
    }

    private renderResultsList(resultList: any) {
        if (!resultList || resultList.length === 0) {
            return (
                <p>
                    <Translation text={'competence.yourResults.noResults'}/>
                </p>
            );
        }

        const resultsMapped = resultList.map((resultItem: any) => {
            return {
                id: resultItem.mostRecentTestRealisationDetails.testRealisationId,
                name: resultItem.serviceInstance.serviceDefinition.name,
                passed: resultItem.mostRecentTestRealisationDetails.isPass,
                points: resultItem.mostRecentTestRealisationDetails.resultPercentage,
            };
        });

        return (
            <ul className={styles.resultList}>
                {resultsMapped.map((result: any, index: number) => (
                    <li key={`${result.id}_${index}`} className={styles.resultListItem}>
                        <Link to={{
                            pathname: `/panel/diagnostics/result/${result.id}`,
                            state: {id: result.id, name: result.name},
                        }} className={styles.resultItemName}>
                            {result.name}
                        </Link>

                        <div className={styles.iconWrapper}>
                            <span className={`${styles.resultIcon} ${result.passed ? styles.passed : styles.failed}`}>
                                <i className={`feather ${result.passed ? 'icon-check' : 'icon-x'}`}/>
                            </span>
                        </div>

                        <div className={styles.pointsWrapper}>
                            <span className={styles.pointsAchieved}>{result.points}</span>
                            <Translation text={'competence.yourResults.points'}/>
                        </div>
                    </li>
                ))}
            </ul>
        );
    }
}

export default TestResultsCard;
