import {Observable} from "rxjs";
import {educatAPI, RestQueryParams} from "educat-common-web";

export function getPaymentSecret(paymentId: string, authToken: string | null): Observable<any> {
    let headers: any = {
        accept: 'application/ld+json',
        'content-type': 'application/json',
    };

    if (authToken) {
        headers['Authorization'] = `Bearer ${authToken}`;
    }

    return educatAPI.get(
        `payment/${paymentId}/stripe/client-secret`,
        new RestQueryParams(),
        headers
    );
}
