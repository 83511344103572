import React from 'react';
import {authTokenSelector, isNotNullOrUndefined, IModelCartServiceInstance} from 'educat-common-web';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {removeCartItem} from '../../../store/reducers/cartSlice';
import MentorItem from './MentorItem';
import ServiceItem from './ServiceItem';
import styles from './styles.module.scss';
import {isCartLoadingSelector} from '../../../store/selectors/cartSelectors';
import MentorCalendarModal from './MentorCalendarModal';
import {ModalCoordinates} from './MentorItemCalendar';


interface IConnectedCartCardProps {
    readonly authToken: string;
    readonly isCartLoading: boolean;
    readonly removeCartItem: typeof removeCartItem;
}

interface IExternalCartCardProps {
    serviceInstances: typeof IModelCartServiceInstance[];
}

interface ICartCardProps extends IConnectedCartCardProps,
    IExternalCartCardProps {
}

interface ICartCardState {
    readonly modalCalendarId: string | null;
    readonly modalCalendar: {[key: string]: any} | null;
    readonly modalStartDate: Date | null;
    readonly modalSelectedDate: Date | null;
    readonly modalAdditionalUnavailableDates: Date[];
    readonly modalCoordinates: ModalCoordinates | null;
    readonly onModalDateSelected?: (date: Date) => void;
}

class CartCard extends React.Component<ICartCardProps, ICartCardState> {

    constructor(props: ICartCardProps) {
        super(props);

        this.state = {
            modalCalendarId: null,
            modalCalendar: null,
            modalStartDate: null,
            modalSelectedDate: null,
            modalAdditionalUnavailableDates: [],
            modalCoordinates: null,
        };
    }

    render() {
        return (
            <React.Fragment>
                {this.props?.serviceInstances.map(service => (
                    <div className={`${styles.cartCard} row`} key={service.id}>
                        {service.mentorServiceDefinitions &&
                        <MentorItem serviceInstance={service}
                                    mentorServiceDefinitions={service.mentorServiceDefinitions}
                                    allServiceInstances={this.props?.serviceInstances}
                                    openCalendarModal={this.openCalendarModal}/>}
                        {service.serviceDefinition &&
                        <ServiceItem serviceInstance={service}
                                     serviceDefinition={service.serviceDefinition}/>}

                        <button className={styles.btnRemove}
                                disabled={this.props.isCartLoading}
                                onClick={() => this.removeItem(service.id)}>
                            <i className="feather icon-x"/>
                        </button>
                    </div>
                ))}

                <MentorCalendarModal isModalVisible={this.isModalShown}
                                     calendarId={this.state.modalCalendarId as string}
                                     calendarDetails={this.state.modalCalendar}
                                     closeModal={this.closeCalendarModal}
                                     startDate={this.state.modalStartDate as Date}
                                     selectedDate={this.state.modalSelectedDate as Date}
                                     additionalUnavailableDates={this.state.modalAdditionalUnavailableDates}
                                     modalCoordinates={this.state.modalCoordinates}
                                     onDateSelected={(date: Date) => {
                                         if (isNotNullOrUndefined(this.state.onModalDateSelected)) {
                                             (this.state.onModalDateSelected as any)(date);
                                         }
                                         this.closeCalendarModal();
                                     }}/>
            </React.Fragment>
        );
    }

    private get isModalShown(): boolean {
        return isNotNullOrUndefined(this.state.modalCalendarId);
    }

    private removeItem = (serviceInstanceId: string) => {
        if (this.props.isCartLoading) {
            return;
        }

        return this.props.removeCartItem([serviceInstanceId]);
    };

    private openCalendarModal = (onModalDateSelected: (date: Date) => void,
                                 modalCalendarId: string,
                                 modalCalendar: {[key: string]: any},
                                 modalAdditionalUnavailableDates: Date[],
                                 modalStartDate: Date,
                                 modalCoordinates: ModalCoordinates|null,
                                 modalSelectedDate: Date|null = null) => {
        this.setState({onModalDateSelected, modalCalendarId, modalCalendar, modalStartDate, modalSelectedDate, modalAdditionalUnavailableDates, modalCoordinates: modalCoordinates ? modalCoordinates : null});
    };

    private closeCalendarModal = () => {
        this.setState({onModalDateSelected: undefined, modalCalendarId: null, modalCalendar: null, modalStartDate: null, modalAdditionalUnavailableDates: []});
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        isCartLoading: isCartLoadingSelector(state),
    }),
    {
        removeCartItem
    }
)(CartCard);
