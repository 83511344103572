import React from 'react';
import styles from "./styles.module.scss";


interface IConnectedActionsProps {}

interface IExternalActionsProps {
    isFavoriteAdded: boolean;
    isApplyingAdded: boolean;
    addToFavorite: () => void;
    addToApplying: () => void;
}

interface IActionsProps extends IConnectedActionsProps,
    IExternalActionsProps {
}

interface IActionsState {}


class Actions extends React.Component<IActionsProps, IActionsState> {

    constructor(props: IActionsProps) {
        super(props);

        this.state = {};
    }

    componentDidMount(): void {}

    componentWillUnmount() {}

    render() {
        return (
            <div className={styles.actionsContainer}>
                <button className={`${styles.actionBtn}
                                    ${styles.actionFavorite}
                                    ${this.props.isFavoriteAdded ? styles.active : ''}`}
                                    onClick={this.props.addToFavorite} />
                <button className={`${styles.actionBtn}
                                    ${styles.actionApplication}
                                    ${this.props.isApplyingAdded ? styles.active : ''}`}
                                    onClick={this.props.addToApplying}/>
            </div>
        );
    }
}

export default Actions;
