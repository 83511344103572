import {Form, FormControlChangeType, IFormConfig, isSameValue, Loader, LoaderType, Translation} from 'educat-common-web';
import React from 'react';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {competenceFormFormConfig} from './competenceFormBaseControl';

interface IExternalCompetenceFormProps {
    readonly updateStepValue: (sectionId: string, stepValue: any, stepValid: boolean) => void;
    readonly stepData?: any;
    readonly section: any;
}

interface ICompetenceFormProps extends IExternalCompetenceFormProps {
}

interface ICompetenceFormState {
    isStepValid: boolean;
    formConfig: typeof IFormConfig;
    isProcessing: boolean;
    stepValue: any;
}

class CompetenceForm extends React.Component<ICompetenceFormProps, ICompetenceFormState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(props: ICompetenceFormProps) {
        super(props);

        const value = this.props.stepData;
        this.state = {
            isStepValid: false,
            isProcessing: false,
            formConfig: competenceFormFormConfig(value, this.props.section),
            stepValue: value,
        };
    }

    componentDidMount() {
        if (this.props.section && this.props.section.testRealisationQuestions) {
            this.setState({
                formConfig: competenceFormFormConfig(this.state.stepValue, this.props.section),
            });
        }
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data),
                    tap((data: any) => this.onFormValueChange(data.value, data.changeType))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<ICompetenceFormProps>, prevState: Readonly<ICompetenceFormState>, snapshot?: any): void {
        if (!isSameValue(this.props.section.sectionId, prevProps.section.sectionId)) {
            this.setState({formConfig: competenceFormFormConfig(this.state.stepValue, this.props.section)});
        }
        if (!isSameValue(this.state.stepValue, prevState.stepValue)) {
            this.props.updateStepValue(this.props.section.sectionId, this.state.stepValue, this.state.isStepValid);
        }
    }

    render() {
        return (
            <div className="competence-form-wrapper">
                <Loader type={LoaderType.Local} showLoader={this.state.isProcessing}/>
                <h2 className="view-subtitle">
                    <Translation text="competence.competenceTest.sectionTitle"/>
                    {this.props.section.sectionName}
                </h2>
                <Form
                    config={this.state.formConfig}
                    controlName={this.props.section.sectionId}
                    onValueStateChange={this.onValueStateChange}
                    onValidationStateChange={this.formValidityChange}
                    value={this.state.stepValue}
                />
            </div>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onFormValueChange = (value: any, changeType: typeof FormControlChangeType) => {
        if (changeType !== FormControlChangeType.Init && changeType !== FormControlChangeType.User) {
            return;
        }
        if (isSameValue(this.state.stepValue, value)) {
            return;
        }
        const newValue = Object.assign({}, this.state.stepValue, value);

        this.setState({stepValue: newValue});
    };

    private formValidityChange = (controlName: string, isValid: boolean) => {
        this.setState({isStepValid: isValid});
    };
}

export default CompetenceForm;
