import {
    convertScheduleInstanceToISchedules,
    CustomCard,
    IModelSchedule,
    isNullOrUndefined,
    TimeLine,
    TimelineDate,
    Translation
} from 'educat-common-web';
import React from 'react';
import {ScheduleMode} from '../../../models/schedule';

interface IExternalTimetableCardProps {
    readonly schedules: typeof IModelSchedule[];
    readonly hasApplication: boolean;
}

interface ITimetableCardProps extends IExternalTimetableCardProps {
}

interface ITimetableCardState {
    scheduleMode: ScheduleMode;
}

class TimetableCard extends React.Component<ITimetableCardProps, ITimetableCardState> {
    constructor(props: ITimetableCardProps) {
        super(props);
        this.state = {
            scheduleMode: ScheduleMode.MONTH,
        };
    }

    componentDidMount(): void {
    }

    render() {
        return (
            <CustomCard showLocalLoader={false}>
                <CustomCard.Header>
                    <div className="d-flex justify-content-between">
                        <h2 className="secondary-title">
                            <Translation text="dashboard.schedule.title"/>
                        </h2>
                        <TimelineDate/>
                    </div>
                </CustomCard.Header>
                <CustomCard.Body>{this.renderScheduleTimeLine(this.props.schedules)}</CustomCard.Body>
            </CustomCard>
        );
    }

    private renderScheduleTimeLine(schedules: any) {
        if (isNullOrUndefined(schedules) || schedules.length === 0) {
            return (
                <div className="text-center">
                    <p>
                        {!this.props.hasApplication ?
                            <Translation text={'dashboard.schedule.noApplication'}/> :
                            <Translation text={'dashboard.schedule.noSchedule'}/>
                        }
                    </p>
                </div>
            );
        }

        return (
            <div>
                <TimeLine
                    data={this.getScheduleDetails()}
                    mode={this.state.scheduleMode}
                    itemHeight={40}
                    itemWidth={40}
                    dayWidth={24}
                    interactiveMode={false}
                />
                <div className={`button-wrapper mt-5`}>
                    <button className="btn btn-theme">
                        <Translation text="buttons.buyApplication"/>
                    </button>
                </div>
            </div>
        );
    }

    private getScheduleDetails() {
        if (isNullOrUndefined(this.props.schedules)) {
            return [];
        }

        return this.props.schedules.map(convertScheduleInstanceToISchedules);
    }
}

export default TimetableCard;
