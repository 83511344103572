import {IPrice, isNullOrUndefined} from 'educat-common-web';

export enum FieldOfStudyListType {
    NULL = 'null',
    FAVOURITE = 'favourite',
    APPLYING = 'applying'
}

export interface IFieldOfStudy {
    readonly id: string;
    readonly studyField: {
        readonly id: string;
        readonly name: string;
        readonly realm: {
            readonly id: string;
            readonly name: string;
        }
    };
    readonly school: {
        readonly id: string;
        readonly city: string | null;
        readonly countries: [
            { readonly id: string }
        ];
        readonly logo: { [key: string]: any } | null;
        readonly name: string;
        readonly rankingPlace: string | null;
        readonly type: string;
        readonly www: string | null;
    };
    readonly www: string | null;
    readonly listType: FieldOfStudyListType | null;
    readonly mode: string;
    readonly duration: {
        readonly length: string;
        readonly unit: string;
    };
    readonly tuitionFees: {
        readonly tuitionFees: ReadonlyArray<ITuitionFee>;
    };
    readonly markedToRemove?: boolean;
    readonly levels: {
        readonly levels: {
            readonly '@id': string;
            readonly name: string;
        }[];
    },
}

export interface ITuitionFee {
    readonly amount: string;
    readonly currency: string;
    readonly period: string;
    readonly region: string;
}

export interface IFieldOfStudyProfile extends IFieldOfStudy {
    readonly deadlines: [{
        readonly date: string;
        readonly type: string;
    }];
    readonly languageRequirements: {
        readonly ielts: string;
        readonly pte: string;
        readonly toeflInternet: string;
        readonly toeflPaper: string;
    };
    readonly requirements: {
        readonly html: string;
        readonly text: string;
    };
    readonly studyStartDate: string;
    readonly position: { readonly lat: number, readonly lng: number } | null;
}

export type ChangeListTypePayload = {
    readonly schoolStudyFieldsId: string,
    readonly listType: FieldOfStudyListType
}

export const getTuitionFee = (fieldOfStudy?: IFieldOfStudy|null): ITuitionFee|null => {
    let tuitionFee: ITuitionFee|null = null;
    fieldOfStudy?.tuitionFees?.tuitionFees?.forEach(candidate => {
        if (candidate.region === 'eea') {
            tuitionFee = candidate;
        } else if (null === tuitionFee && candidate.region === 'international') {
            tuitionFee = candidate;
        }
    });

    return tuitionFee;
};

export const getTuitionFeesPrice = (fieldOfStudy?: IFieldOfStudy|null): typeof IPrice => {
    return getTuitionFeePrice(getTuitionFee(fieldOfStudy));
};

export const getTuitionFeePrice = (tuitionFee?: ITuitionFee|null): typeof IPrice => {
    if (isNullOrUndefined(tuitionFee)) {
        return null;
    }

    return {
        amount: parseInt((tuitionFee as ITuitionFee).amount) * 100,
        currency: {
            code: (tuitionFee as ITuitionFee).currency,
        }
    };
};
