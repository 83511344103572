import {
    collectionInputDataMapper,
    collectionOutputDataMapper,
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    MultiSelectType
} from 'educat-common-web';


export const sortFormConfig = (options: typeof IMultiselectOption[] = []): typeof IFormConfig => ({
    class: 'sort-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'sort_by',
            class: 'row',
            controls: {
                sort: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    multiselectOptions: options,
                    validationRules: [],
                    placeholder: 'sortingForm.form.placeholder',
                    label: 'sortingForm.form.label',
                    hostClass: 'sorting-control',
                    inputDataMapper: collectionInputDataMapper,
                    outputDataMapper: collectionOutputDataMapper,
                }
            }
        }
    ]
});
