import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams, IModelCart} from 'educat-common-web';


export function updateCartItemExtraDataAPI(
    authToken: string,
    itemId: string,
    mentorServiceExtraData: any
): Observable<typeof IModelCart> {
    return educatAPI.put(
        `service_instances/${itemId}`,
        {
            mentorServiceExtraData,
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
